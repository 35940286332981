import {Color} from "./interface/Color"

export const Users: React.FC<Color> = ({
    color
  }) => {
    return(
        <svg width="19.997" height="19.997" viewBox="0 0 19.997 19.997">
            <path 
                id="Caminho_8" 
                data-name="Caminho 8" 
                d="M14,14A5,5,0,1,0,9,9,5,5,0,0,0,14,14Zm0,2.5c-3.337,0-10,1.675-10,5V24H24V21.5C24,18.173,17.335,16.5,14,16.5Z" 
                transform="translate(-4 -4)" 
                fill={color}/>
        </svg>
    )
  }