import { Grid } from "@mui/material";

interface IDashboardBorder {
    children: React.ReactNode;
}

export const DashboardBorder: React.FC<IDashboardBorder> = ({
    children
}) => {
    return (
        <Grid
            container
            border="1px solid #B5B5B5"
            borderRadius="8px"
            paddingX="40px"
            paddingY="31px"
        >   
            { children }
        </Grid>
    )
}