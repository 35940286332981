import React, { useEffect, useReducer, useState } from "react";
import { MessageBarType, TextField } from "@fluentui/react";
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {
GetValidateCode,PutNewPassword
} from "../../../services/IdentitiServices";
import { PutPassword } from "../../../model/IdentitiModel";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Tooltip, Typography } from '@mui/material'
import { RealTimePasswordCheck } from "./components/RealTimePasswordCheck/RealTimePasswordCheck";
import { reducer } from "./components/RealTimePasswordCheck/Reducer/PasswordReducer";
import { initialState } from "./components/RealTimePasswordCheck/InitialState/initialState";
import AlertComponent from "../../../components/Alert/AlertComponent";
import FormButton from "../../PublicLayout/components/Handle/Form/Root/components/FormButton";
import BtnBigWithIcon,{ ButtonTyppe} from "../../../components/Button/BtnBigWithIcon/BtnBigWithIcon";
import { CenterForm } from "../../PublicLayout/components/Handle/CenterForm";
import keyPasswordIcon from "../../../assets//icons/keyPassword-icon.svg"
import { ArrowIcon } from "../../../assets/icons/arrowIcon";
import InfoIcon from "../../../assets/icons/infoIcon.svg"
import { LoaderIcon } from "../../../assets/icons/loaderIcon";
import { PasswordTextFieldWithReveal } from "../../PublicLayout/components/Handle/Form/Components/PasswordTextFieldWithReveal";
import { ArrowLeftIcon } from "../../../assets/icons/ArrowLeftIcon";
import { checkIcon } from "../../../assets/icons/checkIcon";

const NewUserPassword = (props: any) => {

    const [searchParams] = useSearchParams();
    const [isChecked, setCheckedState] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [password, setPassword] = useState<string>("");
    const [load, setLoad] = useState<boolean>(false);

    const [messagePass, setMessagePass] = useState<string>("");
    const [typeMessagePass, setTypeMessagePass] = useState<MessageBarType>(MessageBarType.error);

    const [passwordConfirm, setPasswordConfirm] = useState<string>("");
    const [passwordBorderColor, setPasswordBorderColor] = useState<string>("#D11422");
    const [disable, setDisable] = useState<boolean>(true);
    const codPassword = searchParams.get('code');

    const [hidenPassword, setHidenPassword] = useState<boolean>(false);
    const [hidenGoLOgin, setHidenGoLOgin] = useState<boolean>(true);
    const { t, i18n } = useTranslation();
    const navegate = useNavigate();

    

    const [state, dispatch] = useReducer(reducer, initialState);


    const onChangePassword =
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined ) {
            setPassword(newValue);
        }
        verifyPasswordConditions()
    }; 

   

    const onChangePasswordConfirm =
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined ) {
            setPasswordConfirm(newValue);
        }
    };

    function verifyPasswordConditions(): boolean {
        if (state.capitalCharacter.error ||
            state.minimunCharacters.error ||
            state.numberCharacter.error ||
            state.specialCharacter.error ) {
            return false;
        }
            return true;
    }

    const sendNewPassWord = () => {
        setCheckedState(false);
        setLoad(true);
        if(codPassword != null)
        {
            let bcrypt = require('bcryptjs');
            let salt = bcrypt.genSaltSync(10);

            if(password == passwordConfirm)
            {
                let hashPassword = bcrypt.hashSync(password, salt);
                let putPassword:PutPassword={
                    code:codPassword,
                    newPassword:hashPassword
                };
                
                if (!verifyPasswordConditions()) {
                    setCheckedState(true);
                    setMessagePass(t("AlgoErradoAconteceu.message"));
                    setLoad(false);
                    return 
                }

                PutNewPassword(putPassword)
                .then(()=>{
                    setTypeMessagePass(MessageBarType.success)
                    setIsSuccess(true);
                    setCheckedState(true);
                    setMessagePass(t("AlteradoComSucesso.message"));
                    setDisable(true);
                    
                    setHidenPassword(true);
                    setHidenGoLOgin(false);

                    navegate('/resetPasswordSuccess');
                })
                .catch(()=>{
                    setCheckedState(true);
                    setMessagePass(t("AlgoErradoAconteceu.message"));
                })
                .finally(() => {
                    setLoad(false);
                })
            }
            else
            {
                setCheckedState(true);
                setLoad(false);
                setMessagePass(t("SenhasNaoBatem.message"));
            }
        }
    };

    useEffect(() => {
        if(codPassword != null)
        {
            GetValidateCode(codPassword).then(()=>{
                setDisable(false);
            }).catch(()=>{
                setCheckedState(true);
                setMessagePass(t("CodigoNaoEncontrado.message"));
                setHidenPassword(true);
                setHidenGoLOgin(false);
            });
        }
        else
        {
            setCheckedState(true);
            setMessagePass(t("SemCodigo.message"));
            setHidenPassword(true);
            setHidenGoLOgin(false);
        }
    }, []);

    const login = (event: any) => {
        navegate("/login");
      };

    useEffect(() => {

        if (!verifyPasswordConditions()) {
            setPasswordBorderColor('#D11422')
        } else {
            setPasswordBorderColor('#BFBFBF')
        }

    }, [password, verifyPasswordConditions])

    return (
        <CenterForm 
        title={t("Login.putYourNewPass")} 
        subtitle={t("Login.fillTheFieldsDown")}
        icon={keyPasswordIcon} 
        titleSize={32}
        >
            <Grid container sx={{
                    gap: 3
                }}>
                <Grid item xs={12}>
                    <Box sx={{ position: 'relative' }}>
                        <PasswordTextFieldWithReveal
                            label={t("Senha.newPassword")}
                            placeholder={t("Senha.typeYourPassword")}
                            onChange={onChangePassword}
                            value={password}
                            passwordBorderColor={passwordBorderColor}
                            disable={disable}
                            right={12}
                            bottom={4}
                            setCheckedState={setCheckedState}
                        />
                        
                        <Tooltip sx={{
                            font:' normal normal normal 16px/24px Segoe UI',
                            color: '#FFFFFF',
                            height:'45px'
                        }} title={t("Senha.newPasswordRequirements")} placement="right-start" arrow slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -8],
                                        },
                                    },
                                ],
                            },
                        }}>
                        <Box component={'img'} src={InfoIcon}
                            sx={{
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                marginLeft: i18n.language == 'pt-BR' ? '80px' : '95px',
                                marginTop: '8px',
                                cursor: 'pointer',

                                '&:hover': {
                                    opacity: 1
                                }
                            }}
                        />
                        </Tooltip>
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{
                }}>
                    <Box sx={{ position: 'relative' }}>
                        <PasswordTextFieldWithReveal 
                        label={t("ConfirmarSenha.message")}
                        placeholder={t("Senha.typeYourPasswordAgain")} 
                        onChange={onChangePasswordConfirm} 
                        value={passwordConfirm}
                        passwordBorderColor={passwordBorderColor}
                        disable={disable}
                        right={12}
                        bottom={4}
                        setCheckedState={setCheckedState}
                        />
                    </Box>
                </Grid>
                { isChecked ? (
                    <Grid item xs={12} sx={{marginBottom: '10px'}}>
                        <AlertComponent 
                            text={messagePass}
                            type={isSuccess ? "success" : "error"}
                        />
                    </Grid>
                ) : (
                    <RealTimePasswordCheck 
                        password={password}
                        passwordConfirm={passwordConfirm}
                        state={state}
                        dispatch={dispatch}
                    />
                ) }
            </Grid>
            <FormButton 
                load={false}
                btnBigWithIconConfig={{
                    text:t("ConfirmarSenha.message"),
                    onClick:sendNewPassWord,
                    icon: load == true ? LoaderIcon : checkIcon,
                    disabled: password.length > 0 && passwordConfirm.length > 0 ? false : true,
                }}
            />
            <BtnBigWithIcon 
                isPrimary={ButtonTyppe.secondary}
                text={t("IrParaLogin.message")}
                onClick={login}
                isTextButton
                icon={ArrowLeftIcon}
                iconGap={'10px'}
            />
        </CenterForm>
    );
}

export default NewUserPassword;