import {Color} from "./interface/Color"

export const DocumentType: React.FC<Color> = ({
    color
  }) => {
    return(
        <svg width="22.5" height="18" viewBox="0 0 22.5 18">
            <path 
                id="Caminho_23" 
                data-name="Caminho 23" 
                d="M11,4H4.25A2.247,2.247,0,0,0,2.011,6.25L2,19.75A2.257,2.257,0,0,0,4.25,22h18a2.257,2.257,0,0,0,2.25-2.25V8.5a2.257,2.257,0,0,0-2.25-2.25h-9Z" 
                transform="translate(-2 -4)" 
                fill={color}/>
        </svg>

    )
  }