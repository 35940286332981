import React, {useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import {
  ITag,
  TagPicker,
} from '@fluentui/react/lib/Pickers';
import { DatePicker} from "@fluentui/react";
import LoaderComp from '../../../../components/Loader/LoaderComp';
import PanelGenericText from "../../../../components/PanelGenericText/PanelGenericText"; 
import { ButonSendBone } from "../../../../components/Button/ButonSendBone";
import {standartStyle} from "./Style/TagPickerStyle"
import { User } from "../../../../model/OrganizationModel";
import { formatDate } from "../../../../functions/dateFormater";
import moment from "moment";
import { ItemToValidationRuleVOConditions } from "../../../../model/ValidationRuleModel";
import MuiAlert from '@mui/material/Alert';

export interface HandleValidationProps{
    validationRuleChoiceResponsabilityHide:boolean;
    validationRuleTermHide:boolean;
    waitLoader:boolean;
    errorExternal?: string | undefined;
    filterAlowed: string;
    getUser:(itemId:string)=> Promise<User[]>;
    getUserFiltred:(itemId:string, filter:string)=> Promise<User[]>;
    setConditions:(conditions:ItemToValidationRuleVOConditions | undefined) => void;
}


const HandleValidation: React.ForwardRefExoticComponent<HandleValidationProps> = React.forwardRef(
    ({ validationRuleChoiceResponsabilityHide,
      validationRuleTermHide,
      waitLoader,
      errorExternal,
      filterAlowed,
      getUserFiltred,
      getUser,
      setConditions,
    }, ref: React.Ref<HTMLLIElement>) => {

  const { t } = useTranslation();
  const [validateContract, setValidateContract] = useState<Date | undefined>();
  const [itensTagUser, setItensTagUser] = useState<ITag[] | undefined>([]);
  const [erroMessage, setErroMessage] = useState<string | undefined>(undefined);

  const onFormatDate = (date?: Date): string => {
    return !date ? '' : date.getDate() + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear() % 100);
  };

  const getTextFromItem = (props: ITag): string => {
    return props.name;
  };

  const onResolveSuggestions = async (
    filter: string,
    selectedItems: ITag[] | undefined
  ): Promise<ITag[]> => {
    if (filter) {
      return getUserFiltred(filterAlowed, filter)
      .then((users) => {
        const userTags: ITag[] = users.map(user => {
          return { key: user.id!, name: user.name }
        })
        return userTags;
      })
      .catch(() =>{
        return [];
      });
    }
    return [];
  };

  useEffect(() => {
    if(errorExternal != undefined)
    {
      setErroMessage(errorExternal);
    }
  }, [errorExternal])

  useEffect(() => {
    if (validateContract !== undefined) {
      const formatedInputDate = formatDate(validateContract)
      const formatedActualDate = formatDate(new Date())

      if (moment(formatedInputDate).isBefore(formatedActualDate)) {
        setValidateContract(new Date())
      }
    }
  }, [validateContract])

  const filterPromise = async (
    selectedItems: ITag[] | undefined
  ): Promise<ITag[]> => {
    return getUser(filterAlowed)
    .then((users) => {
      const userTags: ITag[] = users.map(user => ({ key: user.id, name: user.name }))
      return userTags;
    })
    .catch(() =>{
      return [];
    });
  };

  const onParseDateFromString = React.useCallback(
    (newValue: string): Date => {
      const previousValue = validateContract || new Date();
      const newValueParts = (newValue || '').trim().split('/');
      const day =
        newValueParts.length > 0 ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10))) : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year = newValueParts.length > 2 ? parseInt(newValueParts[2], 10) : previousValue.getFullYear();
      if (year < 100) {
        year += previousValue.getFullYear() - (previousValue.getFullYear() % 100);
      }
      return new Date(year, month, day);
    },
    [validateContract],
  );

  function setConditionsList()
  { 
    if(validationRuleChoiceResponsabilityHide == false && (itensTagUser == undefined || itensTagUser.length == 0))
    {
      setErroMessage(t("validationRulePanel.responsableError") );
      return;
    }
    else if(validationRuleTermHide == false && validateContract == undefined)
    {
      setErroMessage(t("validationRulePanel.termError") );
      return;
    }

    setErroMessage(undefined);

    let filtered:string[] = [];
    itensTagUser!.map(x => {
      if(x.key != undefined)
      {
        filtered.push(x.key!.toString()!);
      }
    });

    let newCondition:ItemToValidationRuleVOConditions = {};

    if(validationRuleChoiceResponsabilityHide == false){
      newCondition.userIdLIst= filtered;
    }
    
    if(validationRuleTermHide == false){
      newCondition.timeDeadline = validateContract?.toDateString();
    }
    
    setConditions(newCondition);
  }

  return (
    <div>
      <PanelGenericText
        text={
          (validationRuleChoiceResponsabilityHide && validationRuleTermHide) ? 
          t("validationRulePanel.alreadySetConfig") :
          t("validationRulePanel.execution")}
        paragraph={(validationRuleChoiceResponsabilityHide && validationRuleTermHide) ? 
          t("validationRulePanel.alreadySetValidation") :
          t("validationRulePanel.selectUser")}
        style={{
          marginTop: '31px',
          marginBottom: '31px'
        }}
      />
      
            <div>
              {(validationRuleTermHide == false) && (
                <div
                  style={{marginBottom:"15px"}}
                >
                  <label>{t("Term.message")}</label>
                  <DatePicker
                    placeholder="dd/mm/yyyy" 
                    formatDate={onFormatDate}
                    value={validateContract}             
                    parseDateFromString={onParseDateFromString}
                    onSelectDate={setValidateContract as (date: Date | null | undefined) => void}
                    disabled={waitLoader}
                  />
                </div>
              )}

              { (validationRuleChoiceResponsabilityHide == false) && (
                <div
                  style={{marginBottom:"15px"}}
                >
                  <label>{t("Responsible.message")}</label>
                  <TagPicker
                    removeButtonAriaLabel="Remove"
                    onResolveSuggestions={onResolveSuggestions}
                    getTextFromItem={getTextFromItem}
                    selectionRole="group"
                    onEmptyInputFocus={filterPromise}
                    onChange={setItensTagUser}
                    disabled={waitLoader}
                    pickerCalloutProps={{
                      styles: {
                        calloutMain: {
                          border: '1px solid #989898',
                          borderRadius: "8px"
                        },
                      },
                      style: {
                        top: "0px"
                      }
                    }}
                    styles={standartStyle}
                  />
                </div>
              )}
              
             {erroMessage != undefined &&(
              <MuiAlert elevation={6} variant="filled" sx={{ width: '90%', margin:"5%" }} severity="error">
                {erroMessage}
              </MuiAlert>
             )}

            { waitLoader ? (
              <LoaderComp />
            ) : (
                <ButonSendBone
                  text={t("validationRulePanel.sendToResponsible")}
                  waitLoader={waitLoader!}
                  onClick={setConditionsList}
                />
              
          )}
        </div>
    </div>
  )
} );


export default HandleValidation;