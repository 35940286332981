import React, { ComponentType, useEffect, useState } from "react";
import { WorkSpaceUserName } from '../../../../model/ItemModel';
import {
    ForwardItemVO,
    ItemToValidationRuleVOConditions,
    BackItemVO,
    PreconditionActivity,
    ValidationInformation
  } from "../../../../model/ValidationRuleModel";
import { useTranslation } from "react-i18next";
import PreConditionPannel from "../../../ValidationRule/ValidationRuleList/ValidationRuleChoice/PreConditionPannel";
import { useBoolean } from "@fluentui/react-hooks";
import { User } from "../../../../model/OrganizationModel";
import SignMain from "../../Sign/SignMain";
import { AlertColor } from "@mui/material";

export interface  BackAndFowardInterface {
    actionForwadOrBackWait?: boolean;
    sendToBack: () => void;
    sendToForwardItem: () => void;
    forwardOperation?: string;
    backOperation?: string;
}

export interface BackAndFowardComponentProps {
    FbComponent: ComponentType<BackAndFowardInterface>;
    workspaceItem: WorkSpaceUserName;
    indexOfArray: number;
    userFilter: string;
    signer_email:string,
    signer_display_name:string,
    isAccept?: boolean;

    setPopupError?: (set: boolean, message: string) => void;
    setPopupOk: (set: boolean, message: string) => void;
    setWorkspace: ((workSpaceUserName: WorkSpaceUserName, index: number, isUpload: boolean) => void);
    isUpload: boolean;
    userPromisse(filterMain: string): Promise<User[]>
    userPromisseWords(filterMain: string, words: string): Promise<User[]>
    validationPreconditionBackItem(id: string): Promise<PreconditionActivity>,
    backItem(backItemVO:BackItemVO): Promise<ValidationInformation>;
    forwardItem(forwardItemVO:ForwardItemVO ): Promise<ValidationInformation>;
    validationPreconditionFoward(id: string): Promise<PreconditionActivity>;
  }

interface BackAnfFowarInternal {
  isBackItem: boolean,
  conditions: ItemToValidationRuleVOConditions| undefined,
  newPendece: ForwardItemVO | undefined
}

const BackAndFowardComponent: React.ForwardRefExoticComponent<BackAndFowardComponentProps> = React.forwardRef(({
    FbComponent,
    setPopupOk,
    setWorkspace,
    isUpload,
    indexOfArray,
    setPopupError,
    workspaceItem,
    userFilter,
    userPromisse,
    userPromisseWords,
    signer_email,
    signer_display_name,
    validationPreconditionBackItem,
    backItem,
    validationPreconditionFoward,
    forwardItem,
    isAccept
} , ref) => {

    const [backAnfFowarInternal, setBackAnfFowarInternal] = useState<BackAnfFowarInternal>({
      conditions : undefined,
      isBackItem: false,
      newPendece: undefined
    });
    
    const [actionForwadOrBackWait, setActionForwadOrBackWait] = useState<boolean>(false);

    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [isOpenResp, { setTrue: openPanelResp, setFalse: dismissPanelResp }] = useBoolean(false);

    const [concludedStep, setConcludedStep] = useState<boolean>(false);
    const [isHideModelD4Sign, setIsHideModelD4Sign] = useState<boolean>(false);

    const [validationRuleChoiceResponsabilityHide, setValidationRuleChoiceResponsabilityHide] = useState<boolean>(true);
    const [validationRuleTermHide, setValidationRuleTermHide] = useState<boolean>(true);

    const [workSpaceUserNameResponse, setWorkSpaceUserNameResponse] = useState<WorkSpaceUserName | undefined>(undefined);

    function dismissPanelInternal()
    {
      if(workSpaceUserNameResponse != undefined)
      {
        setWorkspace(workSpaceUserNameResponse, indexOfArray, isUpload);
      }
      dismissPanelResp();
    }

    useEffect(() => {
        if (backAnfFowarInternal.isBackItem == true) {
          if (backAnfFowarInternal.newPendece == undefined && backAnfFowarInternal.conditions != undefined) 
          {
            setBackAnfFowarInternal({
              ...backAnfFowarInternal,
              conditions: undefined
            })
          } else if (backAnfFowarInternal.newPendece != undefined && backAnfFowarInternal.conditions == undefined) 
          {
            let pendenci = backAnfFowarInternal.newPendece;
            pendenci.conditions = undefined;
            sendToBackVerifyItemInternal(pendenci);
          } else if (backAnfFowarInternal.newPendece != undefined && backAnfFowarInternal.conditions != undefined) 
          {
            let pendenci = backAnfFowarInternal.newPendece;
            pendenci.conditions = backAnfFowarInternal.conditions;
            sendToBackItemInternal(pendenci);
          }
        }
        else {
          if (backAnfFowarInternal.newPendece == undefined && backAnfFowarInternal.conditions != undefined) 
          {
            setBackAnfFowarInternal({
              ...backAnfFowarInternal,
              conditions: undefined
            })
          } else if (backAnfFowarInternal.newPendece != undefined && backAnfFowarInternal.conditions == undefined) 
          {
            let pendenci = backAnfFowarInternal.newPendece;
            pendenci.conditions = undefined;
            sendToForwardVerifyItemInternal(pendenci);
          } else if (backAnfFowarInternal.newPendece != undefined && backAnfFowarInternal.conditions != undefined) 
          {
            let pendenci = backAnfFowarInternal.newPendece;
            pendenci.conditions = backAnfFowarInternal.conditions;
            ForwardItemInternal(pendenci);
          }
        }

    }, [backAnfFowarInternal]);

      const sendToBack = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
        if (ev) ev.preventDefault();
        if (workspaceItem?.id) {
          let backItemVO: BackItemVO = {
            itemId: isAccept ? userFilter : workspaceItem?.id,
          };
          setBackAnfFowarInternal({
            conditions : undefined,
            isBackItem:true,
            newPendece: backItemVO
          })
        }
      };

      function sendToBackVerifyItemInternal(backItemVO: BackItemVO) {
        setActionForwadOrBackWait(true);
        validationPreconditionBackItem(isAccept ? userFilter : workspaceItem?.id!)
          .then((preconditionReturn) => {
            if ((preconditionReturn.dinamicUserChoice || preconditionReturn.timeDeadline) && !preconditionReturn.end) {

              if (preconditionReturn.dinamicUserChoice == true) {
                setValidationRuleChoiceResponsabilityHide(false);
              }
              else {
                setValidationRuleChoiceResponsabilityHide(true);
              }

              if (preconditionReturn.timeDeadline == true) {
                setValidationRuleTermHide(false)
              }
              else {
                setValidationRuleTermHide(true)
              }
              
              if (!preconditionReturn?.end) {
                openPanelResp()
              }
            }
            else {
              sendToBackItemInternal(backItemVO)
            }
          })
          .catch((error) => {
            setErrorMessage(error.data)
          })
          .finally(() => {
            setActionForwadOrBackWait(false)
          })
      }

      const sendToBackItemInternal = (backItemVO: BackItemVO) => {
        if (backItemVO) {
          setActionForwadOrBackWait(true);
          backItem(backItemVO)
            .then((itemReturn) => {
              if(itemReturn.itemReturnVOName.validationRule != undefined)
              {
                finalyActivity(itemReturn.itemReturnVOName)
              }
              else
              {
                setPopupOk(true, "Ok")
                setWorkspace(itemReturn.itemReturnVOName, indexOfArray, isUpload);
              }
            })
            .catch((error) => {
              if (setPopupError != undefined) {
                if (error.response && error.response.data && typeof error.response.data === 'string') {
                  setPopupError(true, error.response.data);
                }
                else {
                  setPopupError(true, t("ValidationRule.error"));
                }
              }
            }).finally(() => {
              setActionForwadOrBackWait(false);
            });
        }
      };

      const sendToForwardItem = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
        if (workspaceItem?.id) {
          let fowardItemVO: ForwardItemVO = {
            itemId: isAccept ? userFilter : workspaceItem?.id,
          };
          setBackAnfFowarInternal({
            conditions : undefined,
            isBackItem: false,
            newPendece: fowardItemVO
          })
        }
      };

      const sendToForwardVerifyItemInternal = (forwardItemVO: ForwardItemVO) => {
        if (workspaceItem?.id) {
          setActionForwadOrBackWait(true);
          validationPreconditionFoward(isAccept ? userFilter : workspaceItem?.id)
            .then((preconditionReturn) => {
              if (preconditionReturn.dinamicUserChoice || preconditionReturn.timeDeadline) {
                if (preconditionReturn.dinamicUserChoice == true) {
                  setValidationRuleChoiceResponsabilityHide(false);
                }
                else {
                  setValidationRuleChoiceResponsabilityHide(true);
                }

                if (preconditionReturn.timeDeadline == true) {
                  setValidationRuleTermHide(false)
                }
                else {
                  setValidationRuleTermHide(true)
                }
                openPanelResp();

              } else {
                if (workspaceItem.d4SignStatus == undefined) {
                  ForwardItemInternal(forwardItemVO);
                } else {
                  setIsHideModelD4Sign(false);
                }
              }
            })
            .catch((error) => {
              setErrorMessage(error.data)
            })
            .finally(() => {
              setActionForwadOrBackWait(false);
            })
        }
      };

      function ForwardItemInternal(forwardItemVO: ForwardItemVO) {
        setActionForwadOrBackWait(true)
        forwardItem(isAccept ? forwardItemVO : forwardItemVO)
          .then((itemReturn) => {
            console.log(itemReturn)
            setIsHideModelD4Sign(true);
            if (itemReturn.target.isEnded) {
              setPopupOk(true, t("FinalizadoComSucesso.message"));
            } else {
              setPopupOk(true, t("AlteradoComSucesso.message"));
            }
            setWorkspace(itemReturn.itemReturnVOName, indexOfArray, isUpload);
          })
          .catch((error) => {
            setValidationRuleChoiceResponsabilityHide(true);
            setValidationRuleTermHide(true);
            setIsHideModelD4Sign(true);
            if (setPopupError != undefined) {
              if (
                error.response &&
                error.response.data &&
                typeof error.response.data === "string"
              ) {
                setPopupError(true, error.response.data);
              } else {
                setPopupError(true, t("ValidationRule.error"));
              }
            }
          }).finally(() => {
            setActionForwadOrBackWait(false)
            setConcludedStep(true)
          });
      }

      function getUser() {
        return new Promise<User[]>((resolve, reject) => {
          userPromisse(userFilter)
            .then((result) => {
              resolve(result);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }

      function getUserFiltred(filter: string) {
        return new Promise<User[]>((resolve, reject) => {
          userPromisseWords(userFilter, filter)
            .then((result) => {
              resolve(result);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }

    function setCondition(conditions: ItemToValidationRuleVOConditions | undefined) {
        setValidationRuleChoiceResponsabilityHide(true);
        setValidationRuleTermHide(true);

        setBackAnfFowarInternal({
          ...backAnfFowarInternal,
          conditions: conditions
        })
    }

    function setPopupOkRefresh(
      set: boolean,
      message: string,
      item: WorkSpaceUserName,
      severity?: AlertColor
    ) {
      setWorkspace(item, indexOfArray, isUpload);
      setPopupOk(set, message);
    }

    function finalyActivity(workSpaceUserName : WorkSpaceUserName)
    {
      setConcludedStep(true);
      setWorkSpaceUserNameResponse(workSpaceUserName);
      openPanelResp();
    }

    return (
      <>
        <FbComponent
          sendToForwardItem={sendToForwardItem}
          sendToBack={sendToBack}
          actionForwadOrBackWait={actionForwadOrBackWait}
          backOperation={workspaceItem.validationRule?.backOperation}
          forwardOperation={workspaceItem.validationRule?.forwardOperation}
        />

        {isOpenResp && (
          <PreConditionPannel
            validationRuleChoiceResponsabilityHide={validationRuleChoiceResponsabilityHide}
            validationRuleTermHide={validationRuleTermHide}
            setConditions={setCondition}
            getUser={getUser}
            getUserFiltred={getUserFiltred}
            dismissPanel={dismissPanelInternal}
            isOpen={isOpenResp}
            conclusion={concludedStep}
            errorMessage={errorMessage}
            waitLoader={actionForwadOrBackWait}
          />
        )}

       {(backAnfFowarInternal.newPendece != undefined && workspaceItem.d4SignStatus != undefined && workspaceItem.d4SignStatus.uuid != undefined && signer_email && signer_display_name) && (
          <SignMain
            signMainInfo={{
              fileKey: workspaceItem.d4SignStatus.uuid,
              signer_email: signer_email,
              signer_display_name: signer_display_name
            }}
            setPopupOkRefresh={setPopupOkRefresh}
            forwardItemVO={backAnfFowarInternal.newPendece}
            hideDialog={isHideModelD4Sign}
            setHide={setIsHideModelD4Sign}
            forwardItem={forwardItem}
          />
        )}
      </>
    )
})

export default BackAndFowardComponent;