import { Box, Grid } from "@mui/material"
import { Typography } from "@mui/material"
import { UserTypes } from "./types/userType"
import BtnBigWithIcon, {ButtonTyppe, imageStyle} from "../../components/Button/BtnBigWithIcon/BtnBigWithIcon";
import addIcon from '../../assets/icons/add-icon.svg';
import informationIcon from '../../assets/icons/info-icon.svg';
import { Tooltip } from "@mui/material";
import { WizardUserCard } from "./components/WizardUserCard";
import {AddIcon} from "../../assets/icons/addIcon"
import { useTranslation } from 'react-i18next'

interface IWizardInformations {
  organization: string
  workspace: string
  password: string
  cpassword: string
  emailOne: string
  emails: UserTypes[],
  gotToChangeEmails: () => void,
  handleDelete: (index: number) => void
}

export const WizardInformations: React.FC<IWizardInformations> = ({
  organization,
  workspace,
  emails,
  gotToChangeEmails,
  handleDelete
}) => {
  const { t } = useTranslation();

  return (
    
    <>
    <Grid item xs={12} sx={{ display: 'flex', margin: '5px 0px 5px 0px' }}>
        <Typography
          variant='subtitle1'
        >
          <Typography fontWeight={400}>{t("Wizard.descriptionEnd")}
            <b>{t("Wizard.descriptionEndBold")}</b>{t("Wizard.descriptionEndSecond")}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ display: 'flex', margin: '5px 0px 5px 0px' }}>
        <Typography
          variant='subtitle1'
        >
          <Typography fontWeight={600}>{t("Wizard.organization")}: </Typography>{ organization }
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ display: 'flex', marginBottom: '5px' }}>
        <Typography
          variant='subtitle1'
        >
          <Typography fontWeight={600}>Workspace: </Typography>{ workspace }
        </Typography>
      </Grid>

      <div style={{
        height: "2px",
        width: "calc(100% - 16px)",
        backgroundColor: "#e9e9e9",
        marginLeft:"16px"
      }}></div>

      <Grid item xs={8} sx={{ display: 'flex', margin: '5px 0px 5px 0px'}}>
        <div
          style={{
            margin:"auto",
            width:"100%",
            display: 'flex',
          }}
        >
          <Typography fontWeight={600}>{t("Usuarios.message")} </Typography>
          <Tooltip title={t("Wizard.emailInfo")} disableInteractive placement="right">
              <img src={informationIcon} className={imageStyle.img}/>
          </Tooltip >
        </div>
      </Grid>

      <Grid item xs={4} sx={{ display: 'flex', marginBottom: '5px' }}>
        <BtnBigWithIcon 
          isPrimary={ButtonTyppe.secondary}
          icon={AddIcon}
          onClick={gotToChangeEmails}
          text={t("Wizard.addUsers")}
          disabled={emails.length >= 5}
        />
      </Grid>

      {emails.length > 0 &&(
         <Grid item xs={12}>
         { emails.map(({ name, email }, index) => (
                 <WizardUserCard 
                     email={email}
                     name={name}
                     handleDelete={handleDelete}
                     index={index}
                     key={index}
                 />
             ))
         }
     </Grid>
      )}
    </>
  )
}