import { IComboBox, IComboBoxOption } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ComboBoxComp from "../../../../components/ComboBox/ComboBoxComp";
import {
    GetEnumValidationBackgroundColors,
    GetEnumValidationConvertionType,
    GetEnumValidationOrientation,
    GetEnumValidationPalette,
    GetEnumValidationSize,
} from "../../../../services/Item/ItemServicesGet"
import styles from "./FileConfigStyle.module.css"
import HeaderViewerComp from "../../../../components/Viewer/Header/HeaderViewerComp";
import Btn from "../../../../components/Button/Btn";
import { FileConfigProps } from "./FileConfigProps";
import { Shimmer} from '@fluentui/react';
import { Alert } from "@mui/material";

const FileDwg = (props: FileConfigProps) => {
    const [optionsConvertionType, setOptionsConvertionType] = useState<IComboBoxOption[]>([]);
    const [waitOptionsConvertionType, setWaitOptionsConvertionType] = useState<boolean>(false);

    const [optionsSize, setOptionsSize] = useState<IComboBoxOption[] >([]);
    const [waitOptionsSize, setWaitOptionsSize] = useState<boolean>(false);

    const [optionsPalette, setOptionsPalette] = useState<IComboBoxOption[] >([]);
    const [waitOptionsPalette, setwaitOptionsPalette] = useState<boolean>(false);

    const [optionsOrientation, setOptionsOrientation] = useState<IComboBoxOption[] >([]);
    const [waitOptionsOrientation, setwaitOptionsOrientation] = useState<boolean>(false);

    const [optionsBackgroundColors, setOptionsBackgroundColors] = useState<IComboBoxOption[] >([]);
    const [waitOptionsBackgroundColors, setwaitOptionsBackgroundColors] = useState<boolean>(false);

    const [errorInGet, setErrorInGet] = useState<boolean>(false);

    const [convertionType, setConvertionType] = useState<string | undefined>(undefined);    
    const [size, setSize] = useState<string | undefined>(undefined);
    const [palette, setPalette] = useState<string | undefined>(undefined);
    const [orientation, setOrientation] = useState<string | undefined>(undefined);
    const [backgroundColors, setBackgroundColors] = useState<string | undefined>(undefined); 

    const { t, i18n } = useTranslation();

    useEffect(() => {
        setWaitOptionsConvertionType(true);
        GetEnumValidationConvertionType()
            .then((result) => {
                let options: IComboBoxOption[] = [];
                options.push({ key: "SE", text: t("Select.message") });
                result.map((response) => {
                    options.push({ key: response.index, text: response.name });
                })

                setOptionsConvertionType(options)
            })
            .catch((error) => {
                setErrorInGet(true);
            })
            .finally(() =>{
                setWaitOptionsConvertionType(false);
            });

        setWaitOptionsSize(true);
        GetEnumValidationSize()
            .then((result) => {
                let options: IComboBoxOption[] = [];
                options.push({ key: "SE", text: t("Select.message") });
                result.map((response) => {
                    options.push({ key: response.index, text: response.name });
                })

                setOptionsSize(options)
            })
            .catch((error) => {
                setErrorInGet(true);
            })
            .finally(() =>{
                setWaitOptionsSize(false);
            });

        setwaitOptionsPalette(true);
        GetEnumValidationPalette()
            .then((result) => {
                let options: IComboBoxOption[] = [];
                options.push({ key: "SE", text: t("Select.message") });
                result.map((response) => {
                    options.push({ key: response.index, text: response.name });
                })
                setOptionsPalette(options);
            })
            .catch((error) => {
                setErrorInGet(true);
            })
            .finally(() =>{
                setwaitOptionsPalette(false);
            });

        setwaitOptionsOrientation(true);
        GetEnumValidationOrientation()
            .then((result) => {
                let options: IComboBoxOption[] = [];
                options.push({ key: "SE", text: t("Select.message") });
                result.map((response) => {
                    options.push({ key: response.index, text: response.name });
                })
                setOptionsOrientation(options)
            })
            .catch((error) => {
                setErrorInGet(true);
            })
            .finally(() =>{
                setwaitOptionsOrientation(false);
            });

        setwaitOptionsBackgroundColors(true);
        GetEnumValidationBackgroundColors()
            .then((result) => {
                let options: IComboBoxOption[] = [];
                options.push({ key: "SE", text: t("Select.message")});
                result.map((response) => {
                    options.push({ key: response.index, text: response.name });
                })
                setOptionsBackgroundColors(options)
            })
            .catch((error) => {
                setErrorInGet(true);
            })
            .finally(() =>{
                setwaitOptionsBackgroundColors(false);
            });

    }, [i18n.language])
    
    function changeConverterType (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string)
    {
        if (optionsConvertionType && index != undefined && index > 0) {
            setConvertionType(optionsConvertionType[index].key.toString());
        } else {
            setConvertionType(undefined)
        }
    }

    function changeSize (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string)
    {
        if(optionsSize && index != undefined && index > 0)        
        {
            setSize(optionsSize[index].key.toString());
        } else {
            setConvertionType(undefined)
        }
    }

    function changePalette (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string)
    {
        if(optionsPalette && index != undefined && index > 0)
        {
            setPalette(optionsPalette[index].key.toString());
        } else {
            setConvertionType(undefined)
        }
    }

    function changeOrientation (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string)
    {
        if(optionsOrientation && index != undefined && index > 0)
        {
            setOrientation(optionsOrientation[index].key.toString());
        } else {
            setConvertionType(undefined)
        }
    }

    function changeBackgroundColors (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string)
    {
        if(optionsBackgroundColors && index != undefined && index > 0)
        {
            setBackgroundColors(optionsBackgroundColors[index].key.toString());
        } else {
            setConvertionType(undefined)
        }
    }

    return (
        <div>
            <HeaderViewerComp
                cancel={props.cancel}
                haveChoicerViwerChangeList={true}
            />
            {errorInGet ? (
                <div className={styles.formDwg}>
                    <Alert variant="filled" severity="error">
                        {t("Select.error")}
                    </Alert>
                </div>
            ):(
                <div className={styles.formDwg}>
                    {waitOptionsConvertionType ||
                    waitOptionsSize ||
                    waitOptionsPalette ||
                    waitOptionsOrientation ||
                    waitOptionsBackgroundColors?(
                    <>
                        <Shimmer width="50%" />
                        <Shimmer width="75%" />
                        <Shimmer width="75%" />
                    </>
                    ):(
                    <>
                        <h4>{t("Dwg.title")}  {props.name}.{props.extension}</h4>

                        <ComboBoxComp
                            label={t("Dwg.converterType")}
                            options={optionsConvertionType!}
                            onChange={changeConverterType}
                            selectedKey={convertionType}
                        />

                        {convertionType != undefined ? (
                            convertionType == "1" ? (
                                <ComboBoxComp
                                    label={t("Dwg.backgroundColor")}
                                    options={optionsBackgroundColors!}
                                    onChange={changeBackgroundColors}
                                    selectedKey={backgroundColors}
                                />
                            ) : (
                                <div>
                                    <ComboBoxComp
                                        label={t("Dwg.size")}
                                        options={optionsSize!}
                                        onChange={changeSize}
                                        selectedKey={size}
                                    />

                                    <ComboBoxComp
                                        label={t("Dwg.palette")}
                                        options={optionsPalette!}
                                        onChange={changePalette}
                                        selectedKey={palette}
                                    />

                                    <ComboBoxComp
                                        label={t("Dwg.orientation")}
                                        options={optionsOrientation!}
                                        onChange={changeOrientation}
                                        selectedKey={orientation}
                                    />
                                </div>
                            )
                        ) : (
                            <div></div>
                        )}
                    </>
                    )}

                    <Btn text={t("Selecionar.message")} onClick={() => props.updateViewerPdw({
                        converterType: Number(convertionType),
                        backgroundColors: Number(backgroundColors),
                        orientation: Number(orientation),
                        palette: Number(palette),
                        size: Number(size)
                    })} />
                </div>
            )}
        </div>
    )
}

export default FileDwg;