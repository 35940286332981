import { Paper, Grid, Box, useTheme } from '@mui/material';
import FormLogo from '../Components/FormLogo';
import FirstTitle from '../Components/FirstTitle';
import { FormIcon } from './components/FormIcon';
import FormHeader from '../Components/FormHeader';
import FormPrivacy from '../Components/FormPrivacy';
import { useTranslation } from 'react-i18next';

export interface FormRootInstance {
    iconToRender?: string;
    title: string;
    subTitle?: string | undefined;
}

interface FormRootProps {
    children: React.ReactNode;
    instance: FormRootInstance;
}

export default function FormRoot({
    children,
    instance
}: FormRootProps) {
    const { t, i18n } = useTranslation();

    const theme = useTheme();

    return (
        <Grid 
            container 
            spacing={2}
            style={{
                overflowX:"visible",
                paddingBottom: "8px",
                margin: '0',
                width: "100%",
            }}
        >

            { instance.iconToRender != undefined && (
                <Grid item xs={12} sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <FormIcon 
                        icon={instance.iconToRender}
                    />
                </Grid>
            ) }
            <Grid sx={{padding: '16px 0px'}}>
                <FormHeader
                    titleSize={32}
                    title={instance.title}
                    subTitle={instance.subTitle}
                />
            </Grid>

            { children }
        </Grid>
    )
}
