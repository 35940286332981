export interface WebpConfig {}

export const Webp: React.FC<WebpConfig> = ({}) => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="icon-webp" transform="translate(-521 -504.861)">
        <rect id="Retângulo_258" data-name="Retângulo 258" width="24" height="24" rx="2" transform="translate(521 504.861)" fill="#3c9fe8" />
        <path id="Shape" d="M8.451,0A8.451,8.451,0,1,0,16.9,8.451,8.476,8.476,0,0,0,8.451,0Zm5.831,5.071H11.831a13.357,13.357,0,0,0-1.183-3.042,6.8,6.8,0,0,1,3.634,3.042ZM8.451,1.69a11.347,11.347,0,0,1,1.606,3.38H6.845A12.425,12.425,0,0,1,8.451,1.69ZM1.944,10.141a5.449,5.449,0,0,1-.254-1.69,5.449,5.449,0,0,1,.254-1.69H4.817a11.326,11.326,0,0,0-.085,1.69c0,.592.085,1.1.085,1.69Zm.676,1.69H5.071a13.357,13.357,0,0,0,1.183,3.042A6.8,6.8,0,0,1,2.62,11.831ZM5.071,5.071H2.62A7.122,7.122,0,0,1,6.254,2.028,13.357,13.357,0,0,0,5.071,5.071Zm3.38,10.141a11.347,11.347,0,0,1-1.606-3.38h3.211a12.425,12.425,0,0,1-1.606,3.38Zm1.944-5.071H6.507a11.453,11.453,0,0,1-.169-1.69,11.453,11.453,0,0,1,.169-1.69h3.972a11.453,11.453,0,0,1,.169,1.69,11.666,11.666,0,0,1-.254,1.69Zm.254,4.732a11.99,11.99,0,0,0,1.183-3.042h2.451a6.8,6.8,0,0,1-3.634,3.042Zm1.521-4.732a11.326,11.326,0,0,0,.085-1.69c0-.592-.085-1.1-.085-1.69h2.873a5.449,5.449,0,0,1,.254,1.69,5.449,5.449,0,0,1-.254,1.69Z" transform="translate(524.549 508.41)" fill="#fff" fill-rule="evenodd" />
      </g>
    </svg>
  )
}
