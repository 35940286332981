import { validateEmail } from "../../WorkSpaceItem/PanelItens/ShareFile/ShareFileComp"
import { UserTypes } from "../types/userType"

export const validEmail = (email: string) => {
  return /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/.test(email)
}

export const stepTwoValidation = (
  emails: UserTypes[],
  fn: () => void
): boolean => {
  fn()
  return true
}


export const nameValidation = (name: string): boolean => {
  
  return true
}