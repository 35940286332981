import React from 'react';
import { useId } from '@fluentui/react-hooks';
import { Dialog, DialogFooter, DialogType } from "@fluentui/react";
import Btn from '../../../../components/Button/Btn';

interface DialogUnduDraftProps {
    title: string;
    hidden: boolean;
    onDismiss: () => void;
    primaryButton: {onClick: () => void, text: string}
    secondaryButton?: {onClick: () => void, text: string}
    subText?: string
}

const DialogUnduDraft: React.FC<DialogUnduDraftProps> = (props: DialogUnduDraftProps) => {
    const dialogContentProps = {
        type: DialogType.normal,
        title: props.title,
        closeButtonAriaLabel: 'Close',
        subText: props.subText,
      };
      
      const dialogStyles = { main: { maxWidth: 450 } };
      const labelId: string = useId('dialogLabel');
      const subTextId: string = useId('subTextLabel');
      const modalProps = React.useMemo(
        () => ({
          titleAriaId: labelId,
          subtitleAriaId: subTextId,
          isBlocking: false,
          styles: dialogStyles,
        }),
        [labelId, subTextId],
      );

      return(
        <Dialog
            hidden={props.hidden}
            onDismiss={props.onDismiss}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
        >
            <DialogFooter>
                {
                    props.secondaryButton && (
                        <Btn onClick={props.secondaryButton.onClick} text={props.secondaryButton.text} />
                    )
                }
                <Btn onClick={props.primaryButton.onClick} text={props.primaryButton.text} />
            </DialogFooter>
        </Dialog>
      )
}

export default DialogUnduDraft;