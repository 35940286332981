import { Paper, useTheme } from '@mui/material'

interface LeftHandlerProps {
    children: any;
}

export function LeftHandler({
    children
}: LeftHandlerProps) {

    const theme = useTheme();

    return (
        <Paper
            sx={{
                width: '560px',
                padding: '28px 44px 44px 44px',
                fontFamily: 'Segoe UI',
                marginTop:"auto",
                marginLeft: "215px",
                marginBottom: '15px',
                borderRadius: "8px",
                border: '1px solid #E9E9E9',
                boxShadow: '8px 8px 16px #00000014',
                

                [theme.breakpoints.down("lg")]: {
                    margin: 'auto',
                    marginTop: '10px',
                    marginBottom: '10px',
                    border:"2px solid rgb(45, 45, 45)"
                },
                [theme.breakpoints.down("md")]: {
                    marginLeft: 0,
                    marginTop: 0,
                    marginBottom: 0,
                    width: '100%',
                    border:"0px solid rgb(45, 45, 45)"
                },
            }}
        >
            { children }
        
        </Paper>
    )
}