import React from 'react';
import { BrasilFlag, EuaFlag } from '../I18n/img';
import { useTranslation } from 'react-i18next';
import './styles.css';
import Flag from '../I18n/Flag';

const I18n = () => {
	const { i18n } = useTranslation();

	function handleChangeLanguage(language: any) {
		i18n.changeLanguage(language);
	}

	const selectedLanguage = i18n.language;
	return (
		<div className="flags-container">
			<Flag
				image={BrasilFlag}
				isSelected={selectedLanguage === 'pt-BR'}
				onClick={() => handleChangeLanguage('pt-BR')}
			/>
			<Flag
				image={EuaFlag}
				isSelected={selectedLanguage === 'en-US'}
				onClick={() => handleChangeLanguage('en-US')}
			/>
		</div>
	);
};

export default I18n;
