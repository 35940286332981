import { Box, Typography } from "@mui/material";
import DocumentDetailInternalCircle from "../DocumentDetailInternalCircle/DocumentDetailInternalCircle";

interface IDocumentDetailInternalData
{
    icon?: string;
    textInformation: string;
    valueInformation: string;
    hasCircleDetail?: boolean;
    isSidebarCollapsed?: boolean
}

export default function DocumentDetailInternalData({
    icon,
    textInformation,
    valueInformation,
    hasCircleDetail,
    isSidebarCollapsed
}: IDocumentDetailInternalData)
{

    function returnHasCircle()
    {
        if (hasCircleDetail) 
        {
            return (
                <Typography
                    sx={{
                        textAlign: "left",
                        letterSpacing: "0px",
                        color: "#2D2D2D",
                        opacity: 1,
                        display: 'flex',
                        alignItems: 'baseline',
                        gap: 1
                    }}
                >
                    {textInformation}: <DocumentDetailInternalCircle /> { valueInformation }
                </Typography>
            )
        }

        return <>
            <div style={{whiteSpace:"nowrap"}}>
                {textInformation}: {isSidebarCollapsed && valueInformation }
            </div>
            {!isSidebarCollapsed && valueInformation }
        </>
    }

    return (
        <Box sx={{
            display: 'flex',
            gap: 1
        }}>
            <img src={icon} />
            <Typography
                    sx={{
                        textAlign: "left",
                        letterSpacing: "0px",
                        color: "#2D2D2D",
                        opacity: 1,
                    }}
                >
                    { returnHasCircle() }
                </Typography>
            
        </Box>
    )
}