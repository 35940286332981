import React, { useEffect, useRef, useState, useMemo, RefObject } from "react";
import * as PdfJs from "pdfjs-dist";
import styles from "../../../Viewer.module.css";
import PagePreview from "./PagePreview";


function useOnScreen(ref: RefObject<HTMLElement>) {
    const [isIntersecting, setIntersecting] = useState(false)
  
    const observer = useMemo(() => new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting)
    ), [ref])
  
    useEffect(() => {
        if(ref.current)
            observer.observe(ref.current)
        return () => observer.disconnect()
    }, [])
  
    return isIntersecting
}

interface LazyPreviewProps {
    canRender: boolean;
    pageNumber: number;
    pdfPage: PdfJs.PDFPageProxy | undefined;
    fetchPage: (pageNumber : number) => void;
    setPageScroll: React.Dispatch<React.SetStateAction<number>>
}

const LazyPreview: React.FC<LazyPreviewProps> = (props: LazyPreviewProps) => {
    const canvasLocationRef = useRef<HTMLDivElement>(null)
    const isVisible = useOnScreen(canvasLocationRef);

    useEffect(() => {
        if (isVisible) {
          if (props.canRender == false) {
            props.fetchPage(props.pageNumber);
          }
        }
    }, [isVisible]);

    return (
        <div className={styles.pageCurrPrev} ref={canvasLocationRef}>
            <div className={styles.pdfCurrPrev}>
              {(props.pdfPage) && (
                <PagePreview
                  canRender={props.canRender}
                  pdfPage={props.pdfPage}
                  setPageScroll={props.setPageScroll}
                />
              )}
            </div>
            <span>{props.pageNumber < 10 && 0}{props.pageNumber}</span>
        </div>
    )
}

export default LazyPreview;