import React, { useState, useEffect, useImperativeHandle, useRef } from "react";
import { FileListHash, FileList, FileListAdd, FileReplace, FileWorkspace, FolderWorkspace } from "../../../context/UploadProvider/typesUpload";
import { UploadExistingFile, UploadItem } from "../../../services/Item/ItemUpload";
import { Api } from "../../../services/api";
import { useUpload } from "../../../context/UploadProvider/useUpload";
import { FontIcon } from '@fluentui/react/lib/Icon';
import { useId } from '@fluentui/react-hooks'
import { useTranslation } from "react-i18next";
import styles from "./FileSwith.module.css";
import axios,{CancelTokenSource, AxiosError} from 'axios';
import { IIconProps, initializeIcons } from '@fluentui/react';
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';
import { IconButton } from '@fluentui/react/lib/Button';
import { GetOneItem } from "../../../services/Item/ItemServicesGet";
import { CreateFolder } from "../../../services/Item/ItemServicesCRUD";

interface FileSwithProps {
  file: FileListHash;
  index: number;
  setLastIsDone: (index: number) => void;
  ref?: React.Ref<any>;
  newFolderFatherId?: string;
  indexToUpload: number | undefined;
  uploadDone(): void;
}

export interface InterfaceFileIsDone {
  fileIsDone(): boolean;
}


const FileSwith  : React.ForwardRefExoticComponent<FileSwithProps> =
React.forwardRef(({ file, newFolderFatherId, setLastIsDone, index, indexToUpload, uploadDone }, ref) => {
    const [percentCompleted, setPercentCompleted] = useState<number>(0);
    const upload = useUpload();
    const [status, setStatus] = useState<number>(0);
    const [isDone, setIsDone] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [folderId, setFolderId] = useState<string | undefined>(undefined);
    const [childrenIdUpload, setChildrenIdUpload] = useState<number|undefined>(undefined);
    const { t, i18n } = useTranslation();

    function uploadDoneInternal()
    {
      if(childrenIdUpload != undefined)
      {
        setChildrenIdUpload(childrenIdUpload + 1);
      }
    }

    const [source, setSource] = useState<CancelTokenSource>(axios.CancelToken.source());

    useEffect(() => {
      if(
        file.folderWorkspace != undefined && 
        file.folderWorkspace.itens.length == childrenIdUpload
      )
      {
        uploadDone();
      }
    }, [childrenIdUpload]);

    useImperativeHandle(
      ref,
      () => ({
        fileIsDone() {
          if(file.folderWorkspace != undefined)
          {
            let doIt = true;
            let i:number = 0;
            for(i = 0;i<myRefs.current.length;i++)
            {
              if(myRefs.current[i].fileIsDone() == false)
              {
                doIt = false;
              }
            }

            return doIt;
          }
          else
          {
            return isDone;
          }
        },
      }),
    )

    const onUploadProgress = (progressEvent:any) => {
      setPercentCompleted(Math.round((progressEvent.loaded * 100) / progressEvent.total));
    };

    function fileUpload(
        file: FileListHash,
        onUploadProgress: (progressEvent: any) => void | null, 
        source: CancelTokenSource,
    ) {
      let uploadType: Promise<any> | undefined = undefined;
  
      if (file.fileReplace !== undefined) {
        uploadType = UploadExistingFile(file.fileReplace, onUploadProgress, source);

      } else if(file.fileWorkspace != undefined) {
        const newFileWorkspace = {
          file: file.fileWorkspace.file,
          woskpace: file.fileWorkspace.woskpace,
          folder: newFolderFatherId ? newFolderFatherId : file.fileWorkspace.folder
        }
        uploadType = UploadItem(newFileWorkspace, onUploadProgress, source)
      } 
      else if(file.folderWorkspace != undefined){      
        uploadType = CreateFolder({
          name: file.folderWorkspace.name, 
          workspaceIdMongo: 
          file.folderWorkspace.woskpace, 
          idFatherMongo: newFolderFatherId ? newFolderFatherId : file.folderWorkspace.folder
        });
      }
    
      if(uploadType != undefined)
      {
        uploadType
          .then((alert) =>{
            if (file.fileReplace != undefined)
            {
              GetOneItem(alert.data.itemId)
              .then(item => upload.setReplacedItem(item))
            }

            if(alert.supplementaryInfo){
              setFolderId(alert.supplementaryInfo)
              setStatus(1)
            }

            if(alert != undefined && alert.data != undefined && alert.data.warningMessage != "")
            {
              setError(alert.data.warningMessage);
              setStatus(4);
            }
            else
            {
              setStatus(1);
            }

            if(alert && alert.data && alert.data.itemId)
            {
              upload.setItemId(alert.data.itemId);
            }
            upload.setShowEx(true);
          })
          .catch((error) => {
            var params = JSON.stringify(error);
            if (params == "{}") {
              setStatus(3);
              setError('Request canceled');
            } 
            else
            {
              setStatus(2);
              if( error.response && error.response.data && typeof error.response.data  === 'string'){
                setError( error.response.data);
              }
              else
              {
                setError(t("Upload.error"));
              }
            }
            upload.setShowEx(true);
          })
          .finally(()=>{
            if(file.folderWorkspace == undefined)
            {
              uploadDone();
            }
            else if(file.folderWorkspace.itens.length > 0)
            {
              setChildrenIdUpload(0);
            }
            else if(file.folderWorkspace.itens.length = 0)
            {
              uploadDone();
            }

            setIsDone(true);
            setLastIsDone(index);
          });
        }
      }


    useEffect(() => {
      if(
        file != undefined &&
        indexToUpload == index &&
        file.uploadDone == false && 
        status == 0 &&
        ((file.fileReplace != undefined && file.fileReplace.file) ||
        (file.fileWorkspace != undefined && file.fileWorkspace.file) ||
        file.folderWorkspace != undefined)
      )
      {
        fileUpload(file, onUploadProgress, source);
      }
    }, [file, indexToUpload]);

    const endstyle: React.CSSProperties = {
      color:"#bdffc3",
      fontSize:20
    }

    const errorstyle: React.CSSProperties = {
        color:"#fca2a2",
        fontSize:20
    }

    const alertStyle: React.CSSProperties = {
      color:"#fcba03",
      fontSize:20
    }

    const tooltipId = useId('tooltip');
    const calloutProps = { gapSpace: 0 };
    const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

    function cancel()
    {
      source.cancel('Operation canceled by the user.');
    }

    const stop: IIconProps = {
      iconName: 'CircleStopSolid',
      styles: {
        root: { color: "#fca2a2" }
      }
    };

    const myRefs = useRef<any[]>([]);

    useEffect(() => {
      if(file.folderWorkspace != undefined && file.folderWorkspace.itens.length > 0)
      {
        myRefs.current = myRefs.current.slice(0, file.folderWorkspace.itens.length);
      }
      else
      {
        myRefs.current = myRefs.current.slice(0, 0);
      }
    }, [file.folderWorkspace]);

    return (
      <>
        <div>
          {file.folderWorkspace != undefined && file.folderWorkspace.name+ " "} 
          {file.fileWorkspace != undefined && file.fileWorkspace.file.name+ " "} 
          {file.fileReplace != undefined && file.fileReplace.file.name+ " "} 
          {status == 0  &&(
            <div>
              {percentCompleted.toString() + " %"}
              <IconButton iconProps={stop} title="CircleStopSolid" ariaLabel="CircleStopSolid" onClick={cancel} />
            </div>
          )}
          {status == 1 &&(
            <TooltipHost
              content={t("Upload.done")}
              id={tooltipId}
              calloutProps={calloutProps}
              styles={hostStyles}
            >
              <FontIcon aria-label="Finalizado" iconName="CompletedSolid" style={endstyle}/>
            </TooltipHost>
          )}
          {status == 2 &&(
            <TooltipHost
              content={error}
              id={tooltipId}
              calloutProps={calloutProps}
              styles={hostStyles}
            >
              <FontIcon aria-label="Erro" iconName="Blocked2Solid" style={errorstyle}/>
            </TooltipHost>
          )}
          {status == 3 &&(
            <TooltipHost
              content={error}
              id={tooltipId}
              calloutProps={calloutProps}
              styles={hostStyles}
            >
              <FontIcon aria-label="Refresh" iconName="CircleStopSolid" style={errorstyle}/>
            </TooltipHost>
          )}
          {status == 4 &&(
            <TooltipHost
              content={error}
              id={tooltipId}
              calloutProps={calloutProps}
              styles={hostStyles}
            >
              <FontIcon aria-label="Alert" iconName="AlertSolid" style={alertStyle}/>
            </TooltipHost>
          )}
          
        </div>
        {file.folderWorkspace != undefined && file.folderWorkspace.itens.length > 0 && folderId != undefined && (
          <>
            {file.folderWorkspace.itens.map((itens, index) => (
              <FileSwith
                key={index}
                file={itens}
                index={index}
                setLastIsDone={setLastIsDone}
                ref={el => myRefs.current[index] = el}
                newFolderFatherId={folderId}
                indexToUpload={childrenIdUpload}
                uploadDone={uploadDoneInternal}
              />
            ))}
          </>
        )
      }
      </>
    );
});

export default FileSwith;

