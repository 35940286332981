export interface IIconSideComponentTypes {
  icon: string;
  isInvert?: boolean
}

export const IconSideComponent: React.FC<IIconSideComponentTypes> = ({ icon, isInvert }) => {

  if (isInvert) {
    return (
      <img src={icon} style={{ transform: "rotate(180deg)" }} />
    )
  }

  return (
    <img src={icon} />
  )
}