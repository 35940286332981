import React from "react";
import { TextField } from "@fluentui/react/lib/TextField";
import { IIconProps, Icon, TooltipHost } from "@fluentui/react";
import { ILabelStyles, ILabelStyleProps } from '@fluentui/react/lib/Label';
import { ITextFieldProps, ITextFieldStyleProps, ITextFieldStyles } from "@fluentui/react/lib/components/TextField/TextField.types";
import { t } from "i18next";

export interface TextFieldCompConfig{
    tooltip?: boolean;
    label?: string;
    value?: string;
    disabled?: boolean;
    round?: boolean;
    onChange?: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void;
    iconProps?:IIconProps;
    float?:string
    isPassword?: boolean;
    bigSize?: boolean;
    grow?: boolean;
    autofocus?: boolean;
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}

const TextFieldComp = (props: TextFieldCompConfig, ITextFieldProps: ITextFieldProps) => {

    let disabled:boolean = false;
    if(props.disabled != undefined)
    {
        disabled = props.disabled;
    } 

    let isPassword:boolean = false;
    if(props.isPassword != undefined)
    {
        isPassword = props.isPassword;
    } 

    var float:string = "none";
    if(props.float)
    {
        float = props.float;
    }

    function getLabelStyles(propsLabel: ILabelStyleProps): ILabelStyles {
        return {
          root: {
            color:"rgb(0, 0, 0)",
            fontFamily:"Microsoft YaHei, sans-serif",
            marginBottom:"8px",
            textAlign:"left"
          },
        };
      }

      const textFieldStyleFunction = (propsText: ITextFieldStyleProps): Partial<ITextFieldStyles> => {
        let style: Partial<ITextFieldStyles> = {};

        style.root = {
            width:props.bigSize?"100%":"calc(100% - 5px)",
            maxWidth:props.grow?props.bigSize?"100%":"calc(100% - 5px)": props.bigSize?480:300,
            margin:props.bigSize?"0px":"5px",
            borderColor:"black"
        };

        style.fieldGroup = {
            borderRadius: props.round?props.bigSize?"8px":"5px":"0px",
            border: props.bigSize?"2px solid #2D2D2D4D":undefined,
            height:props.bigSize?"54px":"36px",
            paddingTop:props.bigSize?"8px":"0px",
            paddingBottom:props.bigSize?"8px":"0px",
            backgroundColor: props.bigSize?"#FBFBFB":"rgb(255, 255, 255)",
            ":after": propsText.focused ? {
                borderColor: "rgb(102, 102, 102)",
                borderWidth:"2px",
                borderRadius: props.round?props.bigSize?"8px":"5px":"0px",
            } : undefined,
        };

        style.field = {
            backgroundColor: props.bigSize?"#FBFBFB": "rgb(255, 255, 255)",
            borderColor:"black",
            ":disabled":{
                backgroundColor: "rgb(242, 242, 242)"
            }
        };

        style.subComponentStyles = {
            label: getLabelStyles,
        };

        return style;
      }

      const onRenderLabel = () => {
        return (
          <div style={{ 
            display: 'flex',
            fontFamily: "Microsoft YaHei, sans-serif",
            fontSize: "16px",
            fontWeight: 600,
            padding: "5px 0px",
            color: "rgb(0, 0, 0)",
           }}>
            {props.label}
            {props.tooltip &&
            <TooltipHost content={t("TextField.TooltipTitle")} styles={{ root: { marginLeft:"8px", display:"flex", alignItems:"center" } }}>
              <Icon iconName="Info" styles={{ root: { fontSize: '16px', color: "rgb(0, 0, 0)", cursor:"pointer" } }} />
            </TooltipHost>
            }
          </div>
        );
      };

    return (
      <TextField
        onRenderLabel={onRenderLabel}
        value={props.value}
        autoFocus={props.autofocus}
        disabled={disabled}
        onKeyDown={props.onKeyDown}
        styles={textFieldStyleFunction}
        onChange={props.onChange} 
        iconProps={props.iconProps}
        type={isPassword? "password" : undefined}
        revealPasswordAriaLabel="Show password"
        canRevealPassword
      />      
    );
}

export default TextFieldComp;