import { mergeStyleSets } from "@fluentui/react";
import { MenuConfig } from "../../../components/Menu/MenuConfig";
import {ReactComponent as AlertIcon} from './SVG/alert.svg';
import HeaderViewerComp from "../../../components/Viewer/Header/HeaderViewerComp";
import { useTranslation } from "react-i18next";

export interface NoViwerPageProps{
    text?: string;
    id: string;
    name: string;
    extension: string;
    downloadViwer: () => void
    title?: string;
    showList?: boolean;
    isAcept?: boolean;
    menuConfig?: MenuConfig;
    hideSidebar?: boolean
    toggleHideSidebar?: () => void;
    toggleHideDialog?: () => void;
    cancel?: () => void;
    changeModalContent?: (id: string, name: string, extension: string) => void;
}

const ErrorViwer = (props: NoViwerPageProps) => {
    const { t } = useTranslation();

    return(
        <div className={contentStyles.container}>
            <div className={contentStyles.header}>
                <HeaderViewerComp 
                    haveChoicerViwerChangeList={props.changeModalContent != undefined}
                    title={props.title}
                    showList={props.showList}
                    isAcept={props.isAcept}
                    hideSidebar={props.hideSidebar}
                    menuConfig={props.menuConfig}
                    toggleHideSidebar={props.toggleHideSidebar}
                    toggleHideDialog={props.toggleHideDialog}
                    cancel={props.cancel}
                /> 
            </div>
            <div className={contentStyles.main}>
                <AlertIcon className={contentStyles.icon}/>
                <h1 className={contentStyles.title}>{t("ErrorViwer.title")}</h1>
                <span className={contentStyles.textContent}>{t("ErrorViwer.description")}</span>
            </div>
        </div>
    )
}

const contentStyles = mergeStyleSets({
    container: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        flexDirection: "column",
        justifyContent: "center",
        rowGap: "40px",
        height: "81vh",
    },
    main: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: "304px",
        width: "785px",
        backgroundColor: "#FCFCFC",
        borderRadius: "20px",
        gap: "16px",
    },
    title: {
        font: "normal normal bold 26px/39px Segoe UI",
    },
    textContent: {
        font: "normal normal normal 16px/24px Segoe UI",
        textAlign: "center",
    },
    button: {
        borderRadius: "8px",
        color: "#005A9E",
        backgroundColor: "#FCFCFC",
        width: "725px"
    },
    header: {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
    },
    sidebar: {
        height: "calc(100% - 46px)",
        position: "absolute",
        left: "0",
        top: "46px",
    },
    icon: {
        height: "54px",
        width: "61px",
        fill: "#005A9E"
    },
    pageCurrPrev:{
        minWidth: "109px",
        minHeight: "163px",
        maxWidth: "109px",
        maxHeight: "163px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
    },
    iconCurrPrev:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: "93px",
        minHeight: "128px",
        border: "1px solid #D1D1D1",
        outline: "8px solid #F5F5F5",
        borderRadius: "4px",
    },
})

export default ErrorViwer;