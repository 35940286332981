import {
    ComboBox,
    DatePicker, DirectionalHint, IComboBox, IComboBoxOption, ITooltipProps, Icon, Label, MessageBarType, TooltipHost
} from '@fluentui/react';
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Btn, { TypeStyle } from '../../../components/Button/Btn';
import { getIDatePickerStrings } from "../../../components/DatePiker/DatePikerLanguage";
import LoaderComp from '../../../components/Loader/LoaderComp';
import Message from "../../../components/Message/Message";
import { Temporality, TemporalityVO } from "../../../model/ItemModel";
import {
    SetTemporality
} from "../../../services/Item/ItemServicesCRUD";
import {
    GetEnumTemporality,
    GetItemWithTemporality,
} from "../../../services/Item/ItemServicesGet";
import styles from "./PanelItens.module.css";
import info from "../../../assets/icons/info.svg"
import infoHover from "../../../assets/icons/infoHover.svg"
import { NormalExpandIcon } from '../../../assets/icons/icons.tsx/NormalExpandIcon';
import { HoverExpandIcon } from '../../../assets/icons/icons.tsx/HoverExpandIcon';
import { ConstrictIcon, } from '../../../assets/icons/icons.tsx/ConstrictIcon';


export interface TemporalityConfig {
    itemId: string;
    setTemporalityInternal: (newTemporality: Temporality | undefined, expired: boolean, archiving: boolean, draft: boolean) => void;
}

export interface TemporalityDocumentParams {
    temporalityConfig: TemporalityConfig;
    saveOkMetod: () => void;
    resetOkMetod: () => void;
    resetPanel: () => void;
}

const TemporalityDocument = (props: TemporalityDocumentParams) => {
     const { t, i18n } = useTranslation();
    const [temporality, setTemporality] = useState<Date | undefined>(undefined);
    const [optionsTemporality, setOptionsTemporality] = useState<IComboBoxOption[]>([]);
    const [keySelectTemporality, setKeySelectTemporality] = useState<number | undefined>(undefined);
    const [messageErrorString, setMessageErrorString] = useState<string>();
    const [popupError, setPopupError] = useState<boolean>(false);
    const [waitLoad, setWaitLoad] = useState<boolean>(false);
    const [isToolTipHoverOne, setIsToolTipHoverOne] = useState<boolean>(false)
    const [isToolTipHoverTwo, setIsToolTipHoverTwo] = useState<boolean>(false)
    const [isOptionsListOpen, setIsOptionsListOpen] = useState<boolean>(false);
    const [isHover, setIsHover] = useState<boolean>(false)
    const [iconStyleDatePicker, setIconStyleDatePicker] = useState({
        isOpen: false,
        borderColor:"#2D2D2D33",
        color:"rgb(45, 45, 45, 0.5)",
        backgroundColor:""
      })

   

    useEffect(() => {
        const currentDate = new Date()
        const nextDate = new Date(currentDate)
        nextDate.setDate(currentDate.getDate() + 1)

        setWaitLoad(true);

        let optionInter: IComboBoxOption[] = [];

        GetEnumTemporality()
            .then((enumReturn) => {
                enumReturn.map((data) => {
                    optionInter.push({
                        key: data.index,
                        text: data.name,
                    });

                    setOptionsTemporality(optionInter)
                })
            })
            .catch(e => console.log(e))
    }, []);

    useEffect(() => {
        GetItemWithTemporality(props.temporalityConfig.itemId)
            .then((returnJson) => {
                if (returnJson.itemTemporality != undefined) {
                    setKeySelectTemporality(returnJson.itemTemporality.enumTemporality)
                    if (returnJson.itemTemporality.itemTemporality != undefined)
                        setTemporality(new Date(returnJson.itemTemporality.itemTemporality))
                }
            })
            .catch((error) => {
                if (error.response && error.response.data && typeof error.response.data === 'string') {
                    setMessageErrorString(error.response.data);
                }
                else {
                    setMessageErrorString(t("UnableToReturnItemTemporality.message"));
                }
            })
            .finally(() => {
                setWaitLoad(false);
            })
    }, [optionsTemporality])

    const onFormatDate = (date?: Date): string => {
        if (!date) return '';
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear());
        return i18n.language === 'pt-BR' ? `${day}/${month}/${year}` : `${month}/${day}/${year}`;
    };
    
    const save = () => {

        if (keySelectTemporality == undefined) {
            setMessageErrorString(t("EmptyTemporalityField.message"));
            setPopupError(true)
            return
        }

        var validateDocumentVO: TemporalityVO = {
            itemId: props.temporalityConfig.itemId,
            itemTemporality: temporality?.toDateString(),
            enumTemporality: keySelectTemporality,
        }

        setWaitLoad(true);
        SetTemporality(validateDocumentVO)
            .then(() => {
                    props.temporalityConfig.setTemporalityInternal({
                        itemTemporality: temporality?.toDateString(),
                        enumTemporality: keySelectTemporality,
                    },
                    temporality? (keySelectTemporality == 2 && new Date() >= temporality! ? true : false) : false,
                    temporality? (keySelectTemporality == 0 && new Date() >= temporality! ? true : false) : false,
                    temporality? (keySelectTemporality == 1 && new Date() >= temporality! ? true : false) : false       
                )
                props.saveOkMetod()
            })
            .catch((error) => {
                if (error.response && error.response.data && typeof error.response.data === 'string') {
                    setMessageErrorString(error.response.data);
                }
                else {
                    setMessageErrorString(t("ErroAoSalvar.message"));
                }
                setPopupError(true);
            })
            .finally(() => {
                setWaitLoad(false);
            });
    }

    function changeChangeBoxTemporality(event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) {
        if (index != undefined) {
            console.log(Number(optionsTemporality[index].key));
            setKeySelectTemporality(Number(optionsTemporality[index].key));
        }
    }

    const handleClose = (event: any, reason: any) => {
        closePopUpOk();
    };

    const handleClosePO = (event: any) => {
        closePopUpOk();
    };

    function closePopUpOk() {
        setPopupError(false);
    }

    const handleTooltipToggleOne = () => {
        setIsToolTipHoverOne(!isToolTipHoverOne)
    };
      const handleTooltipToggleTwo = () => {
        setIsToolTipHoverTwo(!isToolTipHoverTwo)
    };
    const onRenderLabel = () => {
        return (

          <div style={{ 
            display: 'flex',
            fontSize: "18px",
            fontWeight: 600,
            padding: "0px 0px 10px 0px",
            color: "rgb(45, 45, 45, 0.8)",
           }}>
            {t("Option.message")}
            <TooltipHost  
                tooltipProps={{
                    onRenderContent:()=>onRender(
                        keySelectTemporality == 2 ? t("TemporalityComboBox.TooltipHostExpired"):
                        (keySelectTemporality == 0 ? t("TemporalityComboBox.TooltipHostArquived"):
                        (keySelectTemporality == 1 ? t("TemporalityComboBox.TooltipHostDraft"):
                            t("TemporalityComboBox.TooltipHostDefalt")
                        )))
                }} 
                onTooltipToggle={handleTooltipToggleOne} 
                directionalHint={DirectionalHint.bottomCenter} 
                calloutProps={{
                    styles:{
                        beak:{
                            backgroundColor:"#0B447B",
                        },beakCurtain:{
                            backgroundColor:"#0B447B",
                            borderRadius:"4px"
                        },
                        calloutMain:{
                            backgroundColor:"#0B447B",
                        },root:{
                            borderRadius:"4px",
                            marginTop:"2px"
                        }
                    }
                }} 
                styles={{ 
                    root: { 
                        marginLeft:"8px", 
                        display:"flex", 
                        alignItems:"center" 
                    } 
                }}>
              <img src={!isToolTipHoverOne ? info : infoHover} alt="info" />
            </TooltipHost>
          </div>
        );
      };

    function onRender (value: any) {
        return (
            <div style={{ padding: 0, color:"#FFFFFF", fontSize:"16px"}}>
                {value}
            </div>
        )
    }
      
    const handleMouseEnter = () => {
        setIsHover(true)
      };
      const handleMouseLeave = () => {
        setIsHover(false)
      };
    
      const handleMenuOpen = () => {
        setIsOptionsListOpen(true);
      };
      const handleMenuDismiss = () => {
        setIsOptionsListOpen(false);
      };
      

      function handleMouseEnterDatePicker(){
        if(!iconStyleDatePicker.isOpen){
        setIconStyleDatePicker({isOpen: false, borderColor:"#005A9E80", color:"rgb(0, 90, 158, 0.5)", backgroundColor:"rgb(0, 90, 158, 0.05)"})
        } 
      }
      function handleMouseLeaveDatePicker(){
        if(!iconStyleDatePicker.isOpen){
            setIconStyleDatePicker({isOpen: false, borderColor:"#2D2D2D33", color:"rgb(45, 45, 45, 0.5)", backgroundColor:""})
        }  
      }

      function handleMenuOpenDatePicker(){
        setIconStyleDatePicker({isOpen: true, borderColor:"#005A9E", color:"#005A9E", backgroundColor:"rgb(0, 90, 158, 0.05)"})
      }

      function handleMenuDismissDatePicker(){
        setIconStyleDatePicker({isOpen: false, borderColor:"#2D2D2D33", color:"rgb(45, 45, 45, 0.5)", backgroundColor:""})
      }
      

    return (
        <div className={styles.hundredHeight}>
            <div style={{height:"100%"}}>
                <div style={{borderBottom:"2px solid rgb(45, 45, 45, 0.1)"}}></div>
                <div className={styles.main} style={{height:"100%", display:"flex", justifyContent:"space-between", paddingTop:"20px"}}>
                    <div style={{display:"flex"}}>
                        <div>
                            <Label htmlFor="datePicker" styles={{ root: { display: 'flex', alignItems: 'center', padding: "0px 0px 10px 0px", fontSize:"18px", fontWeight:600, color: "rgb(45, 45, 45, 0.8)", } }}>
                                {t("DataTableHeaders.Date")}
                                <TooltipHost 
                                    tooltipProps={{onRenderContent:()=>onRender(t("DataTableHeaders.TooltipHost"))}}
                                    onTooltipToggle={handleTooltipToggleTwo} 
                                    directionalHint={DirectionalHint.bottomCenter} 
                                    calloutProps={{
                                        styles:{
                                            beak:{
                                                backgroundColor:"#0B447B",
                                            },beakCurtain:{
                                                backgroundColor:"#0B447B",
                                                borderRadius:"4px"
                                            },
                                            calloutMain:{
                                                backgroundColor:"#0B447B",
                                            },
                                            root:{
                                                borderRadius:"4px",
                                                marginTop:"5px"
                                            }
                                        },
                                    }} 
                                    content={"O vencimento define o tempo de validade de um documento."} 
                                    styles={{ 
                                        root: { 
                                            marginLeft:"8px", 
                                            display:"flex", 
                                            alignItems:"center" 
                                        }
                                    }}>
                                    <img src={!isToolTipHoverTwo ? info : infoHover} alt="info" />
                                </TooltipHost>
                            </Label>
                            <DatePicker
                                id='datePicker'
                                ariaLabel={t("Validity.message")}
                                style={{ marginRight:"20px"}}
                                textField={{
                                    styles:{
                                        fieldGroup:{
                                            display:"flex",
                                            alignItems:"center",
                                            width:"280px",
                                            height:"44px",
                                            borderColor: iconStyleDatePicker.borderColor, 
                                            borderRadius:"6px", 
                                            '&:hover':{
                                                borderColor: iconStyleDatePicker.borderColor,
                                            },
                                            '&::after': {
                                                border:`1px solid ${iconStyleDatePicker.borderColor}`,        
                                                borderRadius:"6px", 
                                            }   
                                        },field:{fontSize:"18px"}                               
                                    }
                                }}
                                calloutProps={{
                                    styles:{
                                        calloutMain:{
                                            borderRadius:"6px"
                                        },root:{
                                            border:"1px solid #005A9E", 
                                            borderRadius:"6px", 
                                            marginTop:"10px"
                                        }
                                    }, doNotLayer: true, 
                                    directionalHint: DirectionalHint.bottomRightEdge, 
                                }}
                                styles={{
                                    icon:{
                                        display:"flex", 
                                        justifyContent:"center", 
                                        height:"30px", 
                                        width:"30px",
                                        color: iconStyleDatePicker.color, 
                                        backgroundColor: iconStyleDatePicker.backgroundColor, 
                                        borderRadius:"50%"
                                    },statusMessage:{
                                        marginTop:"0px"
                                    },textField:{height:"44px"}
                                }}
                                showGoToToday={false}
                                firstWeekOfYear={1}
                                showMonthPickerAsOverlay={true}
                                strings={getIDatePickerStrings(i18n.language)}
                                formatDate={onFormatDate}
                                onSelectDate={setTemporality as (date: Date | null | undefined) => void}
                                value={temporality}
                                onMouseEnter={handleMouseEnterDatePicker}
                                onMouseLeave={handleMouseLeaveDatePicker}
                                onAfterMenuDismiss={handleMenuDismissDatePicker}
                                onClick={handleMenuOpenDatePicker}
                            />
                            <div style={{color:"rgb(45, 45, 45, 0.4)", marginTop:"5px"}}>{t("DataTableHeaders.ExplanatoryText")}</div>
                        </div>
                        <div>
                            <ComboBox
                                placeholder= {t("TemporalityComboBox.PlaceHolder")+"..."}
                                onRenderLabel={onRenderLabel}
                                options={optionsTemporality}
                                onChange={changeChangeBoxTemporality}
                                selectedKey={keySelectTemporality}
                                styles={{root: {
                                    display:"flex",
                                    alignItems:"center",
                                    paddingRight:"7px",
                                    width:"280px",
                                    height:"44px",
                                    '&::after': {
                                    borderRadius: "6px",
                                    border:"1px solid #005A9E",           
                                    },
                                },rootHovered:{
                                    '&::after': {
                                    borderColor:"#2D2D2D33",
                                    },
                                    '&:hover::after': {
                                        border:"1px solid #005A9E80",        
                                    }     
                                },input: {
                                    fontSize:"18px",
                                    color:"#2D2D2D",   
                                    '::placeholder': {
                                    color: 'rgb(45, 45, 45, 0.2)',
                                    fontSize:"18px"
                                    },
                                    '&:hover::placeholder': {
                                    color: 'rgb(45, 45, 45, 0.2) !important'        
                                } 
                                },
                                }}
                                calloutProps={{styles:{root:{
                                    borderRadius:"6px",
                                    border:"1px solid #005A9E",
                                    width:"280px",
                                    marginTop:"10px"
                                },calloutMain:{
                                    borderRadius:"6px"
                                }
                                }
                                }}
                                comboBoxOptionStyles={{root:{
                                    fontSize:"18px",
                                },rootChecked:{
                                    backgroundColor:"#005A9E",
                                    color:"#FFFFFF"
                                }
                                }}
                                iconButtonProps={{styles:{
                                    rootCheckedPressed:{
                                    '&:hover': {
                                        backgroundColor:"transparent",    
                                    },
                                    },rootChecked:{
                                        backgroundColor:"transparent",
                                    },rootPressed:{
                                        height:"30px", 
                                        width:"30px",
                                        backgroundColor:"rgb(0, 90, 158, 0.05)", 
                                        borderRadius:"50%" 
                                    },rootHovered:{
                                        height:"30px", 
                                        width:"30px",
                                        backgroundColor:"rgb(0, 90, 158, 0.05)", 
                                        borderRadius:"50%"  
                                    }
                                }, onRenderIcon:()=>(
                                    !isOptionsListOpen ? (!isHover ? <NormalExpandIcon/> : <HoverExpandIcon/>) : <ConstrictIcon/>
                                )
                                }}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                onMenuOpen={handleMenuOpen}
                                onMenuDismissed={handleMenuDismiss}
                            />
                            <div style={{color:"rgb(45, 45, 45, 0.4)", marginTop:"5px"}}>{t("TemporalityComboBox.ExplanatoryText")}</div>
                        </div>
                    </div>
                    <div className={styles.displayFlex}>
                        {waitLoad ? (
                            <LoaderComp />
                        ) : (
                            <div style={{width:"100%"}}>
                                <div style={{width:"100%"}}>
                                    <Btn
                                    onClick={save}
                                    text={t("Temporality.message")}
                                    style={TypeStyle.buttonTagSaveStyle}
                                    />
                                    <Btn
                                    onClick={props.resetPanel}
                                    text={t("Cancelar.message")}
                                    style={TypeStyle.buttonTagCancelStyle}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Snackbar
                open={popupError}
                autoHideDuration={6000}
                message="Archived"
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                sx={{ bottom: { xs: 50, sm: 20 } }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    sx={{ width: "100%" }}
                    severity="error"
                    onClose={handleClosePO}
                >
                    {messageErrorString}
                </MuiAlert>
            </Snackbar>
        </div>
    )
}

export default TemporalityDocument;