import axios,{AxiosRequestConfig } from 'axios';
import { GetUserLocalStorage, GetOrganizationNameLocalStorage } from '../context/AuthProvider/util';

const config = require("./config.json");

export const ApiWithoutToken = axios.create({
    baseURL : config.baseUrlApp
})

export const Api = axios.create({
    baseURL : config.baseUrlApp
})

export const ApiAuth = axios.create({
    baseURL : config.baseUrlAuth
})

ApiWithoutToken.interceptors.request.use(
    function (config: AxiosRequestConfig) {
        if (config.headers === undefined) {
            config.headers = {};
        }

        var language = localStorage.getItem('i18nextLng');
        if(language != null)
        {
            config.headers["Accept-Language"] = language;
        }

        return config;
    }
);

Api.interceptors.request.use(
    function (config: AxiosRequestConfig) {
        const user = GetUserLocalStorage();

        const organizationName = GetOrganizationNameLocalStorage();

        if (config.headers === undefined) {
            config.headers = {};
        }

        config.headers.Authorization = `Bearer ${user.token}`;
        config.headers.scope = 'greendocs';
        var language = localStorage.getItem('i18nextLng');
        if(language != null)
        {
            config.headers["Accept-Language"] = language;
        }

        if(organizationName != undefined) {
            config.headers["Organization-Name"] = organizationName;
        }

        return config;
    }, 
    function (error: any) {
        console.log(error);
        try {
            if (axios.isCancel(error)) {
                return Promise.reject({
                    cancelUser:true,
                    error:error.message
                });
            }
            else
            {
                return Promise.reject(error);
            }
        } catch (error) {
            console.log(error);
        }
    }
);

Api.interceptors.response.use(
    function (config: AxiosRequestConfig) {
        return config;
    }, 
    function (error: any) {
        try {
            if (axios.isCancel(error)) {
                return Promise.reject({
                    cancelUser:true,
                    error:error.message
                });
            }
            else
            {
                return Promise.reject(error);
            }
        } catch (error) {
            console.log(error);
        }
    }
);