import React, { useEffect, useState, useRef, useCallback } from "react";

import { Nav, INavLinkGroup, INavLink, INavStyles } from "@fluentui/react/lib/Nav";

import { IRenderFunction } from "@fluentui/react/lib/Utilities";

import WorkSpaceItemListFolderComponent from "./WorkSpaceItemListFolderComponent";

import { WorkSpaceItemRecursive } from "../../model/ItemModel";

import { GetTreeByWorkspaceFolderIdAll } from "../../services/Item/ItemServicesGet";

import { GetOneWorkSpace } from "../../services/WorkSpaceServices";

import { FontIcon } from "@fluentui/react/lib/Icon";

import styles from "./WorkSpaceItemList.module.css";

import LoaderComp from '../../components/Loader/LoaderComp';

interface WorkSpaceItemListFolderTreeProps {
	idWorkspace: string;

	setPopupOkMessage: (ok: boolean, message: string) => void;

	setPopupErroMessage: (ok: boolean, message: string) => void;

	clickFunction: (idFolder: string | undefined, nameFolder: string | undefined) => void;

	clickFunctionWorkspace: (nameWorkspace: string | undefined) => void;

	onDrop?: (
		event: React.DragEvent<HTMLDivElement>,
		key: string | undefined,
		endFunction: () => void,
	) => void;

	reloadFolders: () => void;
}

const WorkSpaceItemListFolderTree: React.FC<WorkSpaceItemListFolderTreeProps> = ({
	idWorkspace,

	setPopupOkMessage,

	setPopupErroMessage,

	clickFunction,

	clickFunctionWorkspace,

	onDrop
}) => {
	const navLinkGroups: INavLinkGroup[] = [
		{
			links: [],
		},
	];

	const [reload, setReload] = useState<boolean>(false);
	const [displayNav, setDisplayNav] = useState<boolean>(false);

	const [nav, setNav] = useState<INavLinkGroup[]>(navLinkGroups);

	const [nameWork, setNameWork] = useState<string>();

	const [isHover, setisHover] = useState<boolean>(false);

	const containerRef = useRef<HTMLDivElement | null>(null);

	const renderMenuList: IRenderFunction<INavLink> = (
		menuListProps: INavLink | undefined,

		defaultRender: IRenderFunction<INavLink> | undefined,
	) => {
		if (menuListProps != undefined && defaultRender != undefined) {
			return (
				<WorkSpaceItemListFolderComponent
					idWorkspace={idWorkspace}
					idFolder={menuListProps.key}
					defaultRender={defaultRender}
					menuListProps={menuListProps}
					setPopupError={setPopupErroMessage}
					setPopupSucess={setPopupOkMessage}
					onDrop={onDrop}
				/>
			);
		} else {
			return null;
		}
	};

	useEffect(() => {
		const arrayNav: INavLinkGroup[] = [];

		const newNav: INavLinkGroup = {
			links: [],
		};

		setReload(true)
		GetTreeByWorkspaceFolderIdAll(idWorkspace)
		.then((WorkSpaceItem) => {
			WorkSpaceItem.forEach((element) => {
				newNav.links.push(creatLink(element));
			});

			setDisplayNav(true);

			arrayNav.push(newNav);

			setNav(arrayNav);
		})
		.catch(()=>{})
		.finally(()=>{setReload(false)})

		GetOneWorkSpace(idWorkspace).then((workspaceFound) => {
			if (workspaceFound != null) {
				setNameWork(workspaceFound.name!);
			}
		});
	}, [idWorkspace]);

	function creatLink(element: WorkSpaceItemRecursive) {
		const link: INavLink = {
			key: element.id,

			name: element.name!,
			

			url: "",

			onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
				ev?.stopPropagation();

				clickFunction(element.id, element.name);
			},

			links: [],
		};

		if (element.children != undefined) {
			element.children.forEach((element) => {
				link.links?.push(creatLink(element));
			});
		}

		return link;
	}

	const preventDefaultBehaviour = (event: React.DragEvent<HTMLDivElement>) => {
		event.stopPropagation();

		event.preventDefault();
	};

	const onDragEnterAndOver = (event: React.DragEvent<HTMLDivElement>) => {
		preventDefaultBehaviour(event);

		setisHover(true);
	};

	const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
		preventDefaultBehaviour(event);

		setisHover(false);
	};

	function leave() {
		setisHover(false);
	}

	return (
		<>
		{reload?(
			<div><LoaderComp big={true}/></div>
		):(
			<div style={{ overflowX: "auto" }}>
				<div
					className={isHover ? `${styles.hoverDrop} ${styles.name}` : `${styles.name}`}
					onClick={() => clickFunctionWorkspace(nameWork)}
					onDragEnter={(e) => onDragEnterAndOver(e)}
					onDragLeave={(e) => onDragLeave(e)}
					onDragOver={(e) => onDragEnterAndOver(e)}
					onDrop={onDrop == undefined ? undefined : (e) => onDrop(e, undefined, leave)}
				>
					<FontIcon aria-label="Compass" style={{ marginRight: "4px", fontSize: 25 }} />

					{nameWork}
				</div>

				{displayNav && (
					<Nav
						className={`${styles.scroll__folder}`}
						groups={nav}
						onRenderLink={renderMenuList}
						styles={{
							linkText: {
								color: "black",

								selectors: { "&:hover": { color: "black" } },
							},

							link: {},
						}}
					/>
				)}
			</div>
		)}			
		</>
	);
};

export default WorkSpaceItemListFolderTree;
