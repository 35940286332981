import { useState, useEffect } from "react";
import {useSearchParams } from "react-router-dom";
import LoaderComp from '../../components/Loader/LoaderComp';
import {GetSharedFileInformationByToken, GetSharedFileDownloadByToken} from '../../services/SharedFileServices';
import Message from "../../components/Message/Message"
import { MessageBarType } from "@fluentui/react"
import ChoicerViwer from "../../pages/WorkSpaceItem/Viwer/ChoicerViwer";
import { Box } from '@mui/material';

const SharedFileAcess  = (props: any) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [token, setToken] = useState<string |undefined>(undefined);
    const [wait, setWait] = useState<Boolean>(false);
    const [name, setName] = useState<string>("");
    const [extension, setExtension] = useState<string |undefined>(undefined);
    const [erroMessage, setErroMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        let token = searchParams.get("token");
        setToken(token!);
    },[searchParams])

    useEffect(() => {
        if(token != undefined ){
            setWait(true);
            GetSharedFileInformationByToken(encodeURIComponent(token))
            .then((result) =>{
                setWait(false);
                if(result.name)
                    setName(result.name);
                
                if(result.extension)
                    setExtension(result.extension);
            })
            .catch((error)=>{
                if( error.response && error.response.data && typeof error.response.data  === 'string'){
                    setErroMessage(error.response.data);
                }
                else
                {
                    setErroMessage("Problema em pegar informações do arquivo");
                }
            })
            .finally(() =>{
                setWait(false);
            });
        }

    },[token])
    
    if(token == undefined)
    {
        return(
            <div>
                <Message
                    type={MessageBarType.error}
                    text={"Não encontrado arquivo"}
                />
            </div>
        );
    }

    return (
        <div style={{
            padding:"20px"
        }}>
            {wait && (<LoaderComp big={true}/>)}
            {erroMessage == undefined ?(
                <div>
                    <h3></h3>
                        {extension == undefined?name:name + "." + extension}
                    <h3></h3>
                    <ChoicerViwer
                        idItem={encodeURIComponent(token!)}
                        menuConfig={undefined}
                        download={GetSharedFileDownloadByToken}
                        getItem={GetSharedFileInformationByToken}
                        positionAbsolute={true}
                        fullScreenSetted={false}
                        isAcept={false}
                        isDetailPage={false}
                        isFilePage={false}
                        isSidebarCollapsed={false}
                        isSharedFilePage={true}
                    />
                </div>
            ):(
                <Message
                    type={MessageBarType.error}
                    text={erroMessage}
                />
            )}  
        </div>
    );
}

export default SharedFileAcess;