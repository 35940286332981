import React, { ChangeEvent, useCallback, useState } from "react";
import styles from "./WorkSpaceItemList.module.css";
import { useRef, useEffect } from "react";
import {TagsVO, TagName} from "../../model/Tags"
import {
    mergeStyleSets,
    Modal,
    IIconProps,
    Panel,
    ITag
} from '@fluentui/react';
import {GetAllTags} from "../../services/TagService"
import { TagPicker, IBasePickerSuggestionsProps } from '@fluentui/react/lib/Pickers';
import PanelGenericText from "../../components/PanelGenericText/PanelGenericText"
import Btn, { TypeStyle } from "../../components/Button/Btn";
import { useTranslation } from "react-i18next";
import {SetItemTag} from "../../services/Item/ItemServicesCRUD"
import Loader from "react-ts-loaders";

interface TagsProps {
    idMongo:string;
    setPopupError: (set: boolean, message: string) => void;
    setPopupOk: (set: boolean, message: string) => void;
    tags?:TagName[];
    idWorkspace:string;
    isOpen: boolean;   
    dismissPanel: () => void;
    name:string;
    descricao:string;
    indexOfArray: number;
    updateTagName: ((index: number, tagName: TagName[], isUpload: boolean) => void)
    setTagInternal: (tagName: TagName[] | undefined) => void;
    isUpload: boolean
}

const Tags: React.ForwardRefExoticComponent<TagsProps> =
	React.forwardRef(({ dismissPanel,isOpen,idMongo,setPopupError,setPopupOk,tags,idWorkspace,name,descricao, updateTagName, indexOfArray, isUpload, setTagInternal }, ref: React.Ref<HTMLLIElement>) => {

    const { t, i18n } = useTranslation();
    const [itensTagUser, setItensTagUser] = useState<ITag[] | undefined>([]);
    const [textTag, setTextTag] = useState<boolean>(false);
    const [textTagExist, setTextTagExist] = useState<boolean>(false);
    const [messageTag, setMessage] = useState<string>("");
    const [isWaitingTags, setIsWaitingTags] = useState<boolean>(false);

    const [nameArq, setNameArq] = useState<string>(name);
    const [descArq, setDescArq] = useState<string>(descricao);

    useEffect(() => {
        setNameArq(name)
        setDescArq(descricao)
    },[name,descricao ])

    function addTagItem(){
        let newTagName:TagName[] = [];
        itensTagUser?.map((tag) => {
            newTagName.push({
            idTag:tag.key.toString(),
            name:tag.name
            })
        })

        setIsWaitingTags(true)
        var newTag: TagsVO = {
            idItem: idMongo!,
            tagName: newTagName
        }

        SetItemTag(newTag)
        .then((tagReturn) => {
            if(tags != undefined && tags.length > 0){
                setPopupOk(true, t("tag.editMessage"));
            }
            else{              
                setPopupOk(true, t("tag.addMessage"));
            }
            setTagInternal(tagReturn)
            updateTagName(indexOfArray, tagReturn, isUpload)
        })
        .catch((error) => {
            if( error.response && error.response.data && typeof error.response.data  === 'string'){
                setPopupError(true, error.response.data);
            }
            else if(tags != undefined && tags.length > 0){
                setPopupError(true, t("tag.errorEdit"));
            }
            else{              
                setPopupError(true, t("tag.errorAdd"));
            }
        })
        .finally(() => {
            setIsWaitingTags(false)
            dismissPanel();
        })
    }

    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: messageTag,
        noResultsFoundText: textTagExist == true ?t("tag.tagExist"):t("tag.notFound"),
    };

    const getTextFromItem = (item: ITag) => item.name;

    function convertResultsToPromise(results: ITag[]): PromiseLike<ITag[]> {
    return new Promise<ITag[]>((resolve, reject) => setTimeout(() => resolve(results), 2000));
    }
    
    useEffect(() => {
    if(textTag){
       
    setMessage(t("tag.new"))
        
    }else{
        setMessage(t("tag.suggested"))
    }

    if(textTagExist == true){          
        setMessage(t("tag.messageTag"))
    } 

    },[messageTag,textTag,textTagExist])
         
    useEffect(() => {
        if(tags != undefined){         
          let newItens: ITag[] = 
          tags.map(tag => ({ key: tag.idTag, name: tag.name }));
          setItensTagUser(newItens);
        }
      },[tags])

  
    const onChange = (items: ITag[] | undefined) => {
    setItensTagUser(items);
    };

    const filterPromise = (selectedItems?: ITag[]): ITag[] | PromiseLike<ITag[]> => {
    var idTags:string[];
    idTags = [];

    itensTagUser?.map((x) => 
    idTags.push(x.key.toString()))

    return GetAllTags(idWorkspace,0,50,"",idTags)
    .then((tags) => {
        const userTags: ITag[] = tags.map(item => ({ key: item.id, name: item.name}));
        return userTags;
    })
    .catch(() =>{
        return [];
    });
    };

    const onResolveSuggestions = async (
    filter: string,
    selectedItems: ITag[] | undefined
    ): Promise<ITag[]> => {
        var idTags:string[];
        idTags = [];

        var existTag:boolean = false;

        itensTagUser?.map((x) => {
            idTags.push(x.key.toString())

            if(filter[0] != "#"){
                if(x.name == "#"+filter){
                    setTextTagExist(true)
                    existTag = true;
                }
                else{
                    setTextTagExist(false)
                }
            }
            else{
                if(x.name == filter){     
                    setTextTagExist(true)
                    existTag = true;
                }
                else{
                    setTextTagExist(false)
                }
            }
        })

    if (filter) {
        return GetAllTags(idWorkspace,0,50,filter.replace("#","%23"),idTags)
        .then((users) => {
        if(users.length == 0)
        {           
            if(filter[0] != "#"){
                console.log(existTag)
                if(existTag){
                    return[];
                }
                else{
                    setTextTag(true)
                    const userTags: ITag[] = [{key : "", name : "#"+filter}];
                    return userTags;
                }              
            }
            else{

                if(existTag){
                    return[];
                }
                else{                   
                    setTextTag(true)
                    const userTags: ITag[] = [{key : "", name : filter}];
                    return userTags; 
                }                          
            }
        }
        else
        { 
            if(existTag){
                return[];
            }
            else{
                 
                console.log("else 3")
                const userTags: ITag[] = users.map(item => ({ key: item.id, name: item.name}));
                return userTags;
            }         
        }
        })
        .catch(() =>{
        return [];
        });
    }
    return [];
    };


    return (
        <Panel
            isOpen={isOpen}
            onDismiss={dismissPanel}
            styles={{
                main: {
                width: "630px !important",
                padding: '50px',
                },
                content: {
                position: 'relative',
                height: '80vh',
                }
            }}
            hasCloseButton={false}
            >
                
                <PanelGenericText
                    text={tags != undefined? tags.length > 0?t("tag.edit"):t("tag.add"):t("tag.add")}
                    paragraph={t("tag.insertMessage")}
                    style={{marginBottom:"21px"}}
                />
                <div className={styles.gridTags}>
                    <label className={styles.nameItem}> {t("tag.nameFile")}</label>
                    <label className={styles.dataItem}>{nameArq}</label>

                    <label className={styles.descItem}> {t("tag.descFile")}</label>
                    {descArq != undefined?(
                        <label className={styles.dataItem}>{descArq}</label>
                    ):(
                        <label className={styles.dataItem}>{t("tag.semDesc")}</label>
                    )}
                </div>

                <label className={styles.labelTag}>{t("tag.fileTag")}</label>
                <TagPicker
                    removeButtonAriaLabel="Remove"
                    selectionAriaLabel="People"
                    onResolveSuggestions={onResolveSuggestions}
                    getTextFromItem={getTextFromItem}
                    pickerSuggestionsProps={pickerSuggestionsProps}
                    removeButtonIconProps={{ iconName: 'Delete' }}
                    onChange={onChange}
                    defaultSelectedItems={itensTagUser}
                    onEmptyInputFocus={filterPromise}
                    pickerCalloutProps={{
                        inputMode: "search",
                        calloutWidth: 480,
                        styles: {
                        root: {
                            top: "424px !important",
                            right: "72px !important",
                            width:"484px !important",
                            maxHeight: "107px !important",
                            boxShadow: "none !important"
                        },
                        calloutMain: {
                            border: '1px solid #989898',
                            borderRadius: "8px"
                        }
                        },
                        style: {
                        top: "0px"
                        },
                    }}
                    searchingText={t("tag.LoadingResults")}
                    styles={{
                        text: {
                        borderRadius: 8,
                        textAlign: "center",
                        paddingBottom: 5,
                        },
                        root: {
                        top: "310px",
                        maxHeight: "120px !important",
                        }
                    }}
                />

                {isWaitingTags ? (
                    <Loader className={styles.waitingTagLoader}/>
                ) : (
                    <div>
                        <Btn
                            text={t("tag.save")}
                            style={TypeStyle.buttonTagSaveStyle}
                            onClick={addTagItem}
                        />
                        <Btn
                            text={t("tag.cancel")}
                            style={TypeStyle.buttonTagCancelStyle}
                            onClick={dismissPanel}
                        />
                    </div>
                )}


        </Panel>             
    );
});

    
export default Tags;
