import React, { useEffect, useState } from "react";
import { useParams  } from "react-router-dom";
import styles from "../../Style/List.module.css";
import { Stack } from "@fluentui/react/lib/Stack";
import { Panel } from "@fluentui/react/lib/Panel";
import TextFieldComp from "../../../components/TextField/TextFieldComp";
import Btn from "../../../components/Button/Btn";
import { useBoolean } from "@fluentui/react-hooks";
import GroupUsersList from "./GroupUsersList";
import GroupUsersListAdd from "./GroupUsersListAdd";
import { useTranslation } from "react-i18next";
import { GetOneGroup } from "../../../services/GroupsServices";

const GroupUsers = (props: any) => {

    const [groupName, setGroupName] = useState<string>("");
    const [searchName, setSearchName] = useState<string>("");
    const [searchNameAdd, setSearchNameAdd] = useState<string>("");
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);
    const [reaload, setReaload] = useState<boolean>(false);
    const { t, i18n } = useTranslation();
    const { nameOrg } = useParams();
    const { idGroup } = useParams();

    const onChangeSearchName =
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined ) {
            setSearchName(newValue);
        }
    }; 


    const onChangeSearchNameAdd =
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined ) {
            setSearchNameAdd(newValue);
        }
    }; 

    const onClickNew = () => {
        openPanel();
    };

    const updatePanel = () => {
        setReaload(true);
        dismissPanel();
      };

      useEffect(() => {
        if(reaload == true ){
          setReaload(false)
        }
      }, [reaload]);

      useEffect(() => {
        if(idGroup != undefined ){
            GetOneGroup(idGroup)
            .then((groupResult) => {
                if(groupResult.name)
                    setGroupName(groupResult.name);
            });
        }
      }, [idGroup]);

    return (
        <div>
            <Stack horizontal className={styles.hPage}>
                <Stack.Item grow>
                    <h3 className={styles.hList}>{t("UsuarioDoGrupo.message")} {groupName}</h3>
                </Stack.Item>
                <Stack className={styles.stackSide}>
                    <Stack.Item className={styles.BtnNew}>
                        <Btn text={t("Adicionar.message")} onClick={onClickNew} />
                    </Stack.Item>
                    <Stack.Item className={styles.InputSearch}>
                        <TextFieldComp iconProps={{ iconName: "Filter" }}  value={searchName} onChange={onChangeSearchName}/>
                    </Stack.Item>
                </Stack>
            </Stack>
            {reaload?(<div></div>):(
            nameOrg != undefined && idGroup!= undefined?(<GroupUsersList idOrg={nameOrg} idGroup={idGroup} name={searchName}/>):(<li className={styles.itemContent}></li>))}
            <Panel
                isOpen={isOpen}
                closeButtonAriaLabel={t("Fechar.message")}
                headerText={t("AdicionarUsuario.message")}
                isFooterAtBottom={true}
                onDismiss={updatePanel}
            >
                <TextFieldComp iconProps={{ iconName: "Filter" }}  value={searchNameAdd} onChange={onChangeSearchNameAdd}/>
                {nameOrg != undefined && idGroup!= undefined?(<GroupUsersListAdd idOrg={nameOrg} idGroup={idGroup} name={searchNameAdd}/>):(<li className={styles.itemContent}></li>)}
            </Panel>
        </div>
    );
}

export default GroupUsers;