import { Tooltip } from "@mui/material";
import { MenuItem } from "react-pro-sidebar";
import './ContentSide.css';

type ContentbarType = 'sidebar' | 'actionIcon'


export interface ContentbarProps{
    children: string;
    type: ContentbarType;
    title?: string;
    conditional?: boolean;
    fill?: string;
    footer?: boolean;
    icon?: JSX.Element;
    onClick?: () => void
    handleChange?: (event: React.MouseEvent) => void,
    isSelect:boolean
}

export const ContentSideComp: React.FC<ContentbarProps> = ({children, type, title, conditional, fill, icon, onClick, handleChange,isSelect}: ContentbarProps) => {
  if(type == "sidebar"){
    if(conditional == false)
      return null;

    return(
      <Tooltip title={title} disableInteractive placement="right-start">
        {conditional?(
          <MenuItem
            className={isSelect == false ? undefined:"pro-menu-item-black" }
            icon={icon}
            onClick={onClick}>
              {children}
          </MenuItem>
          ):(<div></div>)}
      </Tooltip>
    )
  }

  if(type == "actionIcon"){
    return(
      <Tooltip title={title} disableInteractive placement="right-start">
        <MenuItem icon={icon} onClick={handleChange}>
            {children}
        </MenuItem>
      </Tooltip >
    )
  }
  return <div></div>
}