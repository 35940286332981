import React from 'react';
import { Color } from './interface/Color';

const Analystic: React.FC<Color> = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18.182" viewBox="0 0 20 18.182">
            <g id="icons" transform="translate(-1 -2)">
                <g id="statistics" transform="translate(1 2)">
                <path id="Caminho_114" data-name="Caminho 114" d="M4.636,12H2.818A1.824,1.824,0,0,0,1,13.818v5.455a1.824,1.824,0,0,0,1.818,1.818H4.636a1.824,1.824,0,0,0,1.818-1.818V13.818A1.824,1.824,0,0,0,4.636,12Z" transform="translate(-1 -2.909)" fill={color}/>
                <path id="Caminho_115" data-name="Caminho 115" d="M12.636,2H10.818A1.824,1.824,0,0,0,9,3.818V18.364a1.824,1.824,0,0,0,1.818,1.818h1.818a1.824,1.824,0,0,0,1.818-1.818V3.818A1.824,1.824,0,0,0,12.636,2Z" transform="translate(-1.727 -2)" fill={color}/>
                <path id="Caminho_116" data-name="Caminho 116" d="M20.636,8H18.818A1.824,1.824,0,0,0,17,9.818v9.091a1.824,1.824,0,0,0,1.818,1.818h1.818a1.824,1.824,0,0,0,1.818-1.818V9.818A1.824,1.824,0,0,0,20.636,8Z" transform="translate(-2.455 -2.545)" fill={color}/>
                </g>
            </g>
        </svg>
  );
};

export default Analystic;
