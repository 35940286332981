import { Box, Grid } from "@mui/material"
import KeyDriveLogo from "../../assets/images/KeyDriveLogoIcon.png";
import W3KLogo from "../../assets/images/w3k.png"
import FormPrivacy from "./components/Handle/Form/Components/FormPrivacy"

export const KeyLogos = () => {
    return (
        <>
            <Box component={'img'} src={KeyDriveLogo} sx={{ position: 'absolute', top: 0, left: 0, zIndex: 1, width: '158px', height: '22px', margin: '40px' }} />

            <Grid sx={{ position: 'absolute', bottom: 0, left: 0, zIndex: 1, margin: '0px 0px 10px 40px' }}>
                <Box component={'img'} src={W3KLogo} marginBottom={'12px'} />
                <FormPrivacy />
            </Grid>
        </>
    )
}