import { Grid, Box } from '@mui/material';
import AlertComponent from '../../../../../../../components/Alert/AlertComponent';

interface IFormErrors {
    isChecked?: boolean,
    messagePass: string
}

export default function FormErrors({
    isChecked,
    messagePass
}: IFormErrors) 
{
    return (
        <Grid item xs={12}>
            { isChecked && (
                <Box sx={{
                    marginTop: '4px'
                }}>
                    <AlertComponent 
                        text={messagePass}
                        type='error'
                    />
                </Box>
            ) }
        </Grid>
    )
}