import React, {useState, useEffect, KeyboardEventHandler} from "react";
import { PrimaryButton, IButtonStyles } from '@fluentui/react/lib/Button';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';

export enum TypeStyle {
    sampleButtonSt,
    errorButtonSty,
    pathButtonSty,
    headerButtonStyles,
    lastPathButtonStyles,
    startButton,
    saveButton,
    AddTagButton,
    EditTagButton,
    buttonTagSaveStyle,
    buttonTagCancelStyle,
    temporalityButtonStylesReset,
    temporalityButtonStylesCancel
}

export enum ButtonTyppe {
    primary,
    secondary
}

export interface BtnConfig{
    text?: string;
    disabled?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    red?: boolean;
    float?:string;
    className?:string;
    id?:string;
    style?:TypeStyle,
    hidden?:boolean,
    menuProps?: IContextualMenuProps;
    customStile?: IButtonStyles;
    pathButtonWithMenu?: boolean;
    isPrimary?:ButtonTyppe;
}

const Btn: React.ForwardRefExoticComponent<BtnConfig> = 
    React.forwardRef(({
        text,
        disabled,
        onClick,
        red,
        float,
        className,
        id,
        style,
        hidden,
        menuProps,
        customStile,
        pathButtonWithMenu,
        isPrimary,
      }, ref) => {

    const [hiddenInternal, setHiddenInternal] = useState<boolean>(false);
    const [disabledInternal, setDisabledInternal] = useState<boolean>(false);
    const [floatInternal, setFloatInternal] = useState<string>("none");

    const sampleButtonStyles: IButtonStyles = {
        root: {
            backgroundColor: "#f8f8f8",
            color: "#114D88",
            borderColor: "#114D88",
            float: floatInternal,
            margin:"5px",
            height:"40px",
            maxHeight:"40px"
        },
        rootHovered: {
            backgroundColor: "#ebebeb",
            color: "#114D88",
            borderColor: "#114D88"
          }
      }

      const buttonTagSaveStyle: IButtonStyles = {
        root: {
            backgroundColor: "#005A9E",
            color: "#114D88",
            borderColor: "#005A9E",
            bottom: "68px",
            width: "100%",
            borderRadius: "6px",
            position: "unset",
            marginBottom:"20px",
            height:"44px"
        },
        rootHovered:{
            backgroundColor: "#114d88",
            color: "#114D88",
            borderColor: "#114d88"
        },
        label:{
            font: "normal normal 600 18px/18px Segoe UI",
            letterSpacing: "0px",
            color: "#FFFFFF"
        },
        labelHovered:{
            font: "normal normal 600 18px/18px Segoe UI",
            letterSpacing: "0px",
            color: "#FFFFFF"
        }
      }

      
      const buttonTagCancelStyle: IButtonStyles = {
        root: {
            width: "100%",
            border: "1px solid #005A9E",
            borderRadius: "8px",
            background:"#FFFFFF",
            height:"44px"
        },
        rootHovered:{
            border: "1px solid #004C85",
            background:"#004C85",
        },
        label:{
            color:"#114d88",
            font: "normal normal 600 18px/18px Segoe UI",
            letterSpacing: "0px"
        },
        labelHovered:{
            color:"#FFFFFF",
            font: "normal normal 600 18px/18px Segoe UI",
            letterSpacing: "0px"
        }
      }

      const errorButtonStyles: IButtonStyles = {
        root: {
            backgroundColor: "red",
            color: "white",
            borderColor: "red",
            float: floatInternal,
            margin:"5px",
            height:"40px",
            maxHeight:"40px"
        },
        rootHovered: {
            backgroundColor: "#f57171",
            color: "white",
            borderColor: "red"
          }
      }

      const headerButtonStyles: IButtonStyles = {
        root: {
            width: '38px',
            height: '38px',
            border: '1px solid #e7e7e7',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }
      }

      const temporalityButtonStylesReset: IButtonStyles = {
        root: {
            width: '352px',
            height: '44px',
            border: '1px solid #005A9E',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: 1
        }
      }

      const temporalityButtonStylesCancel: IButtonStyles = {
        root: {
            width: '352px',
            height: '44px',
            border: '1px solid #005A9E',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: 1,
            background: "white",
            color: "rgb(16, 110, 190)",
            marginRight: "20px"
        }
      }
      const saveButton: IButtonStyles = {
        root: {
            width: "79px",
            height: "32px",
            background: "#005A9E 0% 0% no-repeat padding-box",
            borderRadius: "6px",
            opacity: 1,
        }
      }

      const pathButtonStyles: IButtonStyles = {
        root: {
            backgroundColor: "#f8f8f8",
            color: pathButtonWithMenu ? "#000000" : "#9c9c9c",
            fontWeight: pathButtonWithMenu ? "bold" : "normal",
            border: "none",
            float: floatInternal,
            alignItems: "self-end",
            padding: 0
        },
        rootHovered: {
            backgroundColor: "#f8f8f8",
            color: pathButtonWithMenu ? "#000000" : "#9c9c9c",
            border: "none",
        },
        rootPressed: {
            backgroundColor: "#f8f8f8",
            color: pathButtonWithMenu ? "#000000" : "#9c9c9c",
            border: "none",
        },
        ...(pathButtonWithMenu && {
            rootExpandedHovered: {
                backgroundColor: "#f8f8f8",
                color: "#000000",
                border: "none",
            },
            rootExpanded: {
                backgroundColor: "#f8f8f8",
                color: "#000000",
                border: "none",
            },
        })
      }

      const startButton: IButtonStyles = {
        root: {
            background: "rgb(245, 245, 245, 0.3) 0% 0% no-repeat padding-box",
            border: "1px solid #989898",
            borderRadius: "10px",
            width:  '84px'
        },
        label: {
            color: 'black'
        },
        rootHovered: {
            background: "#fff",
            borderRadius: "10px",
            width:  '84px',
            boxShadow: "rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px",
            border: 'none'
        },
        rootPressed: {
            background: "rgb(245, 245, 245, 0.3) 0% 0% no-repeat padding-box",
            borderRadius: "10px 10px 0px 0px",
            width: '84px'
        },
        menuIcon: {
            color: 'black',
        },
        menuIconExpanded: {
            transform: 'rotate(180deg)',
        },
        rootExpanded: {
            borderRadius: "10px 10px 0px 0px",
            border: 'none',
            background: '#fff',
            boxShadow: "rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px",
        },
      }

      const EditTagButton: IButtonStyles = {
        root: {
            background: "#F6F6F6 0% 0% no-repeat padding-box",
            border: "1px solid #D1D1D1",
            borderRadius: "20px",
            width: "118px",
            height:"32px",
        },
        label: {
            color: '#8E8E8E',
            maxWidth: "73px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap"
        },
        labelHovered:{
            color: 'white',
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap"
        },
        rootHovered: {
            background: "#D1D1D1",
            borderRadius: "20px",
            width: "118px",
            height:"32px",
            border:"none"
        },
        rootPressed: {
            background: "#D1D1D1 0% 0% no-repeat padding-box",
            borderRadius: "20px",
            width: "118px",
            height:"32px",
        },
        menuIcon: {
            color: 'black',
        },
        menuIconExpanded: {
            transform: 'rotate(180deg)',
        },
        rootExpanded: {
            borderRadius: "10px 10px 0px 0px",
            border: 'none',
            background: '#fff'
        }
      }

      const AddTagButton: IButtonStyles = {
        root: {
            background: "rgb(245, 245, 245, 0.3) 0% 0% no-repeat padding-box",
            border: "1px solid #005a9e",
            borderRadius: "20px",
            minWidth: "135px",
            height:"32px",
            textAlign: 'center',
           
        },
        label: {
            color: '#005a9e',

        },
        labelHovered:{
            color: 'white'
        },
        rootHovered: {
            background: "#005a9e",
            borderRadius: "20px",
            border: 'none'
        },
        rootPressed: {
            background: "rgb(245, 245, 245, 0.3) 0% 0% no-repeat padding-box",
            borderRadius: "20px",
            height:"32px",
        },
        menuIcon: {
            color: 'black',
        },
        menuIconExpanded: {
            transform: 'rotate(180deg)',
        },
        rootExpanded: {
            borderRadius: "20px",
            border: 'none',
            background: '#fff',
            boxShadow: "rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px",
        }
      }



    const lastPathButtonStyles: IButtonStyles = {
        root: {
            border: "1px solid #989898",
            background: 'transparent',
            color: "#989898",
            width: 'fit-content',
            borderRadius: '10px',
            fontWeight: 'bold',
            paddingLeft:"8px",
            paddingRight:"8px"
        },
        rootHovered: {
            backgroundColor: "#fff",
            border: "1px solid #989898",
            color: "#989898",
            width:  'fit-content',
            borderRadius: '10px'
        },
        rootPressed: {
            border: "1px solid #989898",
            background: 'transparent',
            color: "#989898",
            width: 'fit-content',
            borderRadius: '10px',
            fontWeight: 'bold'
        },
        ...(pathButtonWithMenu && {
            rootExpandedHovered: {
                background: '#FFF',
                color: "#989898",
                width: 'fit-content',
                borderRadius: '10px 10px 0px 0px',
                fontWeight: 'bold',
                border: 'none',
            },
            rootExpanded: {
                background: '#FFF',
                color: "#989898",
                width: 'fit-content',
                borderRadius: '10px 10px 0px 0px',
                fontWeight: 'bold',
                border: 'none',
            },
            menuIconExpanded: {
                transform: 'rotate(180deg)'
            }
        })
    }

    const btnActionButton: IButtonStyles = {
        root: {
            border: "1px solid #989898",
            background: 'transparent',
            color: "#989898",
            width: 'fit-content',
            borderRadius: '10px',
            fontWeight: 'bold',
            paddingLeft:"8px",
            paddingRight:"8px"
        },
        rootHovered: {
            backgroundColor: "#fff",
            border: "1px solid #989898",
            color: "#989898",
            width:  'fit-content',
            borderRadius: '10px'
        },
        rootPressed: {
            border: "1px solid #989898",
            background: 'transparent',
            color: "#989898",
            width: 'fit-content',
            borderRadius: '10px',
            fontWeight: 'bold'
        },
        ...(pathButtonWithMenu && {
            rootExpandedHovered: {
                background: '#FFF',
                color: "#989898",
                width: 'fit-content',
                borderRadius: '10px 10px 0px 0px',
                fontWeight: 'bold',
                border: 'none',
            },
            rootExpanded: {
                background: '#FFF',
                color: "#989898",
                width: 'fit-content',
                borderRadius: '10px 10px 0px 0px',
                fontWeight: 'bold',
                border: 'none',
            },
            menuIconExpanded: {
                transform: 'rotate(180deg)'
            }
        })
    }

    const [actualStyle, setActualStyle] = useState<IButtonStyles>(sampleButtonStyles);

    useEffect(() => {
        if(float)
        {
            setFloatInternal(float);
        }

        if(disabled != undefined)
        {
            setDisabledInternal(disabled);
        }

        if(hidden != undefined)
        {           
            setHiddenInternal(hidden);
        }

        if(customStile == undefined)
        {
            setActualStyle(sampleButtonStyles);
            if(red == true || style == TypeStyle.errorButtonSty)
            {
                setActualStyle(errorButtonStyles);
            }
            else if(style == TypeStyle.pathButtonSty)
            {
                setActualStyle(pathButtonStyles)
            }
            else if(style === TypeStyle.headerButtonStyles)
            {
                setActualStyle(headerButtonStyles)
            }
            else if(style === TypeStyle.lastPathButtonStyles)
            {
                setActualStyle(lastPathButtonStyles)
            }
            else if(style === TypeStyle.startButton)
            {
                setActualStyle(startButton)
            }
            else if (style === TypeStyle.saveButton)
            {
                setActualStyle(saveButton);
            }
            else if(style == TypeStyle.AddTagButton){
                setActualStyle(AddTagButton)
            }
            else if(style == TypeStyle.EditTagButton){
                setActualStyle(EditTagButton)
            }
            else if(style == TypeStyle.buttonTagSaveStyle){
                setActualStyle(buttonTagSaveStyle)
            }
            else if(style == TypeStyle.buttonTagCancelStyle){
                setActualStyle(buttonTagCancelStyle)
            }
            else if(style == TypeStyle.temporalityButtonStylesReset){
                setActualStyle(temporalityButtonStylesReset)
            }
            else if(style == TypeStyle.temporalityButtonStylesCancel){
                setActualStyle(temporalityButtonStylesCancel)
            }
        }
        else
        {
            setActualStyle(customStile);
        }
    }, []);

    useEffect(() => {
        if(float)
        {
            setFloatInternal(float);
        }
    }, [float]);

    useEffect(() => {
        if(disabled != undefined)
        {
            setDisabledInternal(disabled);
        }
    }, [disabled]);

    useEffect(() => {
        if(hidden != undefined)
        {
            setHiddenInternal(hidden);
        }
    }, [hidden]);

    useEffect(() => {
        if(customStile == undefined)
        {
            setActualStyle(sampleButtonStyles);
            if(red == true || style == TypeStyle.errorButtonSty)
            {
                setActualStyle(errorButtonStyles);
            }
            else if(style == TypeStyle.pathButtonSty)
            {
                setActualStyle(pathButtonStyles)
            }
            else if(style == TypeStyle.headerButtonStyles)
            {
                setActualStyle(headerButtonStyles)
            }
            else if(style == TypeStyle.lastPathButtonStyles)
            {
                setActualStyle(lastPathButtonStyles)
            }
            else if(style === TypeStyle.startButton)
            {
                setActualStyle(startButton)
            }
            else if (style === TypeStyle.saveButton)
            {
                setActualStyle(saveButton);
            }
            else if(style == TypeStyle.AddTagButton){
                setActualStyle(AddTagButton)
            }
            else if(style == TypeStyle.EditTagButton){
                setActualStyle(EditTagButton)
            }
            else if(style == TypeStyle.buttonTagSaveStyle){
                setActualStyle(buttonTagSaveStyle)
            }
            else if(style == TypeStyle.buttonTagCancelStyle){
                setActualStyle(buttonTagCancelStyle)
            }          
            else if(style == TypeStyle.temporalityButtonStylesReset){
                setActualStyle(temporalityButtonStylesReset)
            }
            else if(style == TypeStyle.temporalityButtonStylesCancel){
                setActualStyle(temporalityButtonStylesCancel)
            }
        }
        else
        {
            setActualStyle(customStile);
        }
    }, [style]);

    if(hiddenInternal)
    {
        return (null);
    }

    return (
        <PrimaryButton
            disabled={disabledInternal}
            text={text}
            onClick={onClick} 
            allowDisabledFocus
            styles={actualStyle}
            className={className}
            id={id}
            menuProps={menuProps}
        />
    );
})

export default Btn;