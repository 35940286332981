import React, { useEffect, useState, useMemo } from "react";

import { TranslatorS } from "../../components/I18n";

import styles from "../Style/List.module.css";

import { Stack } from "@fluentui/react/lib/Stack";

import stylesList from "./WorkSpaceItemList.module.css";

import { NewFolderConfig } from "./PanelItens/CreateFolderComp";
import { BatchChangeStatusModel, BatchDownloadModel, IPath, WorkSpaceItem } from "../../model/ItemModel"

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PathListingMap } from "./PathListingMap";
import { filesSelected } from "./WorkSpaceItemListFile";
import { BatchDownloadPost, SetBatchDraft, SetBatchRelease } from "../../services/Item/ItemServicesCRUD";
import {  DownloadWithoutLogin } from "../../services/Item/ItemServicesGet";
import { IContextualMenuProps, IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { addIcon, refreshIcon, batchActionIcon } from '../../assets/icons'
import { Box } from "@mui/material";
import { IconButton } from '@fluentui/react/lib/Button';
import documentIcon from '../../assets/icons/documento.svg'
import pasteIcon from '../../assets/icons/folder_alter.svg'
import permissions from '../../assets/icons/permissoes.svg'
import userPermissions from '../../assets/icons/permissoes-de-usuarios.svg'
import MenuStiledRenderProps from "../../components/Menu/MenuStiledRender";
import downloadIcon from '../../assets/icons/download.svg'
import draft from '../../assets/icons/iconDisable/draft'
import release from '../../assets/icons/iconDisable/release'
import ChooseColumns from "../../components/ChooseColumns/ChooseColumns";


interface WorkSpaceItemListFileHeaderProps {
    nameOrg: string;
    idWorkspace: string;
    idMongoNewFolder?: string;
    clickUpload: () => void;
    clickUploadFolder: () => void;
    refresh: () => void;
    setFolderConfig: (newFolder:NewFolderConfig) => void;
    typePermission?: number;
    isAdmin: boolean;
    path: IPath[];
    nameFolder:string;
    onClickLinkMap: (idFolder:string | undefined, nameFolder:string | undefined) => void;
    selectedCheckboxes: filesSelected[]
    setPopupError: (set: boolean, message: string) => void;
    setPopupOk: (set: boolean, message: string) => void;
    quantityFileSelected: () => number
    quantityAbleToReleaseSelected: () => number
    quantityAbleToDraftSelected: () => number
    setSelectedCheckbox: (set: filesSelected[]) => void;
    setCurrentBatchStatus: (set: "DRAFT" | "RELEASE") => void;
    batchRefresh: () => void;
    setBatchWaiter: (se: boolean) => void;
  }

const WorkSpaceItemListFileHeader: React.ForwardRefExoticComponent<WorkSpaceItemListFileHeaderProps> = 
React.forwardRef(({
    nameOrg,
    idWorkspace,
    idMongoNewFolder, 
    clickUpload, 
    clickUploadFolder,
    refresh, 
    setFolderConfig,
    typePermission,
    isAdmin,
    path,
    nameFolder,
    onClickLinkMap,
    selectedCheckboxes,
    setPopupError,
    setPopupOk,
    quantityFileSelected,
    quantityAbleToReleaseSelected,
    quantityAbleToDraftSelected,
    setSelectedCheckbox,
    setCurrentBatchStatus,
    batchRefresh,
    setBatchWaiter
}, ref) => {
    const { t, i18n } = useTranslation();
    const navegate = useNavigate();
    const [isActionBatch, setIsActionBatch] = useState<boolean>(false);
    const [isAbleToRelease, setIsAbleToRelease] = useState<boolean>(false);
    const [isAbleToDraft, setIsAbleToDraft] = useState<boolean>(false);
    const [menuItems, setMenuItems] = useState<IContextualMenuItem[]>([]);
    const [menuItemsBach, setMenuItemsBach] = useState<IContextualMenuItem[]>([]);

    const refreshClick = (event: React.MouseEvent<HTMLElement>) => {
        if(refresh)
            refresh();
    };

	const upload = () => {
		if (clickUpload) clickUpload();

		return true;
	};

    const uploadFolder = () => {
		if (clickUploadFolder) clickUploadFolder();

		return true;
	};

    const nefolder = () => {
		const newFolder: NewFolderConfig = {
			idWork: idWorkspace,

			idFolder: idMongoNewFolder,
		};

		setFolderConfig(newFolder);

		return true;
	};


	const permission = () => {
		navegate("/" + nameOrg + "/WorkSpace/" + idWorkspace + "/Permission");

		return true;
	};

    const permissionUser = () => {
        navegate("/" + nameOrg + "/WorkSpace/" + idWorkspace + "/UserPermission");
        return true;
    }

    const batchDownloadClick = () => {
        const batchDownload: BatchDownloadModel = {
            IdItens: selectedCheckboxes.map(item => item.id),
        };
        BatchDownloadPost(batchDownload)
        .then(res => 
        {
            if(res.id != undefined)
            {
                window.open(DownloadWithoutLogin(res.id), "_blank")?.focus();
            }
        })
        .catch(err => {
            setPopupError(true, t("DownloadFileError.message"));
        })

    }

    const batchReleaseClick = () => {
        const batchRelease: BatchChangeStatusModel = {
            idChangeStatus: selectedCheckboxes
                .filter(item => item.isRelease === false && item.validationRule === undefined)
                .map(item => item.id),
        }
        var filesReleased: filesSelected[] = selectedCheckboxes.map(file => {
            return {
                extension: file.extension,
                id: file.id,
                img: file.img,
                isFolder: file.isFolder,
                name: file.name,
                isRelease: true,
                validationRule: undefined
            }
        });
        SetBatchRelease(batchRelease)
        .then(res => {
            setPopupOk(true, t("FilesWentToRelease.message"))
            batchRefresh();
            setSelectedCheckbox(filesReleased);
            setCurrentBatchStatus("RELEASE");
        })
        .catch(err => {
            setPopupError(true, t("ErrorWhenUpdatingToRelease.message"));
        }) 
        .finally(() => {
            setBatchWaiter(false);
        })      
    }

    const batchDraftClick = () => {        
        const batchDraft: BatchChangeStatusModel = {
            idChangeStatus: selectedCheckboxes
                .filter(item => item.isRelease === true && item.validationRule === undefined)
                .map(item => item.id),
        }
        var filesDrafted: filesSelected[] = selectedCheckboxes.map(file => {
            return {
                extension: file.extension,
                id: file.id,
                img: file.img,
                isFolder: file.isFolder,
                name: file.name,
                isRelease: false,
                validationRule: undefined
            }
        })
        SetBatchDraft(batchDraft)
        .then(res => 
        {
            setSelectedCheckbox(filesDrafted);
            setCurrentBatchStatus("DRAFT");
            setPopupOk(true, t("FilesWentToDraft.message"));
        })
        .catch(err => {
            setPopupError(true, t("ErrorWhenUpdatingToDraft.message"));
        })
    }

    useEffect(() => {
        if (selectedCheckboxes.length > 1) {
            if (quantityFileSelected() >= 2) {
                setIsActionBatch(true)

                if(quantityAbleToReleaseSelected() >=2){
                    setIsAbleToRelease(true)
                }else{
                    setIsAbleToRelease(false)
                }

                if(quantityAbleToDraftSelected() >=2){
                    setIsAbleToDraft(true)
                }else{
                    setIsAbleToDraft(false)
                }
            } else {
                setIsActionBatch(false);
            }
        } else {
            setIsActionBatch(false);
        }
    }, [selectedCheckboxes, isActionBatch])

    useEffect(() => {

        let items: IContextualMenuItem[]= [
            { 
                key: 'download', 
                text: 'Download', 
                onClick: () => {batchDownloadClick()},
                onRender: (item) =>         
                    <MenuStiledRenderProps
                      item={item}
                      widthStandar={true}
                      disabled={false}
                      src={downloadIcon}
                      onClick={() => batchDownloadClick()}
                    />
            },
            { 
                key: 'release', 
                text: 'Release',
                onClick: () => {batchReleaseClick()}, 
                disabled: !isAbleToRelease,
                onRender: (item) =>         
                    <MenuStiledRenderProps
                      item={item}
                      widthStandar={true}
                      disabled={!isAbleToRelease}
                      icon={draft}
                      onClick={() => batchReleaseClick()}
                    />
            },
            { 
                key: 'draft', 
                text: 'Draft',
                onClick: () => {batchDraftClick()}, 
                disabled: !isAbleToDraft,
                onRender: (item) =>           
                    <MenuStiledRenderProps
                      item={item}
                      widthStandar={true}
                      disabled={!isAbleToDraft}
                      icon={release}
                      onClick={() => batchDraftClick()}
                    />
            },
        ];

        setMenuItemsBach(items);
    },[isAbleToRelease, isAbleToDraft, selectedCheckboxes])

    useEffect(() => {
        let items: IContextualMenuItem[]= [
            { 
                key: 'upload', 
                text: t("Upload.file"), 
                onClick: () => {upload()},
                onRender: (item) =>            
                    <MenuStiledRenderProps
                      item={item}
                      widthStandar={true}
                      disabled={false}
                      src={documentIcon}
                      onClick={() => upload()}
                    />
            },
            { 
                key: 'Uploadfolder', 
                text: t("Upload.folder"),
                onClick: () => {uploadFolder()}, 
                disabled: !isAbleToRelease,
                onRender: (item) =>       
                    <MenuStiledRenderProps
                      item={item}
                      widthStandar={true}
                      disabled={false}
                      src={pasteIcon}
                      onClick={() => uploadFolder()}
                    />
            },
            { 
                key: 'newFolder', 
                text: t("Upload.newFolder"),
                onClick: () => {nefolder()}, 
                disabled: !isAbleToRelease,
                onRender: (item) =>       
                    <MenuStiledRenderProps
                      item={item}
                      widthStandar={true}
                      disabled={false}
                      src={pasteIcon}
                      onClick={() => nefolder()}
                    />
            }
        ];

        if(isAdmin)
        {
            items.push({ 
                key: 'permission', 
                text: t("Upload.permission"),
                onClick: () => {batchDraftClick()}, 
                disabled: !isAbleToDraft,
                onRender: (item) =>         
                    <MenuStiledRenderProps
                      item={item}
                      widthStandar={true}
                      disabled={false}
                      src={permissions}
                      onClick={() => permission()}
                    />
                  
            });

            items.push({ 
                key: 'permissionUser', 
                text: t("Upload.permissionUser"),
                onClick: () => {batchDraftClick()}, 
                disabled: !isAbleToDraft,
                onRender: (item) =>          
                    <MenuStiledRenderProps
                      item={item}
                      widthStandar={true}
                      disabled={false}
                      src={userPermissions}
                      onClick={() => permissionUser()}
                    />
                  
            });
        }

        setMenuItems(items);
    },[isAdmin])

    return(
        <div className={stylesList.header}>
            <PathListingMap 
                path={path}
                onClickLinkMap={onClickLinkMap}
            />
            <Stack horizontal className={styles.hPage}>   
                <Stack.Item grow> 
                    <h2 className={styles.headerTitle}>{nameFolder}</h2>
                </Stack.Item>     
                <Stack className={styles.stackSideBtnBar}>
                    <Stack.Item className={styles.BtnAdd}>
                        <Box sx={{
                            width: '200px',
                            display: 'flex',
                            justifyContent: 'end',
                            gap: '5px',
                            alignItems: 'center'
                        }}>
              <ChooseColumns/>

              {isActionBatch && selectedCheckboxes.length > 1 && (
                                <IconButton 
                                    style={{
                                        width: '38px',
                                        height: '38px',
                                        border: '1px solid #e7e7e7',
                                        borderRadius: '4px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',  
                                    }}
                                    menuProps={{
                                        items:menuItemsBach,
                                    }}
                                    styles={{ menuIcon: {
                                        display: 'none'
                                    } }}
                                >
                                    <img src={batchActionIcon} width={19} />
                                </IconButton>
                            ) }
                            <IconButton 
                                    style={{ 
                                        width: '38px',
                                        height: '38px',
                                        border: '1px solid #e7e7e7',
                                        borderRadius: '4px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',  
                                    }}
                                    styles={{ menuIcon: {
                                        display: 'none'
                                    } }}
                                    onClick={refreshClick}
                                >
                                    <img src={refreshIcon} width={19} />
                            </IconButton>
                            {(
                                typePermission == 1 ||
                                typePermission == 3 
                            ) && (
                                <IconButton 
                                    style={{
                                        width: '38px',
                                        height: '38px',
                                        border: '1px solid #e7e7e7',
                                        borderRadius: '4px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',  
                                    }}
                                    styles={{ menuIcon: {
                                        display: 'none'
                                    } }}
                                    menuProps={{items:menuItems}}
                                >
                                    <img src={addIcon} width={19} />
                            </IconButton>
                            )}
                        </Box>
                            
                    </Stack.Item>
                </Stack>
            </Stack>
        </div>
    );
});



export default WorkSpaceItemListFileHeader;
