import React from "react";
import {
  IComboBox,
  ComboBox,
  IComboBoxOption,
  IComboBoxStyles,
} from "@fluentui/react";
import { ILabelStyles, ILabelStyleProps } from '@fluentui/react/lib/Label';

import styles from "./ComboBoxComp.module.css";

export interface ComboBoxConfig {
  label?: string;
  disabled?: boolean;
  options: IComboBoxOption[];
  onChange?: (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => void;
  selectedKey?: string | number | string[] | number[] | null;
}

const ComboBoxComp = (props: ComboBoxConfig) => {
  var disabled: boolean = false;

  if (props.disabled !== undefined) {
    disabled = props.disabled;
  }

  const comboBoxStyles: Partial<IComboBoxStyles> = {
    root: {
      width:"100%",
      maxWidth: 300,
      borderColor: "#114D88"
    }
  };

  return (
    <div className={styles.globalcombox}>
      <ComboBox
        label={props.label}
        disabled={disabled}
        options={props.options}
        styles={comboBoxStyles}
        onChange={props.onChange}
        selectedKey={props.selectedKey}
      />
    </div>
  );
};

export default ComboBoxComp;
