import { Typography } from "@mui/material"


export const TimeLineIcon = () => {
    return (
      <Typography sx={{
        backgroundColor:"#005A9E",
        height:"54px",
        width:"54px",
        borderRadius:"10px",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        marginRight:"20px"
    }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
        <path id="_2203530_connect_direction_path_way_icon" data-name="2203530_connect_direction_path_way_icon" d="M6.344,16.313H22.656a6.344,6.344,0,0,0,0-12.688H7.25a3.664,3.664,0,1,0-.5,1.813H22.656a4.531,4.531,0,1,1,0,9.063H6.344a6.344,6.344,0,0,0,0,12.688H22.252a3.6,3.6,0,1,0-.5-1.813H6.344a4.531,4.531,0,0,1,0-9.063Z" fill="#fff"/>
      </svg>
      </Typography>
      
    )
}