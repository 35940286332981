import { GridColumn, GridColumnDeleted } from "./ColumnStandard";

const gridColumnString:string = "gridColumn";

export function SetColumnParameters(gridColumn:GridColumn[])
{
    localStorage.setItem(gridColumnString, JSON.stringify(gridColumn));
}

export function GetColumnParameters(): GridColumn[] | undefined {
    let gridLocalStorage: GridColumnDeleted[] = [];
    var jsonSizeSave  = localStorage.getItem(gridColumnString);
    if (jsonSizeSave == null) {
        return undefined;
    }

    gridLocalStorage = JSON.parse(jsonSizeSave);

    return gridLocalStorage;
}