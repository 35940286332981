import { Api } from "../services/api";
import { Organization, User, OrganizationCreate, D4SignSafeBox, SetD4SignModel, OrganizationNameVO, IReturnAllSizes, OrganizationUserServiceVO } from "../model/OrganizationModel";
import { Group } from "../model/GroupModel";
import { WorkSpace } from "../model/WorkSpaceModel";
import {AxiosResponse} from 'axios';
import { UserLogged } from "../model/UserModel";

export async function GetOrganization() {
  const request = await Api.get<Organization[]>("organization");

    return request.data;
}

export async function ContractSizeByOrgName(internalName: string) {
  const request = await Api.get<IReturnAllSizes>(`organization/contractSize/${internalName}`);
  return request.data;
}

export async function GetOrganizationTrimName(skip:number, limit:number, desc:boolean, name:string) {
  const request = await Api.get<Organization[]>("organization?skip=" + skip + "&limit=" + limit + "&desc=" + desc + "&name=" + name);

    return request.data;
}

export async function GetOrganizationTrim(skip:number, limit:number, desc:boolean) {
  const request = await Api.get<Organization[]>("organization?skip=" + skip + "&limit=" + limit + "&desc=" + desc);

    return request.data;
}

export async function GetOneOrganization(internalName: string | null) {
  if (internalName === null) return null;
  const request = await Api.get<Organization>(
    "organization/" + internalName
  );

  return request.data;
}

export async function GetOneOrganizationWithContractResource(internalName: string | null) {
  if (internalName === null) return null;
  const request = await Api.get<Organization>(`organization/${internalName}/ContractResource`);
  return request.data;
}

export async function GetOrganizationUserTrim(internalName: string, skip:number, limit:number) {
  const request = await Api.get<User[]>(
    "organization/" + internalName + "/users?skip=" + skip + "&limit=" + limit
  );

  return request.data;
}

export async function GetOrganizationUserTrimName(internalName: string, skip:number, limit:number, name: string) {
  const request = await Api.get<User[]>(
    "organization/" + internalName + "/users?skip=" + skip + "&limit=" + limit + "&name=" + name
  );

  return request.data;
}

export async function GetOrganizationWorkspaceTrimName(internalName: string, skip:number, limit:number,  desc:boolean, name: string) {
  const request = await Api.get<WorkSpace[]>(
    "organization/" + internalName + "/workspaces?skip=" + skip + "&limit=" + limit + "&desc=" + desc +"&name=" + name
  );

  return request.data;
}

export async function GetOrganizationWorkspaceAdmTrim(internalName: string, skip:number, limit:number,  desc:boolean) {
  const request = await Api.get<WorkSpace[]>(
    "organization/" + internalName + "/wsallowed?skip=" + skip + "&limit=" + limit + "&desc=" + desc
  );

  return request.data;
}

export async function GetOrganizationWorkspaceAdmTrimName(internalName: string, skip:number, limit:number,  desc:boolean, name: string) {
  const request = await Api.get<WorkSpace[]>(
    "organization/" + internalName + "/wsallowed?skip=" + skip + "&limit=" + limit + "&desc=" + desc +"&name=" + name
  );

  return request.data;
}

export async function GetOrganizationUserName(internalName: string, name: string) {
  const request = await Api.get<User[]>(
    "organization/" + internalName + "/users?name=" + name
  );

  return request.data;
}

export async function GetOrganizationAdminUserName(internalName: string, name: string) {
  const request = await Api.get<User[]>(
    "organization/" + internalName + "/adminUsers?name=" + name
  );

  return request.data;
};


export async function GetOrganizationUser(internalName: string) {
  const request = await Api.get<User[]>(
    "organization/" + internalName + "/users"
  );

  return request.data;
}

export async function GetOrganizationAdminUsers(internalName: string) {
  const request = await Api.get<User[]>(
    "organization/" + internalName + "/adminUsers"
  );

  return request.data;
}

export async function GetD4SignSafeBox() {
  const request = await Api.get<D4SignSafeBox[]>(
    "organization/D4SignSafeBox"
  );

  return request.data;
}

export async function GetOrganizationGroup(nameOrg: string, skip:number, limit:number) {
  const request = await Api.get<Group[]>(
    "organization/" + nameOrg + "/groups?skip=" + skip + "&limit=" + limit
  );

  return request.data;
}

export async function GetOrganizationGroupName(nameOrg: string, skip:number, limit:number, name:string) {
  const request = await Api.get<Group[]>(
    "organization/" + nameOrg + "/groups?skip=" + skip + "&limit=" + limit + "&name=" + name
  );

  return request.data;
}

export async function CreateOrganization(organization: OrganizationCreate) {
  const request = await Api.post<any>(
    "organization/",
    organization 
  );

  return request.data;
}

export async function Edit(organization: Organization) {
  const request = await Api.put<any>(
      "organization/",
      organization 
    );

    return request.data;
  }

export async function Delete(organizationId: string) {
  const request = await Api.delete(
    "organization/",    
    { data: { id: organizationId } }   
  );
}

  export async function SetName(organizationNameVO: OrganizationNameVO) {
    const request = await Api.put(
      "organization/SetName",    
      organizationNameVO 
    );
  }

export async function SetAllowDrive(Organization: Organization) {
  const request = await Api.put(
    "organization/SetAllowDrive",    
    Organization 
  );
}

export async function SetD4Sign(setD4SignModel: SetD4SignModel) {
  const request = await Api.put(
    "organization/SetD4Sign",    
    setD4SignModel 
  );
}

  export async function UnsetD4Sign(organizationId: string) {
    const request = await Api.put(
      "organization/UnsetD4Sign",    
      { id: organizationId }
    );
  }

	export async function SetUserService(organizationUserServiceVO: OrganizationUserServiceVO) {
	  const request = await Api.put('organization/SetUserService/', organizationUserServiceVO);
	}
	
	export async function UnsetUserService(organizationId: string) {
	  const request = await Api.put(
	    'organization/UnsetUserService/', 
	    { id: organizationId }
	  );
	}

  export async function CreateUserLoggedByOrg(userLogged: UserLogged) {
    const request = await Api.post('userLogged/', userLogged)
  }