import { initializeIcons } from '@fluentui/font-icons-mdl2';
import AppRoutes from  './Routes/AppRoutes';
import { Helmet } from "react-helmet";
import * as PdfJs from "pdfjs-dist";

const csp = `
  default-src 'self';
  script-src 'self' 'unsafe-inline';
  style-src 'self' 'unsafe-inline';
  img-src 'self' data: blob: http://localhost:3000/;
  font-src 'self' data: https://spoppe-b.azureedge.net/files/fabric-cdn-prod_20210407.001/assets/icons/;
  connect-src 'self' https://login.microsoftonline.com/ blob: http://localhost:3000/ https://grcauth.greendocs.net/ https://grc.greendocs.net/ http://localhost:70/Identidade/connect/token http://localhost/GD_GRC/api/ https://auth.keydrive.com.br/connect/token https://app.keydrive.com.br/api/;
  script-src-elem 'self' http://unpkg.com/pdfjs-dist@${PdfJs.version}/legacy/build/pdf.worker.min.js https://www.gstatic.com/recaptcha/releases/;
  frame-src 'self' https://secure.d4sign.com.br/ https://www.google.com/;
  worker-src 'self' blob: http://localhost:3000/;
`;

initializeIcons('https://my.cdn.com/path/to/icons/');

function App() {
  return (
    <div className="father">
      <div className="father">
        <AppRoutes/>
      </div>
    </div>
  );
}

export default App;