import { ThemeProvider, Tooltip, createTheme, Box } from "@mui/material"
import { BaseSvg } from "../BaseSVg/BaseSvg";
import {term} from "../../pages/WorkSpaceItem/WorkSpaceItemListStyle/ColumnStandard"

export interface IIconCompTypes {
  tooltip?: string
  type: 'OUT' | 'ON' | 'CURRENT'
  opacity?: string
  date?: string
}

export const IconComp: React.FC<IIconCompTypes> = ({ tooltip, type, opacity, date }) => {

  const handleTypeIcon = (type: 'OUT' | 'ON' | 'CURRENT'): string => {
    switch(type) {
        case "ON":
          return "#114d88";
        case "OUT":
          return "#d11422";
        case "CURRENT":
          return "#E1972B";
    }
  }

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: handleTypeIcon(type), 
            color: 'white', 
          },
        },
      },
    },
  });
  
  return (
    <div
      style={{
        gridArea: term,
      }}
    >
      <ThemeProvider theme={theme}>
        <Tooltip
          title={tooltip}
          placement="bottom"
        >
			<Box component='span' display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}
				gap={1}>
            <BaseSvg 
              color={handleTypeIcon(type)}
              width={"20"}
              height={"20"}
              opacity={opacity}
              type={date !== undefined ? type : undefined}
            />
            <p style={{ color: handleTypeIcon(type)}}>{date}</p>
          </Box>
        </Tooltip>
      </ThemeProvider>
    </div> 
  )
}