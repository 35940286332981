import {Color} from "./interface/Color"

export const ValidationRule: React.FC<Color> = ({
    color
  }) => {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20">
            <path 
                id="Caminho_24" 
                data-name="Caminho 24" 
                d="M21.519,13.852H17.074a1.483,1.483,0,0,0-1.481,1.481v1.481H11.455l-3.27-3.27V9.407H9.667a1.483,1.483,0,0,0,1.481-1.481V3.481A1.483,1.483,0,0,0,9.667,2H5.222A1.483,1.483,0,0,0,3.741,3.481V7.926A1.483,1.483,0,0,0,5.222,9.407H6.7v4.138L3.217,17.032a.74.74,0,0,0,0,1.047l3.7,3.7a.74.74,0,0,0,1.047,0L11.455,18.3h4.138v1.481a1.483,1.483,0,0,0,1.481,1.481h4.444A1.483,1.483,0,0,0,23,19.778V15.333a1.483,1.483,0,0,0-1.481-1.481ZM5.222,3.481H9.667V7.926H5.222Zm2.222,16.73L4.788,17.556,7.444,14.9,10.1,17.556Zm9.63-.434V15.333h4.444v4.444Z" 
                transform="translate(-3 -2)" 
                fill={color}/>
        </svg>

    )
  }