import { Grid } from '@mui/material';
import { Close } from '@mui/icons-material';

interface IDocumentDetailHeaderActionsClose
{
    closeDetail: () => void;
}


export default function DocumentDetailHeaderActionsClose({
    closeDetail
}: IDocumentDetailHeaderActionsClose)
{
    return (
        <Grid item onClick={closeDetail} sx={{
            cursor: 'pointer',
            zIndex: 999,
            marginLeft: "20px"
        }}>
            <Close />
        </Grid>
    )
}