import { Box } from '@mui/material';

interface FormIconProps {
    icon: string;
}

export default function FormIcon({ icon }: FormIconProps) {
    return (
        <Box
            sx={{
                width: '100px',
                height: '100px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <img 
                src={icon}
            />
        </Box>
    )
}