import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { WorkSpace, User } from "../../model/WorkSpaceModel";
import { 
  GetOrgWorkSpaceTrim 
 } from "../../services/WorkSpaceServices";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styles from "./WorkSpaceList.module.css";
import stylesList from "../Style/List.module.css";
import { useBoolean } from "@fluentui/react-hooks";
import { IconButton } from '@fluentui/react/lib/Button';
import { IIconProps } from '@fluentui/react';
import { useTranslation } from "react-i18next";
import {
  GetOrganizationWorkspaceTrimName
} from "../../services/OrganizationServices";
import Tooltip from '@mui/material/Tooltip';
import LongMenu from  "../../components/MenuContexto/MenuContexto";
import {SetIdWorkSpaceLocalStorage, GetIdWorkSpaceLocalStorage, SetInternalNameOrg,GetInternalNameOrg} from "../ProtectedLayout/WorkSpaceMenager"
import { useProfile } from '../../context/ProfileProvider/useProfile';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import I18n, { TranslatorP, Translator, TranslatorS } from '../../components/I18n';
import "../../components/MenuContexto/MenuContexto.css";
import LoaderComp from '../../components/Loader/LoaderComp';

export interface WorkSpaceListCompConfig {
  skip: number;
  id: string | undefined;
}

export interface DropdownMenuContextoConfig{
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
}

function useVisibility(
  cb: (isVisible: boolean) => void,
  deps: React.DependencyList
): (node: any) => void {
  const intersectionObserver = useRef<IntersectionObserver | null>(null);
  return useCallback((node) => {
    if (intersectionObserver.current) {
      intersectionObserver.current.disconnect();
    }

    intersectionObserver.current = new IntersectionObserver(([entry]) => {
      cb(entry.isIntersecting);
    });

    if (node) intersectionObserver.current.observe(node);
  }, deps);
}

interface WorkItemProps {
  idWorkspace?: string;
  nameOrg: string;
  name?: string;
  hiddenContexMenu: boolean;
  ref?: React.Ref<HTMLLIElement>;
}


interface ListWorkId {
  nameOrg: string;
  name: string;
  onClickEditar?: React.MouseEventHandler<HTMLElement> | undefined;
  onClickExcluir?: React.MouseEventHandler<HTMLElement> | undefined;
  onClickClone?: React.MouseEventHandler<HTMLElement> | undefined;
  hiddenContexMenu: boolean;
}

const WorkSpaceListComp: React.FC<ListWorkId> = ({nameOrg,name, onClickEditar, onClickExcluir, onClickClone, hiddenContexMenu}) => {
  const [workSpace, setWork] = useState<WorkSpace[]>([]);
  const navegate = useNavigate();
  const [limit, setLimit] = useState<number>(50);
  
  const [itens, setItens] = useState<WorkSpace | null>(null);
  const [newOrgName, setNewOrgName] = useState<string>();
  const forceEdit = useCallback((teste: string) => setNewOrgName(teste), []);

  const [isMessageError, setMessageError] = useState<boolean>(false);
  const [isMessageOk, setMessageOk] = useState<boolean>(false);

  let [workspaceMax, setWorkspaceMax] = useState<boolean>(true)
  let [orderDesc, setOrderDesc] = useState<boolean>(false);
  let [skip, setSkip] = useState<number>(0);
  let [WorkMax, setWorkMax] = useState<boolean>(true);
  const { t, i18n } = useTranslation();
  const [wait, setWait] = useState<Boolean>(false);

  const WorkItem: React.ForwardRefExoticComponent<WorkItemProps> =
  React.forwardRef(({ idWorkspace, nameOrg, name, hiddenContexMenu }, ref: React.Ref<HTMLLIElement>) => {
    const navegate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const [searchParams, setSearchParams] = useSearchParams();

    function redirect()
    {   
      SetIdWorkSpaceLocalStorage(idWorkspace!)
      SetInternalNameOrg(nameOrg);

      var internalNameOrg = GetInternalNameOrg("internalNameOrg");

      var idWorkspaceLocal = GetIdWorkSpaceLocalStorage("idWorkspace")
      
      let redirect = searchParams.get("redirect");

      navegate("/" + internalNameOrg + "/Workspace/" + idWorkspaceLocal + "/validationRule");
     
    }

    const permission = () => {
      navegate("/" + nameOrg + "/WorkSpace/" + idWorkspace + "/Permission");
    }
  
    const permissionUser = () => {
      navegate("/" + nameOrg + "/WorkSpace/" + idWorkspace + "/UserPermission");
    }
    
    return (
      <div>
        {hiddenContexMenu?(
          <li className={`${stylesList.itemContentAdm} ${stylesList.row}`} key={idWorkspace} ref={ref}>
            <Tooltip title="Ir para os Tipos de documento">
              <div className={stylesList.cursorList}  onClick={redirect}>   {name}{" "} </div>    
            </Tooltip>    
          </li>
        ):(
          <li className={`${stylesList.itemContent} ${stylesList.row} `} key={idWorkspace} ref={ref}>
            <Tooltip title="Ir para os Tipos de documento">
              <div className={stylesList.cursorList} onClick={redirect}>   {name}{" "} </div>    
            </Tooltip>

            <div className="iconButton">
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon/>
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                PaperProps={{
                  style: {
                    maxHeight: 216,
                    width: '25ch',                    
                  },
                }}
                onClose={handleClose}
              >
                <MenuItem data-my-value={idWorkspace} onClick={onClickEditar}>
                  {TranslatorS({path:"Editar.message"})}
                </MenuItem>
                <MenuItem data-my-value={idWorkspace} onClick={onClickExcluir}>
                {TranslatorS({path:"Excluir.message"})}
                </MenuItem>
                <MenuItem data-my-value={idWorkspace} onClick={onClickClone}>
                {TranslatorS({path:"Clone.message"})}
                </MenuItem>
                <MenuItem data-my-value={idWorkspace} onClick={permission}>
                {TranslatorS({path:"Upload.permission"})}
                </MenuItem>
                <MenuItem data-my-value={idWorkspace} onClick={permissionUser}>
                {TranslatorS({path:"Upload.permissionUser"})}
                </MenuItem>
              </Menu>
            </div>


          </li>
        )}
      </div>
    );
  });

  const lastBook = useVisibility(
    (visible) => {
      if (visible) {
        setWait(true);
        if (name == "" ) {
          GetOrgWorkSpaceTrim(nameOrg,skip, limit, orderDesc).then((newWork) => {
            if (newWork.length < limit) {
              setWorkMax(false);
            }
            setSkip(skip + newWork.length);
            setWork([...workSpace, ...newWork]);
          })
          .finally(() => {
            setWait(false);
          });
        }
        else
        {
          GetOrganizationWorkspaceTrimName(nameOrg, skip, limit,orderDesc, name).then((newWork) => {
            if(newWork.length < limit) {
              setWorkspaceMax (false);
            }
            setSkip(skip + newWork.length);
            setWork([...workSpace, ...newWork]);
          })
          .finally(() => {
            setWait(false);
          });
        }
      }
    },
    [nameOrg,skip, workSpace]
  );


  useEffect(() => {
    setWait(true);
    if(name == "")
    {
      GetOrgWorkSpaceTrim(nameOrg,0, limit, orderDesc).then((newWork) => {
        if (newWork.length < limit) {
          setWorkMax(false);
        }
        setSkip(newWork.length);
        setWork(newWork);
      })
      .finally(() => {
        setWait(false);
      });
    }
    else
    {
      GetOrganizationWorkspaceTrimName(nameOrg, 0, limit, orderDesc, name).then((newWork) => {
        if (newWork.length < limit) {
          setWorkMax(false);
        }
        setSkip(newWork.length);
        setWork(newWork);
      })
      .finally(() => {
        setWait(false);
      });
    }
  }, [nameOrg,name, orderDesc]);

  function resetMessage() {
    setMessageOk(false);
    setMessageError(false);
  }

  const asc: IIconProps = {
    iconName: 'Down',
    styles: {
      root: {
        color: 'black',
        fontWeight:'bold'
      }
    }
  };

  const desc: IIconProps = {
    iconName: 'Up',
    styles: {
      root: {
        color: 'black',
        fontWeight:'bold'
      }
    }
  };

  const ascClick = () => {
    setOrderDesc(true)
  };

  const descClick = () => {
    setOrderDesc(false)
  };

return (
  <div>
    <ul className={stylesList.ulContent}>
      <li className={`${stylesList.itemContent} ${stylesList.title}`}>
         <>{t("Nome.message")}</>{" "}
          {orderDesc?
          (<IconButton iconProps={desc} title="Descendente" ariaLabel="Descendente" onClick={descClick}/>):
          (<IconButton iconProps={asc} title="Ascendente" ariaLabel="Ascendente" onClick={ascClick}/>)}
        </li>
      {workSpace.map((workSpaceC) => (
        <WorkItem
          key={workSpaceC.id}
          idWorkspace={workSpaceC.id}
          nameOrg={nameOrg}
          name={workSpaceC.name}
          ref={workSpace[workSpace.length - 1].id === workSpaceC.id && WorkMax ? lastBook : null}
          hiddenContexMenu={hiddenContexMenu}
        />
      ))}
      {wait && (<LoaderComp big={true}/>)}
    </ul>
    <div>
      {WorkMax === false ? 
      null
      : null}
      </div>
  </div>
);
}
export default WorkSpaceListComp;
