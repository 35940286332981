import React, { useEffect, useState, useRef, useCallback } from "react";
import WorkSpaceItemListFolderTree from "../WorkSpaceItemListFolderTree";
import  Btn  from '../../../components/Button/Btn';
import { t } from "i18next";
import Message from "../../../components/Message/Message";
import { MessageBarType } from "@fluentui/react";
import {Move} from "../../../services/Item/ItemServicesCRUD"


interface MoveByClickProps {
    idWorkspace: string;
    idItem: string;
    setPopupOkMessage:(ok:boolean, message:string) => void;
    reloadFolders: () => void
  }

  const MoveByClick: React.FC<MoveByClickProps> = ({
    idWorkspace,
    idItem,
    setPopupOkMessage,
    reloadFolders
    }) => {
    const [idFolder, setIdFolder] = React.useState<string|undefined>(undefined);
    const [nameFolder, setNameFolder] = React.useState<string|undefined>(undefined);
    const [nameWorkspace, setNameWorkspace] = React.useState<string|undefined>(undefined);
    const [error, setError] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>("");

    function clickFunction(idFolder:string | undefined, nameFolder:string | undefined){
        setIdFolder(idFolder);
        setNameFolder(nameFolder);
    }

    function clickFunctionWorkspace( nameWorkspace:string | undefined){
        setNameFolder(undefined);
        setNameWorkspace(nameWorkspace);
    }

    function move(){
        Move({
            idMongo: idItem,
            idMongoNewFolder: idFolder
        })
        .then(() =>{
            setPopupOkMessage(true,t("SucessMove.message"));
        })
        .catch((error) =>{
            if( error.response && error.response.data && typeof error.response.data  === 'string'){
                setPopupErroMessage(true, error.response.data);
            }
            else
            {
                setPopupErroMessage(true, t("ErrorMove.message"));
            }
        })
    }


    function setPopupErroMessage(ok:boolean, message:string) 
    {
        setError(ok);
        setErrorMessage(message);
    }

    return (
        <div>
            <WorkSpaceItemListFolderTree
              idWorkspace={idWorkspace}
              setPopupOkMessage={setPopupOkMessage}
              setPopupErroMessage={setPopupErroMessage}
              clickFunction={clickFunction}
              clickFunctionWorkspace={clickFunctionWorkspace}
              reloadFolders={reloadFolders}
            />
            {error && (
                <Message
                    type={MessageBarType.error}
                    text={errorMessage}
                />
            )}

            <Btn onClick={move} text={
                nameFolder != undefined ? t("Move.message") + nameFolder: nameWorkspace != undefined ? t("Move.message")  + nameWorkspace : t("Move.root")
            } />
        </div>
    )
}

export default MoveByClick;