import { ReactNode } from 'react';
import { Box } from '@mui/material';
import styles from '../WorkspaceItemFlow.module.css';
import { SvgFlowCircle, SvgFlowCircleType} from '../SvgStyleComponents/SvgFlowCircle';
import { SvgFlow} from '../SvgStyleComponents/SvgFlow';
import { GetFlowInformationByItemVO } from '../../../../model/ItemModel'
import { ValidationRuleVOActivityUserGroup } from '../../../../model/ValidationRuleModel'
import { AnimatedText } from '../SvgStyleComponents/AnimatedText';

export interface SvgFlowTypesINternal {
    getFlowInformationByItemVO?: GetFlowInformationByItemVO | undefined
    data?: ValidationRuleVOActivityUserGroup | undefined
    strokeColor?: string | undefined
    isStrokeFixed?: boolean
}

export interface SvgItemBoxProps {
    title?: string,
    svgFlowCircleType:SvgFlowCircleType,
    svgFlowLineType?:SvgFlowTypesINternal,
    children?: ReactNode,
  }


export const SvgItemBox: React.FC<SvgItemBoxProps> = (props: SvgItemBoxProps) => {

    return (
        <Box>

            <Box className={styles.BoxContextSize}>
                <SvgFlowCircle 
                    CircleType={props.svgFlowCircleType?.CircleType}
                    className={props.svgFlowCircleType?.className}
                    delayAnimationTime={props.svgFlowCircleType?.delayAnimationTime}
                    timeAnimationTime={props.svgFlowCircleType?.timeAnimationTime}
                    activityNumber={props.svgFlowCircleType?.activityNumber}
                />
                <Box sx={{
                    position: "absolute",
                    left: "22px",
                    top: "70px"
                }}>
                    {props.svgFlowLineType != undefined &&
                        <SvgFlow
                            data={props.svgFlowLineType?.data}
                            getFlowInformationByItemVO={props.svgFlowLineType?.getFlowInformationByItemVO}
                            isStrokeFixed={props.svgFlowLineType?.isStrokeFixed}
                            strokeColor={props.svgFlowLineType?.strokeColor}

                            delayAnimationTime={props.svgFlowCircleType?.delayAnimationTime + props.svgFlowCircleType?.timeAnimationTime}
                            timeAnimationTime={props.svgFlowCircleType?.timeAnimationTime}
                        />
                    }
                </Box>
                <Box className={styles.CentralizeText}>
                    <AnimatedText 
                        title={props.title}
                        children={props.children}
                        delayAnimationTime={props.svgFlowCircleType?.delayAnimationTime + props.svgFlowCircleType?.timeAnimationTime}
                        timeAnimationTime={props.svgFlowCircleType?.timeAnimationTime}
                        getFlowInformationByItemVO={props.svgFlowLineType?.getFlowInformationByItemVO}
                        CircleType={props.svgFlowCircleType?.CircleType}
                        data={props.svgFlowLineType?.data}
                    />
                </Box>
            </Box>
            
        </Box>
    )
};