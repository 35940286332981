import { ContentbarProps } from "../ContentSide/ContentSideComp"
import './ContentHamb.css';
import { ContentHambComp } from "./ContentHambComp";

interface ContentHambListPorps{
    contentList: ContentbarProps[]
}

export const ContentHambList: React.FC<ContentHambListPorps> = ({contentList}: ContentHambListPorps) => {
    return(
        <div className="ContainerMobile">
            <div id="MyLinks">
                {contentList.filter(content => content.type !== "actionIcon").map(content => {
                    return <ContentHambComp 
                    children={content.children} 
                    type={content.type}
                    fill={content.fill}
                    onClick={content.onClick}
                    icon={content.icon}
                    conditional={content.conditional}
                />
                })}
            </div>
        </div>
    )
}

export default ContentHambList