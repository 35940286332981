import React, {createContext, useState, useCallback } from "react";
import { IAuthProvider } from '../AuthProvider/types';
import { FileWorkspace,FileListHash, FileList, FileListAdd, FileReplace, FolderWorkspace } from "./typesUpload";
import {AuthProvider} from '../AuthProvider/';
import { WorkSpaceUserName } from "../../model/ItemModel";

export const UploadContext = createContext<FileListAdd>({} as FileListAdd);


export const UploadProvider = ({ children }: IAuthProvider) => {

    const[list, setList] = useState<FileList>({
        count:0,
        fileList: [],
        show: false
    });

    const [show, setShow] = useState<boolean>(false);
    const [itemId, setItemId] = useState<string | undefined>(undefined);
    const [isReplace, setIsReplace] = useState<boolean>(false);
    const [replacedItem, setReplacedItem] = useState<WorkSpaceUserName>();

    const [isReplaceFile, setIsReplaceFile] = useState<boolean>(false);
    const [replacedFileId, setReplacedFileId] = useState<string>("");

    function fileChooser(itemChooserId: string) {
        document.getElementById(itemChooserId)!.click();
    }

    function replace(newFile: FileReplace) {
        if (newFile === undefined || newFile === null) {
            return;
        }

        let listNew:FileList = {
            count : list == null || list.count == undefined? 1 : list.count + 1,
            fileList: []
        };

        let i:number = 0;
        

        let fileHash: FileListHash = {
            fileReplace:newFile,
            fileHash: listNew.count == undefined? 1 : listNew.count + 1,
            uploadDone: false,
        };

        if(list.fileList == undefined) {
            list.fileList = [];
        }
        listNew.fileList=[...list.fileList, fileHash];

        setShow(true);
        setList(listNew);
    }

   
    async function addFolder (folder:FileListHash[]) {
        let listNew:FileList = {
            count : list == null || list.count == undefined? 1 : list.count + 1,
            fileList: []
        };

        if(list.fileList == undefined)
        {
            list.fileList = [];
        }

        listNew.fileList=[...list.fileList, ...folder];
        setList(listNew);
        setShow(true);
    }

    async function addMany (newFiles:FileWorkspace[]) {
        let listNew:FileList = {
            count : list == null || list.count == undefined? newFiles.length : list.count + newFiles.length,
            fileList: []
        };

        let fileHashNew: FileListHash[] = [];
        let i:number = 0;
        for(i;i<newFiles.length;i++)
        {
            let fileHash:FileListHash={
                fileWorkspace:{
                    file: newFiles[i].file,
                    woskpace: newFiles[i].woskpace,
                    folder: newFiles[i].folder
                },
                fileHash: listNew.count == undefined? 1 + i : listNew.count + i,
                uploadDone: false,
            };
            fileHashNew.push(fileHash);
        }

        if(list.fileList == undefined)
        {
            list.fileList = [];
        }

        listNew.fileList=[...list.fileList, ...fileHashNew];
        setList(listNew);
        setShow(true);
    };

    function clear () {
        setList({
            count:0,
            fileList: [],
            show: false
        });
    };

    function setShowEx (show:boolean) {
        setShow(show);
    };


    return (
        <UploadContext.Provider 
        value={
                {
                    ...list,
                    show,
                    addFolder,
                    addMany,
                    clear,
                    setShowEx,
                    fileChooser,
                    replace,
                    itemId,
                    setItemId,
                    setIsReplace,
                    isReplace,
                    setReplacedItem,
                    replacedItem
                }
            }>
            <AuthProvider>
                {children}
            </AuthProvider>
        </UploadContext.Provider>
    );
}
