import React from "react"; 
import {
	Bmp, 
	Doc, 
	Docx,
	Exe, 
	File,
	Gif,
	Mp4, 
	Jpg, 
	Mpp, 
	Pdf,
	Png, 
	Ppt, 
	Pptx,
	Pst, 
	Svg, 
	Txt, 
	Webp, 
	Xls, 
	Xlsx,
	Zip
} from "../../assets/icons/filesIcon"
 
import { folderIcon } from '../../assets/icons'
import { Dwg } from "../../assets/icons/filesIcon/dwg";
import { Tiff } from "../../assets/icons/filesIcon/tiff";


interface WorkSpaceItemListFileComponentImageProps {
	isFolder?: boolean
	extension?: string;
	widthIcon?: string;
	boxLayout?: boolean
}

export function returnComp(extension: string | undefined){
	if (extension == "bmp") {
		return <Bmp />;
	} else if (extension == "doc") {
		return <Doc />;
	} else if (extension == "docx") {
		return <Docx />;
	} else if (extension == "exe") {
		return <Exe />;
	} else if (extension == "gif") {
		return <Gif />;
	} else if (extension == "jpg") {
		return <Jpg />;
	} else if (extension == "mp4") {
		return <Mp4 />;
	} else if (extension == "mpp") {
		return <Mpp />;
	} else if (extension == "pdf") {
		return <Pdf />;
	} else if (extension == "png") {
		return <Png />;
	} else if (extension == "ppt") {
		return <Ppt />;
	} else if (extension == "pptx") {
		return <Pptx />;
	} else if (extension == "pst") {
		return <Pst />;
	} else if (extension == "svg") {
		return <Svg />;
	} else if (extension == "txt") {
		return <Txt />;
	} else if (extension == "webp") {
		return <Webp />;
	} else if (extension == "xls") {
		return <Xls />;
	} else if (extension == "xlsx") {
		return <Xlsx />;
	} else if (extension == "dwg") {
		return <Dwg />;
	} else if (extension == "tiff") {
		return <Tiff />;
	} else if (
		extension == "rar" ||
		extension == "zip" ||
		extension == "7z" ||
		extension == "arj" ||
		extension == "bz2" ||
		extension == "cab" ||
		extension == "gz" ||
		extension == "lz" ||
		extension == "lzh" ||
		extension == "tar" ||
		extension == "uue" ||
		extension == "xz" ||
		extension == "z" ||
		extension == "zipx" ||
		extension == "zst" ||
		extension == "001" 
	) {
		return <Zip />;
	} else {
		return <File />;
	}
}

const WorkSpaceItemListFileComponentImage: React.ForwardRefExoticComponent<WorkSpaceItemListFileComponentImageProps> =
	React.forwardRef(({ extension, isFolder, boxLayout, widthIcon }, ref: React.Ref<HTMLLIElement>) => {
		return (
			<div>
				{isFolder ? (
					<img width={boxLayout !== undefined && boxLayout ? 20 : 16} src={folderIcon} draggable={false} />
				) : (
					<div style={{
						width: boxLayout !== undefined && boxLayout ? 20 : widthIcon ? widthIcon : 16
					}} draggable={false} >
						{returnComp(extension)}
					</div>
				)}
			</div>
		);
	});

export default WorkSpaceItemListFileComponentImage;