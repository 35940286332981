import React, { useState, useEffect, useCallback, useRef } from "react";
import {PDFViewSigned} from "../PDFInterface";
import { GetD4SignStatus} from "../../../../services/Item/ItemServicesGet";
import {
    mergeStyleSets,
    Modal,
  } from '@fluentui/react';
import { useTranslation } from "react-i18next";
import {
    RevisionIsD4SignSigned
} from "../../../../model/ItemModel";
import Btn from "../../../../components/Button/Btn";
import { Link } from '@fluentui/react';

const D4SignFilewView  = (props: PDFViewSigned) => {
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(props.isOpen);
    const [status, setStatus] = useState<RevisionIsD4SignSigned|undefined>(undefined);
    const [erroMensage, setErroMensage] = useState<string|undefined>();

    useEffect(() =>{

        setIsOpen(props.isOpen);
        if(props.isOpen)
        {
            setErroMensage(t("D4Sign.Wait"));
            props.getLink(props.idItem)
            .then((returnStatus)=>{
                setStatus(returnStatus);
            })
            .catch((error)=>{
                if( error.response && error.response.data && typeof error.response.data  === 'string'){
                    setErroMensage(error.response.data);
                }
                else
                {
                    setErroMensage(t("D4Sign.Error"));
                }
            });
        }
    },[props.idItem, props.isOpen, props.getLink])
    
    function cancel()
    {
        props.setIsOpen(false);
    }

    const contentStyles = mergeStyleSets({
        container: {
          display: 'flex',
          flexFlow: 'column nowrap',
          alignItems: 'stretch',
          width: "90%",
          height: "92%"
        }
    })

    return(
        <Modal
            isOpen={isOpen}
            onDismiss={cancel}
            isBlocking={false}
            containerClassName={(status != undefined && status.downloadCompleted)?contentStyles.container:undefined}
        >
            {status == undefined?(
                <div style={{
                    margin:"15px"
                }}>
                    {erroMensage}
                    <Btn text={t("Fechar.message")} onClick={cancel} /> 
                </div>
            ): (<div style={{
                margin:"15px"
            }}>
                <Link href={status.link} underline onClick={() => props.setIsOpen(false)}>{t("D4Sign.Download")}</Link>
                    <Btn text={t("Fechar.message")} onClick={cancel} /> 
                </div>
            )}
        </Modal>
    )
}

export default D4SignFilewView;
