import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import '../src/pages/i18n/I18n'
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import msalConfig from './config/office-auth.config.json';

const pca = new PublicClientApplication({
  auth: msalConfig.auth,
  cache: msalConfig.cache,
});

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <App />
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
