import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { ReactElement } from "react"
import Btn, { TypeStyle } from "./Button/Btn";
import { useTranslation } from "react-i18next";
import { DeleteIcon } from "../assets/icons/deleteIcon";
import BtnBigWithIcon, { ButtonTyppe } from "./Button/BtnBigWithIcon/BtnBigWithIcon";
import { CheckIcon, CheckIcon3 } from "../assets/svg/checkIcon";

export interface IDialogComponent {
    children: any;
    isOpen: boolean;
    titleContent: string;
    onClickConfirm: () => void;
    onClickCancel: (set: boolean) => void;
    isOtpDialog: boolean;
    isCancelButtonNeeded?: boolean;
}

export const DialogComponent = ({
    children,
    isOpen,
    titleContent,
    onClickCancel,
    onClickConfirm,
    isOtpDialog,
    isCancelButtonNeeded
}: IDialogComponent) => {

    const { t } = useTranslation();

    return (
        <Dialog
        open={isOpen}
        sx={{
            '& .MuiPaper-root': {
                borderRadius: '20px',
                width: '600px',
                paddingY: '10px',
            } 
        }}
        >
        <Box>
            <Box sx={{ width: "100%", display: "flex", alignItems: 'center', justifyContent: "center", marginTop: '10px' }}>  
            {isOtpDialog ? 
                <DeleteIcon/>
             :  <CheckIcon3 />
            }
            </Box>
        <DialogTitle id="alert-dialog-title">
            <Typography
                component='p'
                sx={{
                    textAlign: "center",
                    font: "normal normal 600 20px/28px Segoe UI",
                    letterSpacing: "0px",
                    color: "#2D2D2D",
                    opacity: 1,
                }}
            >
                {titleContent}
            </Typography>
        </DialogTitle>
        <DialogContent                 
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems:'center',
                justifyContent: "center",
            }}
        >
            { children }
        </DialogContent>
        <DialogActions>
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%', gap: 1, marginX: '10px'}}>
        <BtnBigWithIcon
        text={t("Confirmar.message")}
        onClick={() => onClickConfirm()}
        />
        { isCancelButtonNeeded ? (
            <BtnBigWithIcon
                text={t("Cancelar.message")}
                onClick={() => onClickCancel(isOtpDialog)}
                isPrimary={ButtonTyppe.secondary}
                />
            ) : (
                <></>
            ) }
        </Box>
        </DialogActions>
            </Box>
    </Dialog>
    )
}