import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useBoolean } from "@fluentui/react-hooks"
import styles from "../../../Style/List.module.css";
import { useTranslation } from "react-i18next";
import TextFieldComp from "../../../../components/TextField/TextFieldComp";
import PermissionGroupFolderList from "./PermissionGroupFolderList";
import PermissionGroupFolderAdd from "./PermissionGroupFolderAdd";
import I18n, { TranslatorS } from '../../../../components/I18n';
import Btn from "../../../../components/Button/Btn";
import {
  mergeStyleSets,
  Modal,
  IIconProps
} from '@fluentui/react';
import { IconButton, IButtonStyles } from '@fluentui/react/lib/Button';
import { 
  GetOneItem
 } from "../../../../services/Item/ItemServicesGet";

const WorkSpacePermissions  = (props: any) => {
  const { nameOrg } = useParams();
  const { idFolder } = useParams();
  const [searchName, setSearchName] = useState<string>("");
  const [searchNameNew, setSearchNameNew] = useState<string>("");
  const [itemName, setItemName] = useState<string>("");
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const [reload, setReload] = useState<boolean>(false);

  const iconProps = { iconName: "Filter" };
  const { t, i18n } = useTranslation();

  const onChangeSearchName = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    if (newValue != undefined ) {
      setSearchName(newValue);
    }
  };

  const onChangeSearchNameNew = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    if (newValue != undefined ) {
      setSearchNameNew(newValue);
    }
  };

  const onClickNew = () => {
    showModal();
  };

  const closeModal = () => {
    hideModal();
    setReload(true);
  };

  useEffect(() => {
    if(reload == true ){
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    if(idFolder != undefined){
      GetOneItem(idFolder)
      .then((workspace) => {
        if(workspace?.name != undefined)
        setItemName(workspace?.name);
      });
    }
  }, [idFolder]);

  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      minWidth: '400px',
    },
    header: [
      {
        flex: '1 1 auto',
        borderTop: `4px solid`,
        color: "#114D88",
        display: 'flex',
        alignItems: 'center',
        padding: '12px 12px 14px 24px',
      },
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
  });

  const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: "black",
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
      },
      rootHovered: {
        color: "black",
      },
    };

    const cancelIcon: IIconProps = { iconName: 'Cancel' };
  

  return (
    <div>
      <h3 className={styles.hList}>{t("PermicoesItem.message")}{" "}{itemName}</h3>
      <TextFieldComp iconProps={iconProps}  value={searchName} onChange={onChangeSearchName} />
      <Btn text={t("Adicionar.message")} onClick={onClickNew} />
      {reload?(<div></div>):
        nameOrg != undefined && nameOrg != "" && idFolder!= undefined && idFolder != ""?
        (<PermissionGroupFolderList nameOrg={nameOrg} idFolder={idFolder} name={searchName}/>):(<div></div>)
      }

      <Modal
        titleAriaId="Adição de usuários"
        isOpen={isModalOpen}
        onDismiss={closeModal}
        isBlocking={false}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.header}>
          <h3 className={styles.hList}>{t("Adicionar.message")}</h3>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={closeModal}
          />
        </div>
        <div className={contentStyles.body}>
          <TextFieldComp iconProps={iconProps}  value={searchNameNew} onChange={onChangeSearchNameNew} />
          {nameOrg != undefined && nameOrg != "" && idFolder!= undefined && idFolder != ""?
          (<PermissionGroupFolderAdd nameOrg={nameOrg} idFolder={idFolder} name={searchNameNew}/>):(<div></div>)}
          
        </div>
      </Modal>
    </div>
  );
};


export default WorkSpacePermissions;
