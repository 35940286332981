import React, { useState, useEffect, useCallback, useRef } from "react";
import styles from "../ValidationRule.module.css";
import stylesList from "../../Style/List.module.css";
import { useNavigate } from "react-router-dom";
import { ValidationRuleVORetrieve } from "../../../model/ValidationRuleModel";
import { useTranslation } from "react-i18next";
import { GetVrByWorkspaceId, GetVrByWorkspaceIdName } from "../../../services/ValidationRuleService";
import LongMenu from  "../../../components/MenuContexto/MenuContexto";

function useVisibility(
    cb: (isVisible: boolean) => void,
    deps: React.DependencyList
  ): (node: any) => void {
    const intersectionObserver = useRef<IntersectionObserver | null>(null);
    return useCallback((node) => {
      if (intersectionObserver.current) {
        intersectionObserver.current.disconnect();
      }
  
      intersectionObserver.current = new IntersectionObserver(([entry]) => {
        cb(entry.isIntersecting);
      });
  
      if (node) intersectionObserver.current.observe(node);
    }, deps);
  }
  

interface ValidationRuleListCompParams {
    nameOrg: string;
    idWorkspace: string;
    text:string;
    onClickExcluir: React.MouseEventHandler<HTMLElement> | undefined;
}

interface VRItemProps {

    id?: string | undefined;
    nameOrg?: string;
    workSpaceId: string;
    name?: string;
    description?:string;
    ref?: React.Ref<HTMLLIElement>;
    onClickExcluir: React.MouseEventHandler<HTMLElement> | undefined;
  } 
  

const ValidationRuleListComp: React.FC<ValidationRuleListCompParams> = ({nameOrg,idWorkspace,text, onClickExcluir}) => {
    const { t, i18n } = useTranslation();
    const [vrList, setVrList] = useState<ValidationRuleVORetrieve[]>([]);
    const [vrkMax, setVrkMax] = useState<boolean>(true);
    const [limit, setLimit] = useState<number>(50);
    let [skip, setSkip] = useState<number>(0);
    const lastVr = useVisibility(
        (visible) => {
          if (visible) {
            if (text == "" ) {
                GetVrByWorkspaceId(idWorkspace,skip, limit)
                .then((newWork) => {
                    if (newWork.length < limit) {
                        setVrkMax(false);
                    }
                    setSkip(skip + newWork.length);
                    setVrList([...vrList, ...newWork]);
                });
            }
            else
            {
                GetVrByWorkspaceIdName(idWorkspace, skip, limit, text)
                .then((newWork) => {
                    if(newWork.length < limit) {
                        setVrkMax (false);
                    }
                    setSkip(skip + newWork.length);
                    setVrList([...vrList, ...newWork]);
                });
            }
          }
        },
        [ idWorkspace, skip, vrList]
      );
    
    
      useEffect(() => {
        if(text != undefined && text == "" )
        {
            GetVrByWorkspaceId(idWorkspace,0, limit)
            .then((newWork) => {
                if (newWork.length < limit) {
                    setVrkMax(false);
                }
                setSkip(newWork.length);
                setVrList(newWork);
            });
        }
        else
        {
            GetVrByWorkspaceIdName(idWorkspace, 0, limit, text)
            .then((newWork) => {
                if (newWork.length < limit) {
                    setVrkMax(false);
                }
                setSkip(newWork.length);
                setVrList(newWork);
            });
        }
      }, [idWorkspace,text]);
   
    const VRItem: React.ForwardRefExoticComponent<VRItemProps> = React.forwardRef(
        ({ id,nameOrg, workSpaceId, name, description, onClickExcluir }, ref: React.Ref<HTMLLIElement>) => {
          const navegate = useNavigate();

          const onClickEdit = (
            event: React.FormEvent<HTMLElement>
          ) => {
            navegate("/" + nameOrg + "/Workspace/" + workSpaceId + "/validationRule/"  + id);
          };
          

          return (
            <li className={`${styles.itemContentDocument} ${stylesList.row} ${styles.ListDocumetTypes}`} key={id} ref={ref}>
              <div className={styles.nameList}>{name}{" "}</div>
              <div className={styles.descriptionList}>{description}</div>  
              <LongMenu onClickEditar={onClickEdit} onClickExcluir={onClickExcluir} idMenu={id} />   
          </li>
          );
        }
      );

    return(
        <div>
            <ul className={styles.ulContent} >
                  <li className={`${stylesList.itemContent} ${stylesList.title}`}>
                  <div className={styles.nameTitle}>{t("Nome.message")}
                  </div>
                  <div className={styles.descriptionTitle}>{t("Description.message")}</div>
                  </li>
                {vrList.map((vr) => (
                    <VRItem
                        key={vr.id}
                        id={vr.id}
                        nameOrg={nameOrg}
                        workSpaceId={idWorkspace}
                        name={vr.name}
                        description={vr.description}
                        ref={vrList[vrList.length - 1].id === vr.id && vrkMax ? lastVr : null}
                        onClickExcluir={onClickExcluir}
                    />
                ))}
            </ul>
        </div>
    )

}

export default ValidationRuleListComp;