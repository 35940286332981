import React from "react";
import Loader from 'react-ts-loaders';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Grow from '@mui/material/Grow';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Theme } from '@mui/material/styles';

const loaderSave = mergeStyles({
    justifyContent:"left",
    width:"50px",
    paddingTop:"10px",
    paddingBottom:"10px"
  });

  const loaderSavePanel = mergeStyles({
    width:"50px",
    color: 'white'
  });

const loaderSaveBigSize = mergeStyles({
    justifyContent:"left",
    width:"50px",
    color:"rgb(17, 77, 136)",
    margin:"10px"
  });

  const loaderSaveRightSize = mergeStyles({
    justifyContent:"left",
    width:"50px",
    color:"rgb(17, 77, 136)",
    marginTop:"10px",
    marginBottom:"10px",
    position:"absolute",
    right:"0"
  });

const loaderSaveLessWidth = mergeStyles({
  justifyContent:"left",
  width:"35px",
  paddingTop:"10px",
  paddingBottom:"10px"
});

  export interface LoaderCompConfig{
    big?: boolean;
    right?: boolean;
    lessWidth?: boolean;
    panel?: boolean
}

const LoaderComp = (props: LoaderCompConfig) => {

    return(
    <Box>
      <Box>
        {/* Conditionally applies the timeout prop to change the entry speed. */}
          <Loader size={props.big?100:40}  className={props.lessWidth?loaderSaveLessWidth:props.right?loaderSaveRightSize:props.big?loaderSaveBigSize:props.panel?loaderSavePanel:loaderSave} />
      </Box>
    </Box>

    );
}

export default LoaderComp;
