import { useEffect } from 'react';
import ChoicerViwer from "../../../../../../../WorkSpaceItem/Viwer/ChoicerViwer";
import { WorkSpaceUserName } from "../../../../../../../../model/ItemModel";
import { MenuConfig } from "../../../../../../../../components/Menu/MenuConfig";
import { DownloadViwer, GetOneItem } from "../../../../../../../../services/Item/ItemServicesGet";
import { IShowInfo } from "../../../../../../DocumentDetail";

interface IDocumentDetailViwer 
{
    workItem: WorkSpaceUserName;
    setShowViwerInfo: (set: IShowInfo) => void;
    isDetailPage?: boolean;
    fullScreenSetted: boolean;
    isSidebarCollapsed?: boolean;
}

export default function DocumentDetailViwer({
    workItem,
    setShowViwerInfo,
    isDetailPage,
    fullScreenSetted,
    isSidebarCollapsed
}: IDocumentDetailViwer)
{
    return (
        <ChoicerViwer
            idItem={workItem?.id!}
            download={DownloadViwer}
            getItem={GetOneItem}
            positionAbsolute={true}
            title={``}
            setShowViwerInfo={setShowViwerInfo}
            isDetailPage={isDetailPage}
            isAcept={false}
            fullScreenSetted={fullScreenSetted}
            isSidebarCollapsed={isSidebarCollapsed}
            isSharedFilePage={false}
        />
    )
}