export interface Mp4Config {}

export const Mp4: React.FC<Mp4Config> = ({}) => {
  return(
    <svg id="icon-audio" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="Retângulo_258" data-name="Retângulo 258" width="24" height="24" rx="2" fill="#3c9fe8" />
      <path id="_211876_play_icon" data-name="211876_play_icon" d="M104.438,68.609l-7.6-4.522a.573.573,0,0,0-.3-.087.545.545,0,0,0-.54.546h0v9.388h0a.544.544,0,0,0,.54.546.624.624,0,0,0,.306-.093l7.589-4.516a.822.822,0,0,0,0-1.261Z" transform="translate(-88.366 -57.24)" fill="#fff" />
    </svg>
  )
}
