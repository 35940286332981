import { Table, TableContainer } from "@mui/material";
import { useTableDims } from "../../../../../../../../../components/StyledModal/hooks/useTableDims/useTableDims";

interface IDocumentDetailTableWrapper
{
    children: React.ReactNode;
    isSidebarCollapsed: boolean;
    fullScreenSetted: boolean;
}

export default function DocumentDetailTableWrapper({
    children,
    fullScreenSetted,
    isSidebarCollapsed
}: IDocumentDetailTableWrapper)
{

    const { tableDims } = useTableDims(isSidebarCollapsed, fullScreenSetted);

    return (
        <TableContainer sx={{
            display: 'flex',
            justifyContent: 'center',
          }}>
            <Table sx={{
               minWidth: 650,
               border: '2px solid #989898',
               width: `${tableDims.width}`,
               font: "normal normal 600 16px/24px Segoe UI",
               letterSpacing: "0px",
               color: "#2D2D2D",
               borderCollapse: 'inherit',
               borderRadius: '12px',
            }}>
                { children }
            </Table>
        </TableContainer>
    )
}