export interface XlsConfig {}

export const Xls: React.FC<XlsConfig> = ({}) => {
  return(
    <svg id="icon-xlsx" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="Retângulo_243" data-name="Retângulo 243" width="24" height="24" rx="2" fill="#15834a" />
      <path id="Caminho_200" data-name="Caminho 200" d="M4,15.444,8.713,10.17,4.1,5H7.825l2.7,3.2L13.23,5h3.734l-4.609,5.17,4.7,5.274H13.322l-2.794-3.29-2.794,3.29H4Z" transform="translate(1.222 1.528)" fill="#fff" />
    </svg>
  )
}
