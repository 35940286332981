import React, { ChangeEvent, useCallback, useState } from "react";
import styles from "../WorkSpaceItemList.module.css";
import { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    mergeStyleSets,
    Modal,
    IIconProps,
    Panel,
    ITag
} from '@fluentui/react';  
import { IconButton, IButtonStyles } from '@fluentui/react/lib/Button';
import WarningIcon from '@mui/icons-material/Warning';
import Btn, { TypeStyle } from "../../../components/Button/Btn";

interface ModalTemporalityComponentProps {
    isModalTemporalityOpen:boolean;
    closeModalTemporality:() => void;
    reset:() => void;
}

const ModalTemporalityComponent: React.ForwardRefExoticComponent<ModalTemporalityComponentProps> =
	React.forwardRef(({isModalTemporalityOpen,closeModalTemporality,reset }, ref: React.Ref<HTMLLIElement>) => {

    const { t, i18n } = useTranslation();





    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    return (
        <Modal
        titleAriaId="Resetar temporalidade"
        isOpen={isModalTemporalityOpen}
        onDismiss={closeModalTemporality}
        isBlocking={false}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.header}>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={closeModalTemporality}
          />
        </div>
        <div className={contentStyles.body}>

           <div className={styles.warningIcon}>                 
             <WarningIcon style={{color:"#005A9E",fontSize: "-webkit-xxx-large"}}/>
           </div>

           <h3 className={styles.hList}>A data de prazo deste arquivo será resetada</h3>

           <div className={styles.textModalTemporality}>
               Ao alterar o status do arquivo para "draft" a data de prazo é resetada. 
               Confirme se você realmente gostaria de alterar o status com a condição de que a data de prazo 
               sofrerá alteração.
           </div>

           <div className={styles.buttonsModalTemporality}>
             {/* btn de noResultsFoundText */}
             <Btn onClick={closeModalTemporality} text={t("Cancelar.message")}  style={TypeStyle.temporalityButtonStylesCancel}/>

             {/* btn de save */}
             <Btn onClick={reset} text={t("Resetar.message")}  style={TypeStyle.temporalityButtonStylesReset}/>

           </div>
        </div>
     </Modal>
    );
});

const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      minWidth: '400px',
      borderRadius:"20px"
    },
    header: [
      {
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        padding: '12px 12px 14px 24px',
      },
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
  });

  const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
      color: "black",
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
    rootHovered: {
      color: "black",
    },
  };
    
export default ModalTemporalityComponent;
