import React, { useEffect, useRef } from 'react';
import * as pdfjs from 'pdfjs-dist';

export interface PagePreviewProps {
    canRender: boolean;
    pdfPage: pdfjs.PDFPageProxy;
    setPageScroll: React.Dispatch<React.SetStateAction<number>>;
}

const PagePreview: React.FC<PagePreviewProps> = (props: PagePreviewProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
      const renderPage = async () => {
        const canvas = canvasRef.current;
        if (!canvas) {
          setTimeout(renderPage, 100);
          return;
        }

        const viewport = props.pdfPage.getViewport({ scale: 1 });
    
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        if (context) {
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          const renderTask = props.pdfPage.render(renderContext);
          renderTask.promise.then().catch((error) => {
            console.error('Error while rendering:', error, " pdfPage:", props.pdfPage.pageNumber);
          });
        }       
      }

      if(props.canRender){
        renderPage();
      }
    }, [props.canRender, props.pdfPage]);

    return (
      <canvas ref={canvasRef} style={{ width: "100%", height: "100%" }} onClick={() => props.setPageScroll(props.pdfPage.pageNumber)}/>
    );
}

export default PagePreview;