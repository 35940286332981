import React, { useState, useEffect } from "react";


const ReturnBackEndParameters = (props: any) => {

    const config = require("../../services/config.json");

    

    return (
        <div>
            {config.baseUrlApp}
            <br/>
            {config.baseUrlAuth}
        </div>
    );
}

export default ReturnBackEndParameters;
