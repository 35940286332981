import { useEffect, useState } from 'react'

export interface useBoxLayoutTypes {
  breakPoint: number
  objectInScreenReference: any
}

export const useBoxLayout = ({ breakPoint, objectInScreenReference }: useBoxLayoutTypes) => {
  
  const [boxLayout, setBoxLayout] = useState<boolean>(false)

  if (breakPoint === undefined) {
    setBoxLayout(false)
  }
  if (breakPoint < 0 || breakPoint === 0) {
    setBoxLayout(false)
  }

  useEffect(() => {
    
    if (objectInScreenReference >= breakPoint) setBoxLayout(false)
    if (objectInScreenReference < breakPoint) setBoxLayout(true)

  }, [objectInScreenReference])

  return {
    boxLayout
  }

}