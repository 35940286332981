import {
    DatePicker,
    MessageBarType
} from '@fluentui/react';
import { SpinButton } from '@fluentui/react/lib/SpinButton';
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Btn from '../../../../components/Button/Btn';
import CheclkBoxComp from "../../../../components/Checkbox/CheckboxComp";
import { getIDatePickerStrings } from "../../../../components/DatePiker/DatePikerLanguage";
import LoaderComp from '../../../../components/Loader/LoaderComp';
import Message from "../../../../components/Message/Message";
import TextFieldComp from "../../../../components/TextField/TextFieldComp";
import { ShareFile, ShareFileCreate } from "../../../../model/SharedFileModel";
import {
    DeleteSharedFileInformation,
    GetSharedFileInformation,
    SaveSharedFileInformation,
    UpdateSharedFileInformation
} from "../../../../services/SharedFileServices";
import ShareFileMail from './ShareFileMail';


export interface ShareFileConfig{
    itemId:string;
}

export interface ShareFileParams{
    saveOkMetod: () => void
    shareFileConfig:ShareFileConfig;
}

export const validateEmail = (email:string) => {
    return String(email)
    .toLowerCase()
    .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const ShareFileComp = (props: ShareFileParams) => {

    interface emailIndex{
        index:number,
        email:string 
    }

    const [emailChanged, setEmailChanged] = useState<string>("");
    const { t, i18n } = useTranslation();
    const [indexList,setIndexList] = useState<emailIndex[]>([]);
    const [indexNumber,setIndexNumber] = useState<number>(0);
    const [dontExpire, setDontExpire] = useState<boolean>(false);
    const [hasWatermark, setHasWatermark] = useState<boolean>(true);
    const [isCalendar, setIsCalendar] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(new Date());
    const [isAcess, setIsAcess] = useState<boolean>(false);
    const [acessCount, setAcessCount] = useState<string>("1");
    
    const [shareFileConfig, setShareFileConfig] = useState<ShareFile|undefined>(undefined);

    const [waitConsult, setWaitConsult] = useState<boolean>(false);
    const [consultError, setConsultError] = useState<string | undefined>(undefined);
    
    const [waitSave, setWaitSave] = useState<boolean>(false);
    const [waitDelete, setWaitDelete] = useState<boolean>(false);

    const [errorMail, setErrorMail] = useState<string | undefined>(undefined);

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    useEffect(() => {

        setWaitConsult(true);
        GetSharedFileInformation(props.shareFileConfig.itemId)
        .then((response) =>{
            if(response != null || response != undefined)
            {
                setShareFileConfig(response);
            }
            else
            {
                setShareFileConfig(undefined);
            }
        })
        .catch((error) =>{
            if( error.response && error.response.data && typeof error.response.data  === 'string')
            {
                setConsultError(error.response.data);
            }
            else
            {
                setConsultError(t("SharedFile.Error"));
            }
            
        })
        .finally(()=>{
            setWaitConsult(false);
        })
    },[
        props.shareFileConfig
    ])

    useEffect(() => {
        if(shareFileConfig != undefined)
        {

            console.log(shareFileConfig)
            if(shareFileConfig.emailList != undefined)
            {
                let email: emailIndex[] = [];
                let i:number = 0;
                for(i=0;i < shareFileConfig.emailList.length;i++)
                {
                    email.push({
                        email: shareFileConfig.emailList[i],
                        index: i
                    });
                }
                setIndexNumber(i);
                setIndexList(email);
            }

            if(shareFileConfig.dateCalendar != undefined)
            {
                let splited:string[] = shareFileConfig.dateCalendar.split('/');

                let date: Date = new Date(Number(splited[2]), Number(splited[1])-1, Number(splited[0]), 0, 0, 0, 0);
                setSelectedDate(date);
            }

             if(shareFileConfig.hasWatermark != undefined)
                setHasWatermark(shareFileConfig.hasWatermark);
            
            
            if(shareFileConfig.accessTotal != undefined)
                setAcessCount(shareFileConfig.accessTotal.toString());

            if(shareFileConfig.isAlwaysAcess != undefined)
            {
                setDontExpire(shareFileConfig.isAlwaysAcess);
                if(shareFileConfig.isAlwaysAcess == true)
                {
                    setIsCalendar(false);
                    setIsAcess(false);
                }
                else
                {
                    if(shareFileConfig.isCalendar != undefined)
                        setIsCalendar(shareFileConfig.isCalendar);

                    if(shareFileConfig.isCountAcess != undefined)
                        setIsAcess(shareFileConfig.isCountAcess);
                }
            }
            else
            {
                if(shareFileConfig.isCalendar != undefined)
                    setIsCalendar(shareFileConfig.isCalendar);

                if(shareFileConfig.isCountAcess != undefined)
                    setIsAcess(shareFileConfig.isCountAcess);
            }
        }
    },[
        shareFileConfig
    ])

    const onChangeSetChange = React.useCallback(
        (
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
        ) => 
        {
            if (newValue != undefined) {
                setEmailChanged(newValue);
            }
            else
            {
                setEmailChanged("");
            }
        },
        []
    );


    const addEmail = () =>
    {
        if(validateEmail(emailChanged))
        {
            setErrorMail(undefined);
            let email:emailIndex ={
                email:emailChanged,
                index:indexNumber
            };
            console.log(indexList);
            setIndexList([...indexList, email]);
            setIndexNumber(indexNumber+1);
        }
        else
        {
            setErrorMail(t('ErroMail'));
        }
    }

    const save = () =>
    {
        let dateString:string | undefined = undefined;
        if(selectedDate != undefined){
            let stringDay:string = selectedDate.getDate().toString();
            let stringMonth:string = (selectedDate.getMonth() + 1).toString();
            if(stringDay.length == 1)
            {
                stringDay = "0" + stringDay
            }
            if(stringMonth.length == 1)
            {
                stringMonth = "0" + stringMonth
            }

            dateString = stringDay+ "/" + stringMonth + "/" + selectedDate.getFullYear().toString()
        }

        let emailList:string[] = indexList.flatMap(x => x.email);

        let acessCountParsed:number = parseInt(acessCount, 10);

        if ((selectedDate ? (isCalendar && selectedDate > new Date()) : false) || acessCountParsed > 0 || dontExpire) {
            if(emailList.length <= 0){
                return setErrorMessage(t("SharedFile.ListEmails"));
            }               
        } 
     
        if (!isCalendar && !isAcess && !dontExpire) {
            return setErrorMessage(t("SharedFile.NotCheckBox"));
        } 
        
        setWaitSave(true);

        if(shareFileConfig != undefined && shareFileConfig.id)
        {
            let shareFile:ShareFile = {
                id:shareFileConfig.id,
                itemId:props.shareFileConfig.itemId,
                emailList:emailList,
                isCalendar:isCalendar,
                isCountAcess:isAcess,
                dateCalendar:dateString,
                accessTotal:acessCountParsed,
                isAlwaysAcess:dontExpire,
                hasWatermark:hasWatermark
            }

            setErrorMessage(undefined);
 
                UpdateSharedFileInformation(shareFile)
                .then((result) =>
                {
                    props.saveOkMetod();
                    setShareFileConfig(undefined);
                })
                .catch((error) => {
                    if( error.response && error.response.data && typeof error.response.data  === 'string')
                    {
                        setErrorMessage(error.response.data);
                    }
                    else
                    {
                        setErrorMessage(t("SharedFile.ErroOnSave"));
                    }
                })
                .finally(() => {
                    setWaitSave(false);
                })
            } 
        
        else
        {
            let shareFileCreate:ShareFileCreate = {
                itemId:props.shareFileConfig.itemId,
                emailList:emailList,
                isCalendar:isCalendar,
                isCountAcess:isAcess,
                dateCalendar:dateString,
                accessTotal:acessCountParsed,
                isAlwaysAcess:dontExpire,
                hasWatermark: hasWatermark
            }

         
            SaveSharedFileInformation(shareFileCreate)
            .then((result) =>
            {
                props.saveOkMetod();
            })
            .catch((error) => {
                if( error.response && error.response.data && typeof error.response.data  === 'string')
                {
                    setErrorMessage(error.response.data);                     
                }
                else
                {
                    setErrorMessage(t("SharedFile.ErroOnSave"));
                }
            })
            .finally(() => {
                setWaitSave(false);
            })
            
        }
    }

    const add = () =>
    {
        let shareFileadd:ShareFile = {}
        setShareFileConfig(shareFileadd);
    }

    const deleteShare = () =>
    {
        if(shareFileConfig != undefined && shareFileConfig.id)
        {
            setWaitDelete(true);
            DeleteSharedFileInformation(shareFileConfig.id)
            .then((result) =>
            {
                props.saveOkMetod();
                setShareFileConfig(undefined);
            })
            .catch((error) => {
                if( error.response && error.response.data && typeof error.response.data  === 'string')
                {
                    setErrorMessage(error.response.data);
                }
                else
                {
                    setErrorMessage(t("SharedFile.ErroOnDelete"));
                }
            })
            .finally(() => {
                setWaitDelete(false);
            })
        }
    }

    function remove(index: number) {
        setIndexList(
            indexList.filter(a =>
              a.index !== index
            )
        );
    }
    

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key ===  'Enter'){
            addEmail();
        }
    }

    function changeIsCalendar (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) {
        if(checked == undefined || checked == false)
        {
            setIsCalendar(false);
        }
        else
        {
            setIsCalendar(true);
        }
      };

      function changeIsAcess (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) {
        if(checked == undefined || checked == false)
        {
            setIsAcess(false);
            setAcessCount("0");
        }
        else
        {
            setIsAcess(true);
        }
    };

    const onChangeAccesCount = React.useCallback((event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
        if (newValue !== undefined) {
            setAcessCount(newValue);
        }
        else
        {
            setAcessCount("0");
        }
      }, []);

      function changeDontExpire (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) {
        if(checked == undefined || checked == false)
        {
            setDontExpire(false);
        }
        else
        {
            setDontExpire(true);
            setIsCalendar(false);
            setIsAcess(false);
        }
      };

      function changehasWatermark (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) {
        if(checked == undefined || checked == false)
        {
            setHasWatermark(false);
        }
        else
        {
            setHasWatermark(true);
        }
    };

      const onFormatDate = (date?: Date): string => {
        return !date ? '' : date.getDate() + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear() % 100);
      };

      

    return (
        waitConsult || consultError != undefined?(
            <div>
                {waitConsult &&(
                    <LoaderComp/>
                )}
                {consultError != undefined &&(
                    <div>
                        <Message text={consultError} type={MessageBarType.error} />
                        <Btn onClick={add} text={"Reload"} />
                    </div>
                )}
            </div>
        ):
        (
            <div>
                {shareFileConfig != undefined?(
                <div>
                    <TextFieldComp
                        label={"E-mail"}
                        tooltip
                        value={emailChanged}
                        onChange={onChangeSetChange}
                        autofocus
                        onKeyDown={onKeyDown}
                    />

                    {errorMail != undefined && (
                        <Message
                            text={errorMail}
                            type={MessageBarType.error}
                        />
                    )}

                    <Btn onClick={addEmail} text={t("SharedFile.AddEmail")} />
                    <ul>
                        {indexList.map((emailOk)=>(
                            <ShareFileMail
                                key={emailOk.index}
                                index={emailOk.index}
                                mail={emailOk.email}
                                excludeMedot={remove}
                            />
                        ))}
                    </ul>
                    <CheclkBoxComp 
                        label={ t("SharedFile.EndedByDay")}
                        checked={isCalendar}
                        onChange={changeIsCalendar}
                        disabled={dontExpire}
                    />
                    
                    <DatePicker
                        style={{margin:"5px"}}
                        showWeekNumbers={true}
                        firstWeekOfYear={1}
                        showMonthPickerAsOverlay={true}
                        strings={getIDatePickerStrings(i18n.language)}
                        disabled={dontExpire || isCalendar == false}
                        formatDate={onFormatDate}
                        onSelectDate={setSelectedDate as (date: Date | null | undefined) => void}
                        value={selectedDate}
                        minDate={new Date()}
                    />

                    <CheclkBoxComp 
                        label={ t("SharedFile.EndedByAcces")}
                        checked={isAcess}
                        onChange={changeIsAcess}
                        disabled={dontExpire}
                    />
                    <SpinButton
                        style={{margin:"5px"}}
                        min={1}
                        max={999}
                        step={1}
                        incrementButtonAriaLabel="Increase value by 1"
                        decrementButtonAriaLabel="Decrease value by 1"
                        disabled={dontExpire || isAcess == false}
                        value={acessCount}
                        onChange={onChangeAccesCount}
                    />
                    <Grid item sx={{display: 'flex'}} >
                        <CheclkBoxComp
                            label={ t("SharedFile.WithoutEnded")}
                            checked={dontExpire}
                            onChange={changeDontExpire}
                        />
                        <CheclkBoxComp
                            label={ t("SharedFile.HasWatermark")}
                            checked={hasWatermark}
                            onChange={changehasWatermark}
                        />
                    </Grid>

                    {errorMessage != undefined && (
                        <Message
                            text={errorMessage}
                            type={MessageBarType.error}
                        />
                    )}

                    <Btn onClick={save} text={"Save"} disabled={waitSave || waitDelete} />
                    {waitSave &&(<LoaderComp/>)}
                    
                    <Btn onClick={deleteShare} text={"Delete"} disabled={waitSave || waitDelete || (shareFileConfig != undefined && shareFileConfig.id != undefined) == false}/>
                    {waitDelete &&(<LoaderComp/>)}
                </div>
                ):(
                    <div>
                        <h3>{t("SharedFile.FileDontShared")}</h3>
                        <Btn onClick={add} text={t("SharedFile.ToShare")} />
                    </div>
                )}
            </div>
        )
    )
}

export default ShareFileComp;