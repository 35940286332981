import { Shimmer, ShimmerElementType } from "@fluentui/react";
import { Box, Grid } from "@mui/material";
import { DashboardCard } from "../../../DashboardCard/DashboardCard";

export default function DashboardGeneralDataShimmer() {
    return (
        <>
            <Box sx={{
                marginBottom: 2,
                width: "100%"
            }}>
                <DashboardCard>
                    <Grid container
                        xs={12}
                        marginBottom="21px"
                    >
                        <Grid item
                            xs={11}
                        >
                            <Shimmer width="30%" />
                        </Grid>
                        <Grid container
                            xs={1}
                            display="flex"
                        >
                            <Grid item
                                xs={4}
                            >
                                <Shimmer width="30%" />
                            </Grid>
                            <Grid item
                                xs={8}
                            >
                                <Shimmer width="100%" />
                            </Grid>
                        </Grid>
                        <Grid container marginTop={5}>
                            <Grid item xs={12}>
                                <Shimmer width="100%" />
                            </Grid>
                        </Grid>
                    </Grid>
                </DashboardCard>
            </Box>
        </>
    )
}