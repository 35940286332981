import { Box, Grid } from "@mui/material";
import BaseText from "../../../../components/BaseText/BaseText";
import ProgressBar from "../ProgressBar/ProgressBar";
import { useTranslation } from "react-i18next";

interface IDashboardGeneralData {
    total: number;
    consumed: number;
    hasContract?: boolean;
}

export default function DashboardGeneralData({
    total,
    consumed,
    hasContract
}: IDashboardGeneralData) {

    const { t } = useTranslation()

    return (
        <>
            <Grid item
                xs={6}
            >
                <BaseText 
                    fontStyle="normal"
                    fontVariantCss="normal"
                    fontWeight="bold"
                    textColor="#2D2D2D"
                    maxFontSize="30px"
                    minFontSize="20px"
                    text={t("Dashboard.generalDataUsage")}
                />
            </Grid>
            <Grid item
                xs={6}
                sx={{
                    display: "flex",
                    justifyContent: 'flex-end',
                }}
                marginBottom="21px"
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >   
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            marginRight: "20px"
                        }}
                    >

                        <Box 
                            component="div"
                            sx={{
                                height: '16px',
                                width: "16px",
                                borderRadius: "50%",
                                background: "#114D88"
                            }}
                        />
                        <BaseText 
                            text={t("Dashboard.storageInUse")}
                            fontStyle="normal"
                            fontVariantCss="normal"
                            fontWeight="bold"
                            textColor="#2D2D2D"
                            maxFontSize="24px"
                            minFontSize="16px"
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2
                        }}
                    >
                        { hasContract && (
                            <>
                                <Box 
                                    component="div"
                                    sx={{
                                        height: '16px',
                                        width: "16px",
                                        borderRadius: "50%",
                                        background: "#E3EAF0"
                                    }}
                                />
                                <BaseText 
                                    text={t("Dashboard.freeStorage")}
                                    fontStyle="normal"
                                    fontVariantCss="normal"
                                    fontWeight="bold"
                                    textColor="#2D2D2D"
                                    maxFontSize="24px"
                                    minFontSize="16px"
                                />
                            </>
                        ) }
                    </Box>
                </Box>
            </Grid>
            <Grid item
                xs={12}
            >
                <ProgressBar
                    consumedValue={consumed}
                    maxValue={total}
                    hasContract={hasContract!}
                />
            </Grid>
        </>
    )
}