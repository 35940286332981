import { useEffect } from 'react';
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DescriptionLayerWrapper from "../DescriptionLayerWrapper/DescriptionLayerWrapper";

interface IDescriptionLayer {
    description: string;
    showDescription: boolean;
    clickId: number;
    index: number;
}


export default function DescriptionLayer({ description,  showDescription, clickId, index }: IDescriptionLayer) {

    const { t } = useTranslation();

    return (
        <>
            { showDescription && clickId === index ? (
            <TableRow style={{ height: '100%' }}>
                <TableCell colSpan={4}>
                    <Box sx={{
                        textAlign: "left",
                        height: "80px",
                        width: '100%',
                        background: "#F4F4F4 0% 0% no-repeat padding-box",
                        borderRadius: "8px",
                        paddingTop: '5px',
                        paddingLeft: '10px',
                        paddingRight: "10px",
                        paddingBottom: '10px'
                    }}>
                        <>
                            { description !== undefined ? (
                                    <Typography sx={{
                                        textAlign: "left",
                                        font: "normal normal normal 16px/24px Segoe UI",
                                        letterSpacing: "0px",
                                        color: "black",
                                        opacity: 1,
                                    }}>
                                        { description }
                                    </Typography>
                                ) : (
                                    <Typography sx={{
                                        textAlign: "left",
                                        font: "normal normal normal 16px/24px Segoe UI",
                                        letterSpacing: "0px",
                                        color: "black",
                                        opacity: 1,
                                    }}>
                                        { t("WithoutRule.withoutDesc") }
                                    </Typography>
                            ) }
                        </>
                    </ Box>
                </TableCell>
            </TableRow>
                ) : (
                    <></>
                ) }
        </>
    )
}