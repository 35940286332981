import { useEffect } from 'react';
import { Box } from '@mui/material';
import descriptionIcon from '../../../../../../../../../../assets/icons/descrição Alter.svg';

interface IDescription {
    description: string;
    setShowDescription: (set: boolean) => void;
    setClickId: (set: number) => void;
    index: number;
    showDescription: boolean;
    setInternalDescription: (set: string) => void;
}

export default function Description({ description, setClickId, setShowDescription, index, showDescription, setInternalDescription }: IDescription) {

    useEffect(() => {
        setInternalDescription(description);
    }, [])

    return (
        <>
            <Box 
                component="img"
                src={descriptionIcon}
                width="20px"
                onClick={() => {
                    setClickId(index)
                    setShowDescription(!showDescription)
                }}
                sx={{
                    ':hover': {
                        cursor: "pointer"
                    }
                }}
            />
        </>
    )
}