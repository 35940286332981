import { FileListHash, FileWorkspace } from "../../context/UploadProvider/typesUpload";
import { FileListAdd, FileReplace } from "../../context/UploadProvider/typesUpload";

let fileHashNew: FileListHash[] = [];

export const Manyfilepload = async (items: DataTransferItemList, idWorkspace: string, idFolderFather: string | undefined, getUploadContext: () => FileListAdd) => {
	const upload = getUploadContext();
	fileHashNew = []
	const promises: Promise<void>[] = [];

	for (let i = 0; i < items.length; i++) {
		const entry = items[i].webkitGetAsEntry();
		if (entry) {
		promises.push(traverseEntry(entry, idWorkspace, idFolderFather, 1));
		}
	}
	
	await Promise.all(promises); 
	upload.setShowEx(true);
	upload.addFolder(fileHashNew);

};  

export const ManyfileploadDirectoryPicker = async (dirEntries: any, idWorkspace: string, idFolderFather: string | undefined, getUploadContext: () => FileListAdd) => {
	const upload = getUploadContext();
	fileHashNew = [];

	await traverseEntryDirectoryPicker(dirEntries, idWorkspace, idFolderFather, 1, fileHashNew);

	upload.setShowEx(true);
	upload.addFolder(fileHashNew);
}; 

export const Filepload = async (file: FileList, idWorkspace: string, idFolderFather: string | undefined, getUploadContext: () => FileListAdd) => {
	const upload = getUploadContext();

	let i = 0;
	let fileList:FileWorkspace[] = [];

	for(i = 0; i < file.length;i ++) {
		fileList.push({
			file:file[i],
			woskpace:idWorkspace,
			folder:idFolderFather
		});
	}
	upload.setShowEx(true);
	upload.addMany(fileList);		
};  

export const ReplaceUpload = (file: File, fileToReplaceId: string, getUploadContext: () => FileListAdd) => {
	if (fileToReplaceId === "" || fileToReplaceId === undefined) 
		return;

	let fileList:FileReplace = {
		file:file,
		idItem:fileToReplaceId
	}
 
	const upload = getUploadContext();
	upload.setShowEx(true);
	upload.replace(fileList);
};

const traverseEntryDirectoryPicker = async (entry: any, idWorkspace: string, idFolderFather: string | undefined, depth: number, newFileListHash?:  FileListHash[] | undefined) => {
	if (entry.kind === 'file') {
		const file = await entry.getFile();
		  (newFileListHash ? newFileListHash : fileHashNew).push({
			  fileWorkspace:{
				  file: file,
				  woskpace: idWorkspace,
				  folder: idFolderFather
			  },
			  fileHash: newFileListHash == undefined? 1 : newFileListHash.length+1,
			  uploadDone: false,
		  });
	} else if (entry.kind === 'directory') {
	  let fileHash:FileListHash={
		  folderWorkspace:{
			  name: entry.name,
			  itens: [],
			  woskpace: idWorkspace,
			  folder: idFolderFather
		  },
		  fileHash: newFileListHash == undefined? 1 : newFileListHash.length+1,
		  uploadDone: false,
	  };			
	  (newFileListHash ? newFileListHash : fileHashNew).push(fileHash);
  
	  if (depth < 16) {
		for await (const subEntry of entry) {
		  if (entry.kind === 'directory') {
			await traverseEntryDirectoryPicker(subEntry[subEntry.length - 1], idWorkspace, idFolderFather, depth + 1, fileHash.folderWorkspace?.itens);
		  }
		}
	  }
	}
};

const traverseEntry = async (entry: any, idWorkspace: string, idFolderFather: string | undefined, depth: number, newFileListHash?:  FileListHash[] | undefined) => {
	if (entry.isFile) {
	  await new Promise<void>((resolve) => {
		entry.file((file: File) => {
		  (newFileListHash ? newFileListHash : fileHashNew).push({
			  fileWorkspace:{
				  file: file,
				  woskpace: idWorkspace,
				  folder: idFolderFather
			  },
			  fileHash: newFileListHash == undefined? 1 : newFileListHash.length+1,
			  uploadDone: false,
		  });
		  resolve();
		});
	  });
	} else if (entry.isDirectory) {
  
	  let fileHash:FileListHash={
		  folderWorkspace:{
			  name: entry.name,
			  itens: [],
			  woskpace: idWorkspace,
			  folder: idFolderFather
		  },
		  fileHash: newFileListHash == undefined? 1 : newFileListHash.length+1,
		  uploadDone: false,
	  };			
	  (newFileListHash ? newFileListHash : fileHashNew).push(fileHash);
  
	  if (depth < 16) {
		const reader = entry.createReader();
		const entries = await new Promise<any[]>((resolve, reject) => {
		  reader.readEntries((results: any[]) => {
			resolve(results);
		  }, reject);
		});
  
		for (const subEntry of entries) {
		  if (subEntry.isFile) {
			await traverseEntry(subEntry, idWorkspace, idFolderFather, depth + 1, fileHash.folderWorkspace?.itens);
		  }
		}
  
		for (const subEntry of entries) {
		  if (subEntry.isDirectory) {
			await traverseEntry(subEntry, idWorkspace, idFolderFather, depth + 1, fileHash.folderWorkspace?.itens);
		  }
		}
	  }
	}
};