import { Shimmer } from "@fluentui/react";
import { Box, Grid } from "@mui/material";

export default function DashboardHeaderShimmer() {
    return (
        <>
            <Grid item
                xs={12}
            >
                <Shimmer width="20%" />
            </Grid>
            <Grid item
                xs={12}
                marginTop={1}
                marginBottom={2}
            >
                <Shimmer width="100%" />
            </Grid>
        </>
    )
}