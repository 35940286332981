import { ApiAuth } from "../services/api";
import { PutPassword } from "../model/IdentitiModel";

export async function GetValidateCode(code: string) {
    const request = await ApiAuth.get<any>("api/UserInfoPassword/" + code);
  
    return request.data;
  }

  export async function PutNewPassword(putPassword: PutPassword) {
    const request = await ApiAuth.put<any>("api/UserInfoPassword/", putPassword );
  
    return request.data;
  }

  export const putMfaKey = async (email: string) => {
    const request = await ApiAuth.put(`api/UserInfoPassword/mfaKey`, { email });

    return request.data;
  }