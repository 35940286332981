import { useEffect, useRef, useState } from "react";
import { TextField } from "@fluentui/react";
import { Grid, Snackbar, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FormButton from "../../PublicLayout/components/Handle/Form/Root/components/FormButton";
import { useNavigate, useParams } from "react-router-dom";
import { VerifyOtp } from "../../../services/UserServices";
import keyPasswordIcon from "../../../assets//icons/keyPassword-icon.svg"
import MuiAlert, { AlertColor } from '@mui/material/Alert';
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { useProfile } from "../../../context/ProfileProvider/useProfile";
import { IUser } from "../../../context/AuthProvider/types";
import { GetUniqueLinkSearchParams, LoginRequestMFA } from "../../../context/AuthProvider/util";
import { OtpDialog } from "./components/OtpDialog";
import { putMfaKey } from "../../../services/IdentitiServices";
import { SuccessfullyOtpDialog } from "./components/SuccessfullyOtpDialog";
import { CenterForm } from "../../PublicLayout/components/Handle/CenterForm";
import { ButtonTyppe } from "../../../components/Button/BtnBigWithIcon/BtnBigWithIcon";
import { LoaderIcon } from "../../../assets/icons/loaderIcon";
import { checkIcon } from "../../../assets/icons/checkIcon";
import { textFieldStyles } from "../../Style/TextfieldStyles";

export default function MfaOTP() {

    const { t } = useTranslation();
    const [otpCode, setOtpCode] = useState<string>("")
    const { email } = useParams();
    const navigate = useNavigate();
    const [load, setLoad] = useState<boolean>(false);
    const [openOtpDialog, setOpenOtpDialog] = useState<boolean>(false);
    const [successfullyDialog, setSuccessfullyDialog] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [popupOk, setPopupOk] = useState<boolean>(false);
    const [alertColor, setAlertColor] = useState<AlertColor>("success");
    const uniqueLink = GetUniqueLinkSearchParams();

    const auth = useAuth();
    const profile = useProfile();

    function closePopUpOk() {
      setPopupOk(false);
    }

    const handleClose = (event:any, reason:any) => {
      closePopUpOk();
    }

    const onChangeOtpCode = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
      ) => {
        if (newValue != undefined) {
            setOtpCode(newValue);
        } else {
            setOtpCode("");
        }
      };
    
    const handleNavigateLogin = () => {
      navigate(`/login${uniqueLink != undefined ? "?link=" + uniqueLink : '' }`);
    }

    function verifyOtp(otpCode: string) {
      if (otpCode == undefined) {
        setPopupOk(true)
        setMessage(t("Login.otpErrorMessage"))
        throw new Error()
      }

      if (email != undefined) {
        setLoad(true)
        LoginRequestMFA(otpCode, email)
        .then(data => {
          setSuccessfullyDialog(true)
        })
        .catch(() => { 
          setPopupOk(true);
          setMessage(t("Login.oneTimePasswordError"))
          setAlertColor("error");
        })
        .finally(() => setLoad(false))
      }
    } 

    const handleOpenDialog = (isOtpDialog: boolean, data?: any) => {
      if (isOtpDialog) {
        if (openOtpDialog) {
          setOpenOtpDialog(false)
        } else {
          setOpenOtpDialog(true)
        }
      } else {
        if (successfullyDialog) {
          setSuccessfullyDialog(false);
        } else {
          setSuccessfullyDialog(true);
        }
      }
    }
    
    const handleMfaKey = () => {
      putMfaKey(email!)
      .then(() => {
          setMessage("Sua chave de mfa foi resetada com sucesso.");
          setAlertColor("success");
          setPopupOk(true);
      })
      .catch(() => {
        setMessage("Um erro ocorreu ao tentar resetar sua chave mfa.");
        setAlertColor("error");
        setPopupOk(true);
      })
      .finally(() => {
        handleOpenDialog(true);
        navigate(`/login${uniqueLink != undefined ? "?link=" + uniqueLink : ''}`)
        setPopupOk(false);
      })
  }

    return (
      <>
      <CenterForm
          title={t("Login.otpTittle")}
          subtitle={t("Login.otpSub")}
          icon={ keyPasswordIcon }
          titleSize={32}
      >
        <Grid item xs={12} marginBottom={'14px'}>
            <Typography
                component='label'
                sx={{
                    textAlign: "left",
                    font: "normal normal 600 16px/24px Segoe UI",
                    letterSpacing: "0px",
                    color: "#2D2D2D",
                    opacity: 1,
                    marginBottom: "10px"
                }}
                >
                {t("Login.OtpMessage")}
                </Typography>
                <TextField 
                onChange={onChangeOtpCode}
                value={otpCode}
                styles={textFieldStyles}
            />
        </Grid>
        <FormButton 
            load={load}
            btnBigWithIconConfig={{
                icon: load == true ? LoaderIcon : checkIcon,
                text:t("Entrar.message"),
                onClick: (e: any) => verifyOtp(otpCode)
            }}
        />
        <FormButton 
            load={load}
            btnBigWithIconConfig={{
                text:t("otpDialog.button"),
                onClick: (e: any) => handleOpenDialog(true),
                isPrimary: ButtonTyppe.secondary
            }}
        />
      </CenterForm>
        <Snackbar
          open={popupOk}
          autoHideDuration={6000}
          message="Archived"
          onClose={handleClose}
          anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
          sx={{ bottom: { xs: 50, sm: 20 } }}
        >
          <MuiAlert elevation={6} variant="filled" sx={{ width: '100%' }} severity={alertColor} onClose={(e) => handleClose(e, "")}>
            {message}
          </MuiAlert>
        </Snackbar>
        <OtpDialog 
          email={email!}
          handleOpenDialog={handleOpenDialog}
          openDialog={openOtpDialog}
          handleMfaKey={handleMfaKey}
        />
        <SuccessfullyOtpDialog 
          isopen={successfullyDialog}
          handleNavigateLogin={handleNavigateLogin}
          handleOpenDialog={handleOpenDialog}
        />
      </>
    )
}