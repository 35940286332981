import React, { useState, useEffect, useCallback, useRef } from "react";
import { ITextFieldStyles } from "@fluentui/react/lib/TextField";
import { Link } from "@fluentui/react/lib/Link";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import {
  Delete
} from "../../services/WorkSpaceServices";
import {
    WorkSpace,
    WorkSpaceCreate,
    User
} from "../../model/WorkSpaceModel";
import { useNavigate, useParams } from "react-router-dom";
import { useProfile } from '../../context/ProfileProvider/useProfile';
import Btn from "../../components/Button/Btn";
import TextFieldComp from "../../components/TextField/TextFieldComp";
import { Stack } from "@fluentui/react/lib/Stack";
import { Panel } from "@fluentui/react/lib/Panel";
import { useBoolean } from "@fluentui/react-hooks";
import Message from "../../components/Message/Message";
import { DatePicker, List, MessageBarType } from "@fluentui/react";
import WorkSpaceAdmListComp from "../WorkSpaceAdm/WorkSpaceAdmListComp";
import styles from "../Style/List.module.css";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogType,
  DialogFooter,
  DialogContent,
} from "@fluentui/react/lib/Dialog";

const exampleChildClass = mergeStyles({
  display: "block",
  marginBottom: "10px",
});

const textFieldStyles: Partial<ITextFieldStyles> = {
  root: { maxWidth: "300px" },
};


const WorkSpaceAdmList  = (props: any) => {
  const [itens, setWork] = useState<WorkSpace[]>(new Array<WorkSpace>());
  const navegate = useNavigate();
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);
  const [newOrgName, setNewOrgName] = useState<string>();
  const forceUpdate = useCallback((teste: string) => setNewOrgName(teste), []);
  const { t, i18n } = useTranslation();
  const [initialsChanged, setInitials] = useState<string>("");
  const [internalNameChanged, setInternalNameChanged] = useState<string>("");

  const [userEdit, setUserEdit] = useState<string|undefined|number>(0)
  const [userDeleted, setUserDeleted] = useState<string|undefined>(undefined);

  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [userDeletedName, setUserDeletedName] = useState<string>("");

  const [isChecked, setChecked] = useState<boolean>(false);
  const [popupOk, setPopupOk] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  
  const [reload, setReload] = useState<boolean>(false);
  const [searchName, setSearchName] = useState<string>("");

  const profile = useProfile();
  const [isAdminOrganization, setisAdminOrganization] = useState<boolean>(false);

  const { nameOrg } = useParams();

  useEffect(() => {
    if(typeof userEdit != "number")
    {
      openPanel();
    }
  }, [userEdit]);

  useEffect(() => {
    if(userDeleted != undefined)
    {
      toggleHideDialog();
    }
  }, [userDeleted]);


  useEffect(() => {
    if(reload == true ){
      setReload(false)
    }
  }, [reload]);

  useEffect(() => {
    if(profile.organizations != undefined)
    {
      for(let organization of profile.organizations)
      {
        if(nameOrg == organization.internalName)
        {
          if(organization.isAdmin == true)
          {
            
            setisAdminOrganization(true);
          }
          else{
            setisAdminOrganization(false);
          }
        }
      }
    }
  }, [profile]);

  const iconProps = { iconName: "Filter" };

  const onChangeSearchName =
  (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    if (newValue != undefined ) {
      setSearchName(newValue);
    }
  };


  return (
    <div>
      <Stack horizontal className={styles.hPage}>   
        <Stack.Item grow>
          <h3 className={styles.hList}>Workspaces</h3>
        </Stack.Item>     
        <Stack.Item className={styles.InputSearch}>
            <TextFieldComp iconProps={iconProps}  value={searchName} onChange={onChangeSearchName} />
          </Stack.Item>
      </Stack>
      
      {reload?(<div></div>):(
          nameOrg != undefined?(
            <WorkSpaceAdmListComp
              nameOrg={nameOrg}
              name={searchName}
              hiddenContexMenu={isAdminOrganization == false}
            />
          ):(<li className={styles.itemContent}></li>))}
    </div>
  );
};


export default WorkSpaceAdmList;
