import { Grid, Typography, useTheme } from "@mui/material";
import { useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import BtnBigWithIcon,{ ButtonTyppe} from "../../../components/Button/BtnBigWithIcon/BtnBigWithIcon";
import FormAction from "../../PublicLayout/components/Handle/Form/Root/components/FormActions/FormAction";
import { CenterForm } from '../../PublicLayout/components/Handle/CenterForm';
import MailIcon  from '../../../assets/icons/mail-icon.svg';
import { ArrowLeftIcon } from "../../../assets/icons/ArrowLeftIcon";

export default function EmailSendSuccess() {

  const navigate = useNavigate()
  const { t } = useTranslation();
  const theme = useTheme();

  
  const login = (event: any) => {
    navigate("/login");
  };

  return (
    <CenterForm 
        icon={MailIcon} 
        subtitle={t("Login.confirmationEmail")} 
        title={t("Login.checkYourEmail")} 
        titleSize={32}
        >
        <Grid item xs={12}>
          <Grid item xs={12}>
                <FormAction href="/resetPassword"
                  text={t("Login.clickHereToResend")}
                  contextText={t("Login.didntRecieved")}
                  flexDirection="row"
                />
          </Grid>
          <BtnBigWithIcon 
          isPrimary={ButtonTyppe.secondary}
          text={t("IrParaLogin.message")}
          onClick={login}
          isTextButton
          icon={ArrowLeftIcon}
          iconGap={'10px'}
        />
      </Grid>
    </CenterForm>
  )
}