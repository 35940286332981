import * as React from 'react';
import { Panel } from '@fluentui/react/lib/Panel';
import FlowSidebarHeader from './components/FlowSidebarHeader/FlowSidebarHeader';
import FlowSidebarContent from './components/FlowSidebarContent/FlowSidebarContent';
import { GetFlowInformationByItemVO } from '../../../../../model/ItemModel';
import FlowSidebarWrapper from './components/FlowSidebarWrapper/FlowSidebarWrapper';

interface IFlowSidebar 
{
    informationFlowByVO: GetFlowInformationByItemVO | undefined;
    isLoading: boolean;
    setIsSidebarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
    isSidebarCollapsed: boolean;
    fullScreenSetted: boolean;
}
export default function FlowSidebar({
    informationFlowByVO,
    isLoading,
    isSidebarCollapsed,
    setIsSidebarCollapsed,
    fullScreenSetted
}: IFlowSidebar) 
{
    return (
        <FlowSidebarWrapper
            isSidebarCollapsed={isSidebarCollapsed}
            fullScreenSetted={fullScreenSetted}
        >
            <FlowSidebarHeader 
                itemName={informationFlowByVO?.itemName}
                itemExtension={informationFlowByVO?.itemExtension}
                isSidebarCollapsed={isSidebarCollapsed}
                setIsSideBarCollapsed={setIsSidebarCollapsed}
            />
            <FlowSidebarContent 
                isLoading={isLoading}
                isSidebarCollapsed={isSidebarCollapsed}
                informationFlowByVO={informationFlowByVO}
            />
        </FlowSidebarWrapper>
    )
}
