import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { LeftForm } from "../../PublicLayout/components/Handle/LeftForm";
import { useEffect, useRef } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { Box, Typography } from '@mui/material';
import FormButton from '../../PublicLayout/components/Handle/Form/Root/components/FormButton';
import { GetUniqueLinkSearchParams } from '../../../context/AuthProvider/util';
import { CenterForm } from '../../PublicLayout/components/Handle/CenterForm';

const MfaQRCode = () => {
    const { email, key } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const uniqueLink = GetUniqueLinkSearchParams();

    function setMfaUrl() {
        let issuer = "w3k";
        return `otpauth://totp/${issuer}:${email}?secret=${key}&issuer=${issuer}`;
    }

    return (
        <>
            <CenterForm
            title={t("Login.qrcodeTitle")}
            subtitle={t("Login.qrcodeSub")}
            titleSize={32}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        padding: "16px"
                    }}
                >
                    <QRCodeSVG value={setMfaUrl()} size={200} />
                </Box>
                <Box>
                    <Typography
                        sx={{
                            textAlign: "left",
                            font: "normal normal 600 16px/24px Segoe UI",
                            letterSpacing: "0px",
                            color: "#2D2D2D",
                            opacity: 1,
                            padding: "16px"
                        }}
                    >
                        {t("Login.qrcodeRedirect")}
                    </Typography>
                </Box>
                <FormButton
                    load={false}
                    btnBigWithIconConfig={{
                        text:t("Login.oneUsePassword"),
                        onClick: (e: any) => navigate(`/login/otp/${email}${uniqueLink != undefined ? "?link=" + uniqueLink : ''}`, { replace: true })
                    }}
                />
            </CenterForm>
        </>
    );
}

export default MfaQRCode;
