import React, { useState, useEffect } from "react";
import { Stack} from '@fluentui/react';
import Btn from "../../../components/Button/Btn";
import TextFieldComp from "../../../components/TextField/TextFieldComp";
import styles from "../../Style/List.module.css";
import { useTranslation } from "react-i18next";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ValidationRuleListComp from './ValidationRuleListComp'
import { useNavigate, useParams } from "react-router-dom";
import { ValidationRuleModalDeleteComp } from "../ModalDeleteValidationRule/ValidationRuleModalDeleteComp";

const ValidationRuleList  = (props: any) => {

    const { t } = useTranslation();
    const [findValidationRule, setFindValidationRule] = useState<string>("");
    const iconProps = { iconName: "Filter" };
    const [popupOk, setPopupOk] = useState<boolean>(false);
    const { idWorkspace } = useParams();
    const { nameOrg } = useParams();
    const navegate = useNavigate();
    const [idVrDelete, setIdVrDelete] = useState<string|undefined|number>(0);
    const [reload, setReload] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const errorMessage = t("ErroAoSalvar.message")
    const alertColor = "success"

    useEffect(() => {
        if(reload == true ){
          setReload(false);
        }
      }, [reload]);

    const onChangeSetFindValidationRule = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
      ) => {
        if (newValue != undefined) {
            setFindValidationRule(newValue);
        }
        else {
            setFindValidationRule("");
        }
      };

      const handleClose = (event:any, reason:any) => {
        setPopupOk(false);
      }

      const handleClosePO = (event:any) => {
        setPopupOk(false);
      }

      const onClickNew = () => {
        navegate("/" +nameOrg+"/WorkSpace/" + idWorkspace +"/validationRule/new");
      };

      const toggleDelete = (
        value:string|undefined
      ) => {
        if(value ==  idVrDelete)
        {
          if(idVrDelete != undefined)
          {
            setShowModal(true);
          }
        }
        else
        {
            setIdVrDelete(value!);
        }
      };

      useEffect(() => {
        if(typeof idVrDelete != "number"){
          setShowModal(true);
        }
      }, [idVrDelete]);

      const onClickDelete = (
        event: React.FormEvent<HTMLElement>
      ) => {
        const { myValue } = event.currentTarget.dataset;
        toggleDelete(myValue);
      };

    const onCloseModal = () => {
      setShowModal(false)
      setReload(true);
    }

    return (
        <div>
            <Stack horizontal className={styles.hPage}>
                <Stack.Item grow>
                <h3 className={styles.hList}>{t("ValidationRule.menu")}</h3>
                </Stack.Item>
                <Stack className={styles.stackSide}>
                <Stack.Item className={styles.BtnNew}>
                    <Btn text={t("Novo.message")}
                    onClick={onClickNew}/>
                </Stack.Item>
                <Stack.Item className={styles.InputSearch}>
                    <TextFieldComp iconProps={iconProps}
                    value={findValidationRule}
                    onChange={onChangeSetFindValidationRule}/>
                </Stack.Item>
                </Stack>
            </Stack>
            {reload == false && idWorkspace != undefined && nameOrg!= undefined?
                (
                    <ValidationRuleListComp
                        nameOrg={nameOrg}
                        idWorkspace={idWorkspace}
                        text={findValidationRule}
                        onClickExcluir={onClickDelete}
                    />
                ):
                (<div></div>)}

            <ValidationRuleModalDeleteComp onClose={() => onCloseModal()} show={showModal} idVrDelete={idVrDelete}/>
            
            <Snackbar
                open={popupOk}
                autoHideDuration={6000}
                message="Archived"
                onClose={handleClose}
                anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
                sx={{ bottom: { xs: 50, sm: 20 } }}
            >
                <MuiAlert elevation={6} variant="filled" sx={{ width: '100%' }} severity={alertColor} onClose={handleClosePO}>
                    {errorMessage}
                </MuiAlert>
            </Snackbar>
        </div>
    );
}

export default ValidationRuleList;