import React, { useState } from "react";
import { IconButton } from '@fluentui/react/lib/Button';

export interface ShareFileMailParams{
    excludeMedot: (index:number) => void,
    mail:string,
    index:number
}

const ShareFileMail = (shareFileMailParams: ShareFileMailParams) => {
    return (
        <li>
            {shareFileMailParams.mail}
            <IconButton 
                iconProps={{iconName: 'Delete'}} 
                title="Delet" 
                ariaLabel="Delet" 
                onClick={() => shareFileMailParams.excludeMedot(shareFileMailParams.index)} 
            />
        </li>
    );
}

export default ShareFileMail;