import React from "react";
import { Checkbox } from '@fluentui/react';
import { IStackStyles, } from '@fluentui/react/lib/Stack';

export interface CheclkBoxfig {
    label?: string;
    disabled?: boolean;
    checked?: boolean;
    onChange?: (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => void;
  }

const CheclkBoxComp = (props: CheclkBoxfig) => {
    var disabled: boolean = false;

  if (props.disabled !== undefined) {
    disabled = props.disabled;
  }

  const stackStyles: Partial<IStackStyles> = {
    root: { 
        borderColor: "#114D88",
        margin:"5px",
    } 
};

    return (
        <Checkbox
            label={props.label}
            disabled={disabled}
            styles={stackStyles}
            onChange={props.onChange}
            checked={props.checked}          
        />
    );
}

export default CheclkBoxComp;