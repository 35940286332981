import { Box, Typography } from "@mui/material"
import { ReactNode } from "react"
import styled, {keyframes} from 'styled-components'
import { StatusCallout } from "./Callout"
import { GetFlowInformationByItemVO } from "../../../../model/ItemModel"
import { ValidationRuleVOActivityRetrieve, ValidationRuleVOActivityUserGroup } from "../../../../model/ValidationRuleModel"



export interface AnimatedTextTypes {
    title?: string
    children?: ReactNode
    delayAnimationTime?: number
    timeAnimationTime?: number
    getFlowInformationByItemVO?: GetFlowInformationByItemVO | undefined
    CircleType?: "CURRENT" | "FUTURE" | "OVERCOME"
    data?: ValidationRuleVOActivityRetrieve | undefined
}

export const AnimatedText: React.FC<AnimatedTextTypes> = ( { title, children, delayAnimationTime, timeAnimationTime, getFlowInformationByItemVO, CircleType, data }: AnimatedTextTypes ) => {
    const animatedLine = keyframes`
        from {
            opacity: 0;
            transform: translateX(100%);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    `

    const DivStyle = styled.div`
        opacity: 0;
        transform: translateX(100%);
        animation: ${animatedLine} ${timeAnimationTime}s linear ${delayAnimationTime}s 1 normal forwards;
    `;

    return(
        <DivStyle style={{marginTop:"60px"}}>
            <Box sx={{display:"flex", opacity: CircleType === "FUTURE"? "50%" : "100%"}}>
                <Typography style={{fontSize:"20px", color:"#2D2D2D", fontWeight:"600"}}>
                    {title}
                </Typography>
                {
                    (data && getFlowInformationByItemVO?.dateActionForwadOrBack) ?
                    (
                    getFlowInformationByItemVO?.dateActionForwadOrBack.filter(item => item.idAction === data.id).length
                    > 1 && <StatusCallout getFlowInformationByItemVO={getFlowInformationByItemVO} children={children} title={title} />
                    ) : 
                    ("")
                }
            </Box>
            <Typography sx={{opacity: CircleType === "FUTURE"? "50%" : "100%"}}>
                {children}
            </Typography>
            
        </DivStyle>
    )
}