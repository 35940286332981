type ContentbarType = 'sidebar' | 'svg' | 'actionIcon'
export interface ContentbarCompProps{
    children: string;
    type: ContentbarType;
    icon?: JSX.Element;
    fill?: string;
    conditional?: boolean;
    onClick?: () => void
}

export const ContentHambComp: React.FC<ContentbarCompProps> = ({children, type, icon, fill, conditional,onClick}: ContentbarCompProps) => {  
  if(type === 'svg'){
    return(
      <div className='divFatherSvgHamb' onClick={onClick}>
        <div className='divSvgHamb'>
          <svg
                viewBox="-25 -13 45 45"
                xmlns="http://www.w3.org/2000/svg"
                className='svgHamb'
                onClick={onClick}
              >
                <path className="svgPath" d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                <path className="svgPath" d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                <circle
                  cx="12" cy="12" r="3"
                  fill={fill}
                />
              </svg>
              <p>{children}</p>
        </div>
      </div>
    )
  }
  return(
    <div>
      {conditional ? (
        <a onClick={onClick}>{icon}{" "}{children}</a>
      ):(<div></div>)}
    </div>
  )
}