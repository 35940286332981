import { Typography } from "@mui/material";
import { DialogComponent } from "../../../../components/DialogComponent"
import { useTranslation } from "react-i18next";

export interface ISuccessfullyOtpDialog {
    isopen: boolean;
    handleOpenDialog: (set: boolean) => void;
    handleNavigateLogin: () => void;
}

export const SuccessfullyOtpDialog = ({
    handleOpenDialog,
    isopen,
    handleNavigateLogin
}: ISuccessfullyOtpDialog) => {

    const { t } = useTranslation();

    return (
        <DialogComponent
            isOpen={isopen}
            onClickCancel={handleOpenDialog}
            onClickConfirm={handleNavigateLogin}
            titleContent={t("otpDialog.successfullyOtpPass")}
            isOtpDialog={false}
            isCancelButtonNeeded={false}
        >
            <Typography
                component='label'
                sx={{
                    textAlign: "center",
                    font: "normal normal 300 16px/24px Segoe UI",
                    letterSpacing: "0px",
                    color: "#2D2D2D",
                    opacity: 1,
                }}
            >
                {t("otpDialog.successfullyOtpPassContent")}
            </Typography>
        </DialogComponent>   
    )
}