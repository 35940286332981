import React, { useState, useEffect, useCallback, useRef } from "react";
import { ValidationRuleVOActivity } from "../../../model/ValidationRuleModel";
import stylesList from "./ValidationRuleCreateEdit.module.css";
import {GetText} from "../ResponsabilityReturn/ReturnCombo"
import { useTranslation } from "react-i18next";
import ReturnString from "../ResponsabilityReturn/ReturnString"
import LongMenu from  "../../../components/MenuContexto/MenuContexto";
import { IComboBoxOption } from '@fluentui/react';

export interface ValidationRuleVOActivityExtends extends ValidationRuleVOActivity{
    onDelete: (id:string) => void;
    onEdit: (validationRuleVOActivity:ValidationRuleVOActivity) => void;
    options: IComboBoxOption[] | undefined
}

const ValidationRuleCreateEditItem: React.ForwardRefExoticComponent<ValidationRuleVOActivityExtends> = React.forwardRef(
    ({ id, order, name, type, responsability,backOperationLabel, forwardOperationLabel, onDelete, onEdit, options, dinamicUserChoice,term,enumTermDescription}, ref: React.Ref<HTMLLIElement>) => {
      const { t, i18n } = useTranslation();
      const [stype, setStype] = useState<string>("");

      useEffect(() => {
        if(options != undefined && type != undefined)
        {
          var text = GetText(options, type);
          if(text != undefined)
            setStype(text);
        }
      }, [options, type]);

      const onClickEdit = (
        event: React.FormEvent<HTMLElement>
      ) => {
        const { myValue } = event.currentTarget.dataset;
        if(myValue != undefined)
        {
          let newValidationRuleVOActivity:ValidationRuleVOActivity = {
            id : id,
            order: order,
            name : name,
            type : type,
            responsability:responsability,
            deleted:false,
            backOperationLabel: backOperationLabel,
            forwardOperationLabel:forwardOperationLabel,
            dinamicUserChoice:dinamicUserChoice,
            term:term,
            enumTermDescription:enumTermDescription
          }

          onEdit(newValidationRuleVOActivity);
        }
      };

      const onClickDelete = (
        event: React.FormEvent<HTMLElement>
      ) => {
        const { myValue } = event.currentTarget.dataset;
        if(myValue != undefined)
        {
            onDelete(myValue);
        }
      };


      return (

        <li className={stylesList.itemContentDocument} key={id} ref={ref}>
            <div className={stylesList.firstDiv}>
                {stype}
            </div>
            <div className={stylesList.secondDiv}>
                {name}{" "}
            </div>
            <div className={stylesList.thirdDiv}>
                {responsability != undefined && (
                    ReturnString(responsability)
                )}
                {dinamicUserChoice == true && 
                  t("ValidationRule.dinamic")
                }
                {responsability == undefined && (dinamicUserChoice == undefined || dinamicUserChoice == false) && ("-")}
            </div>
            {id != undefined && (
                    <LongMenu onClickEditar={onClickEdit} onClickExcluir={onClickDelete} idMenu={id.toString()} />
            )}
        </li>
      );
    }
  );

  export default ValidationRuleCreateEditItem;