import {Color} from "./interface/Color"

export const ArrowLeftIcon: React.FC<Color> = ({
    color
  }) => {
    return(
     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15.031" viewBox="0 0 20 15.031">
      <path id="arrow_right_alt_FILL1_wght400_GRAD0_opsz48" d="M167.5-707.969l1.344-1.312-5.25-5.25H180v-1.875H163.594l5.281-5.281L167.531-723,160-715.469Z" transform="translate(-160 723)" fill="#22638d"/>
    </svg>
  )
}