import { TabPanel } from '@mui/lab';
import { SxProps, Theme } from '@mui/material';

interface IDocumentDetailTabPanel
{
    children: React.ReactNode;
    value: string;
    sx?: SxProps<Theme> | undefined
}

export default function DocumentDetailTabPanel({
    children,
    value,
    sx
}: IDocumentDetailTabPanel)
{
    return (
        <TabPanel value={value} sx={sx}>
            { children }
        </TabPanel>
    )
}