import { concatString } from "./Concat";

export interface GridColumn {
	name: string;
	length: string;
  deleted?: boolean;
}

export interface GridColumnDeleted {
	name: string;
	length: string;
  deleted: boolean;
}

export const file:string = "file";
export const modal:string = "modal";
export const icon:string = "icon";
export const checkbox:string = "checkbox";
export const name:string = "name";
export const description:string = "desc";
export const tags:string = "tag";
export const size:string = "size";
export const validationRule:string = "vr";
export const term:string = "term";
export const status:string = "status";
export const createdAt:string = "dm";
export const menu:string = "menu";

export const standartGridColumnBegin: string = concatString([menu, checkbox, status, file, modal, icon], true, false) + " ";
export const standartGridSizeBegin: string = concatString([getDefault(menu), getDefault(checkbox), getDefault(status), getDefault(file), getDefault(modal), getDefault(icon)], false, false) + " ";

export const standartGridColumnEnd : string = " " + concatString([''], false, true);
export const standartGridSizeEnd : string = " " + concatString([''], false, false);

export function ReturnColumnSizeUserPendencies()
{
    let returnVarSize:GridColumnDeleted[] =  [
        { name: status, length: getDefault(status), deleted: false},
        { name: modal, length: getDefault(modal), deleted: false},
        { name: icon, length: getDefault(icon), deleted: false},
        { name: name, length: getDefault(name), deleted: false},
        { name: description, length: getDefault(description), deleted: false},
        { name: validationRule, length: getDefault(validationRule), deleted: false},
        { name: createdAt, length: getDefault(createdAt), deleted: false},
        { name: tags, length: getDefault(tags), deleted: false},
        { name: size, length: getDefault(size), deleted: false},
        { name: term, length: getDefault(term), deleted: false},
  ]
  return returnVarSize;
};

export function ReturnColumnSizeStandard()
{
    let returnVarSize:GridColumn[] =  [
        { name: name, length: getDefault(name)},
        { name: description, length: getDefault(description)},
        { name: validationRule, length: getDefault(validationRule)},
        { name: createdAt, length: getDefault(createdAt)},
        { name: tags, length: getDefault(tags)},
        { name: size, length: getDefault(size)},
        { name: term, length: getDefault(term)},
  ]

  return returnVarSize;
};

export function getDefault(columnName: string): string {
  switch(columnName){
    case checkbox:
      return "40px";
    case file:
      return "40px";
    case modal:
      return "30px";
    case icon:
      return "18px";
    case name:
      return "2fr";
    case description:
      return "1fr";
    case validationRule:
      return "170px";
    case createdAt:
      return "112px";
    case tags:
      return "156px";
    case size:
      return "80px";
    case term:
      return "122px";
    case status:
      return "20px";
    case menu:
      return "22px";
    default: 
      return "";
  }
}

