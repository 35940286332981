import { IIconProps, IconButton } from "@fluentui/react"
import { Box, Typography } from "@mui/material"

interface WizardUserCard {
  name: string
  email: string
  handleDelete: (index: number) => void
  index: number
}

const deleteIcon: IIconProps = { iconName: 'Delete' }

export const WizardUserCard: React.FC<WizardUserCard> = ({ name, email, handleDelete, index }) => {
  return (
    <Box sx={{
      justifyContent: 'space-between',
      padding: '10px',
      display:"flex",
    }}
    >
          <Typography
              variant='body1'
              fontWeight={200}
              fontSize={14}
            >
              <b>{ name }</b>
              { " (" + email + ")"}
          </Typography>

        <IconButton 
            iconProps={deleteIcon}
            onClick={() => handleDelete(index)}
        />
    </Box>
  )
}