import React from "react";
import {
  IDocumentCardTitleProps,
} from '@fluentui/react/lib/DocumentCard';
import { ItemToValidationRuleVOConditions } from "../../../../model/ValidationRuleModel";
import { User } from "../../../../model/OrganizationModel";
import HandleValidation from "./HandleValidation"
import {Panel } from "@fluentui/react"
import ValidationRuleConclusionPanel from "../../SendToValidationRulePanel/ValidationRuleConclusionPanel/ValidationRuleConclusionPanel";


export interface PreConditionPannelProps {
    validationRuleChoiceResponsabilityHide?:boolean;
    setConditions:(conditions:ItemToValidationRuleVOConditions | undefined) => void;
    getUser:()=> Promise<User[]>;
    getUserFiltred:(filter:string)=> Promise<User[]>;
    validationRuleTermHide?:boolean;
    waitLoader?:boolean;
    dismissPanel: () => void
    conclusion:boolean;
    errorMessage: string | undefined;
    isOpen: boolean;
}

export interface IUserCardProps {
  documentTitleProps?: IDocumentCardTitleProps;
  id?:string;
  login?:string;
}

const PreConditionPannel: React.ForwardRefExoticComponent<PreConditionPannelProps> = React.forwardRef(
    ({  
      validationRuleChoiceResponsabilityHide,
      validationRuleTermHide,
      setConditions,
      getUser,
      getUserFiltred,
      waitLoader,
      dismissPanel,
      conclusion,
      errorMessage,
      isOpen,
    }, ref: React.Ref<HTMLLIElement>) => {

  return (
      <div>
          <Panel
          isOpen={isOpen}
          onDismiss={dismissPanel}
          styles={{
            main: {
              width: "630px !important",
              padding: '50px',
            },
          }}
          closeButtonAriaLabel="Close"
          >         
            {conclusion?(
              <ValidationRuleConclusionPanel 
                dismissPanel={dismissPanel}
                errorMessage={errorMessage}
                target={undefined}
              />
            ):(
              <div>
                <HandleValidation 
                  getUser={getUser}
                  getUserFiltred={getUserFiltred}
                  filterAlowed="erro"
                  setConditions={setConditions}
                  validationRuleChoiceResponsabilityHide={validationRuleChoiceResponsabilityHide!}
                  validationRuleTermHide={validationRuleTermHide!}
                  waitLoader={waitLoader!}
                />
              </div>
            )}
              
          </Panel>  
      </div>
    );
    
} );


export default PreConditionPannel;