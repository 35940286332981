import React, { useState, useEffect, useRef } from "react";
import { initializeIcons, TextField } from "@fluentui/react";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { useProfile } from "../../../context/ProfileProvider/useProfile";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GetUniqueLinkSearchParams, LoginRequest, LoginRequestMS } from "../../../context/AuthProvider/util";
import { IUser } from "../../../context/AuthProvider/types";
import { RedirectNavigate } from "../../PublicLayout/RedirectNavigate";
import {LeftForm} from "../../PublicLayout/components/Handle/LeftForm";
import { Grid, Box, Typography, Link, IconButton, Divider } from '@mui/material';
import FormButton from "../../PublicLayout/components/Handle/Form/Root/components/FormButton";
import FormActions from "../../PublicLayout/components/Handle/Form/Root/components/FormActions/FormAction";
import FormErrors from "../../PublicLayout/components/Handle/Form/Root/components/FormErrors";
import msalConfig from "../../../config/office-auth.config.json";
import { useMsal } from "@azure/msal-react";
import { ButtonTyppe } from "../../../components/Button/BtnBigWithIcon/BtnBigWithIcon";
import microsoftIcon from '../../../assets/icons/Microsoft_logo_(2012).svg';
import {checkIcon} from '../../../assets/icons/checkIcon';
import { LoaderIcon } from "../../../assets/icons/loaderIcon";
import { PasswordTextFieldWithReveal } from "../../PublicLayout/components/Handle/Form/Components/PasswordTextFieldWithReveal";
import { textFieldStyles } from "../../Style/TextfieldStyles";

initializeIcons();

const LoginComp = (props: any) => {
  const [isChecked, setCheckedState] = useState<boolean>(false);
  const { t } = useTranslation();
  const navegate = useNavigate();
  const auth = useAuth();
  const profile = useProfile();
  const [load, setLoad] = useState<boolean>(false);
  const [loadOffice, setLoadOffice] = useState<boolean>(false);
  const [lg, setLanguage] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [messagePass, setMessagePass] = useState<string>("");
  const { instance } = useMsal();
  const uniqueLink = GetUniqueLinkSearchParams();

  const onChangeEmail = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (newValue != undefined) {
      setEmail(newValue);
    } else {
      setEmail("");
    }
  };


  const onChangePassword = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (newValue != undefined) {
      setPassword(newValue);
    } else {
      setPassword("");
    }
  };

  function loginOffice() {

    setCheckedState(false);
    setLoadOffice(true);

    instance.loginPopup({
      scopes: [msalConfig.scopes["User.Read"]],
      authority: msalConfig.auth.authority,
      redirectUri: window.location.origin
    })
    .then(response => {
      if(response.idToken != undefined)
      {
        LoginRequestMS(response.idToken)
        .then(message => {
          setProfile(message.data.access_token);
        })
        .catch((error) => {
          erroManagement(error)
        });
      }
      else
      {
        SetErrorLogin();
      }
    })
    .catch((error) => {
      erroManagement(error)
      setLoadOffice(false);
    });
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoad(true);
    LoginRequest(email, password)
      .then((message) => {
          setProfile(message.data.access_token) // Usuário retornado com 200, manda direto para aplicação.
      })
      .catch((error) => {
        erroManagement(error)
      });
  };

  function setProfile(token:string)
  {
    profile
      .create(token)
      .then(() => {
        setLoad(false);
        let user: IUser = {
          token:token
        };
        auth.setUserExternal(user);
        if (uniqueLink != undefined){
          RedirectNavigate(navegate, uniqueLink)
        }
        else {
          RedirectNavigate(navegate)
        }
      })
      .catch((error) => {
        auth.logout();
        setCheckedState(true);
        setLoad(false);
      });
  }

  function erroManagement(error: any)
  {
    if (
      error.response != null &&
      error.response.data != null &&
      error.response.data.error == "invalid_client"
    ) {
      SetErrorLogin();
    }
    else if (
      error.response != null &&
      error.response.data != null &&
      error.response.data.error == "unauthorized_client"
    ) {
      // Ambos erros passados para cliente não autorizado e não gerando token (retorno de 400 bad request).
      if (error.response.data.user.Key != undefined) { // Verificação caso gerado nova chave mfa no backend.
        navegate(`/login/qrcode/${error.response.data.user.Login}/${error.response.data.user.Key}${uniqueLink != undefined ? "?link=" + uniqueLink : '' }`); // Se sim, deve enviar para ler qrcode novamente.
      } else {
        navegate(`/login/otp/${error.response.data.user.Login}${uniqueLink != undefined ? "?link=" + uniqueLink : '' }`); // Se não, manda para usuário colocar senha OTP gerado nop autenticador.
      }
    } else {
      SetErrorLogin();
    }
  }

  function SetErrorLogin()
  {
      setLoad(false);
      setCheckedState(true);
  }

  

  useEffect(() => {
    var lgn = localStorage.getItem("i18nextLng");
    if (lgn == "pt-BR") {
      setLanguage(true);
    } else {
      setLanguage(false);
    }
  });


  return (
    <LeftForm
      formRootInstance={{
        title:t("Login.accessAcount"),
        subTitle:t("Login.helloAgain"),
      }}
    >
      <Grid container sx={{
        gap: 1,
      }}>
        <Grid item xs={12} >
            <Box marginBottom={'4px'}>
              <Typography
                component='label'
                sx={{
                  textAlign: "left",
                  font: 'normal normal 600 12px/18px Segoe UI',
                  letterSpacing: "0px",
                  color: "#2D2D2D",
                  opacity: 1,
                }}
              >
                E-mail
              </Typography>
            </Box>
            <TextField 
              onChange={onChangeEmail}
              value={email}
              placeholder={t("Login.typeYourEmail")}
              styles={textFieldStyles}
              onFocus={() => {
                if (isChecked)
                { 
                  setCheckedState(false);
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginY: '4px',
            }}>
            <Typography
                component='label'
                sx={{
                  textAlign: "left",
                  font: 'normal normal 600 12px/18px Segoe UI',
                  letterSpacing: "0px",
                  color: "#2D2D2D",
                  opacity: 1,
                }}
              >
              {t("Login.password")}
            </Typography>
            <Link
              sx={{
                textAlign: "left",
                font: 'normal normal 400 12px/18px Segoe UI',
                letterSpacing: "0px",
                color: "#005A9E",
                opacity: 1,
                textDecoration: 'none',
                
                "&:hover":{
                  textDecoration: "underline"
                }
              }}
              href="/resetPassword"
            >
              {t("Login.forgotPass")}
            </Link>
            </Box>
            <PasswordTextFieldWithReveal 
            top={8}
            right={10} 
            isChecked={isChecked} 
            onChange={onChangePassword} 
            value={password} 
            placeholder={t("Login.typeYourPassword")}
            setCheckedState={setCheckedState}/>
            </Grid>
          <FormErrors 
            messagePass={t("UsuarioOuSenhaIncorretors.message")}
            isChecked={isChecked}
          />
          <FormButton 
            load={false}
            btnBigWithIconConfig={{
              text:t("Entrar.message"),
              icon: load == true ? LoaderIcon : checkIcon,
              onClick:(e: any) => handleSubmit(e),
              disabled: email.length > 0 && password.length > 0 ? false : true,
            }}
          />
        <Divider orientation="horizontal" sx={{ height: '2px', width: '100%', color: '#9A9A9A', display: 'flex', alignItems: 'center', marginY: '8px ' }}>{t("Divider.message")}</Divider>
          <FormButton 
            load={false}
            btnBigWithIconConfig={{
              icon: loadOffice == true ? LoaderIcon : undefined,
              textBeforeIcon: t("Login.microsoft"),
              specificWidth:'88px',
              isPrimary:ButtonTyppe.secondary,
              iconGap: '8px',
              src:microsoftIcon,
              onClick: (e: any) => {
                loginOffice()
              }
            }}
          />
      </Grid>
    </LeftForm>
  );
};

export default LoginComp;
