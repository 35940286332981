import * as React from 'react';
import { useState } from 'react'
import { useEffect } from 'react'
import styles from './WorkspaceItemFlow.module.css';
import { WorkspaceItemFlowListing } from './WorkspaceItemFlowListing'
import { GetFlowInformationByItemVO } from '../../../model/ItemModel';
import { ItemWithValidation } from '../../../services/Item/ItemServicesGet';
import LoaderComp from '../../../components/Loader/LoaderComp';
import { Panel, PanelType } from '@fluentui/react';
import FlowSidebarHeader from '../../DocumentDetail/components/RightSideHandler/FlowSidebar/components/FlowSidebarHeader/FlowSidebarHeader';
import FlowSidebarTitle from '../../DocumentDetail/components/RightSideHandler/FlowSidebar/components/FlowSidebarHeader/FlowSidebarTitle';
import { Box } from '@mui/material';

export interface FlowProps {
  idMongo: string
  nameFile?: string
  extensionFile?: string
}

interface WorkspaceItemFlowComponentProps {
    handleClose?: () => void
    flowProps: FlowProps
}

export const WorkspaceItemFlowComponent: React.FC<WorkspaceItemFlowComponentProps> = (props: WorkspaceItemFlowComponentProps) => {
    const [ oneItem, setOneItem ] = useState<GetFlowInformationByItemVO>()
    const [wait, setWait] = useState<boolean>(false);
    const [erroMessage, setErroMessage] = useState<string|undefined>(undefined);
    
    useEffect(() => {
      setErroMessage(undefined);
      ItemWithValidation(props.flowProps.idMongo)
      .then( returnata =>{
        setOneItem(returnata);
        setWait(true);
      })
      .catch(error =>{
        setWait(true);
        if( error.response && error.response.data && typeof error.response.data  === 'string'){
          setErroMessage(error.response.data);
        }
        else
        {
          setErroMessage("Erro ao busccar fluxo");
        }
      });

    }, [])
  
    const panelStyles = {
      commands: {
        background: 'white',
        '@media (min-height: 480px)': {
          position: 'static',
        },
      },
      scrollableContent: {
          overflowX: 'clip', 
        },main:{
          width: "550px !important" 
        }
    };

    return (
      <div onClick={props.handleClose}>
        <Panel
            isBlocking={false}
            isOpen={true}
            onDismiss={props.handleClose}
            closeButtonAriaLabel="Close"
            styles={panelStyles}
        >
          <Box sx={{marginTop:"-30px"}}>
            <FlowSidebarTitle 
              itemName={props.flowProps.nameFile}
              itemExtension={props.flowProps.extensionFile}
              />
          </Box>
            {!wait ? <LoaderComp big={true}/> : 
              erroMessage == undefined?(
                <WorkspaceItemFlowListing getFlowInformationByItemVO={oneItem}/>)
              :erroMessage
            }
        </Panel>
      </div> 
  );
};
