import styles from "../Viewer.module.css"

interface SidebarViewerCompProps{
    children: React.ReactNode;
    isAcept?: boolean;
    hideSidebar?: boolean;
    changeModalContent?: (id: string, name: string, extension: string) => void;
}

const SidebarViewerComp: React.FC<SidebarViewerCompProps> = (props: SidebarViewerCompProps) => {
    return (
        <div className={styles.sidebarViewerMain} style={{display: props.hideSidebar ? "none" : "block"}}>
            <div className={styles.currentPreviewMain} style={{height:"100%"}}>
                {props.children}
            </div>
        </div>
    )
}

export default SidebarViewerComp;