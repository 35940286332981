import React, { useState, useEffect, useCallback, useRef } from "react";
import { ITextFieldStyles } from "@fluentui/react/lib/TextField";
import { Link } from "@fluentui/react/lib/Link";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import {
  GetOrganization,
  CreateOrganization,
} from "../../services/OrganizationServices";
import {
  Organization,
  OrganizationCreate,
} from "../../model/OrganizationModel";
import { useNavigate } from "react-router-dom";
import Btn from "../../components/Button/Btn";
import TextFieldComp from "../../components/TextField/TextFieldComp";
import { Stack, IStackStyles, IStackItemStyles } from '@fluentui/react';

import { Panel } from "@fluentui/react/lib/Panel";
import { useBoolean } from "@fluentui/react-hooks";
import Message from "../../components/Message/Message";
import { MessageBarType } from "@fluentui/react";
import OrganizationListComp from "./OrganizationListComp";
import styles from "../Style/List.module.css";
import { useProfile } from "../../context/ProfileProvider/useProfile";
import { useTranslation } from "react-i18next";
import MessageComp from "../../components/Dialog/Message";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useUpload } from "../../context/UploadProvider/useUpload";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
});

const OrganizationList  = (props: any) => {
  const profile = useProfile();
  const navegate = useNavigate();
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);
  const [newOrgName, setNewOrgName] = useState<string>("");
  const [findOrgName, setFindOrgName] = useState<string>("");
  const forceUpdate = useCallback((teste: string) => setNewOrgName(teste), []);

  const [isMessageError, setMessageError] = useState<boolean>(false);
  const [reaload, setReaload] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>(t("ErroAoSalvar.message"));
  const [popupOk, setPopupOk] = useState<boolean>(false);
  const upload = useUpload();

  const handleClose = (event:any, reason:any) => {
    closePopUpOk();
  }

  const handleClosePO = (event:any) => {
    closePopUpOk();
  }

  const updatePanel = () => {    
    setNewOrgName("");
    resetMessage();
    setReaload(true);
    dismissPanel();
  };

  useEffect(() => {
    if(reaload == true ){
      setReaload(false)
    }
  }, [reaload]);

  const iconProps = { iconName: "Filter" };

  const onChangeSecondTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (newValue != undefined) {
      forceUpdate(newValue);
    }
    else
    {
      forceUpdate("");
    }
  };

  const onChangeSetFindOrgName = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (newValue != undefined) {
      setFindOrgName(newValue);
    }
    else {
      setFindOrgName("");
    }
  };

  function createOrganization() {
    resetMessage();
    if(newOrgName.length < 3)
    {
      setErrorMessage(t("MinDeCaracteres.message"));
      setMessageError(true);
      return;
    }
    else if(newOrgName.length > 50){
      setErrorMessage(t("MaxDeCaracteres.message"));
      setMessageError(true);
      return;
    }
    let newOrg: OrganizationCreate = {
      name: newOrgName,
    };
    CreateOrganization(newOrg)
      .then((message) => {
        updatePanel();
        setPopupOk(true);
        upload.setShowEx(false);
      })
      .catch((error) => {
        if( error.response && error.response.data && typeof error.response.data  === 'string'){
          setErrorMessage(error.response.data);
        }
        setMessageError(true);
      });
  }

  function closePopUpOk() {
    setPopupOk(false);
  }

  function resetMessage() {
    setMessageError(false);
    setErrorMessage(t("ErroAoSalvar.message"));
  }

  function openPanelBussines() {
    resetMessage();
    openPanel();
  }  

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if(event.key ===  'Enter'){
      createOrganization();
    }
  } 
  return (
    <div>
      <Stack horizontal className={styles.hPage}>
        <Stack.Item grow>
          <h3 className={styles.hList}>{t("Organizacoes.message")}</h3>
        </Stack.Item>
        <Stack className={styles.stackSide}>
          <Stack.Item className={styles.BtnNew} hidden={profile == undefined || !profile.adminSystem}>
            <Btn text={t("Novo.message")}  onClick={openPanelBussines} />
          </Stack.Item>
          <Stack.Item className={styles.InputSearch}>
            <TextFieldComp iconProps={iconProps}
            value={findOrgName}
            onChange={onChangeSetFindOrgName}/>
          </Stack.Item>
        </Stack>
      </Stack>
      {reaload?(<div></div>):(
        <OrganizationListComp text={findOrgName} />)}
      <Panel
        isOpen={isOpen}
        closeButtonAriaLabel={t("Fechar.message")}
        onDismiss={updatePanel}
        headerText={t("SalvarOrg.message")}
        isFooterAtBottom={true}
      >
        <TextFieldComp
          label={t("NomeOrg.message")} 
          value={newOrgName}
          onChange={onChangeSecondTextFieldValue}
          autofocus
          onKeyDown={onKeyDown}     
        />

        {isMessageError ? (
          <Message text={errorMessage} type={MessageBarType.error} />
        ) : (
          <div></div>
        )}
        <Btn text={t("Salvar.message")}  onClick={createOrganization}/>
      </Panel>

      <Snackbar
          open={popupOk}
          autoHideDuration={6000}
          message="Archived"
          onClose={handleClose}
          anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
          sx={{ bottom: { xs: 50, sm: 20 } }}
        >
          <MuiAlert elevation={6} variant="filled" sx={{ width: '100%' }} severity="success" onClose={handleClosePO}>
            {t("Org.saveOk")}
          </MuiAlert>
        </Snackbar>
    </div>
  );
};


export default OrganizationList;
