import { useEffect } from 'react';
import { Grid, TextField, Typography } from "@mui/material";
import DocumentDetailPhButtons from "./components/DocumentDetailPhButtons/DocumentDetailPhButtons";
import { useState } from 'react';
import { UpdateFisicPosition } from "../../../../../../../../services/Item/ItemServicesCRUD";
import { FisicItemInformation } from "../../../../../../../../model/ItemModel";
import { IPersistedPhLocation, IShowInfo } from "../../../../../../DocumentDetail";
import { useTranslation } from 'react-i18next';

interface IDocumentDetailPhLocation
{
    itemId: string;
    setExternalLocalPosition: React.Dispatch<React.SetStateAction<IPersistedPhLocation>>;
    externalLocalPosition: IPersistedPhLocation;
    setSnackBarMessage: (set: string) => void;
    setShowViwerInfo: (set: IShowInfo) => void;
    showViwerInfo: IShowInfo;
}

export default function DocumentDetailPhLocation({
    itemId,
    setExternalLocalPosition,
    externalLocalPosition,
    setSnackBarMessage,
    setShowViwerInfo,
    showViwerInfo
}: IDocumentDetailPhLocation)
{
    const { t } = useTranslation();
    const [internalLocalPosition, setInternalLocalPosition] = useState<IPersistedPhLocation>(externalLocalPosition)

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const actualValue = externalLocalPosition;

    useEffect(() => {
        setShowViwerInfo({ ...showViwerInfo, value: '2' });
    }, [])

    function localHandler(e: React.ChangeEvent<HTMLInputElement>)
    {
        setInternalLocalPosition({
            ...internalLocalPosition,
            local: e.currentTarget.value
        });
    }

    function bookShelfHandler(e: React.ChangeEvent<HTMLInputElement>)
    {
        setInternalLocalPosition({
            ...internalLocalPosition,
            bookshelf: e.currentTarget.value
        })
    }

    function shelfHandler(e: React.ChangeEvent<HTMLInputElement>)
    {
        setInternalLocalPosition({
            ...internalLocalPosition,
            shelf: e.currentTarget.value
        })
    }

    function positionBoxHandler(e: React.ChangeEvent<HTMLInputElement>)
    {
        setInternalLocalPosition({
            ...internalLocalPosition,
            position: e.currentTarget.value
        })
    }

    function updatePositionsHandler()
    {
        const newPosition: FisicItemInformation = {
            ItemId: itemId,
            bookShelf: internalLocalPosition.bookshelf,
            local: internalLocalPosition.local,
            position: internalLocalPosition.position,
            shelf: internalLocalPosition.shelf
        }

        setIsLoading(true);
        UpdateFisicPosition(newPosition)
        .then(() => {
            setExternalLocalPosition({
                local: internalLocalPosition.local,
                position: internalLocalPosition.position,
                bookshelf: internalLocalPosition.bookshelf,
                shelf: internalLocalPosition.shelf
            });
        })
        .catch(error => {
            setSnackBarMessage(error.response.data);
        })
        .finally(() => setIsLoading(false))
    }

    function cancelModifications()
    {
        setExternalLocalPosition(actualValue)
        setInternalLocalPosition(actualValue)
    }

    return (
        <>        
            <Grid container padding={2} gap={2}>
                <Grid item xs={12} sx={{
                    display: 'flex'
                    }} gap={2}>
                        <Grid item xs={6}>
                            <Typography sx={{
                                font: "normal normal 600 16px/24px Segoe UI",
                                letterSpacing: "0px",
                                color: "#2D2D2D",
                                marginBottom: '11px'
                                }}
                            >
                                Local
                            </Typography>
                            <TextField
                                fullWidth
                                placeholder={t("DetailPhLocation.TypeHereLocal")}
                                InputProps={{
                                    sx: {
                                        height: '44px',
                                        borderRadius: '8px'
                                    }
                                }}
                                value={internalLocalPosition.local}
                                onChange={localHandler}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{
                                font: "normal normal 600 16px/24px Segoe UI",
                                letterSpacing: "0px",
                                color: "#2D2D2D",
                                marginBottom: '11px'
                                }}
                            >
                                {t("StroageInformation.bookcase")}
                            </Typography>
                            <TextField 
                                fullWidth
                                placeholder={t("DetailPhLocation.TypeHereBookcase")}
                                InputProps={{
                                    sx: {
                                        height: '44px',
                                        borderRadius: '8px'
                                    }
                                }}
                                value={internalLocalPosition.shelf}
                                onChange={shelfHandler}
                            />
                        </Grid>
                </Grid>
                <Grid item xs={12} sx={{
                    display: 'flex'
                }} gap={2}>
                    <Grid item xs={6}>
                        <Typography sx={{
                            font: "normal normal 600 16px/24px Segoe UI",
                            letterSpacing: "0px",
                            color: "#2D2D2D",
                            marginBottom: '11px'
                        }}
                        >
                            {t("StroageInformation.shelf")}
                        </Typography>
                        <TextField 
                            placeholder={t("DetailPhLocation.TypeHereShelf")}
                            fullWidth
                            InputProps={{
                                sx: {
                                    height: '44px',
                                    borderRadius: '8px'
                                }
                            }}
                            value={internalLocalPosition.bookshelf}
                            onChange={bookShelfHandler}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{
                            font: "normal normal 600 16px/24px Segoe UI",
                            letterSpacing: "0px",
                            color: "#2D2D2D",
                            marginBottom: '11px'
                        }}
                        >
                            {t("StroageInformation.position")}
                        </Typography>
                        <TextField
                            placeholder={t("DetailPhLocation.TypeHerePosition")}
                            fullWidth
                            InputProps={{
                                sx: {
                                    height: '44px',
                                    borderRadius: '8px'
                                }
                            }}
                            value={internalLocalPosition.position}
                            onChange={positionBoxHandler}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <DocumentDetailPhButtons 
                updatePositionsHandler={updatePositionsHandler}
                isLoading={isLoading}
                cancelModifications={cancelModifications}
            />
        </>
    )
}