import { NavigateFunction } from "react-router-dom";
import { GetOrganizationLocalStorage, GetWorkspaceLocalStorage } from "./RedirectManager";

export function RedirectNavigate(navegate: NavigateFunction, uniqueLink?: string){
    const organizationLocalStorage = GetOrganizationLocalStorage();
    const workspaceLocalStorage = GetWorkspaceLocalStorage();
    
    if(uniqueLink){
      navegate(uniqueLink);
    }
    else if (organizationLocalStorage != null && workspaceLocalStorage != null){
      navegate("/" + organizationLocalStorage + "/wsallowed/" +  workspaceLocalStorage);
    } else if (organizationLocalStorage != null && workspaceLocalStorage == null){
      navegate("/" + organizationLocalStorage + "/wsallowed");
    } else {
      navegate("/");
    }
  }