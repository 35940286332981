import React, { useState, useEffect } from "react";
import {
GetDocumentType,
EditDocumentType,
CreateDocumentType
} from "../../services/DocumentType";
import { DocumentTypeCreate } from "../../model/DocumentType";
import Btn from "../../components/Button/Btn";
import MessageComp from "../../components/Dialog/Message";
import TextFieldComp from "../../components/TextField/TextFieldComp";
import { useNavigate, useParams } from "react-router-dom";
import { Separator } from "@fluentui/react/lib/Separator";
import { useBoolean } from "@fluentui/react-hooks";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogType,
  DialogFooter,
  DialogContent,
} from "@fluentui/react/lib/Dialog";
import Message from "../../components/Message/Message";
import { MessageBarType } from "@fluentui/react";
import { useProfile } from "../../context/ProfileProvider/useProfile";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const DocumentTypeConfig = (props: any) => {
  const [itens, setItens] = useState<DocumentTypeCreate | null>(null);
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [popupOk, setPopupOk] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [isChecked, setChecked] = useState<boolean>(false);
  const [nameChanged, setNameChanged] = useState<string>("");
  const [descriptionChanged, setDescriptionChanged] = useState<string>("");
  const [adminOrg, setAdminOrg] = useState<boolean>(false);
  const profile = useProfile();
  const { t, i18n } = useTranslation();
  const navegate = useNavigate();
  const { nameOrg } = useParams();
  const { idWorkspace } = useParams();
  const {idDocumentTypes} = useParams();
  const [reaload, setReaload] = useState<boolean>(false);
  const [popupSucessOk, setPopupSucessOk] = useState<boolean>(false);
  const [popupErrorOk, setPopupErrorOk] = useState<boolean>(false);
  const [messageErrorString, setMessageErrorString] = useState<string>(t("ErroAoSalvar.message"));
  useEffect(() => {
    if(profile.organizations != undefined)
    {
      for(let organization of profile.organizations)
      {
        if(nameOrg == organization.internalName)
        {
          if(organization.isAdmin == true)
          {
            setAdminOrg(false);
          }
          break;
        }
      }
    }
  }, [profile]);

  useEffect(() => {

    if (idDocumentTypes != undefined && idDocumentTypes != "new") {
        GetDocumentType(idDocumentTypes)
        .then((message) => {
          if (message != null) {
            setItens(message);
            if (message.name) {
              setNameChanged(message.name);
            }
            if(message.description){
                setDescriptionChanged(message.description)
            }
          } else {
            setMessage("Não encontrada o tipo de documento");
            setPopupOk(true);
          }
        })
        .catch(() => {
          setMessage("Não encontrada o tipo de documento");
          setPopupOk(true);
        });
    }
  }, [idDocumentTypes]);

  function closePopUpOk() {
    setPopupOk(false);
    navegate("/" + nameOrg + "/Workspace/" + idWorkspace+ "/documentTypes");
    setReaload(true);
  }
  const handleClose = (event:any, reason:any) => {
    closePopUpOkCrud();
  }

  const handleClosePO = (event:any) => {
    closePopUpOkCrud();
  }

  function closePopUpOkCrud() {
    setPopupSucessOk(false);
    setPopupErrorOk(false)
  }

  function editDocument() {
    if(nameChanged.length < 3)
    {
      setMessageErrorString(t("MinDeCaracteres.message"));
      return;
    }
    else if(nameChanged.length > 50){
      setMessageErrorString(t("MaxDeCaracteres.message"));
      return;
    }
    if (itens != null) {
      let itemModelo: DocumentTypeCreate = itens;
      itemModelo.workSpaceId = idWorkspace!;
      itemModelo.name = nameChanged;
      itemModelo.description = descriptionChanged;
      EditDocumentType(itemModelo).then(() => {
        setPopupOk(true);
        setMessage(t("EditadoComSucesso.message"));
      }).catch((error) => {
        setMessageErrorString(error.response.data)
      });
    }
  }

  function createDocument() {
    if(nameChanged.length < 3)
    {
      setMessageErrorString(t("MinDeCaracteres.message"));
      return;
    }
    else if(nameChanged.length > 50){
      setMessageErrorString(t("MaxDeCaracteres.message"));
      return;
    }
    let document: DocumentTypeCreate = {
      name: nameChanged,
      description: descriptionChanged,
      workSpaceId: idWorkspace!
    };

    CreateDocumentType(document)
      .then((message) => {

        if(message != undefined)
          navegate("/" + nameOrg + "/Workspace/" + idWorkspace+ "/documentTypes");
          setPopupSucessOk(true)
        props.saveOkMetod();
        setMessage(t("Folder.save"));
      })
      .catch((error) => {
        setPopupErrorOk(true)
        setMessageErrorString(error.response.data)
        console.log(error.response.data)
      });
  }

  const onChangeSetName = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (newValue != undefined) {
        setNameChanged(newValue);
      }
    },
    []
  );

  const onChangeSetDescripition= React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (newValue != undefined) {
        setDescriptionChanged(newValue);
      }
    },
    []
  );

  const dialogContentProps = {
    type: DialogType.normal,
    title: "Excluir",
    closeButtonAriaLabel: "Excluir",
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if(event.key ===  'Enter'){
      crudWorkspace();
    }
  } 

  function crudWorkspace()
  {
    setMessage(t("ErroAoSalvar.message"));
    if(idDocumentTypes != "new")
    {
      editDocument();   
    }
    else
    {
      createDocument();
    }
  }
  return (
    <div>
      <div className="configdiv">
        <h3>{t("ConfigOrg.message")}</h3>
        <TextFieldComp
          label={t("Nome.message")}
          value={nameChanged}
          onChange={onChangeSetName} 
          autofocus  
          onKeyDown={onKeyDown}       
        />
         <TextFieldComp
          label={t("Description.message")}
          value={descriptionChanged}
          onChange={onChangeSetDescripition} 
          autofocus  
          onKeyDown={onKeyDown}       
        />
        <Btn text={t("Salvar.message")} onClick={crudWorkspace}/>
      </div>
      
      <Separator />
 
      {popupOk ? (
          <MessageComp
            title={message}
            closeButtonAriaLabel={t("Fechar.message")}
            buttonMethod={closePopUpOk}
            text={t("Continuar.message")}
          />
        ) : (
          <div></div>
        )}
         <Snackbar
              open={popupSucessOk}
              autoHideDuration={6000}
              message="Archived"
              onClose={handleClose}
              anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
              sx={{ bottom: { xs: 50, sm: 20 } }}
            >
              <MuiAlert elevation={6} variant="filled" sx={{ width: '100%' }} severity="success" onClose={handleClosePO}>
              {message}
              </MuiAlert>
          </Snackbar>
          <Snackbar
              open={popupErrorOk}
              autoHideDuration={6000}
              message="Archived"
              onClose={handleClose}
              anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
              sx={{ bottom: { xs: 50, sm: 20 } }}
            >
              <MuiAlert elevation={6} variant="filled" sx={{ width: '100%' }} severity="error" onClose={handleClosePO}>
              {messageErrorString}
              </MuiAlert>
          </Snackbar>
    </div>
  );
};

export default DocumentTypeConfig;
