import { Api, ApiWithoutToken } from "./api";
import { ShareFileCreate, ShareFile } from "../model/SharedFileModel"
import { ShareFileTokenReturn } from "../model/ItemModel"
import { ParameterViwer } from "../pages/WorkSpaceItem/Viwer/ParameterViwer";
import { ReturnParameterViwer } from "./ReturnParameterViwer";

export async function GetSharedFileInformation(idFile: string) {
    const request = await Api.get<ShareFile>(
      "item/shared/" + idFile
    );
  
    return request.data;
}

export async function SaveSharedFileInformation(shareFileCreate: ShareFileCreate) {
  const request = await Api.post<ShareFile[]>(
    "item/shared",
    shareFileCreate
  );

  return request.data;
}

export async function UpdateSharedFileInformation(shareFile: ShareFile) {
  const request = await Api.put<ShareFile[]>(
    "item/shared",
    shareFile
  );

  return request.data;
}

export async function DeleteSharedFileInformation(fileId: string) {
  const request = await Api.delete<ShareFile[]>(
    "item/shared",
    { data: { id: fileId } }   
  );

  return request.data;
}


export async function GetSharedFileInformationByToken(token: string) {
  const request = await ApiWithoutToken.get<ShareFileTokenReturn>(
    "SharedFile/" + token
  );
  return request.data;
}

export async function GetSharedFileDownloadByToken(token: string,parameter:ParameterViwer, onDownloadProgress?: (progressEvent: any) => void){

  let returnParameter = ReturnParameterViwer(parameter, true)
 
    const request = await ApiWithoutToken.get<any>(
      "SharedFile/" + token + "/download" + returnParameter,
      {
          responseType: 'blob',
          onDownloadProgress:onDownloadProgress
      }
    );
  
  return request.data;
}