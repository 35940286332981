import {
    mergeStyleSets,
  } from '@fluentui/react';
import React, { useEffect } from "react";
import styles from './PreviewWorkspace.module.css'
import { WorkSpaceUserName } from '../../../../model/ItemModel';
import Loader from 'react-ts-loaders';
import WorkSpaceItemListFileComponentImage from '../../WorkSpaceItemListFileComponentImage';

export interface PreviewThumbnailProps {
    icon: JSX.Element;
    isPortrait: boolean | undefined;
    item: WorkSpaceUserName;
    previewPosition: DOMRect;
    url: string | null | undefined;
    changeModalContent: (id: string, name: string, extension: string) => void;
    fetchThumbnail: (idItem: string) => void;
}

const PreviewThumbnail: React.FC<PreviewThumbnailProps> = ({
    item,
    isPortrait,
    previewPosition,
    url,
    changeModalContent,
    fetchThumbnail,
}) => {
    const maxWidthContainerLandscape = 240;
    const maxWidthContainerPortrait = 192;
    const maxWidthContainer = isPortrait ? maxWidthContainerPortrait : maxWidthContainerLandscape;

    const getStylePosition = () => {
        if(previewPosition.left <= 0){
            return {
                top: previewPosition.bottom,
                left: "5px",
                maxWidth: maxWidthContainer
            }
        } else if (previewPosition.right >= window.innerWidth - 10) {
            return {
                top: previewPosition.bottom,
                right: "5px",
                maxWidth: maxWidthContainer
            }
        } else if ((url == null || url == undefined) && previewPosition.width < maxWidthContainer){
            return {
                top: previewPosition.bottom,
                left: previewPosition.left + 12,
                maxWidth: maxWidthContainer
            }
        } else {
            return {
                top: previewPosition.bottom,
                left: previewPosition.left + previewPosition.width / 2 - maxWidthContainer / 2,
                maxWidth: maxWidthContainer
            }
        }
    }
    
    const getThumbContent = () => {
        if(url === undefined || isPortrait === undefined){
            return <Loader />
        } else if (url === ""){
            fetchThumbnail(item.id!)
            return <WorkSpaceItemListFileComponentImage widthIcon="172px" extension={item.extension!} isFolder={item.itemType! === "folder" ? true : false} />
        } else if (url && isPortrait !== undefined){
            return <img src={url} className={isPortrait ? contentStyles.thumbnailImgPortrait : contentStyles.thumbnailImgLandscape} />
        }
    }

    const haveThumbnail = (extension: string) => {
        if(extension != "pdf" && extension !="png" && extension !="jpg" && extension != "gif" && extension != "svg" && 
        extension != "bmp" && extension != "webp" && extension != "docx" && extension != "doc" &&
        extension != "xlsx" && extension != "ppt" && extension != "pptx" && extension != "mpp" && extension != "xls"){
            return false
        } else {
            return true
        }
    }

    return(
        <div 
            style={getStylePosition()}
            className={contentStyles.container}
            onClick={() => changeModalContent(item.id!, item.name!, item.extension!)}
        >
            {haveThumbnail(item.extension!) && getThumbContent()}
            <span className={`${styles.previewWorkspaceTitle} ${contentStyles.thumbnailText}`}>
                {`${item.name}.${item.extension}`}
            </span>
        </div>
    )
}

const contentStyles = mergeStyleSets({
    container: {
        position: "fixed",
        display: "grid",
        gap: "10px",
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '2px 2px 4px #00000029',
        border: '2px solid #D1D1D1',
        borderRadius: '10px',
        padding: "10px",
        zIndex: 3,
    },
    thumbnailImgLandscape: {
        width: '220px',
        height: '172px',
        border: '1px solid #D1D1D1',
        borderRadius: '5px',
    },
    thumbnailImgPortrait: {
        width: '172px',
        height: '220px',
        border: '1px solid #D1D1D1',
        borderRadius: '5px',
    },
    thumbnailText: {
        font: "normal normal 600 12px/18px Segoe UI",
        color: "#2D2D2D",
        overflow: "hidden",
        textOverflow: "ellipsis",
    }
})


export default PreviewThumbnail;