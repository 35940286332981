import React,{ useState, useMemo } from "react";
import {
    mergeStyleSets,
} from '@fluentui/react';
import "./DwgViwerPng.css";
import HeaderViewerComp from "../Viewer/Header/HeaderViewerComp";
import {ViwerProps} from "../../pages/WorkSpaceItem/Viwer/ViwerProps";
import Image from "./Image"
import Loader from 'react-ts-loaders';
import { mergeStyles } from '@fluentui/react/lib/Styling';

export interface GoToZon{
    positionX:number,
    positionY:number,
    height:number,
    width:number,
    scale:number,
    eclientX:number,
    eclientY:number,
    containerRectTop:number,
    containerRectLeft:number,
    containerRectwidth:number,
    containerRectheight:number,
    deltaY:number
}

const DwgViwerPng  = (props: ViwerProps) => {

    const [zoom, setZoom] = useState<number>(0)
    const [goImage, setGoImage] = useState<GoToZon | undefined>(undefined);
    const [disablePanZoom, setDisablePanZoom] = useState<boolean>(false);
    
    function OnclickDisablePanZoom(disable:boolean){
        setDisablePanZoom(disable)
    }

    function setZoomInternal(
        zoon:number, 
        go: GoToZon
    )
    {
        setZoom(zoon);
        setGoImage(go);
    }

    const loaderSave = mergeStyles({
        justifyContent:"left",
        width:"50px",
        paddingTop:"10px",
        paddingBottom:"10px"
    });

    const contentStyles = mergeStyleSets({
        loader:{
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        }
    })

    const quarto = useMemo(() =>
        <>  
            {props.urlDWG != undefined && props.urlDWG.length > 3  && zoom == 3 ? (  
                <Image
                    heightViwer={props.heightViewer}
                    showList={props.showList}
                    url={props.urlDWG[3]} 
                    setZoom={setZoomInternal}
                    backZoon={2}
                    goToZon={goImage}
                    disablePanZoom={disablePanZoom}
                />
            ):zoom == 3 &&(
                <div className={contentStyles.loader}>
                    <Loader size={120} className={loaderSave} />
                </div>
            )}
        </>,
        [props.urlDWG ]
    );


    return(
        <React.Fragment>
            <HeaderViewerComp
                haveChoicerViwerChangeList={props.changeModalContent != undefined}
                title={props.title}
                showList={props.showList}
                cancel={props.cancel}
                toggleHideDialog={props.toggleHideDialog}
                isAcept={props.isAcept}
                menuConfig={props.menuConfig}
                hideSidebar={props.hideSidebar}
                toggleHideSidebar={props.toggleHideSidebar}
                openFileSetting={props.openFileSetting}
                panZoomProps={{
                    onclickDisablePanZoom:OnclickDisablePanZoom,
                    panZoom:disablePanZoom
                }}
            />

            {props.urlDWG != undefined && props.urlDWG.length > 0  && zoom == 0 ? (  
                 <Image
                 heightViwer={props.heightViewer}
                     showList={props.showList}
                     url={props.urlDWG[0]} 
                     setZoom={setZoomInternal}
                     nextZoon={1}
                     goToZon={goImage}
                     disablePanZoom={disablePanZoom}
                 />
            ):zoom == 0 &&(
                <div className={contentStyles.loader}>
                    <Loader size={120} className={loaderSave} />
                </div>
            )}

            {props.urlDWG != undefined && props.urlDWG.length > 1  && zoom == 1 ? (  
                <Image
                heightViwer={props.heightViewer}
                showList={props.showList}
                url={props.urlDWG[1]} 
                setZoom={setZoomInternal}
                backZoon={0}
                nextZoon={2}
                goToZon={goImage}
                disablePanZoom={disablePanZoom}
            />
            ):zoom == 1 &&(
                <div className={contentStyles.loader}>
                    <Loader size={120} className={loaderSave} />
                </div>
            )}

            {props.urlDWG != undefined && 
            (props.urlDWG.length > 2  && zoom == 2)
            ? (  
                <Image
                heightViwer={props.heightViewer}
                showList={props.showList}
                url={props.urlDWG[2]} 
                setZoom={setZoomInternal}
                backZoon={1}
                nextZoon={3}
                goToZon={goImage}
                disablePanZoom={disablePanZoom}
            />
            ):zoom == 2 &&(
                <div className={contentStyles.loader}>
                    <Loader size={120} className={loaderSave} />
                </div>
            )}

            {quarto}

        </React.Fragment>
    );
}

export default DwgViwerPng;