import { useTranslation } from 'react-i18next';
import styles from '../../../WorkSpaceItemList.module.css';

export default function EmptyRule() {

    const { t } = useTranslation();

    return (
        <div>
            <span className={styles.emptySlot}>{t("WithoutRule.withoutRule")}</span>
        </div>
    )
}