import { Grid } from "@mui/material";

interface IDashboardCard {
    children: React.ReactNode;
}

export const DashboardCard = ({
    children
}: IDashboardCard) => {
    return (
        <Grid
            container
            width="100%"
            height="100%"
            border="1px solid #B5B5B5"
            borderRadius="8px"
            paddingY="23px"
            paddingX="29px"
            paddingBottom={5}
        >
            { children }
        </Grid>
    )
}