import { DirectionalHint, Dropdown, IComboBoxOption, IDropdownOption, TooltipHost } from '@fluentui/react';
import { Stack } from "@fluentui/react/lib/Stack";
import { ConfigProvider, DatePicker as DatePickerAnt } from 'antd';
import { Locale } from 'antd/es/locale-provider';
import localeUs from 'antd/es/locale/en_US';
import localeBr from 'antd/es/locale/pt_BR';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import LoaderComp from '../../../components/Loader/LoaderComp';
import { ItemTemporality } from "../../../model/ItemModel";
import { GetAllTemporalityByFilterDate, GetEnumTemporality } from "../../../services/Item/ItemServicesGet";
import stylesList from "../../Style/List.module.css";
import GridTitle from './GridArchive/GridTitle';
import info from "../../../assets/icons/info.svg"
import infoHover from "../../../assets/icons/infoHover.svg";
import { NormalExpandIcon } from '../../../assets/icons/icons.tsx/NormalExpandIcon';
import { HoverExpandIcon } from '../../../assets/icons/icons.tsx/HoverExpandIcon';
import { ConstrictIcon, } from '../../../assets/icons/icons.tsx/ConstrictIcon';
import calendSeachIcon from '../../../assets/icons/calendSeachIcon.svg'
import { RangePickerIcon } from '../../../assets/icons/icons.tsx/RangePickerIcon';
import { SelectedRangePickerIcon } from '../../../assets/icons/icons.tsx/SelectedRangePickerIcon';
import { HoverRangePickerIcon} from '../../../assets/icons/icons.tsx/HoverRangePickerIcon';
import GridArchive from './GridArchive/GridArchive';

function useVisibility(
    cb: (isVisible: boolean) => void,
    deps: React.DependencyList
  ): (node: any) => void {
    const intersectionObserver = useRef<IntersectionObserver | null>(null);
    return useCallback((node) => {
      if (intersectionObserver.current) {
        intersectionObserver.current.disconnect();
      }
  
      intersectionObserver.current = new IntersectionObserver(([entry]) => {
        cb(entry.isIntersecting);
      });
  
      if (node) intersectionObserver.current.observe(node);
    }, deps);
  }


const TemporalityList: React.FC<any> = () => {

  const [limit, setLimit] = useState<number>(50);
  let [skip, setSkip] = useState<number>(0);
  const [temporalityItens, setTemporalityItens] = useState<ItemTemporality[]>([]);
  let [temporalityMax, setTemporalityMax] = useState<boolean>(true);
  const [selectDateBegin, setSelectDateBegin] = useState<Date | undefined | null>(undefined);
	const [selectDateEnd, setSelectDateEnd] = useState<Date | undefined | null>();
	const [localization, setLocalization] = useState<Locale>();
  const [reload, setReload] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
	const onSelectDateBegin = (date: Date | null | undefined) => {
		setSelectDateBegin(date);
		setReload(true);
	};

  const [options, setOptions] = useState<IComboBoxOption[]>([]);
  const [keySelect, setKeySelect] = useState<string | number | undefined>(undefined);
	const onSelectDateEnd = (date: Date | null | undefined) => {
		setSelectDateEnd(date);
		setReload(true);
	};
  const [isToolTipHoverOne, setIsToolTipHoverOne] = useState<boolean>(false)
  const [isToolTipHoverTwo, setIsToolTipHoverTwo] = useState<boolean>(false)
  const [isOptionsListOpen, setIsOptionsListOpen] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false)

  const [isHoverRangePicker, setIsHoverRangePicker] = useState<boolean>(false)
  const [iconStyleRangePicker, setIconStyleRangePicker] = useState<boolean>(false)

  const { idWorkspace } = useParams();

  const { RangePicker } = DatePickerAnt;

  useEffect(() => {
    let optionInter:IComboBoxOption[] = [];
		GetEnumTemporality()
    .then((enumReturn) => {
      enumReturn.map((data) => {
        optionInter.push({
          key:data.index,
          text:data.name
        });
      })
    })

    setOptions(optionInter)
	},[localization]);

	useEffect(() => {
		if (i18n.language === 'pt-BR') {
			setLocalization(localeBr);
		} else {
			setLocalization(localeUs);
		}
	}, [i18n.language]);

  const lastBook = useVisibility(
      (visible) => {
        if (visible) {
          if(idWorkspace != undefined)
          {
            GetAllTemporalityByFilterDate(idWorkspace,Number(keySelect),selectDateBegin?.toDateString()!,selectDateEnd?.toDateString()!,skip, limit)
            .then((temporality) => {
                  if (temporality.length < limit) {
                  setTemporalityMax(false);
                  }
                  setSkip(temporality.length);
                  setTemporalityItens(temporality);
              });
          }
        }
      },
      [idWorkspace,skip, temporalityItens]
    );
    
    useEffect(() => {
      setReload(true)
      if(idWorkspace != undefined)
      {
        GetAllTemporalityByFilterDate(idWorkspace,Number(keySelect),selectDateBegin?.toDateString()!,selectDateEnd?.toDateString()!,0, limit)
        .then((temporality) => {
              if (temporality.length < limit) {
              setTemporalityMax(false);
              }
              setSkip(temporality.length);
              setTemporalityItens(temporality);
          })
          .finally(() => {
            setReload(false)
          });
      }
    }, [idWorkspace,keySelect,selectDateBegin,selectDateEnd]);

    function changeChangeBox (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption)
    {
      if(option != undefined)
      {
        setKeySelect(option?.key);
      }
    }

    const renderCaretDown = () => {
      return !isOptionsListOpen? (!isHover? <NormalExpandIcon/> : <HoverExpandIcon/>) : <ConstrictIcon/>
    };
     
    const handleTooltipToggle = (value: number) => {
      if(value === 1){
        setIsToolTipHoverOne(!isToolTipHoverOne)
      } 
      if(value === 2){
        setIsToolTipHoverTwo(!isToolTipHoverTwo)
      }
    };

    function onRender (value: any) {
      return (
          <div style={{ padding: 0, color:"#FFFFFF", fontSize:"16px"}}>
              {value}
          </div>
      )
    }

    const onRenderLabel = (label: string, messageTooltip: string, tooltip: number) => {
      return (
        <div style={{ 
          display: 'flex',
          fontSize: "18px",
          fontWeight: 600,
          padding: "0px 0px 10px 0px",
          color: "rgb(45, 45, 45, 0.8)",
         }}>
          {label}
          <TooltipHost  
              tooltipProps={{
                  onRenderContent:()=>onRender(messageTooltip)
              }} 
              content={messageTooltip}
              onTooltipToggle={()=>handleTooltipToggle(tooltip)}
              directionalHint={DirectionalHint.bottomCenter} 
              calloutProps={{
                  styles:{
                      beak:{
                          backgroundColor:"#0B447B",
                      },beakCurtain:{
                          backgroundColor:"#0B447B",
                          borderRadius:"4px"
                      },
                      calloutMain:{
                          backgroundColor:"#0B447B",
                      },root:{
                          borderRadius:"4px",
                          marginTop:"2px"
                      }
                  }
              }} 
              styles={{ 
                  root: { 
                      marginLeft:"8px", 
                      display:"flex", 
                      alignItems:"center" 
                  } 
              }}>
            <img src={ isToolTipHoverOne && tooltip == 1 ? infoHover : isToolTipHoverTwo && tooltip == 2 ? infoHover : info} alt="info" />
          </TooltipHost>
        </div>
      );
    };

    return(
      <div style={{height:"100%"}}>
        <ul style={{height:"100%", display: "flex", flexDirection: "column"}}>
          <Stack  className={stylesList.hPage}>  
            <Stack.Item>
            <h2>{t("Temporality.message")}</h2> 
            </Stack.Item>
          
            <div style={{borderBottom:"1px solid rgb(0, 0, 0, 0.08)", margin:"34.5px 0px 25.5px 0px",}}></div>
            
            <Stack styles={{root:{display:"block"}}}>
              <Stack.Item styles={{root:{display:"flex", alignItems:"end"}}}>
                <ConfigProvider locale={localization}>
                  <div>{onRenderLabel(t("CalendarLabel.message"), t("CalendarLabel.toolTipMessage"), 1)}
                    <RangePicker
                      style={{ height: '44px', width:"280px"}}
                      disabled={reload}
                      placeholder={['dd/mm/aaaa', ' dd/mm/aaaa']}
                      onChange={(values) => {
                        onSelectDateBegin(values?.[0]?.toDate());
                        onSelectDateEnd(values?.[1]?.toDate());
                      }}
                      separator={<div> - </div>}
                      format={i18n.language === 'pt-BR' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
                      suffixIcon={!iconStyleRangePicker? (!isHoverRangePicker? <RangePickerIcon/> : <HoverRangePickerIcon/>) : <SelectedRangePickerIcon/>}
                      onMouseEnter={() => setIsHoverRangePicker(true)}
                      onMouseLeave={() => setIsHoverRangePicker(false)}
                      onOpenChange={(open) => {
                        if (open) {
                          setIconStyleRangePicker(true)
                        } else {
                          setIconStyleRangePicker(false)
                        }
                      }}
                    />
                  </div>
                </ConfigProvider>
                <Dropdown           
                  placeholder={t("FilterBy.placeholderMessage")}
                  onRenderCaretDown={renderCaretDown}
                  onRenderLabel={()=>onRenderLabel(t("FilterBy.message"), t("FilterBy.toolTipMessage"), 2)}
                  options={options}
                  onChange={changeChangeBox}
                  selectedKey={keySelect}
                  disabled={false}
                  styles={{
                    root:{
                      marginLeft:"20px",
                  },
                    title:{
                      height:"44px", 
                      fontSize:"18px",
                      color: keySelect !== "" ? "rgb(45, 45, 45)" : "rgb(45, 45, 45, 0.2)",
                      display:"flex",
                      alignItems:"center",  
                      borderRadius: "6px",
                      borderColor: isOptionsListOpen? "#005A9E": "#2D2D2D33",
                      '&:hover': {
                        borderColor:"#005A9E !important", 
                        },
                        '&::after': {
                          borderRadius: "6px", 
                          border:"none", 
                          }           
                    },dropdown:{height:"44px", width:"290px",
                    '&:focus::after': {
                      borderRadius: "6px", 
                      border:"none", 
                      }  
                      },callout:{      
                        borderRadius:"6px",
                    border:"1px solid #005A9E",
                    width:"290px",
                    marginTop:"10px"},
                    dropdownItem:{
                      fontSize:"18px"
                    },
                    dropdownItemSelected:{
                      backgroundColor:"#005A9E !important",
                      color:"#FFFFFF !important",
                      fontSize:"18px",
                    },caretDownWrapper:{
                      top:"5px",  
                      '&:hover': {
                      borderColor:"#005A9E !important", 
                      },
                      backgroundColor: isHover || isOptionsListOpen? "rgb(0, 90, 158, 0.05)": "",
                      width: "30px",
                      height: "30px",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "50%"  
                    },
                  }}
                  calloutProps={{styles:{calloutMain:{ borderRadius:"6px !important",}, root:{ borderRadius:"6px !important",}}}}
                
                  onMouseEnter={() => setIsHover(true)}
                  onMouseLeave={() => setIsHover(false)}
                  onClick={() => setIsOptionsListOpen(true)}
                  onDismiss={() => setIsOptionsListOpen(false)}
              
                />
              </Stack.Item>  
            </Stack>
          </Stack>
          
          <GridTitle/>
          { temporalityItens.length != 0 &&
            <div style={{display:"flex", flexDirection:"column",height:"100%"}}>
              
              {temporalityItens.map((iten, index) => (
                  <GridArchive 
                    itemTemporality={iten}
                    key={iten.itemId}
                    ref={temporalityItens[temporalityItens.length - 1].itemId === iten.itemId && temporalityMax ? lastBook : null}
                    lastLine={index == temporalityItens.length - 1}
                    firstLine={index == 0}
                  />
              ))}
              
            </div>
          }

          {reload &&(<div style={{ height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
            <LoaderComp big={true}/>
            </div>)
          }

          {reload == false && temporalityItens.length == 0 && 
          <div className={stylesList.noRegistry} style={{backgroundColor: temporalityItens.length == 0 ? "#F5F5F5" : ""}}>
            <div > 
              <div style={{display:"flex", justifyContent:"center"}}>
                <img src={calendSeachIcon} alt="calendSeachIcon" />
              </div>
              <div style={{
                display:"flex", 
                justifyContent:"center", 
                fontSize:"22px", 
                fontWeight: 600,
                margin:"10px 0px 10px 0px"
                }}>
                {t("NoRegistry.title")}
              </div>
              <div style={{fontSize:"18px"}}>
              {t("NoRegistry.message")}
              </div>         
              </div>
            </div>      
          }              
        </ul>
      </div>
    );
}

export default TemporalityList;