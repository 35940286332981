export interface PdfConfig {}

export const Pdf: React.FC<PdfConfig> = ({}) => {
  return(
    <svg id="icon-pdf" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Grupo_601" data-name="Grupo 601">
        <rect id="Retângulo_241" data-name="Retângulo 241" width="24" height="24" rx="2" fill="#b20c00" />
        <path id="Caminho_197" data-name="Caminho 197" d="M19.406,13.075a3.348,3.348,0,0,0-1.492-.63,11.28,11.28,0,0,0-3.833.051.635.635,0,0,1-.656-.232A18.337,18.337,0,0,1,11.444,9.6a.92.92,0,0,1-.09-.836,14.9,14.9,0,0,0,.707-4.013,4.081,4.081,0,0,0-.334-1.672A1.732,1.732,0,0,0,9.939,2.013c-.682.077-1.145.592-1.312,1.479A8.154,8.154,0,0,0,9.27,7.814,1.67,1.67,0,0,1,9.3,9.01c-.5,1.428-1.106,2.8-1.685,4.206a2.821,2.821,0,0,1-1.788,1.775,5.555,5.555,0,0,0-1.2.617,6.7,6.7,0,0,0-2.212,1.814,1.55,1.55,0,0,0-.257,1.724,1.621,1.621,0,0,0,1.621.862,2.827,2.827,0,0,0,1.608-.617A12.445,12.445,0,0,0,8.55,15.442a1.3,1.3,0,0,1,.785-.643c1.171-.373,2.354-.682,3.55-.952a.829.829,0,0,1,.836.206,7.464,7.464,0,0,0,2.47,1.441,4.453,4.453,0,0,0,2.74.206,1.331,1.331,0,0,0,1.029-1.093,1.484,1.484,0,0,0-.566-1.531Zm-15.14,5.48a1.458,1.458,0,0,1-.656.373.511.511,0,0,1-.579-.129.483.483,0,0,1,0-.6,3.611,3.611,0,0,1,.424-.566,7.768,7.768,0,0,1,2.817-1.685,8.759,8.759,0,0,1-1.994,2.624ZM9.5,3.762c.09-.347.1-.836.6-.8.527.039.527.515.527.913a12.328,12.328,0,0,1-.592,3.383A7.941,7.941,0,0,1,9.463,4.3,2.371,2.371,0,0,1,9.5,3.775ZM9.6,13.5c.386-.965.759-1.878,1.158-2.843a24.533,24.533,0,0,0,1.6,2.11c-.926.244-1.814.476-2.753.733ZM17.3,14.786a6.1,6.1,0,0,1-2.92-1.4,10.022,10.022,0,0,1,3.151-.051c.45.051.849.244.8.823A.819.819,0,0,1,17.3,14.786Z" transform="translate(1.008 0.994)" fill="#fefefe" />
      </g>
    </svg>
  )
}
