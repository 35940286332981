import { Grid, Box, Typography } from "@mui/material";
import { DashboardBorder } from "./components/DashboardBorder/DashboardBorder";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ContractSizeByOrgName } from "../../services/OrganizationServices";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, {AlertColor} from '@mui/material/Alert';
import { DashboardCard } from "./components/DashboardCard/DashboardCard";
import { DashboardHeader } from "./components/DashboardHeader/DashboardHeader";
import { IReturnAllSizes } from "../../model/OrganizationModel";
import DashboardGraph from "./components/DashboardGraph/DashboardGraph";
import DashboardGeneralData from "./components/DashboardGeneralData/DashboardGeneralData";
import DashboardShimmer from "./components/DashboardShimmer/DashboardShimmer";
import { useTranslation } from "react-i18next";
interface IObject {
    max: number;
    min: number;
}

export const Dashboard = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState<string|undefined>(undefined);
    const [alertColor, setAlertColor] = useState<AlertColor>("error");
    const [values, setValues] = useState<IObject>({ max: 0, min: 0 });
    const [returnAllSizes, setReturnAllSizes] = useState<IReturnAllSizes>();
    const [contectTotalconsumed, setContectTotalConsumed] = useState<number>(0);
    const { nameOrg } = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        if (nameOrg != undefined) {
            setIsLoading(true)
            ContractSizeByOrgName(nameOrg)
                .then(data => {
                        if (data !== undefined) {
                            setReturnAllSizes(data);
                        
                            let maxValue = Math.max(...data.sizeOrganizations.flatMap(x => x.workspaces.flatMap(x => x.size.storageUseTotal)));
                            let minValue = Math.min(...data.sizeOrganizations.flatMap(x => x.workspaces.flatMap(x => x.size.storageUseTotal)));

                            if (minValue >= maxValue) {
                                minValue = 0;
                            }
                            
                            let contectTotalconsumed = data.sizeOrganizations
                                                    .flatMap(x => x.workspaces)
                                                    .flatMap(x => x.size.storageUseTotal)
                                                    .reduce(
                                                        (accumulator, currentValue) => accumulator + currentValue,
                                                        0,
                                                    );
                            setValues({
                                max: maxValue,
                                min: minValue,
                            });

                            setContectTotalConsumed(contectTotalconsumed);
                        }
                    }
                )
                .catch(err => {
                    if (err.response != undefined) {
                        setSnackBarMessage(err.response?.data);
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }

    }, [])

    function handleClosePO (event:any) {
        setSnackBarMessage(undefined);
    }

    function handleClose (event:any, reason:any) {
        setSnackBarMessage(undefined);
    }
    
    return (
        <>
            { isLoading || returnAllSizes == undefined? (
                <DashboardBorder>
                    <DashboardShimmer />
                </DashboardBorder>
            ) : (
                <>
                    { returnAllSizes.sizeOrganizations?.map((org) => (
                            <>        
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <DashboardBorder>
                                        <DashboardHeader 
                                            organizationName={org.nameOrganization!}
                                            maxStorage={returnAllSizes.hasContract! ? returnAllSizes.totalResouce.storageUseTotal : values.max}
                                            hasContract={returnAllSizes.hasContract!}
                                        />
                                        <Grid item
                                            xs={12}
                                            marginBottom="21px"
                                        >
                                            <DashboardCard>
                                                <DashboardGeneralData 
                                                    consumed={returnAllSizes.hasContract ? org.size.storageUseTotal : contectTotalconsumed}
                                                    total={returnAllSizes.hasContract! ? returnAllSizes.totalResouce.storageUseTotal : contectTotalconsumed}
                                                    hasContract={returnAllSizes.hasContract!}
                                                />
                                            </DashboardCard>
                                        </Grid>
                                        <Grid item
                                            xs={12}
                                        >
                                            <DashboardCard>
                                                { org.workspaces.length > 0 ? (
                                                    <DashboardGraph
                                                        max={values.max}
                                                        min={values.min}
                                                        workspaces={org.workspaces}
                                                        hasContract={returnAllSizes.hasContract!}
                                                    />
                                                ) : (
                                                    <Grid item xs={12}
                                                        display="flex"
                                                        justifyContent="center"
                                                    >
                                                        <Typography
                                                            sx={{
                                                                font: "normal normal bold 28px/42px Segoe UI",
                                                                letterSpacing: "0px",
                                                                color: "#2D2D2D",
                                                                opacity: 1,
                                                            }}
                                                        >
                                                            { t("Dashboard.OrganizationDoesntHaveWorkspace") }
                                                        </Typography>
                                                    </Grid>
                                                ) }
                                            </DashboardCard>
                                        </Grid>
                                    </DashboardBorder>
                                </Box>
                                <Snackbar
                                    open={snackBarMessage != undefined}
                                    autoHideDuration={6000}
                                    message="Archived"
                                    onClose={handleClose}
                                    anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
                                    sx={{ bottom: { xs: 50, sm: 20 } }}
                                >
                                    <MuiAlert elevation={6} variant="filled" sx={{ width: '100%' }} severity={alertColor} onClose={handleClosePO}>
                                        {snackBarMessage}
                                    </MuiAlert>
                                </Snackbar>
                            </>
                    )) }
                </>
            ) }
        </>
    )
}