import React, { useState, useEffect } from "react";
import { ItemToValidationRuleVO } from "../../../../model/ValidationRuleModel";
import { useTranslation } from "react-i18next";
import { IBasePickerSuggestionsProps, ITag, TagPicker } from "@fluentui/react";
import { Box } from "@mui/material";
import styles from '../../../WorkSpaceItem/WorkSpaceItemList.module.css'
import { GetVrByWorkspaceId, GetVrByWorkspaceIdName } from "../../../../services/ValidationRuleService";
import {standartStyle} from "./Style/TagPickerStyle"

interface VRItemProps {
    workSpaceId: string;
    ref?: React.Ref<HTMLLIElement>;
    idMongo?:string;
    setError: (errorMessage: string | undefined) => void;
    CreateUserPendencieExternal:(newPendecia: ItemToValidationRuleVO) => void;
    RemoveUserPendencieVerifyExternal:() => void;
  } 

const ChoiceTheValidationRule: React.ForwardRefExoticComponent<VRItemProps> = React.forwardRef(
    ({  workSpaceId, idMongo,  setError, CreateUserPendencieExternal, RemoveUserPendencieVerifyExternal }, ref: React.Ref<HTMLLIElement>) => {

    const [tagValidation, setTagValidation] = useState<ITag[] | undefined>(undefined);
    const { t } = useTranslation();
    
    const pickerSuggestionsPropsChannel: IBasePickerSuggestionsProps = {
      suggestionsHeaderText: "",
      noResultsFoundText: t("validationRulePanel.norVrFounded"),
    };
      const onResolveSuggestionsValidationRule = async (
        filter: string,
        selectedItems: ITag[] | undefined
      ): Promise<ITag[]> => {
        let vrReturnList: ITag[] = [];
        if (filter) {
          return GetVrByWorkspaceIdName(workSpaceId, 0, 999, filter)
          .then(data => {
            let vrs: ITag[] = data.map(vr => ({ key: vr.id!, name: vr.name! }))
            return vrs
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              typeof error.response.data === "string"
            ) {
              setError(error);
            }
            else{
              setError(t("validationRulePanel.vrError"));
            }
            return []
          })
        }
        return vrReturnList;
      };
    
      const filterPromiseValidationRule = (selectedItems?: ITag[]): ITag[] | PromiseLike<ITag[]> => {
        if(tagValidation != undefined)
        {
          return tagValidation;
        }
        else
        {
          return GetVrByWorkspaceId(workSpaceId,0, 999)
          .then((vrList) => {
            const tagValidationInt: ITag[] = []
            vrList.map(vr => {
              tagValidationInt.push({ key: vr.id!, name: vr.name! })
            });
            setTagValidation(tagValidationInt);
            return tagValidationInt;
          })
          .catch((error) =>{
            if (
              error.response &&
              error.response.data &&
              typeof error.response.data === "string"
            ) {
              setError(error);
            }
            else{
              setError(t("validationRulePanel.vrError"));
            }
            return [];
          });
        }
      };

      const getTextFromItem = (item: ITag) => item.name;

      const onChangeValidationsRule = (items: ITag[] | undefined) => {

        if (items != undefined && items.length > 0 && items[0].key !== undefined) {
          var newItemToVr: ItemToValidationRuleVO = {
            itemId:idMongo,
            validationRuleId: items[0].key.toString()
          }
          CreateUserPendencieExternal(newItemToVr);
          setError(undefined);
        }
        else
        {
          RemoveUserPendencieVerifyExternal();
        }
      }

      return (
        <>
        <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: '36px',
              position:'relative',
              justifyContent: "center"
            }}
              >
                <TagPicker
                  className={styles.focus}
                  removeButtonAriaLabel="Cancel"
                  selectionAriaLabel="validation rule"
                  onResolveSuggestions={onResolveSuggestionsValidationRule}
                  getTextFromItem={getTextFromItem}
                  removeButtonIconProps={{ iconName: 'Cancel', styles: {
                    root: {
                      opacity: 1,
                      borderRadius: '50%'
                    }} }}
                  pickerCalloutProps={{
                    inputMode: "search",
                    calloutWidth: 480,
                    styles: {
                      root: {
                        top: "310px !important",
                        right: "75px",
                        maxHeight: "663px",
                        boxShadow: "none !important"
                      },
                      calloutMain: {
                        border: '1px solid #989898',
                        borderRadius: "8px"
                      },
                    },
                    style: {
                      top: "0px"
                    },
                  }}
                  styles={standartStyle}
                  onChange={onChangeValidationsRule}
                  onEmptyInputFocus={filterPromiseValidationRule}
                  itemLimit={1}
                  pickerSuggestionsProps={pickerSuggestionsPropsChannel}
                />
            </Box>
        </>
      );
    }
  );

  export default ChoiceTheValidationRule;