import { Grid, useTheme } from "@mui/material";
import { useNavigate } from 'react-router-dom'
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import BtnBigWithIcon, { ButtonTyppe } from "../../../components/Button/BtnBigWithIcon/BtnBigWithIcon";
import { CenterForm } from '../../PublicLayout/components/Handle/CenterForm';
import CheckCircleIcon from '../../../assets/icons/checkCirclePassword.svg';
import { checkIcon } from "../../../assets/icons/checkIcon";

export default function ResetPasswordSuccess() {

    const navigate = useNavigate()
    const { t } = useTranslation();
    const theme = useTheme();

    const login = (event: any) => {
        navigate("/login");
    };

    return (
        <CenterForm 
            icon={CheckCircleIcon} 
            subtitle={t("ResetPasswordSuccess.subtitle")}
            title={t("ResetPasswordSuccess.title")} 
            titleSize={32} 
        >
            <Grid item xs={12}>
                <Grid container>
                    <BtnBigWithIcon
                        isPrimary={ButtonTyppe.primary}
                        text={t("IrParaLogin.message2")}
                        onClick={login}
                        icon={checkIcon}
                    />
                </Grid>
            </Grid>
        </CenterForm>
    )
}