import { Box, Divider, Grid, Link, Typography, SxProps, Theme } from "@mui/material";
import { useWindowSize } from "../../../hooks/useWindowSize";

interface PublicFooterProps {
    logo: string;
    children?: React.ReactNode;
    rights: string;
    year: number;
    privacy: string;
}

export function PublicFooter({ logo, children, year, rights, privacy }: PublicFooterProps) {

    const size = useWindowSize(1024);

    const condition = size.width < 1024;

    function positionHandler(condition: boolean): SxProps<Theme> | undefined {
        if (condition) {
            return {
                width:"100%",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }
        }
        return {
            position:'absolute',
            bottom:0,
            width:"100%",
        }
    }

    return (
        <>
            { size.width > 1024 && (
                <>
                    <Box id="footer" sx={{
                        width: '50%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        background: 'white'
                    }}>
                    </Box>
                    <Grid container sx={positionHandler(condition)}>
                        <Grid item sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: 2,
                            paddingRight: 3,
                            paddingLeft: 3,
                        }}>
                            <Box>
                                <Box component={'img'} src={logo} sx={{ height: '80px', width: '200px', opacity: '0.4' }} />
                            </Box>
                        </Grid>
                    </Grid>
                </>
            ) }
        </>
    )
}