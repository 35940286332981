import React, { useState, useEffect } from "react";
import Btn from "../../../components/Button/Btn";
import MessageComp from "../../../components/Dialog/Message";
import TextFieldComp from "../../../components/TextField/TextFieldComp";
import { useBoolean } from "@fluentui/react-hooks";
import Message from "../../../components/Message/Message";
import { MessageBarType } from "@fluentui/react";
import ChoiceGroupComp  from "../../../components/ChoiceGroup/ChoiceGroupComp";
import CheclkBoxComp  from "../../../components/Checkbox/CheckboxComp";
import { UserCreate, UserGet, UserOrganization } from "../../../model/UserModel";
import { Organization } from "../../../model/OrganizationModel";
import { GetUser, EditUser, CreateUser } from "../../../services/UserServices";
import LoaderComp from '../../../components/Loader/LoaderComp';
import { useTranslation } from "react-i18next";
import {
  GetOneOrganization
} from "../../../services/OrganizationServices";
import { Shimmer, ShimmerElementType, mergeStyles } from '@fluentui/react';
import { LoginType } from "../../../utils/constants/LoginTypeConstants";
import { AlertColor } from "@mui/material";
import { useProfile } from "../../../context/ProfileProvider/useProfile";

export interface UserConfigParameters {
  nameOrg: string,
  idUser?: string,
  saveOkMetod: () => void,
  setMessage: (value: string) => void,
  setAlertColor: (value: AlertColor) => void;
}

const UserConfig = (props: UserConfigParameters) => {
  const [user, setUser] = useState<UserGet | null>(null);
  const [org, setOrg] = useState<Organization | null>(null);

  const [waitUserConsult, setWaitUserConsult] = useState<boolean>(false);
  
  const [newUserName, setNewUserName] = useState<string>("");
  const [newUserEmail, setNewUserEmail] = useState<string>();
  const [adminOrg, {setTrue: adminOrgT, setFalse: adminOrgF}] = useBoolean(false);
  const [onlyRead, {setTrue: onlyReadT, setFalse: onlyReadF}] = useBoolean(false);
  const [isMessageError, setMessageError] = useState<boolean>(false);
  const [isMessageOk, setMessageOk] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>(t("ErroAoSalvar.message"));
  const [loader, setLoader] = useState<boolean>(false);
  const [loginType, setLoginType] = useState<number>(LoginType.EmailPassword);
  const profile = useProfile();
  const isEditingUser = props.idUser != undefined;

  useEffect(() => {
    if (props.nameOrg != undefined) {
      GetOneOrganization(props.nameOrg).then((org) => {
        if (org != null) {
          setOrg(org);
          if(props.idUser == undefined)
          {
            if (org.loginType == undefined || org.loginType == 0) {
              setLoginType(LoginType.EmailPassword);
            } else {
              setLoginType(org.loginType);
            }
          }
      }})
      .catch(() => {
        console.log("Não encontrado a organização");
      }); 
    }

    if (props.idUser != undefined){
      getUser(props.idUser);
    }
  }, []);

  function getUser(idUser:string)
  {
    setWaitUserConsult(true);
    GetUser(idUser)
        .then((userFound) => {
          if (userFound != null) {
            setUser(userFound);
            if (userFound.name) {
              setNewUserName(userFound.name);
              setNewUserEmail(userFound.login);
              if(userFound.organizations!= undefined)
                {
                  for(let organization of userFound.organizations)
                  {
                    if(organization.internalName == props.nameOrg)
                    {
                      if(organization.isAdminOrganization)
                      {
                        adminOrgT();
                      }

                      if(organization.isOnlyRead)
                      {
                        onlyReadT();
                      }
                      break;
                    }
                  }
                }
            }
          }
          else
          {
            console.log("Não encontrado o usuário");
          }
        })
        .catch(() => {
          console.log("Não encontrado o usuário");
        })
        .finally(()=>{
          setWaitUserConsult(false);
        }); 
  }

  function crudUser()
  {
    resetMessage();
    if(user?.id != undefined)
    {
      setLoader(true);
      editUser();
    }
    else
    {
      setLoader(true);
      createUser();
    }
  }

  function editUser() {
    if (org != null && org.id && props.idUser != undefined) {
      resetMessage();

      if(newUserName.length < 3)
      {
        setErrorMessage(t("MinDeCaracteres.message"));
        setMessageError(true);
        setLoader(false);
        return;
      }
      else if(newUserName.length > 50){
        setErrorMessage(t("MaxDeCaracteres.message"));
        setMessageError(true);
        setLoader(false);
        return;
      }

      let userEdit: UserCreate={
        name: newUserName,
        login: newUserEmail,
        id:props.idUser,
        loginType: loginType
      };

      let userOrg: UserOrganization={
        idOrganization:org.id,
        isAdminOrganization: adminOrg,
        isReader: onlyRead,
      }
      userEdit.organization = userOrg;

      EditUser(userEdit)
        .then((message) => {
          setMessageOk(true);
        })
        .catch((error) =>{
          if( error.response && error.response.data && typeof error.response.data  === 'string'){
            setErrorMessage(error.response.data);
          }
          setMessageError(true);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }

  function createUser() {
    resetMessage();
    if(newUserName.length < 3)
    {
      setErrorMessage(t("MinDeCaracteres.message"));
      setMessageError(true);
      setLoader(false);
      return;
    }
    else if(newUserName.length > 50){
      setErrorMessage(t("MaxDeCaracteres.message"));
      setMessageError(true);
      setLoader(false);
      return;
    }
    if (org != null && org.id) {
      resetMessage();

      let newUser: UserCreate={
        name: newUserName,
        login: newUserEmail,
        loginType: loginType
      };
      let userOrg: UserOrganization={
        idOrganization:org.id,
        isAdminOrganization: adminOrg,
        isReader: onlyRead,
      }
      newUser.organization = userOrg;

      CreateUser(newUser)
        .then((message) => {
          props.saveOkMetod(); 
          if (message.length > 1)
          {
            props.setMessage(message);
            props.setAlertColor("warning");
          } else 
          {
            setMessageOk(true);
          }

        })
        .catch((error) =>{
          console.log(error);
          if( error.response && error.response.data && typeof error.response.data  === 'string'){
            setErrorMessage(error.response.data);
          }
          setMessageError(true);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }

  function resetMessage() {
    setMessageOk(false);
    setMessageError(false);
    setErrorMessage(t("ErroAoSalvar.message"));
  }


  const onChangeNome =
  (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    if (newValue != undefined ) {
      setNewUserName(newValue);
    }
    else
    {
      setNewUserName("");
    }
  }; 

  const onChangeEmail =
  (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    if (newValue != undefined ) {
      setNewUserEmail(newValue);
    }
    else
    {
      setNewUserEmail("");
    }
  }; 

  function changeOrgAdmin (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) {
    if(checked == undefined || checked == false)
    {
      adminOrgF();
    }
    else
    {
      adminOrgT();
      onlyReadF();
    }
  };

  function changeOnlyUser (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) {
    if(checked == undefined || checked == false)
    {
      onlyReadF();
      adminOrgF();
    }
    else
    {
      onlyReadT();
    }
  };

  
  function changeLoginMicrosoftUser (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) {
    if(checked == undefined || checked == false)
    {
      setLoginType(LoginType.EmailPassword);
    }
    else
    {
      setLoginType(LoginType.Office365);
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if(event.key ===  'Enter'){
      crudUser();
    }
  }

  const wrapperClass = mergeStyles({
    padding: 2,
    selectors: {
      '& > .ms-Shimmer-container': {
        margin: '2px 0',
      },
    },
  });

  return (
    <div className={wrapperClass}>
      {waitUserConsult ? (
        <Shimmer shimmerElements={[
          { type: ShimmerElementType.line, width: '100%', height: 72 }
        ]}/>
      ):(
        <TextFieldComp
          label={t("Nome.message")}
          value={newUserName}
          onChange={onChangeNome}
          autofocus
          onKeyDown={onKeyDown}
        />
      )}

      {waitUserConsult ? (
        <Shimmer shimmerElements={[
          { type: ShimmerElementType.line, width: '100%', height: 73 }
        ]}/>
      ):(
        <TextFieldComp
          label="Email"
          value={newUserEmail}
          onChange={onChangeEmail}
          onKeyDown={onKeyDown}
          disabled={isEditingUser}
        />
      )}

      {waitUserConsult ? (
        <Shimmer shimmerElements={[
          { type: ShimmerElementType.circle },
          { type: ShimmerElementType.gap, width: '2%' },
          { type: ShimmerElementType.line },
        ]}/>
      ):(
        <CheclkBoxComp 
          label={t("AdmOrg.message")}
          checked={adminOrg}
          onChange={changeOrgAdmin}
          disabled={onlyRead}
        />
      )}

      {waitUserConsult ? (
        <Shimmer shimmerElements={[
          { type: ShimmerElementType.circle },
          { type: ShimmerElementType.gap, width: '2%' },
          { type: ShimmerElementType.line },
        ]}/>
      ):(
        <CheclkBoxComp 
          label={t("OnlyRead.message")}
          checked={onlyRead}
          onChange={changeOnlyUser}
          disabled={adminOrg}
        />
      )}

      {waitUserConsult ? (
        <Shimmer shimmerElements={[
          { type: ShimmerElementType.circle },
          { type: ShimmerElementType.gap, width: '2%' },
          { type: ShimmerElementType.line },
        ]}/>
      ):(
        <CheclkBoxComp 
          label={t("Login com microsoft")}
          checked={loginType == LoginType.Office365}
          onChange={changeLoginMicrosoftUser}
          disabled={isEditingUser}
        />
      )}

      {loader && (
        <LoaderComp/>
      )}
      
      {isMessageError && (
        <Message text={errorMessage} type={MessageBarType.error} />
      )}
      {isMessageOk && (
        <Message text={t("SalvoOK.message")} type={MessageBarType.success} />
      )}

      <Btn text={t("Salvar.message")} onClick={crudUser} disabled={waitUserConsult} />
    </div>
  );
};

export default UserConfig;