import { ValidationRuleVOActivityRetrieve } from '../../../model/ValidationRuleModel'
import styles from './WorkspaceItemFlow.module.css'
import { useEffect, useMemo, useState } from 'react'
import { GetFlowInformationByItemVO } from '../../../model/ItemModel'
import moment from 'moment'
import { CloseIcon } from '../../../assets/svg/CloseIcon'
import { Box, Typography } from '@mui/material'
import {SvgItemBox} from "./SvgComposeComponents/SvgItemBox";
import { UserIcon } from '../../../assets/svg/UserIcon'
import { DataIcon } from '../../../assets/svg/DataIcon'
import { useTranslation } from 'react-i18next'
import { CalloutResponsability } from './SvgStyleComponents/CalloutResponsability'
import { WorkspaceFlowListingResponsability } from './WorkspaceFlowListingResponsability/WorkspaceFlowListingResponsability'

export interface WorkspaceItemFlowListingType {
    getFlowInformationByItemVO?:GetFlowInformationByItemVO
}

export const WorkspaceItemFlowListing: React.FC<WorkspaceItemFlowListingType> = ({ getFlowInformationByItemVO }) => {
    const { t, i18n } = useTranslation();
    const animationTime:number = 0.5;
    const [usersInPreConditions, setUsersInPreConditions] = useState<{ actionId: number, users: string[] }>({} as { actionId: number, users: string[] });

    useEffect(() => {
        if (getFlowInformationByItemVO?.activity != undefined) {
            var activitiesWithDynamicUser = getFlowInformationByItemVO.activity.filter(act => act.dinamicUserChoice == true);
            if (activitiesWithDynamicUser.length > 0) {

            }
        }

        console.log(usersInPreConditions)
    }, [getFlowInformationByItemVO])

    const getDynamicUsersByActionId = (actionId: number) => {

    }

    const returnDateAndIconMemo = useMemo(() => (action?: number, dateBack?: string, dateForwad?: string) => {
        if(action === 0) {
            const backDate = moment(dateBack).format("DD/MM/YY HH:mm")
            return (
                <Typography sx={{color: "#2D2D2D", display: "flex", alignItems:"center" }}>
                    <DataIcon/>
                    <Typography sx={{margin:"0 4px 0 8px"}} fontWeight="600">{t("WorkspaceItemFlowListing.date")}:</Typography>
                    <Typography sx={{opacity:"60%", display:"flex"}}>{ backDate } <CloseIcon/></Typography>                   
                </Typography>
            )
        }
        if(action === 1) {
            const forwardDate = moment(dateForwad).format("DD/MM/YY HH:mm")
            return (
                <Typography sx={{color: "#2D2D2D", display: "flex", alignItems:"center" }}>
                    <DataIcon/>
                    <Typography sx={{margin:"0 4px 0 8px"}} fontWeight="600">{t("WorkspaceItemFlowListing.date")}:</Typography>
                    <Typography sx={{opacity:"60%", display:"flex"}}>{forwardDate}</Typography>                   
                </Typography>
            )
        }    
    }, [getFlowInformationByItemVO])
    
    const draftMemo = useMemo(() => {
        return (
            <SvgItemBox
                title="Draft"
                svgFlowCircleType={{
                    CircleType:"OVERCOME",
                    delayAnimationTime:0,
                    timeAnimationTime:animationTime,
                }}
                svgFlowLineType={{
                    isStrokeFixed:true,
                    strokeColor:"#4472C4",
                }}
            >
                <Typography sx={{color: "#2D2D2D", display: "flex", alignItems:"center" }}>
                    <UserIcon/>
                    <Typography sx={{margin:"0 4px 0 8px"}} fontWeight="600">
                        {t("WorkspaceItemFlowListing.responsible")}:
                    </Typography>
                    <Typography sx={{opacity:"60%"}}>
                        {getFlowInformationByItemVO?.currentUserDraftName!}{" "}
                    </Typography> 
                </Typography>

                <Typography sx={{color: "#2D2D2D", display: "flex", alignItems:"center" }}>
                    <DataIcon/>
                    <Typography sx={{margin:"0 4px 0 8px"}} fontWeight="600">
                        {t("WorkspaceItemFlowListing.date")}:
                    </Typography>
                    <Typography sx={{opacity:"60%"}}>
                        {getFlowInformationByItemVO != undefined &&
                        getFlowInformationByItemVO.draftDate != undefined &&
                        moment(getFlowInformationByItemVO?.draftDate).format("DD/MM/YY HH:mm")}
                    </Typography>                   
                </Typography>
 
            </SvgItemBox>
        )

    },[getFlowInformationByItemVO, animationTime])

    const dateActionForwadOrBackMemo = useMemo(()=> (data: ValidationRuleVOActivityRetrieve, array: ValidationRuleVOActivityRetrieve[]) => {

        if(getFlowInformationByItemVO?.dateActionForwadOrBack){
            const listFilter = getFlowInformationByItemVO?.dateActionForwadOrBack.filter(item => item.idAction === data.id)
            
      
                if(listFilter.length) {
                    return listFilter
                    .map((item, index, list) => { 
                            return (
                                <>
                                    <div className={styles.nameCardTest}>
                                      
                                        {list.length - 1 !== index ? (
                                            ""
                                            ) : (
                                                <Typography>
                                                    {item.idAction! > getFlowInformationByItemVO?.validationRule!.actionId! || !list[index] ? (
                                                <Typography>{t("WorkspaceItemFlowListing.by")} "{item.nameResponsability}", {t("WorkspaceItemFlowListing.waiting")}</Typography>
                                            ) : (
                                                <Typography>
                                                    {item.idAction === getFlowInformationByItemVO?.validationRule!.actionId! && !getFlowInformationByItemVO?.isRelease ? (
                                                        <>
                                                           <Typography >{t("WorkspaceItemFlowListing.progress")}</Typography>
                                                           <Typography sx={{ color: "#2D2D2D", display: "flex", alignItems: "center" }}>
                                                               <UserIcon />
                                                               <Typography sx={{ margin: "0 4px 0 8px" }} fontWeight="600">
                                                                    {t("WorkspaceItemFlowListing.responsible")}:
                                                               </Typography>
                                                               {/* <Typography sx={{ opacity: "60%" }}>
                                                                   {data.responsability != undefined && data.responsability.length > 0 ? data.responsability[0].nameObject : t("WorkspaceItemFlowListing.dynamic")}
                                                               </Typography> */}
                                                               <WorkspaceFlowListingResponsability 
                                                                    flowInformation={getFlowInformationByItemVO}
                                                                    index={index}
                                                                    validationRetrieve={data}
                                                               />
                                                           </Typography>
                                                       </> 
                                                    ) : (
                                                        <Typography>
                                                            <Typography sx={{ color: "#2D2D2D", display: "flex", alignItems: "center" }}>
                                                            <UserIcon />
                                                            <Typography sx={{ margin: "0 4px 0 8px" }} fontWeight="600">
                                                            {t("WorkspaceItemFlowListing.responsible")}:
                                                            </Typography>
                                                            <Typography sx={{ opacity: "60%" }}>
                                                                {item?.nameResponsability!}{""}
                                                            </Typography>
                                                            </Typography>
                                                            {returnDateAndIconMemo(item.action, item.dateUpdateBackItem, item.dateUpdateForwadItem)}
                                                        </Typography>
                                                    )}
                                                </Typography>
                                                )}
                                            </Typography>
                                        )}                                              
                                    </div>
                                </>
                            )
                        })
                } else if(getFlowInformationByItemVO?.validationRule?.actionId === data.id){
                    return (
                    <>
                        <Typography >{t("WorkspaceItemFlowListing.progress")}</Typography>
                        <Typography sx={{ color: "#2D2D2D", display: "flex", alignItems: "center" }}>
                            <UserIcon />
                            <Typography sx={{ margin: "0 4px 0 8px" }} fontWeight="600">
                                {t("WorkspaceItemFlowListing.responsible")}:
                            </Typography>
                            <Typography sx={{ opacity: "60%" }}>
                                <WorkspaceFlowListingResponsability 
                                    flowInformation={getFlowInformationByItemVO}
                                    index={0}
                                    validationRetrieve={data}
                                />
                                { !getFlowInformationByItemVO?.activity![0].dinamicUserChoice && (
                                    <>
                                        {data.responsability?.length ? data.responsability.length > 1 ? 
                                        <CalloutResponsability responsability={data.responsability}/>: t("WorkspaceItemFlowListing.dynamic") : t("WorkspaceItemFlowListing.dynamic")}
                                    </>
                                ) }
                            </Typography>
                        </Typography>
                    </> 
                    )                                         
                } else { 
                    return <Typography >{t("WorkspaceItemFlowListing.by")} {data?.responsability![0]?.nameObject}, {t("WorkspaceItemFlowListing.waiting")}</Typography>                                          
            }
        } else {
            return (
                getFlowInformationByItemVO?.validationRule?.actionId === data.id ?
                <>
                    <Typography >{t("WorkspaceItemFlowListing.progress")}</Typography>
                    <Typography sx={{ color: "#2D2D2D", display: "flex", alignItems: "center" }}>
                        <UserIcon />
                        <Typography sx={{ margin: "0 4px 0 8px" }} fontWeight="600">
                            {t("WorkspaceItemFlowListing.responsible")}:
                        </Typography>
                        <WorkspaceFlowListingResponsability 
                            flowInformation={getFlowInformationByItemVO!}
                            index={0}
                            validationRetrieve={data}
                        />
                    </Typography>
                </>
                    :
                <Typography >{t("WorkspaceItemFlowListing.by")} {data?.responsability![0]?.nameObject}, {t("WorkspaceItemFlowListing.waiting")}</Typography>
            )
        }
    }, [getFlowInformationByItemVO])

    const releaseMemo = useMemo(() => {

        return (
            <SvgItemBox
                title="Release"
                svgFlowCircleType={{
                    CircleType:getFlowInformationByItemVO?.isRelease == true?"OVERCOME":"FUTURE",
                    delayAnimationTime: getFlowInformationByItemVO && getFlowInformationByItemVO.activity?.length ? (getFlowInformationByItemVO.activity?.length + 1) * animationTime : animationTime,
                    timeAnimationTime:animationTime,
                    activityNumber: getFlowInformationByItemVO?.activity?.length ? getFlowInformationByItemVO?.activity?.length + 2 : 2
                }}
            >
                {getFlowInformationByItemVO?.isRelease == true ?(
                <>
                    <Typography sx={{color: "#2D2D2D", display: "flex", alignItems:"center" }}>
                        <UserIcon/>
                        <Typography sx={{margin:"0 4px 0 8px"}} fontWeight="600">
                            {t("WorkspaceItemFlowListing.responsible")}:
                        </Typography>
                        <Typography sx={{opacity:"60%"}}>
                            {getFlowInformationByItemVO?.currentUserDraftName!}{" "}
                        </Typography> 
                    </Typography>

                    <Typography sx={{color: "#2D2D2D", display: "flex", alignItems:"center" }}>
                        <DataIcon/>
                        <Typography sx={{margin:"0 4px 0 8px"}} fontWeight="600">
                        {t("WorkspaceItemFlowListing.date")}:
                        </Typography>
                        <Typography sx={{opacity:"60%"}}>
                            {getFlowInformationByItemVO != undefined &&
                            getFlowInformationByItemVO.releasedDate != undefined &&
                            moment(getFlowInformationByItemVO?.releasedDate).format("DD/MM/YY HH:mm")}
                        </Typography>                   
                    </Typography>
                </>
                ):(
                    <Typography>{t("WorkspaceItemFlowListing.waiting")}</Typography>
                )}
            </SvgItemBox>
        )
        
    }, [getFlowInformationByItemVO, animationTime])

    return (
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Box display="flex" flexDirection="column" width={"100%"}>
                { getFlowInformationByItemVO?.wasDraft == false ? (
                    <Box>
                        {releaseMemo}
                    </Box>
                ) : (
                    <>
                        <Box>
                            {draftMemo}
                        </Box>                  
                        { getFlowInformationByItemVO != undefined && getFlowInformationByItemVO.activity !== undefined && (
                        getFlowInformationByItemVO.activity.map((data, index, array) => {      
                            const circleType = () => {
                                if(getFlowInformationByItemVO?.validationRule?.actionId === data.id! && !getFlowInformationByItemVO.isRelease) { 
                                    return "CURRENT"
                                    } else if (getFlowInformationByItemVO?.validationRule?.actionId! < data.id!){
                                        return "FUTURE"
                                    } else {
                                        return "OVERCOME"
                                    }
                                }

                                return (
                                    <Box> 
                                        
                                        <SvgItemBox                 
                                            title={data.name}
                                            svgFlowCircleType={{
                                                CircleType:circleType(),
                                                delayAnimationTime: animationTime * (index + 1),
                                                timeAnimationTime:animationTime,
                                                activityNumber: index + 2 
                                            }}
                                            svgFlowLineType={{
                                                getFlowInformationByItemVO: getFlowInformationByItemVO, 
                                                data: data,
                                            }}
                                        >
                                            {dateActionForwadOrBackMemo(data, array)}
                                        </SvgItemBox>
                                    </Box>
                                )
                                
                            })
                        )}
                        <Box>
                            {releaseMemo}
                        </Box>
                    </>
                ) }
            </Box>
        </Box>
    )
}