import { Color } from "./interface/Color"

export const TemporalityDocument: React.FC<Color> = ({
    color
  }) => {
    return(
        <svg id="Camada_2" data-name="Camada 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.87 100.3">
            <defs>
                <style>
                    .cls-1 {`
                        stroke-width: 0px;
                    `}
                </style>
            </defs>
            <g id="Icons">
                <g>
                    <rect className="cls-1" x="-1.08" y="10.35" width="24.24" height="7" transform="translate(-6.56 11.87) rotate(-44.99)" />
                    <rect className="cls-1" x="83.32" y="1.73" width="7" height="24.24" transform="translate(15.63 65.45) rotate(-45)" />
                    <path className="cls-1" d="m92.75,50c0-22.97-17.77-41.84-40.29-43.63V0h-7v6.37C22.95,8.16,5.17,27.03,5.17,50c0,12.31,5.12,23.45,13.33,31.41l-13.94,13.94,4.95,4.95,14.41-14.41c7.1,4.97,15.73,7.9,25.04,7.9s18.18-3.01,25.33-8.11l14.62,14.62,4.95-4.95-14.18-14.18c8.06-7.95,13.07-18.98,13.07-31.17Zm-43.79,36.79c-20.28,0-36.79-16.5-36.79-36.79S28.68,13.21,48.96,13.21s36.79,16.5,36.79,36.79-16.5,36.79-36.79,36.79Z" />
                    <path className="cls-1" d="m52.46,49.97v-20.37c0-1.93-1.57-3.5-3.5-3.5s-3.5,1.57-3.5,3.5v21.63s0,.06,0,.09c-.03.93.31,1.87,1.02,2.57l11.2,11.2c.68.68,1.58,1.03,2.47,1.03s1.79-.34,2.47-1.03c1.37-1.37,1.37-3.58,0-4.95l-10.17-10.17Z" />
                </g>
            </g>
        </svg>
    )
}