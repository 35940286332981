export interface DocxConfig {}

export const Docx: React.FC<DocxConfig> = ({}) => {
  return(
    <svg id="icon-doc" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Grupo_600" data-name="Grupo 600">
        <rect id="Retângulo_240" data-name="Retângulo 240" width="24" height="24" rx="2" fill="#2165c2" />
        <path id="Caminho_196" data-name="Caminho 196" d="M6.653,15.667,3,5H5.72l2.12,6.693L10.133,5h1.88l2.2,6.827L16.44,5H19L15.32,15.667H13.2L11.013,9.253,8.787,15.667H6.667Z" transform="translate(1 1.667)" fill="#fff" />
      </g>
    </svg>

  )
}
