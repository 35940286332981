import React, { useState, useEffect,useMemo } from "react";
import { useTranslation } from "react-i18next";
import TextFieldComp from "../../../components/TextField/TextFieldComp";
import { SetOwner } from "../../../services/Item/ItemServicesCRUD";
import { Owner } from "../../../model/ItemModel";
import ComboBoxComp from '../../../components/ComboBox/ComboBoxComp';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { IComboBox, IComboBoxOption, Label, MessageBarType, Pivot, PivotItem } from '@fluentui/react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { List , IList} from '@fluentui/react/lib/List';
import { GetItemUser,GetItemGroup, GetOneItem } from "../../../services/Item/ItemServicesGet";
import { TagPicker, ITag, IBasePickerSuggestionsProps } from '@fluentui/react/lib/Pickers';
import { GetOrganizationAdminUserName } from "../../../services/OrganizationServices";
import { IStackStyles, } from '@fluentui/react/lib/Stack';
import Btn from "../../../components/Button/Btn";
import LoaderComp from '../../../components/Loader/LoaderComp';
import { useNavigate, useParams } from "react-router-dom";
import { IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import Checkbox from '../../../components/Checkbox/CheckboxComp'
import Message from "../../../components/Message/Message";

export interface EditFolderOpen{
    idMongo: string;
    nameChanged: string;
    ownerId?: string;
    ownerName?: string;
    sensitivity?: number;
    notificationEmail?:boolean;
}

export interface EditFolderParams{
    editFolderOpen: EditFolderOpen;
    saveOkMetod: () => void;
    nameOrg: string;
}

const rootClass: Partial<IStackStyles> = {
    root: {
        width:"100%",
        borderColor: "#114D88",
        margin:"5px",
    }
};


const titlePicker = mergeStyles({
    fontWeight:900,
    margin:"5px",
    fontFamily: "Microsoft YaHei, sans-serif",
    fontSize:"14px"
});
const styles = mergeStyleSets({
    container: {
      overflow: 'auto',
      maxHeight: 100,
      border: '1px solid #CCC',
    },
    titlePivot: {
        margin: "5px",
        width: "100%",
    },
    listPermissions: {
        paddingLeft: "8px",    
    },
    listItens:{
        borderBottom:"solid 1px",
        height: "30px",
        lineHeight: "29px"
    },
    Btns:{
        // background:"rgb(17, 77, 136)"
    },
    itemContent: [
      {
        position: 'relative',
        boxSizing: 'border-box',
        display: 'block',
        paddingLeft: 27
      },
    ],
  });

const EditFolder = (props: EditFolderParams) => {
    const { t, i18n } = useTranslation(); 
    const [nameChanged, setNameChanged] = useState<string>(props.editFolderOpen.nameChanged); 
    const [messageErrorString, setMessageErrorString] = useState<string>(t("ErroAoSalvar.message"));
    const [isMessageError, setMessageError] = useState<boolean>(false);
    const [wait, setWait] = useState<boolean>(false);
    const [itemsUser, setItemsUser] = useState<string[]>([]);
    const [itemsGroup, setItemsGroup] = useState<string[]>([]);
    const [itensTag, setItensTag] = useState<ITag[] | undefined>([]);
    const navegate = useNavigate();
    const { idWorkspace } = useParams();
    const listRef: React.RefObject<IList> = React.useRef(null);
    const [reaload, setReaload] = useState<boolean>(false);
    const [selectNotification, setSelectNotification] = useState<boolean>(false);
    //const [sensitivity, setSensitivity] = useState<number>(props.sensitivity!);
    const [selectSensitivity, setSelectSensitivity] = useState<number>(props.editFolderOpen.sensitivity!);

    useEffect(() => {
        if(reaload == true ){
          setReaload(false)
        }
      }, [reaload]);
    
      useEffect(() => {
        if(reaload == false ){
          setReaload(true);
        }
      }, [itensTag]);

    useEffect(() =>{
        if(props.editFolderOpen.idMongo)
        {
            GetItemUser(props.editFolderOpen.idMongo,0, 0).then((newUsers) => {
                var users = newUsers.map((user) => user.userName)
                setItemsUser(users);
            });

            GetItemGroup(props.editFolderOpen.idMongo,0, 0).then((newGroups) => {
                var groups = newGroups.map((group) => group.groupName)
                setItemsGroup(groups);
            });
        }
        
        if(props.editFolderOpen.ownerId != undefined && props.editFolderOpen.ownerName != undefined)
        {
            let newitems: ITag[] = [];
            newitems.push({
                key: props.editFolderOpen.ownerId,
                name: props.editFolderOpen.ownerName
            });

            setItensTag(newitems);
        }
    },[
        props.editFolderOpen.idMongo,
        props.editFolderOpen.ownerId,
        props.editFolderOpen.ownerName
    ])

    const onRenderCell = (item?: string, index?: number, isScrolling?: boolean): JSX.Element => {
        return (
          <div data-is-focusable className={styles.listItens}>
            {item}
          </div>
        );
    };

    const onResolveSuggestions = async (
        filter: string,
        selectedItems: ITag[] | undefined
      ): Promise<ITag[]> => {
        if (filter) {
        return GetOrganizationAdminUserName(props.nameOrg, filter)
          .then((users) => {
            const userTags: ITag[] = users.map(item => ({ key: item.id, name: item.name}));
            return userTags;
          })
          .catch(() =>{
            return [];
          });
        }
        return [];
    };

    const onChange = (items: ITag[] | undefined) => {
        setItensTag(items);          
    };

    const onChangeSetName = React.useCallback(
        (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
        ) => {
            if (newValue != undefined) {
                if (newValue.includes("\\") || newValue.includes("/")) {
                    setMessageError(true)
                    setMessageErrorString(t("NameMustNotIncludeEitherSlashes.message"));
                } else {
                    setMessageError(false)
                    setMessageErrorString("");
                    setNameChanged(newValue);
                }
            } else {
                setNameChanged("");
            }
        },
        []
    );

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key ===  'Enter'){
            UpdateOwner();
        }
    }

    const filterPromise = (selectedItems?: ITag[]): ITag[] | PromiseLike<ITag[]> => {
        return GetOrganizationAdminUserName(props.nameOrg, "")
        .then((users) => {
        const userTags: ITag[] = users.map(item => ({ key: item.id, name: item.name}));
        return userTags;
        })
        .catch(() =>{
        return [];
        });
      };

    const UpdateOwner = () => {

        let owner:Owner = {
            idFolder:props.editFolderOpen.idMongo,
            idNewOwner:itensTag?.map((user) => user.key).toString(),
            NewName:nameChanged,
            sensitivity:selectSensitivity,
            idItem:props.editFolderOpen.idMongo,
            notificationEmail:selectNotification
        };
        SetOwner(owner)
        .then(() => {
            props.saveOkMetod();
        })
        .catch((error) => {
            if( error.response && error.response.data && typeof error.response.data  === 'string'){
                setMessageErrorString(error.response.data);
            }
            else
            {
                setMessageErrorString(t("ErroAoSalvar.message"));
            }
            setWait(false);
            setMessageError(true);
        })
    }

    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: t("UsuariosSugeridos.message"),
        noResultsFoundText: t("UsuariosNaoEncontradors.message"),
    };

    const getTextFromItem = (item: ITag) => item.name;

    const permissionGroup = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem)  => {
        if(ev)
          ev.preventDefault();
    

         navegate("/" + props.nameOrg + "/wsallowed/" + idWorkspace +"/"+ props.editFolderOpen.idMongo + "/PermissionGroup");
        
      }
    
      const permissionUser = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem)  => {
        if(ev)
          ev.preventDefault();
        
        navegate("/" + props.nameOrg + "/wsallowed/" + idWorkspace +"/"+ props.editFolderOpen.idMongo + "/PermissionUser");
        
      }

    function onChangeSelected(event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number): void{
        const key = option?.key;
        
        if(key || key === 0){
            setSelectSensitivity(Number(key));   
        }

        if(key == 0 || key == 1){
            setSelectNotification(false);
        }
        else{
            setSelectNotification(props.editFolderOpen.notificationEmail!);
        }
    }

    useEffect(() => {
        if(props.editFolderOpen.notificationEmail == true){
            setSelectNotification(true)
        }
    },[props.editFolderOpen.notificationEmail]);


    function selectCheckBoxNotification (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) {
        
        if(checked == undefined || checked == false)
        {
         setSelectNotification(false);
        }
        else
        {
          setSelectNotification(true);     
        }
    };

    return (    
        <div>
            <TextFieldComp
                label={t("Nome.message")}
                value={nameChanged}
                onChange={onChangeSetName}
                autofocus
                onKeyDown={onKeyDown}
            />
            {isMessageError && <Message text={messageErrorString} type={MessageBarType.error} />}

            <ComboBoxComp
        
                options={[
                    {
                        key:0,
                        text:t("Sensibility.low"),
                        selected:selectSensitivity === 0 ? true : false
                    },
                    {
                        key:1,
                        text:t("Sensibility.medium"),
                        selected:selectSensitivity === 1 ? true : false
                    },
                    {
                        key:2,
                        text:t("Sensibility.higth"),
                        selected:selectSensitivity === 2 ? true : false
                    }
                ]}
                label={t("Sensibility.word")}
                onChange={onChangeSelected}
                selectedKey = {selectSensitivity}
            />
            <br/>

            <Checkbox
            label={t("NotificationEmail.message")}
            onChange={selectCheckBoxNotification}
            checked={selectNotification}  
            disabled={selectSensitivity != 2?true:false}        
            />
            <br/>
                        
            
            <label className={titlePicker}>{t("owner.message")}</label>
            {reaload?(<div></div>):(
                <TagPicker
                    removeButtonAriaLabel="Remove"
                    selectionAriaLabel="People"
                    onResolveSuggestions={onResolveSuggestions}
                    getTextFromItem={getTextFromItem}
                    pickerSuggestionsProps={pickerSuggestionsProps}
                    removeButtonIconProps={{ iconName: 'Delete' }}
                    pickerCalloutProps={{ doNotLayer: true }}
                    styles={rootClass}
                    onChange={onChange}
                    defaultSelectedItems={itensTag}
                    onEmptyInputFocus={filterPromise}
                    itemLimit={1}
                />
            )}       
            <br/>
            <label className={titlePicker}>{t("permissionFolder.message")}</label>
            <Pivot linkFormat="links"
                style={{
                    margin:"5px"
                }}
                styles={{
                    linkIsSelected:{
                        backgroundColor:"rgb(17, 77, 136)",
                        color:"rgb(248, 248, 248)",
                        selectors: {
                            ':before': {
                            height: '0px',
                            }
                        }
                    },
                        link:{
                        border:"1px solid rgb(17, 77, 136)",
                        backgroundColor:"rgb(248, 248, 248)",
                        color:"rgb(17, 77, 136)",
                        selectors: {
                            ':hover': {
                            backgroundColor:"#ebebeb",
                            }
                        }
                    }
                }}
            >
                <PivotItem headerText="Groups" itemIcon="People" className={styles.Btns}>
                    <div className={styles.container} data-is-scrollable>
                        {itemsGroup.length > 0?(
                        <List
                            componentRef={listRef}
                            items={itemsGroup}
                            onRenderCell={onRenderCell}
                            className={styles.listPermissions}
                        />
                        ):(
                            <h4>{t("Empity.message")}</h4>
                        )}
                    </div>
                </PivotItem>
                <PivotItem headerText="Users" itemIcon="Contact" className={styles.Btns}>
                    <div className={styles.container} data-is-scrollable>
                        {itemsUser.length > 0?(
                            <List
                                componentRef={listRef}
                                items={itemsUser}
                                onRenderCell={onRenderCell}
                                className={styles.listPermissions}
                            />   
                        ):(
                            <h4>{t("Empity.message")}</h4>
                        )}                                    
                    </div>
                </PivotItem>
            </Pivot>

            <Btn text={t("Salvar.message")} onClick={UpdateOwner} disabled={wait}/>
            {wait && (
                <LoaderComp/>
            )}
            
            <Btn text={t("Upload.permission")} menuProps={{
                shouldFocusOnMount: true,
                items:[
                    {
                    key:"group",
                    text:t("Upload.permissionGroup"),
                    onClick: permissionGroup
                    },
                    {
                    key:"user",
                    text:t("Upload.permissionUser"),
                    onClick: permissionUser
                    }
                ]
            }}/>
        </div>
    )

}

export default EditFolder;

