import { Box } from '@mui/material';

interface IDocumentDetailTabTablesWrapper
{
    children: React.ReactNode;
}

export default function DocumentDetailTabTablesWrapper({
    children
}: IDocumentDetailTabTablesWrapper)
{
    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}>
            { children }
        </Box>
    )
}