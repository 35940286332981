import React, { useEffect, useRef } from 'react';
import { renderTextLayer } from 'pdfjs-dist';
import styles from "../PDFPageViwer.module.css";
import * as pdfjs from 'pdfjs-dist';
import { GetViewportParameters, TextContent, TextItem, TextMarkedContent } from 'pdfjs-dist/types/src/display/api';

interface PageViewerProps {
  canRender: boolean;
  pdfPage: pdfjs.PDFPageProxy;
  scale: number;
  setTextPdfList: React.Dispatch<React.SetStateAction<(HTMLCollection | Element[] | undefined)[]>>;
}

const PageViewer: React.ForwardRefExoticComponent<PageViewerProps> = 
React.forwardRef(({
  canRender, pdfPage, scale, setTextPdfList
}, ref) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const textRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const loadText = async () => {
      const textDiv = textRef.current;

      if (!textDiv) {
        setTimeout(loadText, 100);
        return;
      }
    
      const scaleViewer: GetViewportParameters = {
        scale
      };
      const viewport = pdfPage.getViewport(scaleViewer);

      textDiv.style.width = `${viewport.width}px`;
      textDiv.style.height = `${viewport.height}px`;

      const parentContainer = textDiv.parentElement;
      if (parentContainer) {
        parentContainer.style.setProperty("--scale-factor", `${scale}`);
      }

      pdfPage.getTextContent().then(textContent => {
        let itemsTextContent: (TextItem | TextMarkedContent)[] = [];

        textContent.items.forEach(item => {
          var wordList = (item as TextItem).str.split(/(\s+)/);
  
          if (wordList.length > 1) {
              const charCount = wordList.reduce((accumulator, currentValue) => accumulator + currentValue.length, 0);
              const totalWidth = (item as TextItem).width;
              const spacePerChar = totalWidth / charCount;
  
              wordList.forEach((word, index) => {
                  const wordTransform = (item as TextItem).transform.slice();
                  const wordWidth = word.length * spacePerChar;
  
                  if (index > 0) {
                      wordTransform[4] += wordList.slice(0, index).reduce((acc, curr) => acc + curr.length, 0) * spacePerChar;
                  }
  
                  const textContentItem: TextItem = {
                      str: word,
                      dir: (item as TextItem).dir,
                      transform: wordTransform,
                      width: wordWidth,
                      height: (item as TextItem).height,
                      fontName: (item as TextItem).fontName,
                      hasEOL: (item as TextItem).hasEOL
                  };
  
                  itemsTextContent.push(textContentItem);
              });
          } else {
              itemsTextContent.push(item);
          }
      });

        const textContentPerWord: TextContent = {
          items: itemsTextContent,
          styles: textContent.styles
        }
        
        const renderText: pdfjs.TextLayerRenderTask = renderTextLayer({
          textContentSource: textContentPerWord,
          container: textDiv,
          viewport: viewport,
          textDivs: [],
        });
  
        setTextPdfList(prev => [...prev, renderText._textDivs])
  
        return () => {
          renderText.cancel();
        };
      })
    }

    loadText();
  }, [pdfPage, scale]);

  useEffect(() => {
    const renderPage = async () => {
      const canvas = canvasRef.current;

      if (!canvas) {
        setTimeout(renderPage, 100);
        return;
      }
    
      const scaleViewer: GetViewportParameters = {
        scale
      };
      const viewport = pdfPage.getViewport(scaleViewer);

      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      if (context) {
        const renderContext = {
          canvasContext: context,
          viewport: viewport
        };

        const renderTask = pdfPage.render(renderContext);
        renderTask.promise.then().catch((error) => {
          console.error('Error while rendering:', error, " pdfPage:", pdfPage.pageNumber);
        });
      }
    }

    if(canRender){
      renderPage();
    }
  }, [canRender, pdfPage, scale]);
  
  return (
    <div>
        <div>
          <div className={styles.pageState}>
            <canvas ref={canvasRef}/>
            <div ref={textRef} className={styles.PdfPage__textLayer}/>
          </div>
        </div>
    </div>
  );
})

export default PageViewer;