import { DownloadViwer } from "../../../../../services/Item/ItemServicesGet";
import { ParameterViwer } from "../../../../WorkSpaceItem/Viwer/ParameterViwer";
  
export function downloadViwerFile(id: string, name: string, extension:string, parameter:ParameterViwer, setMessage: (set: string) => void, t: (set: string) => string) {
    if (name) {
      DownloadViwer(id!,parameter)
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name + "." + extension);
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
          setMessage(t("DownloadSuccess.message"));
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            typeof error.response.data === "string"
          ) {
            let blob = new Blob([error.response.data]);
            blob
              .text()
              .then((message) => {
                setMessage(message);
              })
              .catch(() => {
                setMessage(t("DownloadFileError.message"));
              });
          } else {
            setMessage(t("DownloadFileError.message"));
          }
        });
    }
  }