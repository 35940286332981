import React from "react";
import {
    MessageBar, MessageBarType, IMessageBarStyleProps, IMessageBarStyles
} from "@fluentui/react";

export interface MessageConfig{
  text: string;
  type: MessageBarType;
  style?: React.CSSProperties | undefined;
}

const Message = (props: MessageConfig) => {
  return(
    <div
      style={props.style}
    >
          <MessageBar delayedRender={false}
            messageBarType={props.type}
            role='alert'
            styles={MessageBarStyles}
          >
          {props.text}
          </MessageBar>
      </div>
  )
}

export default Message;

export const MessageBarStyles = (props: IMessageBarStyleProps): Partial<IMessageBarStyles> => {
    const { messageBarType } = props;

    return {
      root: {
        borderRadius: '5px'
      },
    };
  };
