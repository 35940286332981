import { useEffect, useState } from "react";
import { TemporalityConfig } from "../../pages/WorkSpaceItem/PanelItens/TemporalityDocument";
import { CopyFolderStructureDrawerParameters } from "../../pages/WorkSpaceItem/PanelItens/CopyFolderStructureDrawer";

export interface IPanelWidthProps {
    idFileRevision: string | undefined;
    temporalityConfig: TemporalityConfig | undefined;
    copyFolderStructureDrawerParameters: CopyFolderStructureDrawerParameters | undefined;
}

export interface IWidthProps {
    commonWidth: string;
}

export const usePanelWidth = (panelProps: IPanelWidthProps) => {
    const [width, setWidth] = useState<IWidthProps>({ commonWidth: "340px" })

    useEffect(() => {
        if (panelProps.copyFolderStructureDrawerParameters == undefined 
            && panelProps.idFileRevision == undefined && panelProps.temporalityConfig != undefined) {
                setWidth({ commonWidth: "35% !important" })
        } else {
            setWidth({ commonWidth: "340px" })
        }
    }, [panelProps.copyFolderStructureDrawerParameters, panelProps.idFileRevision, panelProps.temporalityConfig])

    return { width }
}