import { Api } from "./api";
import { TagReturn,TagsVO } from "../model/Tags";

export async function GetAllTags(workspaceId:string,skip:number, limit:number, name: string, outTags: string[]) {
  let outTagsString: string ="";
  let first:boolean = true;
  
  if(outTags.length <= 0){
    outTagsString = "?outTags="
  }
  for(let i:number = 0;i<outTags.length;i++)
  {
      if(first)
      {
        outTagsString = outTagsString + "?outTags=" + outTags[i].toString();
        first = false;
      }
      else
      {
        outTagsString = outTagsString + "&outTags=" + outTags[i].toString();
      }
  }

    var request = await Api.get<TagReturn[]>("Tag/"+ workspaceId + outTagsString + "&skip=" + skip + "&limit=" + limit +"&name=" + name)

    return request.data; 

}
