import styled from 'styled-components'

export const Handle = styled.div((props) => ({
  backgroundColor:"#FBFBFB",
  bottom: 0,
  cursor: 'ew-resize',
  display: 'inline-block',
  height: "100%",
  right: 0,
  width: "15px",
}))
