import { Box } from "@mui/material";
import { SidebarContent } from "react-pro-sidebar";
import LoaderComp from "../../../../../../../components/Loader/LoaderComp"; 
import styles from '../../../../../Documentdetail.module.css';
import { GetFlowInformationByItemVO } from "../../../../../../../model/ItemModel";
import { WorkspaceItemFlowListing } from "../../../../../../WorkSpaceItem/FlowFolder/WorkspaceItemFlowListing";

interface IFlowSidebarContent 
{
    isSidebarCollapsed: boolean;
    isLoading: boolean;
    informationFlowByVO: GetFlowInformationByItemVO | undefined
}

export default function FlowSidebarContent({
    isSidebarCollapsed,
    isLoading,
    informationFlowByVO
}: IFlowSidebarContent)
{
    return (
        <SidebarContent className={styles.sideBarContent}>
            { !isSidebarCollapsed && (
                <>
                    { isLoading ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%',                
                            }}
                        >
                            <LoaderComp />
                        </Box>
                    ) : (
                        <Box sx={{marginLeft:"80px", width:"100%"}}>
                            <WorkspaceItemFlowListing 
                                getFlowInformationByItemVO={informationFlowByVO}
                            />
                        </Box>
                    ) }
                </>
            ) }
        </SidebarContent>
    )
}