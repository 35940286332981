import DashboardHeaderShimmer from './components/DashboardHeaderShimmer/DashboardHeaderShimmer';
import DashboardGeneralDataShimmer from './components/DashboardGeneralDataShimmer/DashboardGeneralDataShimmer';

export default function DashboardShimmer() {
    return (
        <>
            <DashboardHeaderShimmer />
            <DashboardGeneralDataShimmer />
        </>
    )
}