import React, {useState,useEffect} from "react";
import { initializeIcons, MessageBarType, TextField } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { ResetPassword } from "../../../services/UserServices";
import { PasswordReset } from "../../../model/UserModel";
import forgetPasswordIcon from '../../../assets/icons/esqueceu-sua-senha.svg'
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FormErrors from "../../PublicLayout/components/Handle/Form/Root/components/FormErrors";
import FormButton from "../../PublicLayout/components/Handle/Form/Root/components/FormButton";
import BtnBigWithIcon,{ ButtonTyppe} from "../../../components/Button/BtnBigWithIcon/BtnBigWithIcon";
import ReCAPTCHA from "react-google-recaptcha";
import { CenterForm } from "../../PublicLayout/components/Handle/CenterForm";
import { LoaderIcon } from "../../../assets/icons/loaderIcon";
import { ArrowLeftIcon } from "../../../assets/icons/ArrowLeftIcon";
import { checkIcon } from "../../../assets/icons/checkIcon";
import { textFieldStyles } from "../../Style/TextfieldStyles";

initializeIcons();

const ResetPasswordComp = (props: any) => {
    const key = require("../../../services/key.json");
    
    const [isChecked, setCheckedState] = useState<boolean>(false);
    const { t, i18n } = useTranslation();
    const [load, setLoad] = useState<boolean>(false);
    const [lg, setLanguage] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const navigate = useNavigate();

    const [messagePass, setMessagePass] = useState<string>("");
    const [typeMessagePass, setTypeMessagePass] = useState<MessageBarType>(MessageBarType.error);

    const recaptchaRef = React.useRef(null);

    const regex = new RegExp("([!#-'+/-9=?A-Z^-~-]+(.[!#-'+/-9=?A-Z^-~-]+)|'([]!#-[^-~ \t]|(\[\t -~]))+')@([!#-'+/-9=?A-Z^-~-]+(.[!#-'+/-9=?A-Z^-~-]+)|[[\t -Z^-~]*])");

    const onChangeEmail =
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined ) {
            setEmail(newValue);
        }
        else
        {
            setEmail("");
        }
    };

    const handleSubmit = (event: any) => {
        if(!regex.test(email)){
            setTypeMessagePass(MessageBarType.error);
            setMessagePass(t("InvalidEmail.message"));
            setCheckedState(true);
            return
        };

        if(!captchaToken){
            setTypeMessagePass(MessageBarType.error);
            setMessagePass(t("InvalidCaptcha.message"));
            setCheckedState(true);
            return
        };

        let reset: PasswordReset = {
            login: email,
            captchaToken: captchaToken
        };
        
        setLoad(true)
        ResetPassword(reset)
        .then(()=>{    
            navigate('/emailSendSuccess');
        })
        .catch((error)=>{
            setTypeMessagePass(MessageBarType.error)
            setMessagePass(error.response.data);
            setCheckedState(true);
        })
        .finally(() => {
            setLoad(false)
        })
    }

    useEffect(() => {
        var lgn = localStorage.getItem("i18nextLng");
        if(lgn == "pt-BR"){
            setLanguage(true);
        }
        else{
            setLanguage(false);
        }

    })

    const login = (event: any) => {
        navigate("/login");
    };
    
    return (
        <CenterForm 
        title={t("Login.forgotYourPass")} 
        subtitle={t("Login.fillTheEmailRegistered")}
        icon={forgetPasswordIcon}
        titleSize={32} 
        >
            <Grid container sx={{
                    gap: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                <Grid item xs={12}>
                    <TextField
                        label="E-mail"
                        onChange={onChangeEmail}
                        value={email}
                        styles={textFieldStyles}
                        placeholder={t("Login.typeYourEmail")}
                        onFocus={() => {
                            setCheckedState(false);
                        }}
                    />
                </Grid>
                    <Box>
                        <FormErrors
                            messagePass={messagePass}
                            isChecked={isChecked}
                        />
                    </Box>
                    <Box margin={'0px auto'}>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={key.siteKey}
                            onChange={(token) => setCaptchaToken(token)}
                        />
                    </Box>
                    <FormButton 
                        load={false}
                        btnBigWithIconConfig={{
                            text: t("Login.confirm"),
                            onClick:handleSubmit,
                            icon: load == true ? LoaderIcon : checkIcon,
                            disabled: email.length > 0 ? false : true,
                        }}
                    />
                    <BtnBigWithIcon 
                        isPrimary={ButtonTyppe.secondary}
                        text={t("IrParaLogin.message")}
                        onClick={login}
                        isTextButton
                        icon={ArrowLeftIcon}
                        iconGap={'10px'}
                    />
                </Grid>
        </CenterForm>
    );
}

export default ResetPasswordComp;