import {LeftHandler} from "./LeftHandler"
import FormRoot, {FormRootInstance} from "./Form/Root/FormRoot"

interface LeftFormProps {
    children: any;
    formRootInstance: FormRootInstance
}

export function LeftForm({
    children,
    formRootInstance
}: LeftFormProps) {

    return (
        <LeftHandler>
            <FormRoot
                instance={formRootInstance}
            >
                { children }
            </FormRoot>
        </LeftHandler>
    )
}