import { useRef, useEffect } from 'react';
import { Box, Tab } from '@mui/material';
import TabList from '@mui/lab/TabList';
import { useTranslation } from 'react-i18next';

interface IDocumentDetailTitleTabs
{
    handleTabChange: (event: React.SyntheticEvent, newValue: string) => void;
    isLoading: boolean;
    isEditorCannotSeeRevision: boolean;
}

export default function DocumentDetailTitleTabs({
    handleTabChange,
    isLoading,
    isEditorCannotSeeRevision
}: IDocumentDetailTitleTabs)
{
    const { t } = useTranslation();
    return (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} sx={{
                paddingLeft: '66px'
            }}>
                <Tab label={t("DetailDocumentPage.Visualization")} value="1" />
                <Tab label={t("DetailDocumentPage.ActivityTracker")} value="2" />
                <Tab label={t("DetailDocumentPage.Location")} value="3" />
                { !isEditorCannotSeeRevision && (<Tab label={t("DetailDocumentPage.Reviews")} value="4" />) }
            </TabList>
        </Box>
    )
}