export const textFieldStyles ={ 
    wrapper: {
    '.ms-Label': {
        font: 'normal normal 600 12px/18px Segoe UI',
    }
},
    fieldGroup: {
        background: "#FBFBFB",
        border: "0px",
        borderBottom: '1px solid #9F9F9F',
        opacity: 1,
        height: '30px',
        borderRadius: '0px',
        "::after": {
            outline: "none",
            borderColor: 'transparent'
        },
        "input": {
            padding: '0px 0px'
        },
    }}