import stylesList from "./WorkSpaceItemList.module.css";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { IIconProps, IconButton } from "@fluentui/react";

const chevronRightMedIcon: IIconProps = {
    iconName: 'ChevronRightMed', 
    style: {
        fontSize: "12px"
    } 
};

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#818181",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#818181",
      borderRadius: "8px",
      padding: "8px"
    },
  }));
interface PathListMapProps {
    name: string 
    id: string 
    onClickMap: (idFolder:string | undefined, nameFolder:string | undefined) => void 
    listPathLength: number
    index: number
}

const renderAnchorElements = (name: string, id: string, onClickMap: (idFolder:string | undefined, nameFolder:string | undefined) => void , listPathLength: number, index: number) => {

    const isUndefined = listPathLength > 1 && id === undefined ? undefined : id;
    const isLast:boolean = (index + 1) == listPathLength;

    return (
        <div className={stylesList.fatherPathList}>
            <CustomTooltip title={name} placement="top" disableInteractive>
                <a id={id} onClick={() => onClickMap(isUndefined, name)} className={isLast ? stylesList.lastTextPathist : stylesList.PathListItem}>
                    { name } 
                </a>
            </CustomTooltip>
            {(isLast == false) && (
                <IconButton
                    iconProps={chevronRightMedIcon}
                    title="ChevronRightMed"
                    ariaLabel="ChevronRightMed"
                    disabled={true}
                    className={stylesList.buttonIcon}
                />
        )}
        </div>
    )
} 

export const PathListMap: React.FC<PathListMapProps> = ({ name, id, onClickMap, listPathLength, index }) => {

    return (
        <>
            { renderAnchorElements(name, id, onClickMap, listPathLength, index) }
        </>
    )

}

