import { Typography } from "@mui/material"
import { GetFlowInformationByItemVO } from "../../../../model/ItemModel"
import { ValidationRuleVOActivityRetrieve } from "../../../../model/ValidationRuleModel";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IWorkspaceFlowListingResponsability {
    flowInformation: GetFlowInformationByItemVO;
    validationRetrieve: ValidationRuleVOActivityRetrieve;
    index: number;
}


export const WorkspaceFlowListingResponsability: React.FC<IWorkspaceFlowListingResponsability> = ({
    flowInformation,
    validationRetrieve,
    index
}) => {

    const [dynamicUser, setDynamicUser] = useState<boolean>(false);
    const { t } = useTranslation();

    const handleDynamicUser = () => {
        if (flowInformation.activity![index].dinamicUserChoice) {
            setDynamicUser(true)
        }
    }

    useEffect(() => {
        handleDynamicUser();
    }, [])

    return (
        <Typography sx={{ opacity: "60%" }}>
            { 
                validationRetrieve.responsability != undefined && validationRetrieve.responsability.length > 0 ?
                validationRetrieve.responsability[0].nameObject : 
                dynamicUser ? 
                flowInformation.activity![index].preConditionUser?.map(user => user) :
                t("WorkspaceItemFlowListing.dynamic")
            }
        </Typography>
    )
}