import { Box, Button, Fade, Grid, InputAdornment, TextField } from "@mui/material";
import DocumentDetailTabFilterButton from "../DocumentDetailTabFilterButton/DocumentDetailTabFilterButton";
import { useEffect, useState } from "react";
import { GetTrackerWithFilters, getEnumOrder } from "../../../../../../../../../services/Item/ItemServicesGet";
import { WorkSpaceItemRevision } from "../../../../../../../../../model/ItemModel";
import { convertItemFunction } from "../utils/convertItemFunction";
import { useTranslation } from "react-i18next";
import DocumentDetailFilterRw, { FilterOptions } from "../DocumentDetailTabMenuFilter/DocumentDetailFilter/DocumentDetailFilterRw/FilterRw";

interface IDocumentDetailTabFilterReviews {
    setItemRevision: React.Dispatch<React.SetStateAction<WorkSpaceItemRevision[] | undefined>>;
    itemId: string;
    persistedItemRevision: WorkSpaceItemRevision[] | undefined;
    setRevisionIdListReview: React.Dispatch<React.SetStateAction<string[]>>;
    revisionIdListReview: string[];
    revisionLoading: boolean;
    setSnackBarMessage: (set: string) => void;
}


export default function DocumentDetailTabFilterReviews({
    setItemRevision,
    itemId,
    persistedItemRevision,
    revisionIdListReview,
    setRevisionIdListReview,
    revisionLoading,
    setSnackBarMessage
}: IDocumentDetailTabFilterReviews) {

    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [itemRevisionOriginalState, setItemRevisionOriginalState] = useState<WorkSpaceItemRevision[]>([]);
    const [ascDescState, setAscDescState] = useState<string | number | null>(null);
    const [reviewState, setReviewState] = useState<string | number | null>(null);
    const [enumOrder, setEnumOrder] = useState<FilterOptions<string | number>[]>();
    const { t } = useTranslation();

    useEffect(() => {
        getEnumOrder()
        .then(data => {
            let orderEnums: FilterOptions<string | number>[] = [];
            data.map(odEnum => {
                orderEnums.push({ label: odEnum.name, value: odEnum.index });
            })
            setEnumOrder(orderEnums);
        })
        .catch(error => {
            setSnackBarMessage(error.data.message)
        })
        setItemRevisionOriginalState(persistedItemRevision!);

        return () => {
            setItemRevision(persistedItemRevision);
        }
    }, [])

    useEffect(() => {
        if (revisionIdListReview.length <= 0) {
            setReviewState(null);
        }
    }, [revisionIdListReview.length])
    
    const dateOrdenateOptions: FilterOptions<string | number>[] = [
        { label: t("FilterTables.Descending"), value: 3 },
        { label: t("FilterTables.Ascending"), value: 4 },
    ]

    const enumerateReviews = itemRevisionOriginalState?.map(reviews => ({ label: reviews.enumerate?.toString()!, value: reviews.id! }));
    const reviewOptions: FilterOptions<string | number>[] =  enumerateReviews!;
    
    function clearFilters() {
        setAscDescState(null);
        setReviewState(null);
        setRevisionIdListReview([]);
        setItemRevision(persistedItemRevision);
    }

    function handleDateOrdenateOptions(value: string | number) {
        GetTrackerWithFilters(itemId, Number(value), revisionIdListReview, true)
        .then(data => {
            convertItemFunction(data, setItemRevision, (revision) => {
                return {
                    enumerate: revision.enumerate!,
                    description: revision.description!,
                    itemName: revision.nameItem!,
                    draftDate: revision.dateTracker!
                }
            })
        })
        .catch(error => {
            setSnackBarMessage(error.response.data)
        })
    };

    function handleReview(value: string | number) {
        GetTrackerWithFilters(itemId, 0, revisionIdListReview, true)
        .then(data => {
            convertItemFunction(data, setItemRevision, (revision) => {
                return {
                    enumerate: revision.enumerate!,
                    description: revision.description!,
                    itemName: revision.nameItem!,
                    draftDate: revision.dateTracker!
                }
            })
        })
        .catch(error => {
            setSnackBarMessage(error.response.data)
        })
    };

    if (revisionLoading) 
        return <></>

    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: !showFilters ? 'flex-end' : 'space-between',
            marginBottom: '15px'
        }}>
            { showFilters && (
                <Fade in={showFilters} mountOnEnter>
                    <Box sx={{
                        width: '100%'
                    }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8} sx={{
                            display: 'flex',
                            gap: 2
                        }}>

                            <DocumentDetailFilterRw 
                                label={t("FilterTables.OrdenationDate")} 
                                options={dateOrdenateOptions} 
                                onFilterChange={handleDateOrdenateOptions}
                                selectedValue={ascDescState}
                                setSelectedValue={setAscDescState}
                                clearFilters={clearFilters}
                                setRevisionIdListReview={setRevisionIdListReview}
                                revisionIdListReview={revisionIdListReview}
                            />

                            <DocumentDetailFilterRw 
                                label={t("FilterTables.Reviews")} 
                                options={reviewOptions} 
                                onFilterChange={handleReview}
                                selectedValue={reviewState}
                                setSelectedValue={setReviewState}
                                clearFilters={clearFilters}
                                isReview
                                setRevisionIdListReview={setRevisionIdListReview}
                                revisionIdListReview={revisionIdListReview}
                            />
                            <Button
                                variant='text'
                                sx={{
                                    font: "normal normal 600 16px/24px Segoe UI",
                                    letterSpacing: "0px",
                                    color: "#005A9E",
                                    opacity: 0.4,
                                    transition: 'all 0.3s',
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                    ':hover': {
                                        opacity: 1,
                                        textDecoration: 'underline',
                                    }
                                }}
                                onClick={clearFilters}
                            >
                                {t("FilterTables.clearFilters")}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                </Fade>
            ) }
            <DocumentDetailTabFilterButton 
                setShowFilters={setShowFilters}
                showFilters={showFilters}
                isLoading={revisionLoading}
                onClick={clearFilters}
            />
        </Box>
    )
}