import { createUseStyles } from "react-jss";

export const useInputLayout = createUseStyles({
  myDiv: {
    width: "55px",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    zIndex: 1,
    justifyContent: "end",
    right: "10px",
    top: "10px",
  },
  default: {
    width: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    zIndex: 1,
    justifyContent: "start",
    marginTop: "10px",
    marginLeft: "4px",
    // left: "4px",
  }
})
