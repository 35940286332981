import { ITag } from '@fluentui/react'
import styles from './styles/painel.module.css'
import LoaderComp from '../Loader/LoaderComp'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface ButonSendBoneProps {
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    waitLoader: boolean,
    text:string
}


export const ButonSendBone: React.FC<ButonSendBoneProps> = ({
    onClick,
    waitLoader,
    text
  }) => {
    const { t } = useTranslation()

    return (
        <div style={{
            width: '480px',
            display: 'flex',
            justifyContent: 'center',
          }}>
            <button className={styles.sendButton} onClick={onClick}>
              { waitLoader ? (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <LoaderComp panel />
                </Box>
                ) : (
                <>
                    <span className={styles.sendButtonText}>{text}</span>
                </>
                ) }
            </button>
          </div>
    )
}