import React, { useState, useEffect, useRef } from "react";
import { IIconProps } from '@fluentui/react';
import { IconButton } from '@fluentui/react/lib/Button';
import styles from "./FileUploader.module.css";
import { useUpload } from "../../context/UploadProvider/useUpload";
import FileSwith from "./FileSwith/FileSwith";


export const FileUploader : React.FC = () => {

  const upload = useUpload();
  const [lastIsDone, setLastIsDone] = useState<number|undefined>(undefined);
  const [childrenIdUpload, setChildrenIdUpload] = useState<number|undefined>(undefined);

  function uploadDone()
  {
    if(childrenIdUpload != undefined)
    {
      setChildrenIdUpload(childrenIdUpload + 1);
    }
  }

  const asc: IIconProps = {
    iconName: 'Favicon',
    className: styles.icon,
  };

  const desc: IIconProps = {
    iconName: 'ChromeMinimize',
    className: styles.icon,
  };

  const ascClick = () => {
    upload.setShowEx(true)
  };

  const descClick = () => {
    upload.setShowEx(false)
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      let doIt = true;
      let i:number = 0;
      for(i = 0;i<myRefs.current.length;i++)
      {
        if(myRefs.current[i].fileIsDone() == false)
        {
          doIt = false;
        }
      }

      if(doIt)
      {
        console.log("limpo");
        upload.clear();
        if(upload.fileList == undefined || (upload.fileList!= undefined && upload.fileList.length == 0))
        {
          setLastIsDone(undefined);
        }
      }
      else
      {
        console.log("nao limpo");
      }
    }, 30000)
  
    return () => clearTimeout(timer)
  }, [lastIsDone])

  const myRefs = useRef<any[]>([]);

  useEffect(() => {
    if(childrenIdUpload == undefined && upload.fileList != undefined && upload.fileList.length > 0)
    {
      setChildrenIdUpload(0);

      myRefs.current = myRefs.current.slice(0, upload.fileList.length);
    }
    else
    {
      setChildrenIdUpload(undefined);
      myRefs.current = myRefs.current.slice(0, 0);
    }

 }, [upload.fileList]);

  

  return (
    <div
      className={
        (upload.fileList != undefined && upload.fileList.filter(x => x != undefined).length > 0)? `${styles.fileUploader} ${styles.visible}`:`${styles.invisible}`
      }
      style={{
        maxHeight:"100%",
        overflowY:"scroll"
      }}
    >
      <div>
        Upload
        {upload.show?
            (<IconButton iconProps={desc} onClick={descClick} style={{float:"right"}}/>):
            (<IconButton iconProps={asc} onClick={ascClick}/>)}
      </div>
        <div 
          className={ upload.show ? styles.visible:styles.invisible}
        >
          {upload.fileList != undefined &&(
            upload.fileList.map((file, index) => (
              <FileSwith 
                ref={el => myRefs.current[index] = el}
                file={file} 
                key={index}
                index={index}
                setLastIsDone={setLastIsDone}
                indexToUpload={childrenIdUpload}
                uploadDone={uploadDone}
              />
            ))
          )}
        </div>
    </div>
  );
};
