import React, { useEffect, useState } from "react";
import { Link, Tooltip, Typography } from "@mui/material";
import { UseStatus, UseStatusWithCurrentOperation } from "./hooks/useStatus";
import { ItemValidationRule } from "../../model/ItemModel";
import LoaderComp from "../Loader/LoaderComp";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { DraftStatusIcon } from "../../assets/icons/DraftStatusIcon";
import { CheckStatusIcon } from "../../assets/icons/CheckStatusIcon";
import { ArchivedStatusIcon } from "../../assets/icons/ArchivedStatusIcon";
import { WorkFlowStatusIcon } from "../../assets/icons/WorkFlowStatusIcon";
import { FontSizes } from "@fluentui/react";
import { SignedStatusIcon } from "../../assets/icons/SignedStatusIcon";

const statusStyle = {
    '&:hover': {
        cursor: 'pointer'
    }

}

interface IStatus {
    onClick?: () => void;
    release: boolean;
    validationObject: ItemValidationRule | undefined;
    type: string;
    batchWaiter?: boolean;
    archiving?:boolean;
    expired?:boolean;
    draft?: boolean;
    d4Signed?: boolean;
}

const Status: React.ForwardRefExoticComponent<IStatus> = React.forwardRef(({
    onClick,
    release,
    type,
    validationObject,
    batchWaiter,
    archiving,
    expired,
    draft,
    d4Signed
}, ref) => {

    const currentStatus = UseStatus(release,validationObject, type, batchWaiter,archiving, expired, d4Signed);

    const currentOperation = UseStatusWithCurrentOperation(release, validationObject, type, batchWaiter, archiving, expired, d4Signed).currentOperation;

    function tooltipTitleRender(): string {
        return currentOperation !== undefined ? `${currentStatus}` : currentStatus
    }

    function returnStatusIcon () {
        switch (currentStatus) {
            case "Filed": 
                return <ArchivedStatusIcon />
            case "Release":
                return <CheckStatusIcon />
            case "Flow":
                return <WorkFlowStatusIcon />
            case "Draft":
                return <DraftStatusIcon />
            case "Removido":
                return <RiDeleteBin2Fill />
            case "Signed":
                return <SignedStatusIcon />
            default:
                return null
        }
    }

    return (
        <Link
            onClick={type !== 'folder' ? onClick : undefined}
            underline="none"
            sx={statusStyle}
        >
            <Tooltip title={tooltipTitleRender()}>
                <span style={{display: 'flex', justifyContent:'center', alignItems:'center', height: '100%'}}>
                {returnStatusIcon()}
                </span>
            </Tooltip>
        </Link>
    )
});

export default Status;