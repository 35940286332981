import {Color} from "./interface/Color"

export const Group: React.FC<Color> = ({
    color
  }) => {
    return(
        <svg width="31.429" height="20" viewBox="0 0 31.429 20">
            <path 
                id="Caminho_10" 
                data-name="Caminho 10" 
                d="M22.429,13.571a4.286,4.286,0,1,0-4.286-4.286A4.268,4.268,0,0,0,22.429,13.571ZM11,13.571A4.286,4.286,0,1,0,6.714,9.286,4.268,4.268,0,0,0,11,13.571Zm0,2.857c-3.329,0-10,1.671-10,5V25H21V21.429C21,18.1,14.329,16.429,11,16.429Zm11.429,0c-.414,0-.886.029-1.386.071a6.028,6.028,0,0,1,2.814,4.929V25h8.571V21.429C32.429,18.1,25.757,16.429,22.429,16.429Z" 
                transform="translate(-1 -5)" 
                fill={color}
            />
        </svg>

    )
  }