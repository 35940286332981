import { useEffect } from 'react';
import { MenuConfig } from "../../../../../../../../components/Menu/MenuConfig";
import { WorkSpaceUserName } from "../../../../../../../../model/ItemModel";
import { IShowInfo } from "../../../../../../DocumentDetail"
import ViwerInfo from "../../../../components/ViwerInfo/ViwerInfo";
import DocumentDetailViwer from "../DocumentDetailViwer/DocumentDetailViwer";
import { Box } from '@mui/material';
import { ParameterViwer } from '../../../../../../../WorkSpaceItem/Viwer/ParameterViwer';
import { ModalStyled } from '../../../../../../../../components/StyledModal/StyledModal';
import { ViewerOptions } from '../../../../../../../WorkSpaceItem/Viwer/ChoicerViwer';

interface IDocumentDetailViwerSwitch {
    showViwerInfo: IShowInfo;
    workItem: WorkSpaceUserName;
    setShowViwerInfo: (set: IShowInfo) => void;
    isSidebarCollapsed: boolean;
    setSnackBarMessage: (set: string) => void;
    itemName: string;
    itemExtension: string;
    fullScreenSetted: boolean;
    parameter:ParameterViwer;
}

export default function DocumentDetailViwerSwitch({
    setShowViwerInfo,
    showViwerInfo,
    workItem,
    isSidebarCollapsed,
    setSnackBarMessage,
    itemName,
    itemExtension,
    fullScreenSetted,
    parameter
 }: IDocumentDetailViwerSwitch) {

    useEffect(() => {
        setShowViwerInfo({ ...showViwerInfo, value: '1' });
    }, [])

    return (
        <>
            { showViwerInfo.isShown ? (
                <ModalStyled
                    fullScreenSetted={fullScreenSetted}
                    isSidebarCollapsed={isSidebarCollapsed}
                    isNoViwer
                    showViwerInfo={showViwerInfo}
                    viewerOptions={ViewerOptions.NoViewer}
                    isAcept={false}
                    isModalViwer={false}
                    isSharedFilePage={false}
                >
                    <ViwerInfo 
                        itemId={workItem?.id!}
                        setSnackBarMessage={setSnackBarMessage}
                        itemExtension={itemExtension}
                        itemName={itemName}
                        parameter={parameter}
                    />
                </ModalStyled>
            ) : (

                    <DocumentDetailViwer 
                        workItem={workItem}
                        setShowViwerInfo={setShowViwerInfo}
                        isDetailPage={true}
                        fullScreenSetted={fullScreenSetted}
                        isSidebarCollapsed={isSidebarCollapsed}
                    />
            ) }
        </>
    )
}