import React, { useState, useEffect } from "react";
import { ITextFieldStyles } from "@fluentui/react/lib/TextField";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import { useParams } from "react-router-dom";
import { useProfile } from "../../context/ProfileProvider/useProfile";
import WorkSpaceItemListFolder from "./WorkSpaceItemListFolder";
import { useTranslation } from "react-i18next";
import styles from "./WorkSpaceItemList.module.css";
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import { useBoolean } from "@fluentui/react-hooks";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertColor } from "@mui/material/Alert";
import { useUpload } from "../../context/UploadProvider/useUpload";
import { Delete } from "../../services/Item/ItemServicesCRUD";
import { Dialog, DialogType, DialogFooter, DialogContent } from "@fluentui/react/lib/Dialog";
import Btn from "../../components/Button/Btn";
import WorkSpaceItemListFileRevisionComponent from "./WorkSpaceItemRevisionComp";
import EditFolder, { EditFolderOpen } from "./PanelItens/EditFolder";
import LoaderComp from "../../components/Loader/LoaderComp";
import MoveByClick from "./PanelItens/MoveByClick";
import RenameComp, { RenameConfig } from "./PanelItens/RenameComp";
import CreateFolderComp, { NewFolderConfig } from "./PanelItens/CreateFolderComp";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ShareFile, { ShareFileConfig } from "./PanelItens/ShareFile/ShareFileComp";
import { FlowProps, WorkspaceItemFlowComponent } from "./FlowFolder/WorkspaceItemFlowComponent";
import stylesTimeLine from './FlowFolder/WorkspaceItemFlow.module.css'
import { Box } from "@mui/material";
import {WorkSpaceItemListFileConfigSearch} from "./WorkSpaceItemListFile";
import {useSearchParams } from "react-router-dom";
import ValidateDocument, { TemporalityConfig } from "./PanelItens/TemporalityDocument";
import CopyFolderStructureDrawer, { CopyFolderStructureDrawerParameters } from "./PanelItens/CopyFolderStructureDrawer";
import { createPortal } from "react-dom";
import { usePanelWidth } from "../../functions/hooks/usePanelWidth";

const exampleChildClass = mergeStyles({
	display: "block",
	marginBottom: "10px",
});

const textFieldStyles: Partial<ITextFieldStyles> = {
	root: { maxWidth: "300px" },
};

const WorkSpaceItemMain = (props: any) => {
	const { t, i18n } = useTranslation();
	const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
	const [userEdit, setUserEdit] = useState<string | undefined | number>(0);
	const [reload, setReload] = useState<boolean>(false);
	const [searchName, setSearchName] = useState<string>("");
	const profile = useProfile();
	const { nameOrg } = useParams();
	const { idWorkspace } = useParams();
	const { idFolder } = useParams();
	const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
	const upload = useUpload();
	const [newFolderConfig, setNewFolderConfig] = useState<NewFolderConfig | undefined>(undefined);
	const [renameConfig, setRenameConfig] = useState<RenameConfig | undefined>(undefined);
	const [temporalityConfig, setTemporalityConfig] = useState<TemporalityConfig | undefined>(undefined)
	const [idFileDeted, setIdFileDeted] = useState<string | undefined>(undefined);
	const [idFileRevision, setIdFileRevision] = useState<string | undefined>(undefined);
	const [hideDialog, setHideDialog] = useState<boolean>(true);
	const [hideDialogMove, setHideDialogMove] = useState<boolean>(true);
	const [itemMove, setItemMove] = useState<string>("");
	const [messageSnack, setMessageSnack] = useState<string>("");
	const [alertColor, setAlertColor] = useState<AlertColor>("success");
	const [idFolderInternal, setIdFolderInternal] = useState<string | undefined>(idFolder);
	const [editFolderOpen, setEditFolderOpen] = useState<EditFolderOpen | undefined>(undefined);
	const [copyFolderStructureDrawerParameters, setCopyFolderStructureDrawerParameters] = useState<CopyFolderStructureDrawerParameters | undefined>(undefined);
	const [shareFileConfigOpen, setShareFileConfigOpen] = useState<ShareFileConfig | undefined>(
		undefined,
	);
	const [flowProps, setFlowProps] = useState<FlowProps | undefined>(undefined)
	const [openModalFlow, setOpenModalFlow] = useState<boolean>(false)
	const [reloadFolder, setReloadFolder] = useState<boolean>(false);
	const [searchParams, setSearchParams] = useSearchParams();

	const { width } = usePanelWidth({
		idFileRevision: idFileRevision,
		temporalityConfig: temporalityConfig,
		copyFolderStructureDrawerParameters: copyFolderStructureDrawerParameters
	});
	
	const [configSearch, setconfigSearch] = useState<WorkSpaceItemListFileConfigSearch | undefined>(undefined)

	const handleCloseSnackBar = (event: any, reason: any) => {
		setShowSnackBar(false);
	};

	const handleCloseSnackBarPO = (event: any) => {
		setShowSnackBar(false);
	};

	useEffect(() => {
		if (reload == true) {
			setReload(false);
		}
	}, [reload]);

	const resetPanel = () => {
		dismissPanel();
		setEditFolderOpen(undefined);
		setIdFileRevision(undefined);
		setNewFolderConfig(undefined);
		setRenameConfig(undefined);
		setTemporalityConfig(undefined)
		setShareFileConfigOpen(undefined);
		setHideDialog(true);
		setHideDialogMove(true);
		setCopyFolderStructureDrawerParameters(undefined)
	};

	const updatePanelSave = () => {
		setMessageSnack(t("Folder.save"));
		setAlertColor("success");
		resetPanel();
		setReload(true);
		setShowSnackBar(true);
		upload.setShowEx(false);
	};

	const updatePanelSaveTemporality = () => {
		setMessageSnack(t("Folder.save"));
		setAlertColor("success");
		resetPanel();
		//setReload(true);
		setShowSnackBar(true);
		upload.setShowEx(false);
	};

	const ResetTemporality = () => {
		setMessageSnack(t("Reset.message"));
		setAlertColor("success");
		resetPanel();
		//setReload(true);
		setShowSnackBar(true);
		upload.setShowEx(false);
	};

	const reloadFolders = () => {
		setMessageSnack(t("SucessMove.message"));
		setAlertColor("success");
		setReload(true);
		setShowSnackBar(true);
	}

	const setIdFolderExternal = (folderId: string | undefined, searshParams:string | undefined ) => {
		if (folderId == undefined) {
			window.history.replaceState(null, "Workspace", "/" + nameOrg + "/wsallowed/" + idWorkspace);
		} else {
			window.history.replaceState(
				null,
				"Workspace",
				"/" + nameOrg + "/wsallowed/" + idWorkspace + "/" + folderId,
			);
		}

		if(searshParams == "" || searshParams == undefined)
		{
			searchParams.delete("search");
			searchParams.delete("tag");
		}

		setIdFolderInternal(folderId);

		var folder = searchParams.get("folder");

		if(idWorkspace != undefined)
		{
			setconfigSearch({
				idWorkspace: idWorkspace,
				idFolder: folderId,
				search: searshParams,
				folder:folder!,
				idTag:""
			})
		}
	};

	useEffect(() =>{
		let search = searchParams.get("search");
		let tag = searchParams.get("tag");

		var folder = searchParams.get("folder");
		
		if(idWorkspace != undefined)
		{
			if(search == undefined || search == "")
			{
				if(tag == undefined || tag == ""){
					setconfigSearch({
						idWorkspace: idWorkspace,
						idFolder: idFolderInternal,
						search: "",
						folder:folder!,
						idTag:""
					})
				}
				else{
					setconfigSearch({
						idWorkspace: idWorkspace,
						idFolder: idFolderInternal,
						search: "",
						folder:folder!,
						idTag:tag
					})
				}
			}
			else
			{
				setconfigSearch({
					idWorkspace: idWorkspace,
					idFolder: idFolderInternal,
					search: search,
					folder:folder!,
					idTag:tag!
				})
			}

				
		}
	},[searchParams, idWorkspace])


	function setIdFileRevisionExternal(file: string) {
		resetPanel();
		setIdFileRevision(file);
		openPanel();
	}

	function setIdFileRevisionMoveExternal(file: string) {
		setItemMove(file);
		setHideDialogMove(false);
	}

	function setRenameConfigExternal(renameConfig: RenameConfig) {
		resetPanel();
		setRenameConfig(renameConfig);
		openPanel();
	}

	function setTemporalityConfigExternal(temporalityConfig: TemporalityConfig){
		resetPanel();
		setTemporalityConfig(temporalityConfig)
		openPanel();
	}

	function setShareFileConfigOpenExternal(shareFileConfig: ShareFileConfig) {
		resetPanel();
		setShareFileConfigOpen(shareFileConfig);
		openPanel();
	}

	function setFolderConfig(newFolder: NewFolderConfig) {
		resetPanel();
		setNewFolderConfig(newFolder);
		setReloadFolder(true);
		openPanel();
	}

	function setEditFolderOpenExternal(editFolderOpenParameter: EditFolderOpen) {
		resetPanel();
		setEditFolderOpen(editFolderOpenParameter);
		openPanel();
	}

	function setCopyFolderStructureDrawerParametersExternal(copyFolderStructureDrawerParameters: CopyFolderStructureDrawerParameters) {
		resetPanel();
		setCopyFolderStructureDrawerParameters(copyFolderStructureDrawerParameters);
		openPanel();
	}

	function OpenRevision(itemId: string) {
		resetPanel();
		setIdFileRevision(itemId);
		openPanel();
	}

	useEffect(() => {
		if (idFileDeted != undefined) {
			setHideDialog(false);
		} else {
			setHideDialog(true);
		}
	}, [idFileDeted]);

	function deleteItem(folderId: string) {
		setIdFileDeted(folderId);
	}

    const handleOpen = () => {
		setOpenModalFlow(true)
	  }
  
	  const handleclose = () => {
		setOpenModalFlow(false)
	  }
	  
	function openFlowComp(flowProps: FlowProps){
		setFlowProps(flowProps);
		handleOpen();
	}

	function deleteOk() {
		if (idFileDeted != undefined) {
			Delete(idFileDeted)
				.then((returnMessage) => {
					setMessageSnack(t("Item.deleteSucess"));
					setAlertColor("success");
					setHideDialog(true);
					setReload(true);
					setShowSnackBar(true);
				})
				.catch((returnError) => {
					if (returnError.response) {
						setMessageSnack(returnError.response.data);
					} else {
						setMessageSnack(t("Folder.deleteProblem"));
					}
					setAlertColor("error");
					setHideDialog(true);
					setShowSnackBar(true);
				})
				.finally(()=>{
					setIdFileDeted(undefined);
				});
		}
	}

  function HeaderAltern (): string {

    if (idFileRevision != undefined) {
      return t("Revisao.term")
    }

    if (idFileRevision == undefined) {
      return t("Folder.toSave")
    }

    return ""
  }

	interface CheckboxItemList {
		boxLayout: boolean
		checkBox: { myDiv: "", default: "" }
		onChange: any
		value: string
		id: string
		name: string
	}

  const CheckboxItemList: React.FC<CheckboxItemList> = ({
		boxLayout,
		checkBox,
		onChange,
		value,
		id,
		name
	}) => {

    const Checkbox = 
    <div className={boxLayout?checkBox.myDiv:checkBox.default}>
      <input 
          type="checkbox" 
          onChange={onChange}     
          value={value}   
          id={id}   
          name={name}       
        />
      </div> 

		return Checkbox
	}

	const showSnackbar = (message: string, type: "success" | "error") => {
		setMessageSnack(message)
		setAlertColor(type)
		setShowSnackBar(true)
	}

	return (
		<div className={styles.main}>
			{reload == false && idWorkspace != undefined && nameOrg != undefined && configSearch != undefined && (
				<WorkSpaceItemListFolder
					nameOrg={nameOrg}
					configSearch={configSearch}
					setFolderConfig={setFolderConfig}
					deleteItem={deleteItem}
					onClickRevision={setIdFileRevisionExternal}
					onclikMoveOpen={setIdFileRevisionMoveExternal}
					editFolderOpen={setEditFolderOpenExternal}
					rename={setRenameConfigExternal}
					temporalityDocument={setTemporalityConfigExternal}
					setIdFolder={setIdFolderExternal}
					setShareFileConfig={setShareFileConfigOpenExternal}
					reloadFolder={reloadFolder}
					CheckboxItemList={CheckboxItemList}
					reloadFolders={reloadFolders}
					onClickFlow={openFlowComp}
					setCopyFolderStructureDrawerParametersExternal={setCopyFolderStructureDrawerParametersExternal}
				/>
			)}
			<Panel
				isOpen={isOpen}
				closeButtonAriaLabel={t("Fechar.message")}
				onDismiss={resetPanel}
				headerText={idFileRevision != undefined ? t("Revisao.term") : temporalityConfig != undefined ? t("Temporality.panelTitle") : copyFolderStructureDrawerParameters != undefined ? t("DuplicateFolder.message") : t("Folder.toSave")}
				isFooterAtBottom={true}
				type={idFileRevision != undefined ? PanelType.medium : undefined}
				styles={{content:{padding:"0px 30px 40px 30px"},main:{width: `${width.commonWidth}`, justifyContent:"center"},header:{margin:"40px 0px 24px 6px"},headerText:{fontSize:"28px", fontWeight:"bold", color:"#2D2D2D"}}}
			>
				{newFolderConfig != undefined && (
					<CreateFolderComp newFolderConfig={newFolderConfig} saveOkMetod={updatePanelSave} />
				)}

				{renameConfig != undefined && (
					<RenameComp renameConfig={renameConfig} saveOkMetod={updatePanelSave} />
				)}

				{idFileRevision != undefined && (
					<WorkSpaceItemListFileRevisionComponent idItem={idFileRevision} />
				)}

				{temporalityConfig != undefined && (
					<ValidateDocument temporalityConfig={temporalityConfig} saveOkMetod={updatePanelSaveTemporality} resetOkMetod={ResetTemporality} resetPanel={resetPanel}/>
				)}

				{editFolderOpen != undefined && nameOrg != undefined &&(
				<EditFolder
					nameOrg={nameOrg}
					saveOkMetod={updatePanelSave}
					editFolderOpen={editFolderOpen}
				/>
				)}
				
				{shareFileConfigOpen != undefined && (
					<ShareFile 
						saveOkMetod={updatePanelSave} 
						shareFileConfig={shareFileConfigOpen}
					/>
				)}
				{copyFolderStructureDrawerParameters != undefined && (
					<CopyFolderStructureDrawer 
						copyFolderStructureDrawerParameters={copyFolderStructureDrawerParameters}
						saveOkMetod={updatePanelSave}
						showSnackbar={showSnackbar}
					/>
				)}
      </Panel>

	  	{ openModalFlow && flowProps && (
            <div className={stylesTimeLine.dialogContainer}>
               	<WorkspaceItemFlowComponent  handleClose={handleclose} flowProps={flowProps}/>
            </div>
        )}
      <Dialog hidden={hideDialog} onDismiss={() => setHideDialog(true)}>
          <DialogContent title={t("Excluir.message")}>
            <h3>{t("Item.deleteMessage")}</h3>
          </DialogContent>
          <DialogFooter>
            <Btn onClick={() => setHideDialog(true)} text={t("Cancelar.message")} />
            <Btn onClick={deleteOk} text={t("Excluir.message")} />
          </DialogFooter>
        </Dialog>

			<Dialog hidden={hideDialogMove} onDismiss={() => setHideDialogMove(true)}>
				<AiOutlineCloseCircle
					className={styles.iconClose}
					size={25}
					onClick={() => setHideDialogMove(true)}
				/>
				<DialogContent title={t("Move.message")}>
					{idWorkspace != undefined ? (
						<MoveByClick
							idWorkspace={idWorkspace}
							idItem={itemMove}
							setPopupOkMessage={updatePanelSave}
							reloadFolders={reloadFolders}
						/>
					) : (
						<LoaderComp />
					)}
				</DialogContent>
			</Dialog>
			<Snackbar
				open={showSnackBar}
				autoHideDuration={6000}
				message="Archived"
				onClose={handleCloseSnackBar}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				sx={{ bottom: { xs: 50, sm: 20 } }}
			>
				<MuiAlert
					elevation={6}
					variant="filled"
					sx={{ width: "100%" }}
					severity={alertColor}
					onClose={handleCloseSnackBarPO}
				>
					{messageSnack}
				</MuiAlert>
			</Snackbar>
		</div>
	);
};

export default WorkSpaceItemMain;
