export interface TiffConfig { }

export const Tiff: React.FC<TiffConfig> = ({ }) => {
    return (
        <svg id="icon-tiff" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <rect id="Retângulo_259" data-name="Retângulo 259" width="24" height="24" rx="2" fill="#fff8e2" />
            <circle id="Elipse_125" data-name="Elipse 125" cx="3.747" cy="3.747" r="3.747" transform="translate(2.04 2.267)" fill="#ff884d" />
            <rect id="Retângulo_260" data-name="Retângulo 260" width="6.947" height="6.947" transform="translate(14.653 4.747)" fill="#8ac451" />
            <path id="Caminho_202" data-name="Caminho 202" d="M9.723,8.32l2.347,5.24,2.347,5.24H5.03l2.347-5.24Z" transform="translate(1.677 2.773)" fill="#ffc44f" />
        </svg>

    )
}
