import { createUseStyles } from "react-jss";

export const useLessMargin = createUseStyles({
  myDiv: {
    display: "flex",
    position: "absolute",
    top: 0,
    left: 0,
  },
  default: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "-8px",
  },
  viwers: {
    display: "flex",
    position: "absolute",
    top: 0,
    right:"44%"
  },
  
  viwerPdf:{
    right:"35%",
    display: "flex",
    position: "absolute",
    top: 0
  }
})
