import React, { useEffect, useState, useRef, useCallback  } from "react"
import {WorkSpaceUserName} from "../../../model/ItemModel"
import {GetValidationByItemGroup} from "../../../services/Item/ItemServicesGet"
import stylesList from "../../Style/List.module.css"
import { useTranslation } from "react-i18next";
import WorkSpaceItemListFileComponentImage from "../WorkSpaceItemListFileComponentImage"
import ListGroupValidation from "../PermissionFolder/ListGroupValidation";
import I18n, { TranslatorS } from '../../../components/I18n';
import {
  Dialog,
  DialogType,
  DialogFooter,
  DialogContent,
} from "@fluentui/react/lib/Dialog";
import Btn from "../../../components/Button/Btn";
import { useBoolean } from "@fluentui/react-hooks";
import {
  mergeStyleSets,
  Modal,
  IIconProps
} from '@fluentui/react';
import { IconButton, IButtonStyles } from '@fluentui/react/lib/Button';
import TextFieldComp from "../../../components/TextField/TextFieldComp";


interface PendenciesValidationConfig{
    name?:String;
    idFolder?:string;
    extension?:string;
    lastWriteDate?:string;
    currentVr?:string;
    nameUser?:string;
}

interface PendenciesValidation {
    idFolder:string;
    idGroup:string;
    removeGroup: () => void;
    togglePermission: boolean;
  }

const ListPendenciesGroupValidation: React.FC<PendenciesValidation> = ({idFolder,removeGroup,togglePermission,idGroup}) => {

   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const [itens, setItens] = useState<WorkSpaceUserName[]>([]);
   const open = Boolean(anchorEl);
   const [showContextualMenu, setShowContextualMenu] = React.useState(false);
   const onShowContextualMenu = React.useCallback((ev: React.MouseEvent<HTMLElement>) => {
     ev.preventDefault();
     if(loader == false)
  {
    setShowContextualMenu(true);
     }
   }, []);
   const onHideContextualMenu = React.useCallback(() => setShowContextualMenu(false), []);
   const { t, i18n } = useTranslation();
   const [loader, setLoader] = useState<boolean>(false);
   const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
   const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
   const [searchNameNew, setSearchNameNew] = useState<string>("");
   const [idVr, setIdVr] = useState<string>("");
   const iconProps = { iconName: "Filter" };

  const ItemValidation: React.ForwardRefExoticComponent<PendenciesValidationConfig> =
  React.forwardRef(({name,idFolder,extension,lastWriteDate,currentVr,nameUser}, ref: React.Ref<HTMLLIElement>) => {
    setIdVr(currentVr!)
    console.log(nameUser)
    return (
        <div>
            <li className={`${stylesList.itemContentAdm} ${stylesList.row} ${stylesList.DivFatherListUser}`} key={idFolder} ref={ref}>
                    <h3>{nameUser}</h3>
                    
                    <div className={stylesList.DivListNameUser}>
                        <WorkSpaceItemListFileComponentImage extension={extension} isFolder={false}/>
                        {name}.{extension}
                    </div>          
            </li>
        </div>
    );
  });

  const onChangeSearchNameNew = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    if (newValue != undefined ) {
      setSearchNameNew(newValue);
    }
  };

  useEffect(() => {
    if(togglePermission == true){
        toggleHideDialog()
    }
},[togglePermission])

  useEffect(() => {
    GetValidationByItemGroup(idFolder,idGroup).then((item) => {
        setItens(item);
      });

  }, [idFolder,idGroup]);

const closeModal = () => {
  hideModal();
  toggleHideDialog();
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    minWidth: '400px',
  },
  header: [
    {
      flex: '1 1 auto',
      borderTop: `4px solid`,
      color: "#114D88",
      display: 'flex',
      alignItems: 'center',
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  DialogBtn:{
    display:"inline-flex"
  }
});

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
      color: "black",
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
    rootHovered: {
      color: "black",
    },
  };
  const cancelIcon: IIconProps = { iconName: 'Cancel' };
  

  function AddNewUserPendencieAndRemoveActual(){
    //SetPseudoValidation()
  }

  const onClickNew = () => {
    showModal();
  };

return (
  <div>
    <Dialog hidden={hideDialog} onDismiss={toggleHideDialog}>
        <DialogContent>
            <h3>{t("ArqResp.message")}</h3>
            <ul className={stylesList.ulContent}>
                <li className={`${stylesList.itemContent} ${stylesList.title}`}>
                    <div>{t("Nome.message")}</div>
                </li>
              {itens.map((itensValidation) => (
                <ItemValidation
                  key={itensValidation.id}
                  idFolder={itensValidation.id}
                  name={itensValidation.name}
                  extension={itensValidation.extension}
                  lastWriteDate={itensValidation.lastWriteDate}
                  currentVr={itensValidation.idVr}
                  nameUser = {itensValidation.nameUser}
                />
              ))}
            </ul>                
        </DialogContent>
        <DialogFooter>
          <div  className={contentStyles.DialogBtn}>
            <Btn onClick={toggleHideDialog} text={t("Cancelar.message")} />
            <Btn onClick={onClickNew} text={t("NewResp.message")}/>
          </div>        
        </DialogFooter>
    </Dialog>

  <Modal
    titleAriaId="Adição de usuários"
    isOpen={isModalOpen}
    onDismiss={closeModal}
    isBlocking={false}
    containerClassName={contentStyles.container}
  >
    <div className={contentStyles.header}>
      <h3 className={stylesList.hList}>{t("SelectResp.message")}</h3>
      <IconButton
        styles={iconButtonStyles}
        iconProps={cancelIcon}
        ariaLabel="Close popup modal"
        onClick={closeModal}
      />
    </div>
    <div className={contentStyles.body}>
      <TextFieldComp iconProps={iconProps}  value={searchNameNew} onChange={onChangeSearchNameNew} />
      {idFolder!= undefined && idFolder != ""?
      (<ListGroupValidation idFolder={idFolder} name={searchNameNew} idVr={idVr} closeModal={closeModal} removeUser={removeGroup}/>):(<div></div>)}

    </div>
  </Modal>
  </div>
);
}
export default ListPendenciesGroupValidation;
