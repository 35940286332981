import React, { useState, useEffect, useCallback, useRef } from "react";
import { WorkspacePendenci,ItemValidationRule } from "../../model/ItemModel";
import UserPendenciesListComp from "../../pages/UserPendencies/UserPendenciesListComp"
import { useNavigate, useParams } from "react-router-dom";
import { useProfile } from '../../context/ProfileProvider/useProfile';
import TextFieldComp from "../../components/TextField/TextFieldComp";
import { Stack } from "@fluentui/react/lib/Stack";
import styles from "../Style/List.module.css";
import { useTranslation } from "react-i18next";
import { NewFolderConfig } from '../WorkSpaceItem/PanelItens/CreateFolderComp';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, {AlertColor} from '@mui/material/Alert';
import { RenameConfig } from '../../pages/WorkSpaceItem/PanelItens/RenameComp';
import { FlowProps, WorkspaceItemFlowComponent } from "../WorkSpaceItem/FlowFolder/WorkspaceItemFlowComponent";
import stylesTimeLine from '../WorkSpaceItem/FlowFolder/WorkspaceItemFlow.module.css'

const UserPendenciesList  = (props: any) => {
  const [userPendencies, setUserPendencies] = useState<WorkspacePendenci[]>([]);
  const navegate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { t, i18n } = useTranslation();

  const [popupOk, setPopupOk] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [searchName, setSearchName] = useState<string>("");
  const profile = useProfile();
  const [isAdminOrganization, setisAdminOrganization] = useState<boolean>(false);
  const { nameOrg } = useParams();
  const { idWorkspace } = useParams();
  const [alertColor, setAlertColor] = useState<AlertColor>("error");
	const [flowProps, setFlowProps] = useState<FlowProps | undefined>(undefined)
	const [openModalFlow, setOpenModalFlow] = useState<boolean>(false)

  const iconProps = { iconName: "Filter" };

  useEffect(() => {
    if(profile.organizations != undefined)
    {
      for(let organization of profile.organizations)
      {
        if(nameOrg == organization.internalName)
        {
          if(organization.isAdmin == true)
          {
            setisAdminOrganization(true);
          }
          else{
            setisAdminOrganization(false);
          }
        }
      }
    }
  }, [profile]);

  const handleClose = (event:any, reason:any) => {
    closePopUpOk();
  }

  const handleClosePO = (event:any) => {
    closePopUpOk();
  }

  function closePopUpOk() {
    setPopupOk(false);
  }

  const handleOpen = () => {
		setOpenModalFlow(true)
	}
  
	const handleclose = () => {
		setOpenModalFlow(false)
	}

  function setPopupOkExternalError(set:boolean, message:string) {
    setAlertColor('error');
    setErrorMessage(message);
    setPopupOk(set);
  }

  function setPopupOkExternalOk(set:boolean, message:string) {
    setAlertColor('success');
    setErrorMessage(message);
    setPopupOk(set);
  }

  const setFolder = (newFolder:NewFolderConfig) =>{

  }
  const rename = (renameConfig:RenameConfig) =>{

  }

  const setFolderExternal = (folderId:string) =>{

  }
  const deleteItem = (itemId: string) => {

  }
  const onClickRevision = (itemId: string) => {

  }

  const array: any = []

  interface CheckboxItemList {
		boxLayout: boolean
		checkBox: { myDiv: "", default: "" }
		onChange: any
		value: string
		id: string
		name: string
	}

  const CheckboxItemList: React.FC<CheckboxItemList> = ({
		boxLayout,
		checkBox,
		onChange,
		value,
		id,
		name
	}) => {

    const Checkbox = 
    <div className={boxLayout?checkBox.myDiv:checkBox.default}>
      <input 
          type="checkbox" 
          onChange={onChange}     
          value={value}   
          id={id}   
          name={name}       
        />
      </div> 

		return Checkbox
	}

  function openFlowComp(flowProps : FlowProps){
    setFlowProps(flowProps);
		//setFlowProps({idMongo: file, nameFile, extensionFile})
		handleOpen()
	}
  return (
    <div>
      <Stack horizontal className={styles.hPage}>   
        <Stack.Item grow>
          <h3 className={styles.hList}>{t("Pendencias.message")}</h3>
        </Stack.Item>     
      </Stack>
      
      {reload?(<div></div>):(
          nameOrg != undefined?(
            <UserPendenciesListComp
              nameOrg={nameOrg}
              setFolderConfig={setFolder}
              setFolderExternal={setFolderExternal}
              deleteItem={deleteItem}
              onClickRevision={onClickRevision}
              onclickMove={onClickRevision}
              isAdmin={isAdminOrganization}
              setPopupError={setPopupOkExternalError}
              setPopupOk={setPopupOkExternalOk}
              rename={rename}
              array={array}
              CheckboxItemList={CheckboxItemList}
              onClickFlow={openFlowComp}
            />
          ):(<li className={styles.itemContent}></li>))}

        { openModalFlow && flowProps && (
            <div className={stylesTimeLine.dialogContainer}>
               	<WorkspaceItemFlowComponent  handleClose={handleclose} flowProps={flowProps}/>
            </div>
        )}

      <Snackbar
        open={popupOk}
        autoHideDuration={6000}
        message="Archived"
        onClose={handleClose}
        anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
        sx={{ bottom: { xs: 50, sm: 20 } }}
      >
        <MuiAlert elevation={6} variant="filled" sx={{ width: '100%' }} severity={alertColor} onClose={handleClosePO}>
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};


export default UserPendenciesList;
