import { Menu, SidebarContent, SidebarFooter, SidebarHeader } from "react-pro-sidebar"
import { ContentSideComp, ContentbarProps } from "./ContentSideComp"
import './ContentSide.css';
import { useEffect, useState } from "react";

interface ContentSideListProps{
    contentList: ContentbarProps[]
}

export const ContentSideList: React.FC<ContentSideListProps> = ({contentList}: ContentSideListProps) => {
    const [isOrganizationPage, setIsOrganizationPage] = useState<boolean>(false)
    useEffect(() => {
        if(window.location.pathname === "/"){
            setIsOrganizationPage(true)
        } else {
            setIsOrganizationPage(false)
        }
    }, [window.location.pathname])
    
    return(
        <>
            <SidebarHeader className='sidebarHeader'>
            </SidebarHeader>
            <SidebarContent>
                <Menu className={isOrganizationPage ? "none" : ""}>
                    {contentList.filter(content => content.footer !== true).map((content, index) => {              
                        return <ContentSideComp 
                            children={content.children} 
                            type={content.type}
                            onClick={content.onClick}
                            conditional={content.conditional}
                            fill={content.fill}
                            icon={content.icon}
                            title={content.title}
                            footer={content.footer}
                            key={index}
                            isSelect={content.isSelect}
                        />
                    })}
                </Menu>
            </SidebarContent>
            <SidebarFooter>
            <Menu>
                {contentList.filter(content => content.footer === true).map((content, index) => {              
                    return <ContentSideComp 
                        children={content.children} 
                        type={content.type}
                        onClick={content.onClick}
                        handleChange={content.handleChange}
                        conditional={content.conditional}
                        fill={content.fill}
                        icon={content.icon}
                        title={content.title}
                        footer={content.footer}
                        key={index}
                        isSelect={content.isSelect}
                    />
                })}
            </Menu>
            </SidebarFooter>
        </>
    )
}

export default ContentSideList