import { useState } from 'react';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoaderComp from '../../../../../../components/Loader/LoaderComp';
import { downloadViwerFile } from '../../utils/downloads';
import warning from '../../../../../../assets/icons/alert.svg';
import { ParameterViwer } from '../../../../../WorkSpaceItem/Viwer/ParameterViwer';

interface IViwerInfo {
    itemId: string;
    setSnackBarMessage: (set: string) => void;
    itemName: string;
    itemExtension: string;
    parameter:ParameterViwer
}

export default function ViwerInfo({ setSnackBarMessage, itemExtension,parameter, itemId, itemName}: IViwerInfo) {

    const { t } = useTranslation();
    const [isDownload, setIsDownloading] = useState<boolean>(false);

    function download() {
        downloadViwerFile(itemId, itemName, itemExtension,parameter,setSnackBarMessage, t);
    }

    return (
        <Grid 
            container 
            width="785px" 
            height="304px" 
            padding="30px" 
            justifyContent={"center"}
            sx={{
                backgroundColor: 'white',
                borderRadius: '20px'
            }}
        >
            <Grid container spacing={3}>
                <Grid item sx={{
                    textAlign: "center"
                }} xs={12}>
                    <Box 
                        component="img"
                        src={warning}
                        width="61px"
                        height="54px"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{
                        textAlign: "center",
                        font: "normal normal bold 26px/39px Segoe UI",
                        letterSpacing: "0px",
                        color: "#2D2D2D",
                        opacity: 1,
                    }}>
                        {t("ViwerInfo.cannotView")}
                    </Typography>
                </Grid>
                <Grid item sx={{
                    textAlign: "center",
                    font: "normal normal normal 16px/24px Segoe UI",
                    letterSpacing: "0px",
                    color: "#2D2D2D",
                    opacity: 1,
                }} xs={12}>
                        {t("ViwerInfo.notPreview")}                
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth variant="outlined" sx={{ borderRadius: '8px' }} onClick={download}>
                        { isDownload ? (
                            <LoaderComp />
                        ) : (
                            <>
                                {t("ViwerInfo.downloadFile")}
                            </>
                        ) }
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}