import { useEffect, useState } from 'react';
import { GetRevision, GetRevisionTracker, ItemWithValidation } from '../../services/Item/ItemServicesGet';
import { FolderInformation, GetFlowInformationByItemVO, ItemValidationRule, WorkSpaceItemRevision, WorkSpaceUserName } from '../../model/ItemModel';
import FlowSidebar from './components/RightSideHandler/FlowSidebar/FlowSidebar'; 
import RightSideHandler from './components/RightSideHandler/RightSideHandler';
import LeftSideHandler from './components/LeftSideHandler/LeftSideHandler';
import { IContextualMenuItem } from '@fluentui/react';
import DocumentDetailWrapper from './components/DocumentDetailWrapper/DocumentDetailWrapper';
import DocumentDetailHeader from './components/LeftSideHandler/DocumentDetailHeader/DocumentDetailHeader'; 
import { MenuConfig } from '../../components/Menu/MenuConfig';
import DocumentDetailPivot from './components/LeftSideHandler/DocumentDetailContent/DocumentDetailPivot/DocumentDetailPivot';
import DocumentDetailActivityTable, { ITrackerItems } from './components/LeftSideHandler/DocumentDetailContent/DocumentDetailPivot/components/DocumentDetailActivityTable/DocumentDetailActivityTable';
import DocumentDetailTabPanel from './components/LeftSideHandler/DocumentDetailContent/DocumentDetailPivot/components/DocumentDetailTabPanel/DocumentDetailTabPanel';
import DocumentDetailTabTablesWrapper from './components/LeftSideHandler/DocumentDetailContent/DocumentDetailPivot/components/DocumentDetailTabTablesWrapper/DocumentDetailTabTablesWrapper';
import DocumentDetailPhLocation from './components/LeftSideHandler/DocumentDetailContent/DocumentDetailPivot/components/DocumentDetailPhLocation/DocumentDetailPhLocation';
import DocumentDetailReviewTable from './components/LeftSideHandler/DocumentDetailContent/DocumentDetailPivot/components/DocumentDetailReviewTable/DocumentDetailReviewTable';
import DocumentDetailTabFilterReviews from './components/LeftSideHandler/DocumentDetailContent/DocumentDetailPivot/components/DocumentDetailTabFilters/DocumentDetailTabFilterReviews/DocumentDetailTabFilterReviews';
import DocumentDetailTabFilterTracker from './components/LeftSideHandler/DocumentDetailContent/DocumentDetailPivot/components/DocumentDetailTabFilters/DocumentDetailTabFilterTracker/DocumentDetailTabFilterTracker';
import { TagName } from '../../model/Tags';
import { useTranslation } from 'react-i18next';
import { Snackbar, snackbarClasses } from '@mui/material';
import MuiAlert, {AlertColor} from '@mui/material/Alert';
import DocumentDetailViwerSwitch from './components/LeftSideHandler/DocumentDetailContent/DocumentDetailPivot/components/DocumentDetailViwerSwitch/DocumentDetailViwerSwitch';
import { ParameterViwer } from '../WorkSpaceItem/Viwer/ParameterViwer';
import { useTableDims } from '../../components/StyledModal/hooks/useTableDims/useTableDims';


interface IDocumentDetail 
{
  setValidationRule: (itemValidationRule: ItemValidationRule | undefined, index: number, isUpload: boolean) => void;
  isOpen: boolean;
  workItem: WorkSpaceUserName;
  closeDetail: () => void;
  returnMenuConfig: () => MenuConfig;
  status: string;
  setPopupError: (set: boolean, message: string) => void;
  setPopupOk: (set: boolean, message: string) => void;
  tagInternal: TagName[] | undefined;
  isValidateReadViwer: boolean;
  itemTypeInternal: string;
  respValidationRule: boolean;
  unvalidType: boolean;
  isOpenTag: boolean;
  openPanelTag: () => void;
  onClickTag: () => void;
  boxLayout: boolean;
  isHover: boolean;
  isValidateViwer: boolean;
  leave: () => void;
  onDragEnterAndOver: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragLeave: (event: React.DragEvent<HTMLDivElement>) => void;
  progress: number;
  setFolderExternal: (itemId: string, itemName: string | undefined) => void;
  itemType: string;
  timeDeadLineInternal?: string;
  updateTagName: (index:number, tagName: TagName[], isUpload: boolean) => void;
  indexOfArray: number;
  setTagInternal: (tagName: TagName[] | undefined) => void;
  parameter?:ParameterViwer;
  updateNameDescriptionByIndex:(index:number, name:string, isUpload: boolean, description:string) => void;
  isUpload: boolean;
  nameOrg: string;
  setWorkspace: ((workSpaceUserName: WorkSpaceUserName, index: number, isUpload: boolean) => void);
  idWorkspace: string;
  currentFolder: FolderInformation | undefined;
}

export interface IPersistedPhLocation 
{
  local: string;
  shelf: string;
  position: string;
  bookshelf: string;
}

export interface IRadioActions 
{
    action: string;
    user: string;
    asc: string;
    desc: string;
}

export interface IObjectGetterTrackerFilter {
  itemId: string;
  action: number;
  order: number;
  enumerator: number | null;
}

export interface IObjectGetterReviewFilter {
  itemId: string;
  order: number;
  enumerator: number | null;
}

export interface IShowInfo {
  value: string;
  isShown: boolean;
}

export default function DocumentDetail({
  setValidationRule,
  isOpen,
  workItem,
  closeDetail,
  returnMenuConfig,
  status,
  setPopupError,
  setPopupOk,
  tagInternal,
  itemTypeInternal,
  respValidationRule,
  isOpenTag,
  openPanelTag,
  boxLayout,
  onClickTag,
  isHover,
  isValidateReadViwer,
  isValidateViwer,
  leave,
  onDragEnterAndOver,
  onDragLeave,
  progress,
  setFolderExternal,
  unvalidType,
  itemType,
  updateTagName,
  indexOfArray,
  setTagInternal,
  parameter,
  updateNameDescriptionByIndex,
  isUpload,
  nameOrg,
  setWorkspace,
  idWorkspace,
  currentFolder
}: IDocumentDetail)
{   
    const [informationFlowByVO, setInformationFlowByVO] = useState<GetFlowInformationByItemVO>();
    const [isLoading, setisLoading] = useState<boolean>(false);
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false);

    const [itemTracker, setItemTracker] = useState<ITrackerItems[]>();
    const [itemRevision, setItemRevision] = useState<WorkSpaceItemRevision[]>();
    const [externalLocalPosition, setExternalLocalPosition] = useState<IPersistedPhLocation>({
      local: '',
      position: '',
      bookshelf: '',
      shelf: ''
    })
    const [persistedItemRevision, setPersistedItemRevision] = useState<WorkSpaceItemRevision[] | undefined>([]);
    const [persistedItemTracker, setPersistedItemTracker] = useState<ITrackerItems[] | undefined>([]);
    const [revisionIdListTracker, setRevisionIdListTracker] = useState<string[]>([]);
    const [revisionIdListReview, setRevisionIdListReview] = useState<string[]>([]);
    const [refreshOnlyTags,  setRefreshOnlyTags] = useState<boolean>(false);
    const [revisionTrackerLoading, setRevisionTrackerLoading] = useState<boolean>(false);
    const [revisionLoading, setRevisionLoading] = useState<boolean>(false);
    const [snackBarMessage, setSnackBarMessage] = useState<string|undefined>(undefined);
    const [alertColor, setAlertColor] = useState<AlertColor>("success");
    const [showViwerInfo, setShowViwerInfo] = useState<IShowInfo>({ value: '', isShown: false });
    const [fullScreenSetted, setFullScreenSetted] = useState<boolean>(false);
    const { tableDims } = useTableDims(isSidebarCollapsed, fullScreenSetted);

    const isReleaseConst: boolean = workItem.isRelease == true;

    useEffect(() => {
      if(isOpen)
      {
        if (workItem?.id !== undefined) {
          setisLoading(true);
          ItemWithValidation(workItem.id!)
          .then(data => {
              setInformationFlowByVO(data);
          })
          .catch(error => {
            setSnackBarMessage(error.response.data);
          })
          .finally(() => setisLoading(false));
        }
      }
    }, [workItem, isOpen])

    useEffect(() => {
        if (workItem?.id !== undefined && isOpen) {
            setRevisionLoading(true)
            GetRevision(workItem?.id!)
            .then(data => {
              let extractedRevision: WorkSpaceItemRevision[] = [];
              data?.map(itemRevision => {
                extractedRevision.push({
                    enumerate: itemRevision.enumerate,
                    itemName: itemRevision.itemName!,
                    draftDate: itemRevision.draftDate,
                    description: itemRevision.description!,
                    id: itemRevision.id!
                })
              })
              setItemRevision(extractedRevision)
              setPersistedItemRevision(extractedRevision);
            })
            .catch(error => {
              setSnackBarMessage(error.response.data)
            })
            .finally(() => setRevisionLoading(false))

            setRevisionTrackerLoading(false);
            GetRevisionTracker(workItem?.id!)
            .then(data => {
              let trackerListExtracted: ITrackerItems[] = [];
              data?.trackerList!.map(workSpaceRevisionTracker => {
                workSpaceRevisionTracker?.trackerList?.map(tracker => {
                  trackerListExtracted.push({
                      ip: tracker.ipUser!,
                      message: tracker.message!,
                      nameUser: tracker.nameUser!,
                      revision: workSpaceRevisionTracker.enumerate!,
                      trackerDateTime: tracker.trackerDateTime!
                  })
                })
              })
              setItemTracker(trackerListExtracted);
              setPersistedItemTracker(trackerListExtracted);
            })
            .catch(error => {
              setSnackBarMessage(error.response.data)
            })
            .finally(() => setRevisionTrackerLoading(false))
        }

        if (
          workItem !== undefined &&
          workItem.local !== undefined &&
          workItem.position !== undefined &&
          workItem.bookShelf !== undefined &&
          workItem.shelf !== undefined
        ) 
        {
          setExternalLocalPosition({
            local: workItem.local,
            position: workItem.position,
            bookshelf: workItem.bookShelf,
            shelf: workItem.shelf
          })
        }
    }, [workItem, isOpen])

    function handleClosePO (event:any) {
      setSnackBarMessage(undefined);
    }

    function handleClose (event:any, reason:any) {
      setSnackBarMessage(undefined);
    }

    function setSnackbarType(message: string, severity: AlertColor): void
    {
      setSnackBarMessage(message);
      setAlertColor(severity);
    }


    return (
      <DocumentDetailWrapper
        isOpen={isOpen}
        documentName={workItem?.name!}
        closeDetail={closeDetail}
        extension={workItem?.extension!}
        fullScreenSetted={fullScreenSetted}
        setFullScreenSetted={setFullScreenSetted}
      >
        {isOpen &&(
          <>
            <LeftSideHandler>
              <DocumentDetailHeader 
                closeDetail={closeDetail}
                workItem={workItem}
                returnMenuConfig={returnMenuConfig}
                status={status}
                isSidebarCollapsed={isSidebarCollapsed}
                setPopupOk={setPopupOk}
                setPopupError={setPopupError}
                tagInternal={tagInternal}
                refreshOnlyTags={refreshOnlyTags}
                setRefreshOnlyTags={setRefreshOnlyTags}
                isReleaseConst={isReleaseConst}
                itemTypeInternal={itemTypeInternal}
                respValidationRule={respValidationRule}
                openPanelTag={openPanelTag}
                isOpenTag={isOpenTag}
                boxLayout={boxLayout}
                onClickTag={onClickTag}
                isHover={isHover}
                isValidateReadViwer={isValidateReadViwer}
                isValidateViwer={isValidateViwer}
                leave={leave}
                onDragEnterAndOver={onDragEnterAndOver}
                onDragLeave={onDragLeave}
                progress={progress}
                setFolderExternal={setFolderExternal}
                unvalidType={unvalidType}
                itemType={itemType}
                updateTagName={updateTagName}
                indexOfArray={indexOfArray}
                setTagInternal={setTagInternal}
                updateNameDescriptionByIndex={updateNameDescriptionByIndex}
                isUpload={isUpload}
                idWorkspace={idWorkspace}
                setValidationRule={setValidationRule}
                nameOrg={nameOrg}
                setWorkspace={setWorkspace}
              >
                <DocumentDetailPivot
                  isLoading={isLoading}
                  isEditorCannotSeeRevision={currentFolder?.isEditorCannotSeeRevision!}
                >
                  <DocumentDetailTabPanel value="1" sx={{ padding: 0 }}>
                    <DocumentDetailViwerSwitch 
                      setShowViwerInfo={setShowViwerInfo}
                      showViwerInfo={showViwerInfo}
                      workItem={workItem}
                      itemName={workItem?.name!}
                      itemExtension={workItem?.extension!}
                      isSidebarCollapsed={isSidebarCollapsed}
                      setSnackBarMessage={setSnackBarMessage}
                      parameter={parameter!}
                      fullScreenSetted={fullScreenSetted}
                    />
                  </DocumentDetailTabPanel>
                  <DocumentDetailTabTablesWrapper>
                    <DocumentDetailTabPanel
                      value="2"
                      sx={{
                        position: "fixed",
                        overflow: "auto",
                        height: `${tableDims.height}`,
                        overflowX: "hidden",
                        padding: "30px",
                      }}
                    >
                      <DocumentDetailTabFilterTracker
                        itemId={workItem?.id!}
                        setItemTracker={setItemTracker!}
                        persistedItemRevision={persistedItemRevision}
                        persistedItemTracker={persistedItemTracker}
                        setRevisionIdList={setRevisionIdListTracker}
                        revisionIdList={revisionIdListTracker}
                        revisionTrackerLoading={revisionTrackerLoading}
                      />
                      <DocumentDetailActivityTable
                        fullScreenSetted={fullScreenSetted}
                        isSidebarCollapsed={isSidebarCollapsed}
                        itemTracker={itemTracker}
                        revisionTrackerLoading={revisionTrackerLoading}
                        setShowViwerInfo={setShowViwerInfo}
                        showViwerInfo={showViwerInfo}
                      />
                    </DocumentDetailTabPanel>
                  </DocumentDetailTabTablesWrapper>
                  <DocumentDetailTabPanel
                    value="3"
                  >
                <DocumentDetailPhLocation 
                  itemId={workItem?.id!}
                  setExternalLocalPosition={setExternalLocalPosition}
                  externalLocalPosition={externalLocalPosition}
                  setSnackBarMessage={setSnackBarMessage}
                  setShowViwerInfo={setShowViwerInfo}
                  showViwerInfo={showViwerInfo}
                />
              </DocumentDetailTabPanel>
              <DocumentDetailTabTablesWrapper>
              { !currentFolder?.isEditorCannotSeeRevision && (
                <DocumentDetailTabPanel
                    value="4"
                    sx={{
                      overflow: "auto",
                      height: "-webkit-fill-available",
                      overflowX: "hidden",
                      padding: "30px",
                      position: 'fixed',
                    }}
                  >
                    <DocumentDetailTabFilterReviews 
                      setItemRevision={setItemRevision}
                      itemId={workItem?.id!}
                      persistedItemRevision={persistedItemRevision}
                      setRevisionIdListReview={setRevisionIdListReview}
                      revisionIdListReview={revisionIdListReview}
                      revisionLoading={revisionLoading}
                      setSnackBarMessage={setSnackBarMessage}
                    />
                    <DocumentDetailReviewTable
                      fullScreenSetted={fullScreenSetted}
                      isSidebarCollapsed={isSidebarCollapsed}
                      itemRevision={itemRevision!}
                      revisionLoading={revisionLoading}
                      setShowViwerInfo={setShowViwerInfo}
                      showViwerInfo={showViwerInfo}
                    />
                  </DocumentDetailTabPanel>
                ) }
              </DocumentDetailTabTablesWrapper>
            </DocumentDetailPivot>
          </DocumentDetailHeader>
        </LeftSideHandler>
        <RightSideHandler>
          <FlowSidebar
              informationFlowByVO={informationFlowByVO}
              isLoading={isLoading}
              isSidebarCollapsed={isSidebarCollapsed}
              setIsSidebarCollapsed={setIsSidebarCollapsed}
              fullScreenSetted={fullScreenSetted}
          />
        </RightSideHandler>
         </>
        )}
        <Snackbar
              open={snackBarMessage != undefined}
              autoHideDuration={6000}
              message="Archived"
              onClose={handleClose}
              anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
              sx={{ bottom: { xs: 50, sm: 20 } }}
          >
              <MuiAlert elevation={6} variant="filled" sx={{ width: '100%' }} severity={alertColor} onClose={handleClosePO}>
                  {snackBarMessage}
              </MuiAlert>
          </Snackbar>
      </DocumentDetailWrapper>
    )
}