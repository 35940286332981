export interface PstConfig {}

export const Pst: React.FC<PstConfig> = ({}) => {
  return(
	  <svg id="icon-pst" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		  <g id="Grupo_600" data-name="Grupo 600">
			  <rect id="Retângulo_240" data-name="Retângulo 240" width="24" height="24" rx="2" fill="#2165c2" />
			  <path id="_8673621_ic_fluent_mail_filled_icon" data-name="8673621_ic_fluent_mail_filled_icon" d="M16,7.226v5.7A2.275,2.275,0,0,1,13.854,15.2l-.129,0H4.275A2.275,2.275,0,0,1,2,13.054l0-.129v-5.7l6.756,3.539a.525.525,0,0,0,.487,0ZM4.275,4h9.45a2.275,2.275,0,0,1,2.264,2.046L9,9.707,2.011,6.046A2.275,2.275,0,0,1,4.145,4l.13,0h0Z" transform="translate(3 2.4)" fill="#fff" />
		  </g>
	  </svg>

  )
}

