import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { Grid } from '@mui/material';

export interface IDocumentDetailHeaderActionsFull {
    fullScreenToggle: () => void;
    fullScreenSetted: boolean;
}

export default function DocumentDetailHeaderActionsFull({
    fullScreenToggle,
    fullScreenSetted
}: IDocumentDetailHeaderActionsFull) {
    return (
        <Grid item onClick={fullScreenToggle} sx={{
            cursor: 'pointer',
            zIndex: 999,
            marginLeft: "20px"
        }}>
            { fullScreenSetted ? 
                <CloseFullscreenIcon 
                    fontSize="small"
                />
            :              
                <OpenInFullIcon 
                    fontSize="small"
                />
            }
        </Grid>
    )
}