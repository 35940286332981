import { Divider, Grid, Typography } from "@mui/material";
import { bytesConverter } from "../MemoryProgress/bytesConverter";
import { useTranslation } from "react-i18next";

export interface IDashboardHeader {
    organizationName: string;
    maxStorage?: number;
    hasContract?: boolean;
}

export function DashboardHeader({
    organizationName,
    maxStorage,
    hasContract
}: IDashboardHeader) {

    const { t } = useTranslation();

    return (
            <>
            <Grid item
                xs={6}
                marginBottom="20px"
            >
                <Typography
                    sx={{
                        font: "normal normal bold 28px/42px Segoe UI",
                        letterSpacing: "0px",
                        color: "#2D2D2D",
                        opacity: 1,
                    }}
                >
                    { organizationName }
                </Typography>
            </Grid>
            { hasContract ? (
                <Grid item
                    xs={6}
                    marginBottom="20px"
                    display="flex"
                    justifyContent="end"
                >
                    <Typography
                        sx={{
                            textAlign: "left",
                            font: "normal normal normal 16px/24px Segoe UI",
                            letterSpacing: "0px",
                            color: "#2D2D2D",
                            opacity: 0.5,
                        }}
                    >
                        {t("Dashboard.storageLimit")} { bytesConverter(maxStorage!) }
                    </Typography>
                </Grid>
            ) : (
                <Grid item
                    xs={6}
                    marginBottom="20px"
                    display="flex"
                    justifyContent="end"
                >
                    <Typography
                        sx={{
                            textAlign: "left",
                            font: "normal normal normal 16px/24px Segoe UI",
                            letterSpacing: "0px",
                            color: "#2D2D2D",
                            opacity: 0.5,
                        }}
                    >
                        {t("Dashboard.storageUseWithoutContract")}
                    </Typography>
                </Grid>      
            ) }
            <Grid item
                xs={12}
                marginBottom="20px"
            >
                <Divider 
                    sx={{
                        color: "black",
                        background: "black"
                    }}
                />
            </Grid>
        </>
    )
}