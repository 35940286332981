import { PasswordActions, PasswordState } from "../Types/PasswordTypes"

export function reducer(state: PasswordState, action: PasswordActions): PasswordState  {
    switch(action.type) {
        case 'min':
            return { ...state, minimunCharacters: { error: false, warningStyle: 'success' } }
        case 'clearMin':
            return { ...state, minimunCharacters: { error: true, warningStyle: 'error' } }
        case 'special':
            return { ...state, specialCharacter: { error: false, warningStyle: 'success' } }
        case 'clearSpecial':
            return { ...state, specialCharacter: { error: true, warningStyle: 'error' } }
        case 'capital':
            return { ...state, capitalCharacter: { error: false, warningStyle: 'success' } }
        case 'clearCapital':
            return { ...state, capitalCharacter: { error: true, warningStyle: 'error' } }
        case 'numberC':
            return { ...state, numberCharacter: { error: false, warningStyle: 'success' }  }
        case 'clearNumberC':
            return { ...state, numberCharacter: { error: true, warningStyle: 'error' }  }
    }
}