import React, { useEffect, useState, useRef, useCallback  } from "react";
import stylesList from "../../../Style/List.module.css";
import { useTranslation } from "react-i18next";
import { UserPermission } from "../../../../model/UserModel";
import { 
  GetItemUser, GetItemUserName
 } from "../../../../services/Item/ItemServicesGet";
 import PermissionUser from "./PermissionUser";

interface PermissionUserFolderListConfig {
    nameOrg: string;
    idFolder: string;
    name: string;
  }

  function useVisibility(
    cb: (isVisible: boolean) => void,
    deps: React.DependencyList
  ): (node: any) => void {
    const intersectionObserver = useRef<IntersectionObserver | null>(null);
    return useCallback((node) => {
      if (intersectionObserver.current) {
        intersectionObserver.current.disconnect();
      }
  
      intersectionObserver.current = new IntersectionObserver(([entry]) => {
        cb(entry.isIntersecting);
      });
  
      if (node) intersectionObserver.current.observe(node);
    }, deps);
  }

const PermissionUserFolderList: React.FC<PermissionUserFolderListConfig> = ({nameOrg,idFolder, name}) => {
    const { t, i18n } = useTranslation();
    const [userPermission, setUserPermission] = useState<UserPermission[]>([]);
    let [ItemMax, setItemMax] = useState<boolean>(true);
    let [skip, setSkip] = useState<number>(0);
    const [limit, setLimit] = useState<number>(50);

    const lastItem = useVisibility(
      (visible) => {
        if (visible) {
          if(name == "")
          {
            GetItemUser(idFolder,skip, limit).then((newGroups) => {
              if (newGroups.length < limit) {
                setItemMax(false);
              }
              setSkip(skip + newGroups.length);
              setUserPermission([...userPermission, ...newGroups]);
            });
          }
          else
          {
            GetItemUserName(idFolder, skip, limit, name).then((newGroups) => {
              if (newGroups.length < limit) {
                setItemMax(false);
              }
              setSkip(skip + newGroups.length);
              setUserPermission([...userPermission, ...newGroups]);
            });
          }
        }
      },
      [skip, idFolder, userPermission]
    );

    useEffect(() => {
      if(name == "")
      {
        GetItemUser(idFolder,0, limit).then((newWork) => {
          if (newWork.length < limit) {
            setItemMax(false);
          }
          setSkip(newWork.length);
          setUserPermission(newWork);
        });
      }
      else
      {
        GetItemUserName(idFolder, 0, limit, name).then((newWork) => {
          if (newWork.length < limit) {
            setItemMax(false);
          }
          setSkip(newWork.length);
          setUserPermission(newWork);
        });
      }
    }, [idFolder,name]);

    return (
            <ul className={stylesList.ulContent}>
                <li className={`${stylesList.itemContent} ${stylesList.title}`}>
                    <>{t("Nome.message")}</>{" "}
                    </li>
                      {userPermission.map((userPerm) => (
                            <PermissionUser
                            key={userPerm.userId}
                            idUser={userPerm.userId}
                            nameUser={userPerm.userName}
                            isAdminOrganization={userPerm.isAdminOrganization}
                            isReaderOrganization={userPerm.isReaderOrganization}
                            typeUser={userPerm.permissionType}
                            idFolder={idFolder}
                            ref={userPermission[userPermission.length - 1].userId === userPerm.userId && ItemMax ? lastItem : null}
                            />
                ))}
                </ul>
    )
}

export default PermissionUserFolderList;