import React, { useEffect, useState, useRef } from "react";
import {
	WorkSpaceItemRecursive,
	FolderInformation,
	WorkspaceItemMove,
	WorkspaceItemMultiMove
} from "../../model/ItemModel";
import { Move,MoveList } from "../../services/Item/ItemServicesCRUD";
import {
	GetTreeByWorkspaceFolderIdAll,
	GetFolderInformation,
} from "../../services/Item/ItemServicesGet";
import { useNavigate } from "react-router-dom";
import styles from "./WorkSpaceItemList.module.css";
import stylesList from "../Style/List.module.css";

import {
	GetOneWorkSpace,
	GetOneWorkSpaceFolderInformation,
} from "../../services/WorkSpaceServices";
import { useTranslation } from "react-i18next";
import { Nav, INavLinkGroup, INavLink, INavStyles } from "@fluentui/react/lib/Nav";
import WorkSpaceItemListFile, {WorkSpaceItemListFileConfigSearch} from "./WorkSpaceItemListFile";
import { NewFolderConfig } from "./PanelItens/CreateFolderComp";
import { RenameConfig } from "./PanelItens/RenameComp";
import { useUpload } from "../../context/UploadProvider/useUpload";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useResize } from "./resize/useResize";
import { Handle } from "./resize/styles";
import { Manyfilepload } from "./UploadFunction";
import { EditFolderOpen } from "./PanelItens/EditFolder";
import WorkSpaceItemListFolderTree from "./WorkSpaceItemListFolderTree";
import WaitPage from "../ErroPage/WaitPage";
import { ShareFileConfig } from "./PanelItens/ShareFile/ShareFileComp";
import { useBoxLayout } from "./ResizeStyles/ResizeFunction/useBoxLayout";
import {filesSelected} from "../WorkSpaceItem/WorkSpaceItemListFile";
import { FlowProps } from "../WorkSpaceItem/FlowFolder/WorkspaceItemFlowComponent";
import { TemporalityConfig } from "./PanelItens/TemporalityDocument"
import { CopyFolderStructureDrawerParameters } from "./PanelItens/CopyFolderStructureDrawer";

export interface WorkSpaceItemListCompConfig {
	skip: number;
	id: string | undefined;
}

interface WorkSpaceItemListFolderProps {
	
	// idWorkspace: string;
	// idFolder: string | undefined;
	configSearch: WorkSpaceItemListFileConfigSearch;

	nameOrg: string;
	//searshName: string;
	setFolderConfig: (newFolder: NewFolderConfig) => void;
	deleteItem: (itemId: string) => void;
	onClickRevision: (itemId: string) => void;
	onclikMoveOpen: (itemId: string) => void;
	editFolderOpen: (editFolderOpen: EditFolderOpen) => void;
	rename: (renameConfig: RenameConfig) => void;
	temporalityDocument:(temporalityConfig: TemporalityConfig) => void;
	setIdFolder: (folderId: string | undefined, searshParams:string | undefined) => void;
	setShareFileConfig: (shareFileConfig: ShareFileConfig) => void;
	reloadFolder: boolean;
	CheckboxItemList: any;
	reloadFolders: () => void
	onClickFlow: (flowProps : FlowProps) => void;
	setCopyFolderStructureDrawerParametersExternal: (copyFolderStructureDrawerParameters: CopyFolderStructureDrawerParameters) => void;
}

const WorkSpaceItemListFolder: React.FC<WorkSpaceItemListFolderProps> = ({
	configSearch,
	nameOrg,
	setFolderConfig,
	deleteItem,
	onClickRevision,
	onclikMoveOpen,
	editFolderOpen,
	rename,
	setIdFolder,
	setShareFileConfig,
	reloadFolder,
	CheckboxItemList,
	reloadFolders,
	onClickFlow,
	temporalityDocument,
	setCopyFolderStructureDrawerParametersExternal,
}) => {
	const navegate = useNavigate();

	const widthLocalConst:string = "width";

	const { t, i18n } = useTranslation();
	const navLinkGroups: INavLinkGroup[] = [{ links: [] }];
	const componentMounted = useRef(true);
	const [nav, setNav] = useState<INavLinkGroup[]>(navLinkGroups);
	const [displayNav, setDisplayNav] = useState<boolean>(false);
	const [nameWork, setNameWork] = useState<string>();
	const [popupError, setPopupError] = useState<boolean>(false);
	const [popupSucessOk, setPopupSucessOk] = useState<boolean>(false);
	const [sucessOkMessage, setSucessOkMessage] = useState<string>("");
	const [erromove, setErroMove] = useState<string>("");
	const [widthLocal, setWidthLocal] = useState<string>("86px");
	const [refreshFile, setRefreshFile] = useState<boolean>(false);
	const [reloadfolderlist, setReloadfolderlist] = useState<boolean>(reloadFolder);
	const upload = useUpload();
	const ref = React.useRef(null);
	const [currentFolder, setCurrentFolder] = useState<FolderInformation | undefined>(undefined);
	const { initResize, size } = useResize(ref);

	const liRef = useRef<HTMLLIElement | null>(null)
	const { boxLayout } = useBoxLayout({ breakPoint: 1000, objectInScreenReference: liRef.current?.offsetWidth! })

  function getUpload()
  {
    return upload;
  }

	useEffect(() => {
		if (reloadfolderlist == true) {
			setReloadfolderlist(false);
		}
	}, [reloadfolderlist]);

	useEffect(() => {

		setCurrentFolder(undefined);

		if (configSearch.idFolder != undefined) {
			GetFolderInformation(configSearch.idFolder).then((folderFound) => {
				setCurrentFolder(folderFound);
			});
		} else {
			GetOneWorkSpaceFolderInformation(configSearch.idWorkspace)
			.then((workspaceFound) => {
				setCurrentFolder(workspaceFound);
			});
		}

		getWorkspace(configSearch.idWorkspace);

	}, [configSearch.idWorkspace, configSearch.idFolder]);

	useEffect(() => {
		GetOneWorkSpace(configSearch.idWorkspace).then((workspaceFound) => {
			if (workspaceFound != null) {
				setNameWork(workspaceFound.name!);
			}
		});
	});

	const handleClose = (event: any, reason: any) => {
		closePopUpOk();
	};

	const handleClosePO = (event: any) => {
		closePopUpOk();
	};

	function closePopUpOk() {
		setPopupError(false);

		setPopupSucessOk(false);
	}

	const preventDefaultBehaviour = (event: React.DragEvent<HTMLDivElement>) => {
		event.stopPropagation();

		event.preventDefault();
	};

	function setPopupErrorMessage(show: boolean, message: string) {
		setErroMove(message);
		setPopupError(show);
	}

	function setPopupOkMessage(show: boolean, message: string) {
		setSucessOkMessage(message);
		setPopupSucessOk(show);
	}

	function getWorkspace(idWorkFolder: string) {
		const arrayNav: INavLinkGroup[] = [];

		const newNav: INavLinkGroup = {
			links: [],
		};

		GetTreeByWorkspaceFolderIdAll(idWorkFolder).then((WorkSpaceItem) => {
			WorkSpaceItem.forEach((element) => {
				newNav.links.push(creatLink(element));
			});

			setDisplayNav(true);

			arrayNav.push(newNav);

			setNav(arrayNav);
		});
	}

	function creatLink(element: WorkSpaceItemRecursive) {
		const link: INavLink = {
			key: element.id,

			name: element.name!,

			url: "",

			onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
				ev?.stopPropagation();

				setFolderExternal(element.id, element.name);
			},

			links: [],
		};

		if (element.children != undefined) {
			element.children.forEach((element) => {
				link.links?.push(creatLink(element));
			});
		}

		return link;
	}

	function setFolderExternal(folderId: string | undefined, folderName: string | undefined) {
		setIdFolder(folderId, undefined );
	}

	const onDrop = (
		event: React.DragEvent<HTMLDivElement>,
		key: string | undefined,
		endFunction: () => void,
	) => {
		preventDefaultBehaviour(event);

		const idItem = event.dataTransfer.getData("idFile");
		let selected = event.dataTransfer.getData("selected");
		
		if (idItem != "") {
			var json:string[]  = JSON.parse(idItem);                    
			let itemModeloList: WorkspaceItemMultiMove = {
				idItens: json,
			};
			itemModeloList.idMongoNewFolder = key;
			MoveList(itemModeloList)
			.then(() => {
				reloadFolders();
			})
			.catch((error) =>{
				let errorMessae = "";
				if (error.response && error.response.data && typeof error.response.data === "string") {
					errorMessae = error.response.data;
				} else {
					errorMessae = t("SucessMove.error");
				}
				setPopupErrorMessage(true, errorMessae);
			});
		} else {
			const items = event.dataTransfer.items;
			Manyfilepload(items, configSearch.idWorkspace, key, getUpload);
		}

		endFunction();
	};

	useEffect(() => {
		
		const widthLocalStorage = localStorage.getItem(widthLocalConst);

		if (widthLocalStorage != null) {
			setWidthLocal(widthLocalStorage);
		} else {
			localStorage.setItem(widthLocalConst, widthLocal);
		}
	}, []);

	useEffect(() => {
		if (refreshFile == true) {
			setRefreshFile(false);
		}
	}, [refreshFile]);

	return (
		<div className={`${styles.father}`}>
			<div
				className={styles.folder}
				ref={ref}
				style={{ width: widthLocal }}
			>
				{reloadfolderlist ? (
					<div></div>
				) : (
					<WorkSpaceItemListFolderTree
						idWorkspace={configSearch.idWorkspace}
						setPopupOkMessage={setPopupOkMessage}
						setPopupErroMessage={setPopupErrorMessage}
						clickFunctionWorkspace={() => setIdFolder(undefined, undefined)}
						clickFunction={setFolderExternal}
						onDrop={onDrop}
						reloadFolders={reloadFolders}
					/>
				)}
			</div>

			<Handle onMouseDown={initResize} />

			{(refreshFile == false && configSearch != undefined) ? (
				<WorkSpaceItemListFile
					nameOrg={nameOrg}
					setFolderConfig={setFolderConfig}
					setFolderExternal={setFolderExternal}
					deleteItem={deleteItem}
					onClickRevision={onClickRevision}
					onclikMoveOpen={onclikMoveOpen}
					editFolderOpen={editFolderOpen}
					currentFolder={currentFolder}
					setShareFileConfig={setShareFileConfig}
					rename={rename}
					temporalityDocument={temporalityDocument}
					boxLayout={boxLayout}
					objectReference={liRef}
					CheckboxItemList={CheckboxItemList}
					setPopupOkMessage={setPopupOkMessage}
					setPopupErroMessage={setPopupErrorMessage}
					onClickFlow={onClickFlow}
					onDrop={onDrop}
					searshParams={configSearch}
					setCopyFolderStructureDrawerParametersExternal={setCopyFolderStructureDrawerParametersExternal}
				/>
			) : (
				<WaitPage />
			)}

			<Snackbar
				open={popupError}
				autoHideDuration={6000}
				message="Archived"
				onClose={handleClose}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				sx={{ bottom: { xs: 50, sm: 20 } }}
			>
				<MuiAlert
					elevation={6}
					variant="filled"
					sx={{ width: "100%" }}
					severity="error"
					onClose={handleClosePO}
				>
					{erromove}
				</MuiAlert>
			</Snackbar>

			<Snackbar
				open={popupSucessOk}
				autoHideDuration={6000}
				message="Archived"
				onClose={handleClose}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				sx={{ bottom: { xs: 50, sm: 20 } }}
			>
				<MuiAlert
					elevation={6}
					variant="filled"
					sx={{ width: "100%" }}
					severity="success"
					onClose={handleClosePO}
				>
					{sucessOkMessage}
				</MuiAlert>
			</Snackbar>
		</div>
	);
};

export default WorkSpaceItemListFolder;
