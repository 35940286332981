import React from "react";
import BtnBigWithIcon from "../../components/Button/BtnBigWithIcon/BtnBigWithIcon";
import { useNavigate } from "react-router-dom";
import { color } from "@mui/system";

const ServerDontAllowed = (props: any) => {

    const navegate = useNavigate();

    const navegateToLogin = () => {
        navegate("/login");
    }

    return (
        <div>
            <div>
                <h1 style={{color:'white'}}>Servidor não acessível</h1>
                <BtnBigWithIcon text="Ir para login" onClick={navegateToLogin}/>
            </div>
        </div>
    );
}

export default ServerDontAllowed;