import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { WorkSpace, User } from "../../model/WorkSpaceModel";
import {
  GetOrgWorkSpaceAdmTrim
} from "../../services/WorkSpaceServices";
import { useNavigate, useParams } from "react-router-dom";
import stylesList from "../Style/List.module.css";
import { IconButton } from '@fluentui/react/lib/Button';
import { IIconProps } from '@fluentui/react';
import { useTranslation } from "react-i18next";
import {
  GetOrganizationWorkspaceAdmTrimName
} from "../../services/OrganizationServices";
import { AddWorkspaceLocalStorage } from "../PublicLayout/RedirectManager";

export interface WorkSpaceListCompConfig {
  skip: number;
  id: string | undefined;
}

export interface DropdownMenuContextoConfig {
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
}

function useVisibility(
  cb: (isVisible: boolean) => void,
  deps: React.DependencyList
): (node: any) => void {
  const intersectionObserver = useRef<IntersectionObserver | null>(null);
  return useCallback((node) => {
    if (intersectionObserver.current) {
      intersectionObserver.current.disconnect();
    }

    intersectionObserver.current = new IntersectionObserver(([entry]) => {
      cb(entry.isIntersecting);
    });

    if (node) intersectionObserver.current.observe(node);
  }, deps);
}

interface WorkItemProps {
  id?: string;
  nameOrg: string;
  name?: string;
  hiddenContexMenu: boolean;
  ref?: React.Ref<HTMLLIElement>;
}


interface ListWorkId {
  nameOrg: string;
  name: string;
  onClickEditar?: React.MouseEventHandler<HTMLElement> | undefined;
  onClickExcluir?: React.MouseEventHandler<HTMLElement> | undefined;
  hiddenContexMenu: boolean;
}

const WorkSpaceListCompAdm: React.FC<ListWorkId> = ({ nameOrg, name, onClickEditar, onClickExcluir, hiddenContexMenu }) => {
  const [workSpace, setWork] = useState<WorkSpace[]>([]);
  const navegate = useNavigate();
  const [limit, setLimit] = useState<number>(50);

  const [itens, setItens] = useState<WorkSpace | null>(null);
  const [newOrgName, setNewOrgName] = useState<string>();
  const forceEdit = useCallback((teste: string) => setNewOrgName(teste), []);

  const [isMessageError, setMessageError] = useState<boolean>(false);
  const [isMessageOk, setMessageOk] = useState<boolean>(false);

  let [workspaceMax, setWorkspaceMax] = useState<boolean>(true)
  let [orderDesc, setOrderDesc] = useState<boolean>(false);
  let [skip, setSkip] = useState<number>(0);
  let [WorkMax, setWorkMax] = useState<boolean>(true);
  const { t, i18n } = useTranslation();

  const WorkItem: React.ForwardRefExoticComponent<WorkItemProps> =
    React.forwardRef(({ id, name, hiddenContexMenu, nameOrg }, ref: React.Ref<HTMLLIElement>) => {
      function redirect() {
        if (id) {
          AddWorkspaceLocalStorage(id);
        }

        navegate("/" + nameOrg + "/wsallowed/" + id);
      }

      return (
        <div>
          <li className={`${stylesList.itemContent} ${stylesList.row} ${stylesList.pointer}`} key={id} ref={ref} onClick={redirect}>
            {name}{" "}
          </li>
        </div>
      );
    });

  const lastBook = useVisibility(
    (visible) => {
      if (visible) {
        if (name == "") {
          GetOrgWorkSpaceAdmTrim(nameOrg, skip, limit, orderDesc).then((newWork) => {
            if (newWork.length < limit) {
              setWorkMax(false);
            }
            setSkip(skip + newWork.length);
            setWork([...workSpace, ...newWork]);
          });
        }
        else {
          GetOrganizationWorkspaceAdmTrimName(nameOrg, skip, limit, orderDesc, name).then((newWork) => {
            if (newWork.length < limit) {
              setWorkspaceMax(false);
            }
            setSkip(skip + newWork.length);
            setWork([...workSpace, ...newWork]);
          });
        }
      }
    },
    [nameOrg, skip, workSpace]
  );


  useEffect(() => {
    if (name == "") {
      GetOrgWorkSpaceAdmTrim(nameOrg, 0, limit, orderDesc).then((newWork) => {
        if (newWork.length < limit) {
          setWorkMax(false);
        }
        setSkip(newWork.length);
        setWork(newWork);
      });
    }
    else {
      GetOrganizationWorkspaceAdmTrimName(nameOrg, 0, limit, orderDesc, name).then((newWork) => {
        console.log(newWork);
        if (newWork.length < limit) {
          setWorkMax(false);
        }
        setSkip(newWork.length);
        setWork(newWork);
      });
    }
  }, [nameOrg, name, orderDesc]);

  function resetMessage() {
    setMessageOk(false);
    setMessageError(false);
  }

  const asc: IIconProps = {
    iconName: 'Down',
    styles: {
      root: {
        color: 'black',
        fontWeight: 'bold'
      }
    }
  };

  const desc: IIconProps = {
    iconName: 'Up',
    styles: {
      root: {
        color: 'black',
        fontWeight: 'bold'
      }
    }
  };

  const ascClick = () => {
    setOrderDesc(true)
  };

  const descClick = () => {
    setOrderDesc(false)
  };

  return (
    <div>
      <ul className={stylesList.ulContent}>
        <li className={`${stylesList.itemContent} ${stylesList.title}`}>
          <>{t("NomeWorkspace.message")}</>{" "}
          {orderDesc ?
            (<IconButton iconProps={desc} title="Descendente" ariaLabel="Descendente" onClick={descClick} />) :
            (<IconButton iconProps={asc} title="Ascendente" ariaLabel="Ascendente" onClick={ascClick} />)}
        </li>
        {workSpace.map((workSpaceC) => (
          <WorkItem
            key={workSpaceC.id}
            id={workSpaceC.id}
            nameOrg={nameOrg}
            name={workSpaceC.name}
            ref={workSpace[workSpace.length - 1].id === workSpaceC.id && WorkMax ? lastBook : null}
            hiddenContexMenu={hiddenContexMenu}
          />
        ))}
      </ul>
      <div>
        {WorkMax === false ?
          null
          : null}
      </div>
    </div>
  );
}
export default WorkSpaceListCompAdm;
