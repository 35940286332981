import * as React from 'react';
import { GridColumn, GridColumnDeleted } from ".././../pages/WorkSpaceItem/WorkSpaceItemListStyle/ColumnStandard";
import { GridContextType } from './GridContextTypes';

import { SetColumnParameters } from ".././../pages/WorkSpaceItem/WorkSpaceItemListStyle/WorkspaceColumnSave";

export const GridContext = React.createContext<GridContextType | null>(null);

const GridProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {

    const [columns, setColumns] = React.useState<GridColumnDeleted[]>([]);

    const setColumnsEx = (gridColumns: GridColumn[]) => {
      setColumns(gridColumns.map(column => ({
        name: column.name,
        length: column.length,
        deleted: column.deleted == true
      } as GridColumnDeleted)));
    }

    const addColumn = (gridColumn: GridColumn) => {
      let changed = false;
      columns.filter((columnChanged: GridColumnDeleted) => {
        if (columnChanged.name === gridColumn.name) {
          columnChanged.deleted = false;
          columnChanged.length = gridColumn.length;
          setColumns([...columns]);
          changed = true;
        }
      });

      if(changed == false)
      {
        const newColumn: GridColumnDeleted = {
          name: gridColumn.name,
          length: gridColumn.length,
          deleted: false
        };
        setColumns([...columns, newColumn]);
      }
    }

    const removeColumn = (name: string) => {
      columns.filter((columnDeleted: GridColumnDeleted) => {
        if (columnDeleted.name === name) {
          columnDeleted.deleted = true;
          setColumns([...columns]);
        }
      });
    }

    const changeColumnSize = (name: string, length:string ) => {
      columns.filter((columnDeleted: GridColumnDeleted) => {
        if (columnDeleted.name === name) {
          columnDeleted.length = length;
          setColumns([...columns]);
        }
      });
    }
    
    const persistColumn = () => {
      SetColumnParameters(columns);
    }

    return (
        <GridContext.Provider value={{ columns, setColumnsEx, addColumn, removeColumn, changeColumnSize, persistColumn }}>
          {children}
        </GridContext.Provider>
      );
  }

  export default GridProvider;