import { Grid, Paper, useTheme } from '@mui/material'
import FormRoot, { FormRootInstance } from './Form/Root/FormRoot';
import FormIcon from './Form/Components/FormIcon';
import FormHeader from './Form/Components/FormHeader';

interface CenterFormProps {
    children: any;
    icon?: string;
    title: string;
    subtitle: string;
    titleSize: number;
}

export function CenterForm({
    children,
    icon,
    title,
    subtitle,
    titleSize
}: CenterFormProps) {
    
    const theme = useTheme();

    return (
        <Grid container xs={12} sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center',background: '#ffff' }}>
            <Paper
                sx={{
                    width: '480px',
                    display: 'flex',
                    flexDirection: 'column',
                    fontFamily: 'Segoe UI',
                    boxShadow: 'none',

                    [theme.breakpoints.down("lg")]: {
                        margin: 'auto',
                        marginTop: '10px',
                        marginBottom: '10px',
                        border: "2px solid rgb(45, 45, 45)"
                    },

                    [theme.breakpoints.down("md")]: {
                        marginLeft: 0,
                        marginTop: 0,
                        marginBottom: 0,
                        width: '100%',
                        border: "0px solid rgb(45, 45, 45)"
                    }
                }}
            >
                <Grid item xs={12} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'rgb(45, 45, 45, 0.05)',
                    margin: 'auto',
                    borderRadius: '50%'
                }}>
                    {icon ? (
                        <FormIcon
                            icon={icon}
                        />
                    ) : 
                        <></>
                    }
                   
                </Grid>

                <Grid sx={{ padding: '16px 0px', textAlign:'center' }}>
                    <FormHeader
                        titleSize={titleSize}
                        title={title}
                        subTitle={subtitle}
                    />
                </Grid>

                {children}
                
            </Paper>
        </Grid >
    )
}