import { PathListMap } from "./PathListMap"
import { IPath } from "../../model/ItemModel"
import stylesList from "./WorkSpaceItemList.module.css";

interface PathListingMapProps {
    path: IPath[]
    onClickLinkMap: (idFolder:string | undefined, nameFolder:string | undefined) => void
}

const renderElements = (path: IPath[], onClickLinkMap: (idFolder:string | undefined, nameFolder:string | undefined) => void) => {
    function onClickLinkMapInternal(idFolder:string | undefined, nameFolder:string | undefined)
    {
        onClickLinkMap(idFolder, nameFolder);
    }

    return (
        path.map((item, index, array) => {
            return (
                <PathListMap 
                    id={item.id}
                    name={item.name}
                    listPathLength={array.length}
                    key={index}
                    onClickMap={() => onClickLinkMapInternal(item.id, item.name)}
                    index={index}
                />
            )
        })
    )
}

export const PathListingMap: React.FC<PathListingMapProps> = ({ path, onClickLinkMap }) => {
    return (
        <div className={stylesList.mainPathList}>
            { renderElements(path, onClickLinkMap) }
        </div>
    )
}