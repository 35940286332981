export function returnByteWithNOtation(totslByte: number | undefined) {
    if (totslByte == undefined) {
      return "--";
    } else if (totslByte != undefined) {
      let repetion: number = 0;
      let bytes: number = totslByte;

      while (bytes >= 1024 && repetion < 4) {
        bytes = bytes / 1024;
        repetion = repetion + 1;
      }

      if (repetion == 0) {
        return (
          Math.round(bytes).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          }) + " bytes"
        );
      } else if (repetion == 1) {
        return (
          Math.round(bytes).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          }) + " Kb"
        );
      } else if (repetion == 2) {
        return (
          Math.round(bytes).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          }) + " Mb"
        );
      } else if (repetion == 3) {
        return (
          Math.round(bytes).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          }) + " Gb"
        );
      } else if (repetion == 4) {
        return (
          Math.round(bytes).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          }) + " Tb"
        );
      }
    }
  }