import { Box, Divider, Grid, Tooltip, Typography } from '@mui/material';
import ProgressBar from '../ProgressBar/ProgressBar';
import { ISizeWorkspace } from '../../../../model/OrganizationModel';
import BaseText from '../../../../components/BaseText/BaseText';
import { useTranslation } from 'react-i18next';
import DashboardLegend from '../DashboardLegend/DashboardLegend';
import { convertToNextUnit } from '../../functions/convertNextUnit';

interface IDashboardGraph {
    workspaces: ISizeWorkspace[];
    max: number;
    min: number;
    hasContract: boolean;
}

export default function DashboardGraph({
    workspaces,
    max,
    min,
    hasContract,
}: IDashboardGraph) {

    const { t, i18n } = useTranslation()

    console.log(max, min)

    return (
        <>
            <Grid item
                xs={6}
                marginBottom={4}
            >
                <BaseText 
                    text={t("Dashboard.wsDataUsage")}
                    fontStyle="normal"
                    fontVariantCss="normal"
                    fontWeight="bold"
                    textColor="#2D2D2D"
                    maxFontSize="30px"
                    minFontSize="20px"
                />
            </Grid>
            <Grid item
                xs={6}
                justifyContent="flex-end"
                display="flex"
            >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            marginRight: "20px"
                        }}
                    >

                        <Box 
                            component="div"
                            sx={{
                                height: '16px',
                                width: "16px",
                                borderRadius: "50%",
                                background: "#114D88"
                            }}
                        />
                        <BaseText
                            text={t("Dashboard.storageInUse")}
                            fontStyle="normal"
                            fontVariantCss="normal"
                            fontWeight="bold"
                            textColor="#2D2D2D"
                            maxFontSize="24px"
                            minFontSize="16px"
                        />
                    </Box>
            </Grid>
            <Grid container
                position="relative"
            >
            { workspaces.map((workspace, index) => (
                <>
                    <Grid item
                        xs={1.4}
                        marginBottom="35px"
                    >
                        <Typography
                            sx={{
                                textAlign: "left",
                                font: "normal normal normal 16px/24px Segoe UI",
                                letterSpacing: "0px",
                                color: "#2D2D2D",
                                opacity: 1,
                            }}
                            maxWidth="180px"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                        >
                            { workspace.nameWorkspace }
                        </Typography>
                    </Grid>
                    <Grid item 
                        xs={0.9}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                textIndent: "15px"
                            }}
                        >
                            <Tooltip
                                title={convertToNextUnit(workspace?.size.storageUseTotal!, max!, workspace?.size.storageUseTotal!, i18n.language).bytesNotConverted}
                            >
                                <Typography
                                    sx={{
                                        textAlign: "left",
                                        font: "normal normal bold 16px/24px Segoe UI",
                                        letterSpacing: "0px",
                                        color: "#2D2D2D",
                                        opacity: 1,
                                    }}
                                >
                                    { convertToNextUnit(workspace?.size.storageUseTotal!, max!, workspace?.size.storageUseTotal!, i18n.language).bytesConverted }
                                </Typography>
                            </Tooltip> 
                        </Box>
                        { index != workspaces.length - 1 && (
                            <Divider 
                                style={{
                                    borderColor: "black",
                                    marginTop: "16px"
                                }}
                            />
                        ) }
                    </Grid>
                    <Grid item
                        xs={9.7}
                        position="relative"
                    >
                        <ProgressBar
                            consumedValue={workspace?.size.storageUseTotal!}
                            maxValue={max}
                            minValue={min}
                            isGraph
                            hasContract={hasContract}
                        />
                        { index != workspaces.length - 1 && (
                            <Divider 
                                style={{
                                    borderColor: "black",
                                    marginTop: "10px"
                                }}
                            />
                        ) }
                    </Grid>
                </>
            )) }
            <Grid item
                display="flex"
            >
                <Grid 
                    item
                    xs={9.7}
                    position="absolute"
                    top={0}
                    right={0}
                    width="100%"
                    height="100%"
                    borderBottom="1px solid black"
                    display="flex"
                    justifyContent="space-between"
                >
                    <Divider
                        orientation='vertical'
                        sx={{
                            borderStyle: "dashed",
                            borderSpacing: 2
                        }}
                    />
                    <Divider 
                        orientation='vertical'
                        sx={{
                            borderStyle: "dashed",
                            borderSpacing: 2
                        }}
                    />
                    <Divider 
                        orientation='vertical'
                        sx={{
                            borderStyle: "dashed",
                            borderSpacing: 2
                        }}
                    />
                </Grid>
            </Grid>
            <DashboardLegend 
                max={max}
                min={min}
            />
            </Grid>
        </>
    )
}