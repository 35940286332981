import { ValidationTypes } from "../../../model/ValidationRuleModel";
import { GetValidationTypes } from "../../../services/ValidationRuleService";
import { IComboBoxOption } from '@fluentui/react';
import { TFunction } from "react-i18next";

export function GetOptions(t: TFunction<"translation", undefined>, organizationId:string)
{
    return new Promise<IComboBoxOption[]>( (resolve, reject) => {
        GetValidationTypes(organizationId)
        .then((result) =>{
            let options: IComboBoxOption[] = [];
            options.push( { key: 'SE', text: t("Select.message") });
            result.forEach(validationType => {
                options.push( { key: validationType.index, text: validationType.name });
            });

            resolve(options);
        })
        .catch((error) =>{
            reject(error);
        });
    });
}

export function GetText(options: IComboBoxOption[], keySearsh:string)
{
    let returnFinder = options.filter(x => x.key == keySearsh);

    if(returnFinder.length == 1)
    {
        return(returnFinder[0].text);
    }
    else
    {
        return("");
    }
}