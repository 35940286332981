import { useState, useEffect } from 'react';
import { Box, Fade, Grid, Button } from '@mui/material';
import DocumentDetailTabFilterButton from '../DocumentDetailTabFilterButton/DocumentDetailTabFilterButton';
import { GetTrackerWithFilters, getEnumAction, getEnumOrder } from '../../../../../../../../../services/Item/ItemServicesGet';
import { ITrackerItems } from '../../DocumentDetailActivityTable/DocumentDetailActivityTable';
import { WorkSpaceItemRevision } from '../../../../../../../../../model/ItemModel';
import { FilterOptions } from '../DocumentDetailTabMenuFilter/DocumentDetailFilter/DocumentDetailFilterTk/FilterTk';
import DocumentDetailFilterTk from '../DocumentDetailTabMenuFilter/DocumentDetailFilter/DocumentDetailFilterTk/FilterTk';
import { convertItemFunction } from '../utils/convertItemFunction';
import LoaderComp from '../../../../../../../../../components/Loader/LoaderComp';
import { useTranslation } from 'react-i18next';


interface IDocumentDetailTabFilterTracker
{
    itemId: string;
    setItemTracker: React.Dispatch<React.SetStateAction<ITrackerItems[] | undefined>>;    
    persistedItemRevision: WorkSpaceItemRevision[] | undefined;
    persistedItemTracker: ITrackerItems[] | undefined;
    setRevisionIdList: React.Dispatch<React.SetStateAction<string[]>>;
    revisionIdList: string[];
    revisionTrackerLoading: boolean;
}

export default function DocumentDetailTabFilterTracker({
    itemId,
    setItemTracker,
    persistedItemRevision,
    persistedItemTracker,
    setRevisionIdList,
    revisionIdList,
    revisionTrackerLoading
}: IDocumentDetailTabFilterTracker)
{
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [itemRevisionOriginalState, setItemRevisionOriginalState] = useState<WorkSpaceItemRevision[] | undefined>([]);
    const [alphabeticState, setAlphabeticState] = useState<string | number | null>(null);
    const [ascDescState, setAscDescState] = useState<string | number | null>(null);
    const [reviewState, setReviewState] = useState<string | number | null>(null);
    const [enumAction, setEnumAction] = useState<FilterOptions<string | number>[]>();
    const [enumOrder, setEnumOrder] = useState<FilterOptions<string | number>[]>();
    const [ordenationValue, setOrdenationValue] = useState<number>(0);
    const { t } = useTranslation();

    useEffect(() => {
        getEnumAction()
        .then(data => {
            let actionEnums: FilterOptions<string | number>[] = [];
            data.map(acEnum => {
                actionEnums.push({ label: acEnum.name, value: acEnum.index });
            })
            setEnumAction(actionEnums);
        })

        getEnumOrder()
        .then(data => {
            let orderEnums: FilterOptions<string | number>[] = [];
            data.map(odEnum => {
                orderEnums.push({ label: odEnum.name, value: odEnum.index });
            })
            setEnumOrder(orderEnums);
        })
        setItemRevisionOriginalState(persistedItemRevision);

        return () => { 
            setItemTracker(persistedItemTracker);
        }
    }, [])

    useEffect(() => {
        if (revisionIdList.length <= 0) {
            setReviewState(null);
        }
    }, [revisionIdList.length])
    
    const alphabeticOptions: FilterOptions<string | number>[] = [
        { label: t("FilterTables.OrderByUsers"), value: 1 },
        { label: t("FilterTables.OrderByMessages"), value: 2 },
    ]!;

    const dateOrdenateOptions: FilterOptions<string | number>[] = [
        { label: t("FilterTables.Descending"), value: 3 },
        { label: t("FilterTables.Ascending"), value: 4 },
    ]!

    const enumerateReviews = itemRevisionOriginalState?.map(reviews => ({ label: reviews.enumerate?.toString()!, value: reviews.id! }));
    const reviewOptions: FilterOptions<string | number>[] =  enumerateReviews!;
    
    function clearAllFilters() {
        setAlphabeticState(null);
        setAscDescState(null);
        setReviewState(null);
        setRevisionIdList([]);
        setItemTracker(persistedItemTracker);
        setOrdenationValue(0);
    }

    function clearRadioFilters() {
        setAlphabeticState(null);
        setAscDescState(null);
    }
    
    function handleAlphabeticalFilterChange(value: string | number) {
        setOrdenationValue(Number(value));
        GetTrackerWithFilters(itemId, Number(value), revisionIdList, false)
        .then(data => {
            convertItemFunction(data, setItemTracker, (tracker) => {
                return {
                    ip: tracker.ipUser!,
                    message: tracker.message!,
                    nameUser: tracker.nameUser!,
                    revision: tracker.enumerate!,
                    trackerDateTime: tracker.dateCreateTracker!
                }
            })
        })
        .catch(error => console.log(error.data.message))
    };

    function handleDateOrdenateOptions(value: string | number) {
        setOrdenationValue(Number(value));
        GetTrackerWithFilters(itemId, Number(value), revisionIdList, false)
        .then(data => {
            convertItemFunction(data, setItemTracker, (tracker) => {
                return {
                    ip: tracker.ipUser!,
                    message: tracker.message!,
                    nameUser: tracker.nameUser!,
                    revision: tracker.enumerate!,
                    trackerDateTime: tracker.dateCreateTracker!
                }
            })
        })
        .catch(error => console.log(error.data.message))
    };

    function handleReview(value: string | number) {  
        GetTrackerWithFilters(itemId, ordenationValue, revisionIdList, false)
        .then(data => {
            convertItemFunction(data, setItemTracker, (tracker) => {
                return {
                    ip: tracker.ipUser!,
                    message: tracker.message!,
                    nameUser: tracker.nameUser!,
                    revision: tracker.enumerate!,
                    trackerDateTime: tracker.dateCreateTracker!
                }
            })
        })
        .catch(error => console.log(error.data.message))
    };

    if (revisionTrackerLoading) {
        return (<></>)
    }

    return (
        <Box sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: !showFilters ? 'flex-end' : 'space-between',
                marginBottom: '15px'
            }}>
            { showFilters && (
                <Fade in={showFilters} mountOnEnter>
                    <Box sx={{
                        width: '100%'
                    }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8} sx={{
                            display: 'flex',
                            gap: 2
                        }}>
                            <DocumentDetailFilterTk 
                                label={t("FilterTables.AlphaOrder")}
                                options={alphabeticOptions} 
                                onFilterChange={handleAlphabeticalFilterChange}
                                selectedValue={alphabeticState}
                                setSelectedValue={setAlphabeticState}
                                clearAllFilters={clearAllFilters}
                                clearRadioFilters={clearRadioFilters}
                                setRevisionIdList={setRevisionIdList}
                                revisionIdList={revisionIdList}
                            />

                            <DocumentDetailFilterTk 
                                label={t("FilterTables.OrdenationDate")} 
                                options={dateOrdenateOptions} 
                                onFilterChange={handleDateOrdenateOptions}
                                selectedValue={ascDescState}
                                setSelectedValue={setAscDescState}
                                clearAllFilters={clearAllFilters}
                                clearRadioFilters={clearRadioFilters}
                                setRevisionIdList={setRevisionIdList}
                                revisionIdList={revisionIdList}
                            />

                            <DocumentDetailFilterTk 
                                label={t("FilterTables.Reviews")} 
                                options={reviewOptions} 
                                onFilterChange={handleReview}
                                selectedValue={reviewState}
                                setSelectedValue={setReviewState}
                                clearAllFilters={clearAllFilters}
                                clearRadioFilters={clearRadioFilters}
                                isReview
                                setRevisionIdList={setRevisionIdList}
                                revisionIdList={revisionIdList}
                            />
                            <Button
                                variant='text'
                                sx={{
                                    font: "normal normal 600 16px/24px Segoe UI",
                                    letterSpacing: "0px",
                                    color: "#005A9E",
                                    opacity: 0.4,
                                    transition: 'all 0.3s',
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                    ':hover': {
                                        opacity: 1,
                                        textDecoration: 'underline',
                                    }
                                }}
                                onClick={clearAllFilters}
                            >
                                {t("FilterTables.clearFilters")}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                </Fade>
            ) }
            <DocumentDetailTabFilterButton 
                setShowFilters={setShowFilters}
                showFilters={showFilters}
                isLoading={revisionTrackerLoading}
                onClick={clearAllFilters}
            />
        </Box>
    )
}