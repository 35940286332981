import {Color} from "./interface/Color"

export const AddIcon: React.FC<Color> = ({
    color
  }) => {
    return(
        <svg width="20.172" height="20.172" viewBox="0 0 20.172 20.172">
            <path 
                id="add-icon" 
                d="M14.086,4A10.086,10.086,0,1,0,24.172,14.086,10.083,10.083,0,0,0,14.086,4Zm5.043,11.095H15.095v4.034H13.077V15.095H9.043V13.077h4.034V9.043h2.017v4.034h4.034Z" 
                transform="translate(-4 -4)"
    
                fill={color}
            />
        </svg>

    )
  }