import { Box, SxProps, Theme } from "@mui/material"

interface FormIconProps {
  icon: string
  sx?: SxProps<Theme> | undefined
}

export function FormIcon({ icon, sx }: FormIconProps) {
  return (
    <Box sx={sx}>
      <img src={icon} />
    </Box>
  )
}