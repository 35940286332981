import {Color} from "./interface/Color"

export const Feed: React.FC<Color> = ({
    color
  }) => {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20">
            <g id="Grupo_3" data-name="Grupo 3" transform="translate(-114 -299.44)">
                <circle 
                    id="Elipse_1botões em a" 
                    data-name="Elipse 1" 
                    cx="2.802" 
                    cy="2.802" 
                    r="2.802" 
                    transform="translate(114 313.836)" 
                    fill={color}
                />
                <path 
                    id="Caminho_17" 
                    data-name="Caminho 17" 
                    d="M4,4.44V8.078A16.363,16.363,0,0,1,20.362,24.44H24a20.006,20.006,0,0,0-20-20Zm0,7.275v3.638a9.1,9.1,0,0,1,9.087,9.087h3.638A12.721,12.721,0,0,0,4,11.715Z" 
                    transform="translate(110 295)" 
                    fill={color}
                />
            </g>
        </svg>

    )
  }