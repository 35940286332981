import React, { useEffect, useRef, useState, useMemo, RefObject } from "react";
import * as PdfJs from "pdfjs-dist";
import PageViewer from "./PageViewer";

function useOnScreen(ref: RefObject<HTMLElement>) {
    const [isIntersecting, setIntersecting] = useState(false)
  
    const observer = useMemo(() => new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting)
    ), [ref])
  
  
    useEffect(() => {
        if(ref.current)
            observer.observe(ref.current)
        return () => observer.disconnect()
    }, [])
  
    return isIntersecting
}


interface LazyViewerProps {
    canRender: boolean;
    documentSize: { width: number, height: number };
    pageNumber: number;
    pdfPage: PdfJs.PDFPageProxy | undefined;
    scale: number;
    fetchPage: (pageNumber : number) => void;
    setTextPdfList: React.Dispatch<React.SetStateAction<(HTMLCollection | Element[] | undefined)[]>>;
    ref: React.Ref<HTMLDivElement>
}

const LazyViewer: React.ForwardRefExoticComponent<LazyViewerProps> = 
    React.forwardRef(({
        canRender, documentSize, pageNumber, pdfPage, scale, fetchPage, setTextPdfList
      }, ref) => {
          const canvasLocationRef = useRef<HTMLDivElement>(null)
          const isVisible = useOnScreen(canvasLocationRef);

          useEffect(() => {
            if(isVisible){
                if(canRender == false){
                    fetchPage(pageNumber)
                }
            }
          }, [isVisible]) 

          return (
            <div ref={canvasLocationRef} style={{ minWidth: documentSize.width, minHeight: documentSize.height, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div ref={ref}>
                    {(pdfPage) && (
                        <PageViewer
                            canRender={canRender}
                            pdfPage={pdfPage}
                            scale={scale}
                            setTextPdfList={setTextPdfList}
                        />
                    )}
                </div>
            </div>
          );
      }
)

export default LazyViewer;