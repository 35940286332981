import { bytesConverter } from "../components/MemoryProgress/bytesConverter";

export function convertToNextUnit(value: number, maxValue: number, consumedValue: number, locale: string): {
    bytesConverted: string,
    bytesNotConverted: number | string
} {
    const units = ["B", "KB", "MB", "GB", "TB"];
    
    const maxNumberUnit = bytesConverter(maxValue)?.slice(-2);
    const unitIndex = units.indexOf(maxNumberUnit!);
    const exponent = unitIndex;
    const actualConsumedValueUnit = bytesConverter(value)?.slice(-2);
    
    let result = consumedValue;
    
    if (actualConsumedValueUnit !== maxNumberUnit) {
        result = value / Math.pow(1024, exponent);
        
        if (result === 0) {
            return {
                bytesConverted: `${result}${maxNumberUnit}`,
                bytesNotConverted: Math.round(consumedValue * 0.125) + " B"
            }
        }
        
        const epsilon = 0.001; 
        if (Math.abs(result) < epsilon) {
            let epsilonResult = locale == "pt-BR" ? "0,01" : "0.01";
            
            return {
                bytesConverted: `${epsilonResult}${maxNumberUnit}`,
                bytesNotConverted: Math.round(consumedValue * 0.125)+ " B"
            }
        }
        
        const roundedResult = Math.round(result * 100) / 100;

        return {
            bytesConverted: `${roundedResult.toLocaleString(locale)}${maxNumberUnit}`,
            bytesNotConverted: Math.round(consumedValue * 0.125)+ " B"
        };
    }
    
    return {
        bytesConverted: bytesConverter(result)!,
        bytesNotConverted: Math.round(consumedValue * 0.125)+ " B"
    };
}
