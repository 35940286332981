import { SidebarHeader } from "react-pro-sidebar";
import { Box} from '@mui/material';
import { doubleArrowIcon } from '../../../../../../../assets/icons';
import styles from '../../../../../Documentdetail.module.css';
import { IconSideComponent } from '../../../../../../../components/Sidebar/components/IconSideComponent';
import FlowSidebarTitle from "./FlowSidebarTitle";

const internalCollapsedStyles = {
    padding: '40px',
    textAlign: 'center',
    display: "flex",
    justifyContent: "center",
    borderBottom: 'none'
}

const internalStyles = {
    padding: '40px',
    borderBottom: 'none'
}

interface IFlowSidebarHeader
{
    itemName?: string
    itemExtension?: string
    isSidebarCollapsed: boolean;
    setIsSideBarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function FlowSidebarHeader({
    itemName,
    itemExtension,
    isSidebarCollapsed,
    setIsSideBarCollapsed
}: IFlowSidebarHeader)
{
    return (
        <SidebarHeader
        style={isSidebarCollapsed ? internalCollapsedStyles : internalStyles}
        className={styles.sideBarHeaderStyles}
        >
            <Box
                onClick={() => setIsSideBarCollapsed(!isSidebarCollapsed)}
            >
                <Box 
                sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    gap: 2,
                    minWidth:"70%",
                    marginRight:"30px"
                }}>
                    { isSidebarCollapsed ? <IconSideComponent icon={doubleArrowIcon} isInvert /> : <IconSideComponent icon={doubleArrowIcon} /> }
                    { !isSidebarCollapsed && (
                        <FlowSidebarTitle itemName={itemName} itemExtension={itemExtension}/>
                    )}  
                </Box> 
            </Box>   
        </SidebarHeader>
    )
}