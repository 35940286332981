import { Box, Typography } from '@mui/material';
import { TimeLineIcon } from "../../../../../../../assets/svg/TimeLineIcon";
import { Tooltip, TooltipHost } from '@fluentui/react';


interface IFlowSidebarTitle
{
    itemName?: string
    itemExtension?: string
}

export default function FlowSidebarTitle({
    itemName,
    itemExtension,
}: IFlowSidebarTitle)
{

    const truncateText = (text: string, maxLength: number) => {
        return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
      };

    return (
   
        <Box sx={{minWidth:"100%"}}>
            <Typography sx={{display:"flex"}}>             
                <TimeLineIcon/>
                <Typography             
                    sx={{
                        textAlign: "left",
                        font: "normal normal 600 24px/36px Segoe UI",
                        letterSpacing: "0px",
                        color: "#2D2D2D",
                        opacity: 1,
                        fontWeight:"fontWeightBold",
                        marginTop:"-5px"
                    }}>
                        Timeline
           
                        <TooltipHost
                        content={itemName + (itemExtension != undefined ? "." + itemExtension : "")}
                        >
                            <Typography   
                                sx={{
                                    textAlign: "left",
                                    letterSpacing: "0px",
                                    color: "#2D2D2D",
                                    opacity: "60%",
                                    fontSize:"20px",
                                }}>
                                {truncateText(itemName ?? '', 30)}.
                                {itemExtension}
                            </Typography> 
                        </TooltipHost>
                </Typography>     
            </Typography>
            <Typography sx={{border:"solid black 2px", marginTop:"30.5px", marginBottom:"-23px"}}></Typography>
        </Box>  
    )
}