import { Grid } from "@mui/material";

interface IDocumentDetailHeaderActionsWrapper
{
    children: React.ReactNode;
}

export default function DocumentDetailHeaderActionsWrapper({
    children
}: IDocumentDetailHeaderActionsWrapper)
{
    return (
        <Grid 
            container 
            display='flex'
            alignItems='center'
            flexDirection="row-reverse"
            sx={{
                background: "#F5F5F5 0% 0% no-repeat padding-box",
                boxShadow: "0px 0px 6px #00000029",
                opacity: 1,
                height: '58px',
                zIndex: 1000
            }}
        >
            { children }
        </Grid>
    )
}