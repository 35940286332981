export interface PptConfig {}

export const Ppt: React.FC<PptConfig> = ({}) => {
  return(
    <svg id="icon-ppt" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="Retângulo_238" data-name="Retângulo 238" width="24" height="24" rx="2" fill="#ef6c00" />
      <path id="Caminho_193" data-name="Caminho 193" d="M5,17V5h6.9a5.92,5.92,0,0,1,3.765,1.05A3.471,3.471,0,0,1,17,8.93a3.471,3.471,0,0,1-1.335,2.88A5.92,5.92,0,0,1,11.9,12.86H8.75V17H5.015Zm3.735-6.54H11.24c1.47,0,2.19-.51,2.19-1.53S12.695,7.4,11.24,7.4H8.735Z" transform="translate(1 1)" fill="#fff" />
    </svg>
  )
}
