import React, {useState} from "react";
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import  Btn  from '../Button/Btn';
import { useBoolean } from '@fluentui/react-hooks';


export interface MessageCompConfig{
    text?: string;
    buttonMethod?:() => void;
    title: string;
    closeButtonAriaLabel:string,
    subText?: string,
    hidden?:boolean
}

const MessageComp = (props: MessageCompConfig) => {
    
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(false);
  const [hidden, setHidden] = useState<boolean>(props.hidden != undefined ?props.hidden : false);
  
  function rightMethod()
  {
    toggleHideDialog();
    if(props.buttonMethod != undefined)
    {props.buttonMethod();}
  }


  const dialogContentProps = {
    type: DialogType.normal,
    title: props.title,
    closeButtonAriaLabel: props.closeButtonAriaLabel,
    subText: props.subText
  };

    return (
        <Dialog
            hidden={hidden}
            onDismiss={toggleHideDialog}
            dialogContentProps={dialogContentProps}
        >
        <DialogFooter>
          <Btn onClick={rightMethod} text={props.text} />
        </DialogFooter>
      </Dialog>
    );
}

export default MessageComp;