import React, { useEffect, useState, useRef, useCallback } from "react";
// import axios from "axios";
import { Group } from "../../model/GroupModel";
import { GetOrganizationGroup, GetOrganizationGroupName } from "../../services/OrganizationServices";
import styles from "../Style/List.module.css";
import LongMenu from "../../components/MenuContexto/MenuContexto";
import stylesList from "../Style/List.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import I18n, { TranslatorP, Translator, TranslatorS } from '../../components/I18n';
import "../../components/MenuContexto/MenuContexto.css";

function useVisibility(
  cb: (isVisible: boolean) => void,
  deps: React.DependencyList
): (node: any) => void {
  const intersectionObserver = useRef<IntersectionObserver | null>(null);
  return useCallback((node) => {
    if (intersectionObserver.current) {
      intersectionObserver.current.disconnect();
    }

    intersectionObserver.current = new IntersectionObserver(([entry]) => {
      cb(entry.isIntersecting);
    });

    if (node) intersectionObserver.current.observe(node);
  }, deps);
}

interface GroupItemProps {
  id?: string | undefined;
  idGroup?: string | undefined;
  name?: string;
  ref?: React.Ref<HTMLLIElement>;
}

interface ListOrgId {
  nameOrg: string;
  name: string;
  onClickEditar?: React.MouseEventHandler<HTMLElement> | undefined;
  onClickExcluir?: React.MouseEventHandler<HTMLElement> | undefined;
}

const GroupListComp: React.FC<ListOrgId> = ({ nameOrg, name, onClickEditar, onClickExcluir }) => {
  const [group, seGroup] = useState<Group[]>([]);
  let [skip, setSkip] = useState<number>(0);
  const [limit, setLimit] = useState<number>(50);
  let [groupMax, groupOrgMax] = useState<boolean>(true);
  const { t, i18n } = useTranslation();
    
const GroupItem: React.ForwardRefExoticComponent<GroupItemProps> =
React.forwardRef(({ id, idGroup, name }, ref: React.Ref<HTMLLIElement>) => {
  const navegate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function ChangeUserGroup()
  {
    if(nameOrg != undefined && idGroup != undefined)
    {
      navegate("/" + nameOrg + "/groups/" + idGroup);
    }   
  }

  return (
    <li className={`${styles.itemContent} ${styles.row}`} key={idGroup} ref={ref}>
        {name}
        <div className="iconButton">
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon/>
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            PaperProps={{
              style: {
                maxHeight: 216,
                width: '20ch',                    
              },
            }}
            onClose={handleClose}
          >
            <MenuItem data-my-value={idGroup} onClick={onClickEditar}>
              {TranslatorS({path:"Editar.message"})}
            </MenuItem>
            <MenuItem data-my-value={idGroup} onClick={onClickExcluir}>
            {TranslatorS({path:"Excluir.message"})}
            </MenuItem>
            <MenuItem data-my-value={idGroup} onClick={ChangeUserGroup}>
            {TranslatorS({path:"AlterarUsuarios.message"})}
            </MenuItem>
          </Menu>
        </div>
      </li>
    );
  });

  const lastBook = useVisibility(
    (visible) => {
      if (visible) {
        if(name == "")
        {
          GetOrganizationGroup(nameOrg, skip, limit).then((newOrg) => {
            if (newOrg.length < limit) {
              groupOrgMax(false);
            }
            setSkip(skip + newOrg.length);
            seGroup([...group, ...newOrg]);
          });
        }
        else
        {
          GetOrganizationGroupName(nameOrg, skip, limit, name).then((newOrg) => {
            if (newOrg.length < limit) {
              groupOrgMax(false);
            }
            setSkip(skip + newOrg.length);
            seGroup([...group, ...newOrg]);
          });
        }
      }
    },
    [skip, group]
  );

  useEffect(() => {
    if(name == "")
    {
      GetOrganizationGroup(nameOrg, 0, limit).then((newOrg) => {
        if (newOrg.length < limit) {
          groupOrgMax(false);
        }
        setSkip(newOrg.length);
        seGroup(newOrg);
      });
    }
    else
    {
      GetOrganizationGroupName(nameOrg, 0, limit, name).then((newOrg) => {
        if (newOrg.length < limit) {
          groupOrgMax(false);
        }
        setSkip(newOrg.length);
        seGroup(newOrg);
      });
    }
  }, [name]);

  return (
    <div>
      <ul className={stylesList.ulContent}>
      <li className={`${stylesList.itemContent} ${stylesList.title}`}>
          <>{t("Nome.message")}</>{" "}
        </li>
        {group.map((groupC) => (
          <GroupItem
            key={groupC.id}
            id={nameOrg}
            idGroup={groupC.id}
            name={groupC.name}
            ref={
              group[group.length - 1].id === groupC.id && groupMax
                ? lastBook
                : null
            }
          />
        ))}
      </ul>
    </div>
  );
};

export default GroupListComp;
