import React, { useState } from "react";

import TextFieldComp from "../../../components/TextField/TextFieldComp";

import { useTranslation } from "react-i18next";

import { NewFolder } from "../../../model/ItemModel";

import { CreateFolder } from "../../../services/Item/ItemServicesCRUD";

import Message from "../../../components/Message/Message";

import { MessageBarType } from "@fluentui/react";

import Btn from "../../../components/Button/Btn";

import LoaderComp from "../../../components/Loader/LoaderComp";

export interface NewFolderConfig{
    idWork: string;
    idFolder?: string;
    sensitivity?: number;
}

export interface CreateFolderCompParams {
	saveOkMetod: () => void;

	newFolderConfig: NewFolderConfig;
}

const CreateFolderComp = (props: CreateFolderCompParams) => {
	const { t } = useTranslation();

	const [nameChanged, setNameChanged] = useState<string>("");

	const [isMessageError, setMessageError] = useState<boolean>(false);

	const [messageErrorString, setMessageErrorString] = useState<string>(t("ErroAoSalvar.message"));

	const [wait, setWait] = useState<boolean>(false);

	const onChangeSetName = React.useCallback(
		(
			event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,

			newValue?: string,
		) => {
			if (newValue != undefined) {
				if (newValue.includes("\\") || newValue.includes("/")) {
					setMessageErrorString(t("NameMustNotIncludeEitherSlashes.message"))
					setMessageError(true);
				} else {
					setMessageErrorString("")
					setMessageError(false);
					setNameChanged(newValue);
				}
			} else {
				setNameChanged("");
			}
		},

		[],
	);

	const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			createFolder();
		}
	};

	const createFolder = () => {
		setWait(true);

		if (props.newFolderConfig) {
			const folder: NewFolder = {
				name: nameChanged,

				workspaceIdMongo: props.newFolderConfig.idWork,

				idFatherMongo: props.newFolderConfig.idFolder,
			};

			CreateFolder(folder)
				.then((result) => {
					props.saveOkMetod();
				})

				.catch((error) => {
					if (error.response && error.response.data && typeof error.response.data === "string") {
						setMessageErrorString(error.response.data);
					}

					setMessageError(true);
				})

				.finally(() => {
					setWait(false);
				});
		}
	};

	return (
		<div>
			<TextFieldComp
				label={t("Nome.message")}
				value={nameChanged}
				onChange={onChangeSetName}
				autofocus
				onKeyDown={onKeyDown}
			/>

			{isMessageError && <Message text={messageErrorString} type={MessageBarType.error} />}

			<Btn text={t("Salvar.message")} onClick={createFolder} disabled={wait} />

			{wait && <LoaderComp />}
		</div>
	);
};

export default CreateFolderComp;

