import BtnBigWithIcon,{BtnBigWithIconConfig} from "../../../../../../../components/Button/BtnBigWithIcon/BtnBigWithIcon";
import { Box, Grid } from '@mui/material';
import LoaderComp from "../../../../../../../components/Loader/LoaderComp";

interface IFormButton {
    btnBigWithIconConfig: BtnBigWithIconConfig;
    load: boolean;
}

export default function FormButton({
    btnBigWithIconConfig,
    load
}: IFormButton)
{
    return (
        <Grid item xs={12 }>
            { load ? (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <LoaderComp />
                </Box>
            ) : (
                <BtnBigWithIcon
                    textBeforeIcon={btnBigWithIconConfig.textBeforeIcon}
                    text={btnBigWithIconConfig.text}
                    icon={btnBigWithIconConfig.icon}
                    isPrimary={btnBigWithIconConfig.isPrimary}
                    onClick={btnBigWithIconConfig.onClick}
                    src={btnBigWithIconConfig.src}
                    display={btnBigWithIconConfig.display}
                    disabled={btnBigWithIconConfig.disabled}
                    specificWidth={btnBigWithIconConfig.specificWidth}
                />
            ) }
        </Grid>
    )
}