import { useState, useEffect } from 'react'

export const useWindowSize = (size: number) => {
    const [width, setWidth] = useState(0);
    const [isSized, setIsSized] = useState<boolean>(false);

    useEffect(() => {
        function resizeHandler() {
            setWidth(window.innerWidth);
        }

        window.addEventListener("resize", resizeHandler);

        resizeHandler();


        if (window.innerWidth > size) {
            setIsSized(true)
        }

        return () => {
            window.removeEventListener("resize", resizeHandler);
        }
    }, [setWidth])

    return {
        width,
        isSized
    };
}