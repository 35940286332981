import { Api } from "../api";
import { FileWorkspace, FileReplace } from "../../context/UploadProvider/typesUpload";
import axios,{CancelTokenSource} from 'axios';
import {FileCreatedWebVO} from "../../model/ItemModel"

const config = {
    onUploadProgress: function(progressEvent:any) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    }
  }

export function UploadItem(
    fileWorkspace: FileWorkspace, 
    onUploadProgressMethod: (progressEvent: any) => void, 
    cancelToken: CancelTokenSource) 
{
    var formData = new FormData();
    formData.append("file", fileWorkspace.file);

    let urlUpload:string ="";

    if(fileWorkspace.folder == undefined)
    {
        urlUpload = 'Item/file/' + fileWorkspace.woskpace;
    }
    else
    {
        urlUpload = 'Item/file/' + fileWorkspace.woskpace + "/" + fileWorkspace.folder;
    }

    return Api.post<FileCreatedWebVO>(urlUpload, 
        formData,
        {
            headers: {
            'Content-Type': 'multipart/form-data'
            },
            cancelToken: cancelToken.token,
            onUploadProgress: onUploadProgressMethod,
        });
}

export function UploadExistingFile(
    fileReplace: FileReplace,
    onUploadProgressMethod: (progressEvent: any) => void, 
    cancelToken: CancelTokenSource,
) {
    var formData = new FormData();
    formData.append("file", fileReplace.file);

    return Api.post<FileCreatedWebVO>(`Item/file/upload/${fileReplace.idItem}`,
        formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            cancelToken: cancelToken.token,
            onUploadProgress: onUploadProgressMethod
        });
}
