import {Color} from "./interface/Color"

export const Workspace: React.FC<Color> = ({
    color
  }) => {
    return(
        <svg width="19.997" height="19.997" viewBox="0 0 19.997 19.997">
            <g id="Grupo_2" data-name="Grupo 2" transform="translate(-3 -3)">
                <path
                    id="Caminho_6"
                    data-name="Caminho 6"
                    d="M18.553,11.888V3H7.444V7.444H3V23h8.888V18.553h2.222V23H23V11.888ZM7.444,20.775H5.222V18.553H7.444Zm0-4.444H5.222V14.109H7.444Zm0-4.444H5.222V9.666H7.444Zm4.444,4.444H9.666V14.109h2.222Zm0-4.444H9.666V9.666h2.222Zm0-4.444H9.666V5.222h2.222Zm4.444,8.888H14.109V14.109h2.222Zm0-4.444H14.109V9.666h2.222Zm0-4.444H14.109V5.222h2.222Zm4.444,13.331H18.553V18.553h2.222Zm0-4.444H18.553V14.109h2.222Z"
                    transform="translate(0 0)"
                    fill={color}
                />
            </g>
        </svg>
    )
  }