import { IContextualMenuItem, IContextualMenuProps } from '@fluentui/react';
import { IconButton } from "@fluentui/react/lib/Button";
import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from "react-i18next";
import { gridIcon } from "../../assets/icons";
import { GridContext } from '../../context/GridProvider/GridContext';
import { GridContextType } from '../../context/GridProvider/GridContextTypes';
import { CanShow } from "../../pages/WorkSpaceItem/WorkSpaceItemListStyle/CanShow";
import { createdAt, description, getDefault, size, tags, term, validationRule } from "../../pages/WorkSpaceItem/WorkSpaceItemListStyle/ColumnStandard";
import CheckboxComp from "../Checkbox/CheckboxComp";

interface IChooseColumns {
}

const ChooseColumns: React.ForwardRefExoticComponent<IChooseColumns> = React.forwardRef(({ }, ref) => {
  const { t, i18n } = useTranslation();
  const { columns, addColumn, removeColumn, persistColumn } = React.useContext(GridContext) as GridContextType;

  const menuItems = [
    { item: description, name: t('ChooseColumns.description') },
    { item: validationRule, name: t("ChooseColumns.validationRule") },
    { item: createdAt, name: t('ChooseColumns.createdAt') },
    { item: tags, name: 'Tags' },
    { item: size, name: t('ChooseColumns.size') },
    { item: term, name: t('ChooseColumns.term') }
  ];


  const changeColumnVisibility = (name: string, checked: boolean) => {
    if (checked) {
      addColumn({
        name: name,
        length: getDefault(name)
      });
    } else {
      removeColumn(name);
    }
    persistColumn();
  };

  const handleMenuHeader = (): IContextualMenuItem => {
    return {
      key: "header",
      onRender: () => (
        <Comp>
          <Box sx={{ height: '32px' }}>
            <Typography variant='body2' component={'p'} marginTop={'8px'}>
              {t('ChooseColumns.message')}
            </Typography>
          </Box>
        </Comp>
      )
    };
  };

  const handleMenuItem = (nameColumn: string, name: string): IContextualMenuItem => {
    return {
      key: nameColumn,
      onRender: () => (
        <Comp>
          <CheckboxComp
            label={name}
            checked={CanShow(columns, nameColumn)}
            onChange={(ev, checked) => {
              if (checked !== undefined) {
                changeColumnVisibility(nameColumn, checked);
              }
            }}
          />
        </Comp>
      )
    };
  };

  const sortMenuItems = (items: any[]) => {
    return items.slice().sort((a, b) => {
      const itemA = a.name;
      const itemB = b.name;
      return itemA.localeCompare(itemB);
    });
  };

  const mappedItems = sortMenuItems(menuItems).map(({ item, name }) =>
    handleMenuItem(item, name)
  );

  const handleMenuProps = (): IContextualMenuProps => {
    return {
      items: [handleMenuHeader(), ...mappedItems],
      directionalHintFixed: true,
      onItemClick: (ev, item) => {
        ev?.preventDefault();
      }
    };
  };

  return (
    <IconButton
      style={{
        width: "38px",
        height: "38px",
        border: "1px solid #e7e7e7",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      menuProps={handleMenuProps()}
      styles={{
        menuIcon: {
          display: "none",
        },
      }}
    >
      <img src={gridIcon} width={19} />
    </IconButton>
  );
});

interface CompProp {
  children: React.ReactNode;
}

const Comp: React.FC<CompProp> = ({ children }: CompProp) => (
  <div
    style={{
      padding: "5px"
    }}
  >
    {children}
  </div>
);

export default ChooseColumns;
