  
import React, { useState } from 'react';
import { Button, Menu, MenuItem, Radio, RadioGroup, FormControlLabel, FormGroup, Checkbox } from '@mui/material';
import { returnChecked } from '../../../utils/returnChecked';
import { useTranslation } from 'react-i18next';


export interface FilterOptions<T> 
{
    label: string;
    value: T;
    id?: string;
}

interface IDocumentDetailFilterTkProps<T> 
{
  clearAllFilters(): void;
  clearRadioFilters(): void;
  label: string;
  options: FilterOptions<T>[];
  onFilterChange: (selectedValue: T) => void;
  selectedValue: T | null;
  setSelectedValue: React.Dispatch<React.SetStateAction<T | null>>;
  isReview?: boolean;
  setRevisionIdList: React.Dispatch<React.SetStateAction<string[]>>;
  revisionIdList: string[];
}

export default function DocumentDetailFilterTk<T>({ clearRadioFilters, revisionIdList, label, options, onFilterChange, selectedValue, setSelectedValue, clearAllFilters, isReview, setRevisionIdList }: IDocumentDetailFilterTkProps<T>) 
{
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isRadioOpen, setIsRadioOpen] = useState<boolean>(false);

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
    setIsRadioOpen(true);
  };

  function handleClose() {
    setIsRadioOpen(false);
  };

  function handleRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
    clearRadioFilters();
    const valueGetted = event.target.value as unknown as T;
    setSelectedValue(valueGetted);
    onFilterChange(valueGetted);
    handleClose()
  };

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const valueGetted = event.target.checked
    if (valueGetted) {
      if (!revisionIdList.includes(event.target.value as string)) {
        setRevisionIdList([...revisionIdList, event.target.value as string]);
        setSelectedValue(revisionIdList as unknown as T);
      }
    } else {
      let clickedCheckBox = event.target.value as string;
      let filteredList = revisionIdList.filter(id => id !== clickedCheckBox);
      setRevisionIdList(filteredList);
      setSelectedValue(filteredList as unknown as T);
    }
  }

  const styleSetter: boolean = selectedValue === null;

  function setStyles(styleSetter: boolean)
  {
    if (styleSetter)
    {
        return {
            border: '1px solid black',
            color: 'black',
            opacity: 0.6,
            ':hover': {
                opacity: 1,
                backgroundColor: 'transparent',
                color: 'black',
                border: '1px solid black',
            } 
        }

    }

    return {}
  }
  

  return (
    <div>
      <Button 
        onClick={handleClick}
        variant='outlined'
        sx={setStyles(styleSetter)}
      >
        {label}
      </Button>
      <Menu anchorEl={anchorEl} open={isRadioOpen} onClose={handleClose}>
        { isReview ? (
            <FormGroup onChange={handleCheckboxChange}>
              {options?.map((option, index) => (
                <MenuItem key={index}>
                  <FormControlLabel checked={returnChecked(option, revisionIdList)} id={option.value as unknown as string} value={option.value} control={<Checkbox />} label={option.label} />
                </MenuItem>
              ))}
              <Button 
                variant="text"
                onClick={() => onFilterChange(selectedValue as unknown as T)}
                disabled={revisionIdList.length <= 0}
              >
                {t("FilterTables.apply")}
              </Button>
            </FormGroup>
        ) : (
            <RadioGroup value={selectedValue} onChange={handleRadioChange}>
              {options?.map((option, index) => (
                <MenuItem key={index}>
                  <FormControlLabel value={option.value} control={<Radio />} label={option.label} />
                </MenuItem>
              ))}
            </RadioGroup>
        )  }
      </Menu>
    </div>
  );
}

