import { Box, Grid } from "@mui/material";
import { useWindowSize } from "../../../hooks/useWindowSize";

interface PublicHeaderProps {
    icon: string;
    withoutOpacity?:boolean;
}

export function PublicHeader({ icon, withoutOpacity }: PublicHeaderProps) {

    const size = useWindowSize(1024);

    return (
        <>
        { size.width > 1024 && (
            <Grid container id="header">
                    <Grid item sx={{
                            top: 0,
                            left: 0,
                            padding: 3,
                            textAlign: 'flex-start',
                            position: 'absolute',
                            opacity: withoutOpacity == true? null: '0.3'
                        }}>
                        <Box component="img" src={icon} sx={{ width: '150px'}} />
                    </Grid>
            </Grid>
        ) }
        </>
    )
}