import { ValidationRuleVOActivityResponsabilityRetrieve } from "../../../model/ValidationRuleModel";

function ReturnString( responsability: ValidationRuleVOActivityResponsabilityRetrieve[])
{

    if(responsability.length == 0)
    {
        return("");
    }

    return(
        responsability.map((vr, index) => index < responsability.length - 1 ? (
            vr.nameObject + " - "
        ):(
            vr.nameObject
        )
        )
    );
}

export default ReturnString;