import {Color} from "./interface/Color"

export const ArrowIcon: React.FC<Color> = ({
    color
  }) => {
    return(
      <svg width="22.414" height="22.828" viewBox="0 0 22.414 22.828">
        <g id="arrow-icon" transform="translate(-862.586 -616.586)">
          <line id="Linha_356" data-name="Linha 356" y1="10" x2="10" transform="translate(864 618)" fill="none" stroke={color} stroke-linecap="round" stroke-width="2"/>
          <line id="Linha_357" data-name="Linha 357" x2="10" y2="10" transform="translate(864 628)" fill="none" stroke={color} stroke-linecap="round" stroke-width="2"/>
          <line id="Linha_358" data-name="Linha 358" x2="20" transform="translate(864 628)" fill="none" stroke={color} stroke-linecap="round" stroke-width="2"/>
        </g>
      </svg>
  )
}