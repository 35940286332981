import React, { useState, useEffect } from "react";

import {
    IDatePickerStrings
} from '@fluentui/react';


export function getIDatePickerStrings(selectedLanguage:string)
{
    if(selectedLanguage === 'pt-BR')
    {
        const dayPickerStringsPt: IDatePickerStrings = {
            months: [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro'
            ],
          
            shortMonths: ['Jan', 'Fen', 'Mar', 'Abt', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
          
            days: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sabado'],
          
            shortDays: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
          
            goToToday: 'Para hoje',
            prevMonthAriaLabel: 'Para mês anterior',
            nextMonthAriaLabel: 'Para mês posterior',
            prevYearAriaLabel: 'Para ano anterior',
            nextYearAriaLabel: 'Para ano posterior'
        };

        return dayPickerStringsPt;
    }

    const dayPickerStringsUs: IDatePickerStrings = {
        months: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ],
      
        shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      
        days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      
        shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
      
        goToToday: 'Go to today',
        prevMonthAriaLabel: 'Go to previous month',
        nextMonthAriaLabel: 'Go to next month',
        prevYearAriaLabel: 'Go to previous year',
        nextYearAriaLabel: 'Go to next year'
    };

    return dayPickerStringsUs;
}