export function RemoveOrganizationLocalStorage() {
    return localStorage.removeItem("organizationLS")
 }

 export function AddOrganizationLocalStorage(nameOrg:string) {
    localStorage.setItem("organizationLS", nameOrg)
 }

 export function GetOrganizationLocalStorage() {
    return localStorage.getItem("organizationLS")
 }

export function RemoveWorkspaceLocalStorage() {
    return localStorage.removeItem("workspaceLS")
 }

 export function AddWorkspaceLocalStorage(nameWorkspace:string) {
    return localStorage.setItem("workspaceLS",  nameWorkspace)
 }

 export function GetWorkspaceLocalStorage() {
    return localStorage.getItem("workspaceLS")
 }