
export function concatString(arrayString: string[], quotesBegin: boolean, quotesEnd: boolean)
{
  let stringReturn: string = "";

  if(quotesBegin)
  {
    stringReturn = "'"
  }

  if(arrayString.length == 1)
  {
    stringReturn = arrayString[0];
  }
  else if(arrayString.length > 1)
  {
    for(let i = 0; i < arrayString.length; i++)
    {
      if(i == arrayString.length - 1)
      {
        stringReturn =  stringReturn + arrayString[i]
      }
      else
      {
        stringReturn =  stringReturn + arrayString[i] + " "
      }
    }
  }

  if(quotesEnd)
  {
    stringReturn =  stringReturn + "'"
  }

  return stringReturn;
}