import { Typography } from "@mui/material";

interface IBaseText {
    opacity?: number;
    minFontSize?: string;
    maxFontSize?: string;
    letterSpacing?: number;
    textColor?: string;
    fontStyle: string;
    fontVariantCss: string;
    fontWeight: string;
    text: string
}

export default function BaseText({
    text,
    opacity = 1,
    letterSpacing = 0,
    maxFontSize,
    minFontSize,
    textColor,
    fontStyle,
    fontVariantCss,
    fontWeight,
}: IBaseText) {
    return (
        <Typography
            sx={{
                font: `${fontStyle} ${fontVariantCss} ${fontWeight} ${minFontSize}/${maxFontSize} Segoe UI`,
                letterSpacing: {letterSpacing},
                color: {textColor},
                opacity: {opacity},
            }}
        >
            { text }
        </Typography>
    )
}