import React from 'react'

interface ShrinkTitleTypes {
  text: string
}

export const ShrinkTitle: React.FC<ShrinkTitleTypes> = ({ text }) => {
  return (
    <span
        style={{
          textAlign: "left",
          font: "normal normal 600 16px/24px Segoe UI",
          letterSpacing: "0px",
          color: "#2D2D2D",
          opacity: 0.6,
        }}
      >
      { text }
    </span>
  )
}