import { Box } from "@mui/material";

interface IDocumentDetailinternalSeparator
{
    separator: string;
}

export default function DocumentDetailinternalSeparator({
    separator
}: IDocumentDetailinternalSeparator)
{
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            opacity: '0.3',
            margin:"0px 10px 0px 10px"
        }}>
            { separator }
        </Box>
    )
}