import { mergeStyles } from "@fluentui/react";
import { t } from "i18next";
import { useState } from "react";
import Btn from "../../../components/Button/Btn";
import CheclkBoxComp from "../../../components/Checkbox/CheckboxComp";
import LoaderComp from "../../../components/Loader/LoaderComp";
import TextFieldComp from "../../../components/TextField/TextFieldComp";
import { CopyFolderStructureVO } from "../../../model/ItemModel";
import { CopyFolderStructure } from "../../../services/Item/ItemServicesCRUD";

export interface CopyFolderStructureDrawerParameters {
    id: string;
    name: string;
}

export interface CopyFolderStructureDrawerProps {
    copyFolderStructureDrawerParameters: CopyFolderStructureDrawerParameters;
    saveOkMetod: () => void;
    showSnackbar: (message: string, type: "success" | "error") => void;
}

const CopyFolderStructureDrawer = (props: CopyFolderStructureDrawerProps) => {
    const [isWaiting, setIsWaiting] = useState<boolean>(false);
    const [name, setName] = useState<string>(t("DuplicationOf.message") + " " + props.copyFolderStructureDrawerParameters.name);
    const [maintainPermissions, setMaintainPermissions] = useState<boolean>(false);

    const copyFolderStruct = () => {
        setIsWaiting(true);
        const copyFolderStructVO: CopyFolderStructureVO = {
            folderIdThatWillBeCopied: props.copyFolderStructureDrawerParameters.id,
            maintainPermissions: maintainPermissions,
            name: name,
        }

        CopyFolderStructure(copyFolderStructVO)
            .then(() => props.saveOkMetod())
            .catch((error) => props.showSnackbar(error.response.data, "error"))
            .finally(() => setIsWaiting(false))
    }

    return (
        <div className={containerClass}>
            <TextFieldComp
                label={t("Nome.message")}
                value={name}
                onChange={(ev, newValue) => setName(newValue!)}
                autofocus
                disabled={isWaiting}
            />
            <CheclkBoxComp
                label={t("MaintainPermissions.message")}
                onChange={(ev, checked) => setMaintainPermissions(checked!)}
                checked={maintainPermissions}
                disabled={isWaiting}
            />
            <Btn text={t("Salvar.message")} onClick={copyFolderStruct} className={buttonClass} disabled={isWaiting} />
            {isWaiting && (
                <LoaderComp />
            )}
        </div>
    )

}

const containerClass = mergeStyles({
    display: "flex",
    flexDirection: 'column',
    gap: '8px',
});

const buttonClass = mergeStyles({
    width: "80px",
});

export default CopyFolderStructureDrawer;

