import { Box, Grid } from "@mui/material";
import { convertToNextUnit } from "../../functions/convertNextUnit";
import { useTranslation } from "react-i18next";

interface IDashboardLegend {
    min: number;
    max: number;
}

export default function DashboardLegend({
    max,
    min
}: IDashboardLegend) {

    const { i18n } = useTranslation();

    return (
        <>
            <Grid item
                xs={9.7}
                position="absolute"
                bottom={-40}
                right={0}
                width="100%"
                height="40px"
                display="flex"
                justifyContent="space-between"
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Box 
                        sx={{
                            width: "1%",
                            height: "10px",
                            background: "black",
                        }}
                    />
                    <Box
                        sx={{
                            transform: "translateX(-50%)"
                        }}
                    >
                        { convertToNextUnit(min, max, min, i18n.language).bytesConverted }
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: "center"
                    }}
                >
                    <Box 
                        sx={{
                            width: "1%",
                            height: "10px",
                            background: "black",
                        }}
                    />
                    <Box
                    >
                        { convertToNextUnit(Math.round(max / 2), max, Math.round(max / 2), i18n.language).bytesConverted }
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Box 
                        sx={{
                            width: "1%",
                            height: "10px",
                            background: "black",
                            alignSelf: "flex-end"
                        }}
                    />
                    <Box
                        sx={{
                            transform: "translate(50%)"
                        }}
                    >
                        { convertToNextUnit(max, max, max, i18n.language).bytesConverted }
                    </Box>
                </Box>
            </Grid>
        </>
    )
}