import React, { ChangeEvent, useEffect, useRef, CSSProperties, useState } from "react";
import { IIconProps } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { IconButton as IconButtonFluent } from "@fluentui/react/lib/Button";
import { itemContent } from "./ResizeStyles/ListModuleStyles/ItemContent.css";
import { filesSelected } from "./WorkSpaceItemListFile";
import { WorkSpaceUserName } from "../../model/ItemModel";
import { returnStyles, paddingStandart } from "./WorkSpaceItemListStyle/WorkSpaceItemListStyle";

import styles from "./WorkSpaceItemList.module.css";

import { ResizeColumns } from "./WorkSpaceItemListFileCompose/GridProps/GridProps";

import { GridColumnDeleted, term, size, tags, createdAt, validationRule, description, name, status, icon, modal, file, checkbox, menu } from "./WorkSpaceItemListStyle/ColumnStandard";
import { Property } from 'csstype';
import {CanShow} from   "./WorkSpaceItemListStyle/CanShow";
import { GridContext } from '../../context/GridProvider/GridContext';
import { GridContextType } from '../../context/GridProvider/GridContextTypes';

//termino

interface WorkSpaceItemListFileTitleProps extends ResizeColumns {
	orderDesc: boolean;
	setOrderDesc?: (order: boolean) => void;
	selectedCheckbox?: filesSelected[];
	workspaceItems?: WorkSpaceUserName[];
	indeterminate?: boolean;
	chengeAllChackbox : (change:boolean) => void;
	nameOrganization: string;
	layoutPendencies: boolean;
	columnsSended?: GridColumnDeleted[];
}

const WorkSpaceItemListFileTitle: React.ForwardRefExoticComponent<WorkSpaceItemListFileTitleProps> =
	React.forwardRef(({
		orderDesc, 
		setOrderDesc, 
		selectedCheckbox, 
		workspaceItems, 
		chengeAllChackbox, 
		nameOrganization, 
		indeterminate,
		layoutPendencies,
		columnsSended
	}, ref: React.Ref<HTMLLIElement>) => {

	const { columns } = React.useContext(GridContext) as GridContextType;

	const switchColumns: GridColumnDeleted[] = columnsSended != undefined ? columnsSended : columns;
	
	const { t } = useTranslation();

	const useItemContent = itemContent()

	const ascClick = () => {
		if (setOrderDesc) setOrderDesc(true);
	};

	const descClick = () => {
		if (setOrderDesc) setOrderDesc(false);
	};

	const checkboxReference = useRef<HTMLInputElement | null>(null);

	const setIndeterminateCheckbox = (value: boolean) => {
		if(checkboxReference != undefined && checkboxReference.current != undefined && checkboxReference.current.indeterminate != undefined)
		{
			checkboxReference.current.indeterminate = value;
		}
	}

	const isAllSelectedCheckBoxes = (value: boolean) => {
		if(checkboxReference != undefined && checkboxReference.current != undefined && checkboxReference.current.indeterminate != undefined)
		{
			checkboxReference.current.indeterminate = value;
		}
	}

	useEffect(() => {
		
		if (selectedCheckbox != undefined) {
			if (selectedCheckbox!.length < workspaceItems!.length && selectedCheckbox!.length > 0) {

				setIndeterminateCheckbox(true);

			} else {

				setIndeterminateCheckbox(false);
			}

			if (selectedCheckbox!.length === workspaceItems!.length) {

				isAllSelectedCheckBoxes(true)

			} else {

				isAllSelectedCheckBoxes(false)

			}
		}


	}, [checkboxReference, indeterminate, selectedCheckbox, workspaceItems]);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		chengeAllChackbox(e.currentTarget.checked);
	}

	const Currentpathname = `/${nameOrganization}/ValidationRules/UserPendencies`

	const refTitle = useRef<HTMLLIElement | null>(null);
		
		useEffect(() => {
		const gridElements = document.querySelectorAll(".resize");
		gridElements.forEach((element) => {
			const gridArea = getComputedStyle(element as HTMLElement).gridArea;
			const gridColumnItem = columns!.find((item) => item.name === gridArea);

			if (gridColumnItem) {
			(element as HTMLElement).style.gridArea = gridColumnItem.name;
			}
		});
		}, [columns]);

		return (
			<li className={
					returnStyles(layoutPendencies, switchColumns).gridTitle
				}
				ref={refTitle}
			>
				{layoutPendencies == false && window.location.pathname !== Currentpathname && (
					<div
						style={{
							margin: "auto",
							gridArea:checkbox
						}}
					>
						<input
							ref={checkboxReference!}
							className={styles.checkboxContainer}
							onChange={handleChange}
							type="checkbox"
						/>
					</div>
				)}

				{layoutPendencies == false && (
					<>
						{retunDivEstiled(undefined, file, undefined, undefined, false)}
					</>
				)}
				
				{retunDivEstiled(undefined, modal, undefined, undefined, false)}
				{retunDivEstiled(undefined, icon, undefined, undefined, false)}

				{retunDivEstiled(t("Nome.message"), name, 'left',
					setOrderDesc != undefined ? (
					<div>
						{orderDesc ? (
							<IconButtonFluent iconProps={desc} title="Descendente" ariaLabel="Descendente" onClick={descClick}
							/>
						) : (
							<IconButtonFluent iconProps={asc} title="Ascendente" ariaLabel="Ascendente" onClick={ascClick}
							/>
						)}
					</div>
					): undefined,
				true 
				)}
				
				{CanShow(switchColumns, description) && (
					<>
						{retunDivEstiled(t("Description.message"), description, 'left',  undefined, true)}
					</>
				)}
	
				{CanShow(switchColumns, validationRule ) && (
					<>
						{retunDivEstiled(t("ChooseColumns.validationRule"), validationRule, "center",  undefined, true)}
					</>
				)}

				{CanShow(switchColumns, createdAt ) && (
					<>
						{retunDivEstiled(t("Datadamodificacao.message"), createdAt, "center",  undefined, true)}
					</>
				)}

				{CanShow(switchColumns, tags ) && layoutPendencies == false && (
					<>
						{retunDivEstiled("Tags", tags, "center",  undefined, true)}
					</>
				)}

				{CanShow(switchColumns, size ) && (
					<>
						{retunDivEstiled(t("Tamanhodoarquivo.message"), size, "center", undefined, true)}
					</>
				)}

				{CanShow(switchColumns, term ) && (
					<>
						{retunDivEstiled(t("Term.message"), term, "center", undefined, true)}
					</>
				)}
				
				{layoutPendencies == false && (
					<>
						{retunDivEstiled("", menu, "center", undefined, true)}
					</>
				)}

			</li>
	);
});

const asc: IIconProps = {
	iconName: "Down",
	styles: {
		root: {
			color: "black",
			fontWeight: "bold",
		},
	},
};

const desc: IIconProps = {
	iconName: "Up",
	styles: {
		root: {
			color: "black",
			fontWeight: "bold",
		},
	},
};


function retunDivEstiled(
	columnName: string | undefined,
	gridArea: string, 
	textAlignSended : Property.TextAlign| undefined , 
	controls: React.ReactNode | undefined,
	padding: boolean)
{
	return (
		<div id={gridArea}
			style={returnStyleDiv(gridArea, textAlignSended, padding)} 
		>
			{columnName != undefined &&(
				<div 
					style={{ display: "flex", userSelect: "none", width: "100%", alignItems: 'center' , justifyContent: textAlignSended }}
				>
					{columnName}
					{controls != undefined && controls}
				</div>
			)}
		</div>
	);
}

function returnStyleDiv(gridArea: string, textAlignSended : Property.TextAlign| undefined , padding: boolean) : CSSProperties
{
	let style: CSSProperties = {
		fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
		fontWeight: 600,
		fontSize: "16px",
		lineHeight:"24px",
		opacity:0.6,
		display:"flex",
		gridArea:gridArea,
		paddingRight:"0px",
		textAlign: textAlignSended,
		height: "100%",
		borderRadius: "8px",
		overflow: "hidden",
		overflowWrap: "break-word",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis"
	};
	
	return style;
}


export default WorkSpaceItemListFileTitle;
