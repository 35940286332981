
import {IconDisable} from "./IconDisable"

const draft: React.FunctionComponent<IconDisable> = ({ disable}) => {
    
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <path 
        id="draft" 
        d="M17.75,3A3.25,3.25,0,0,1,21,6.25V13H16.25A3.25,3.25,0,0,0,13,16.25V21H6.25A3.25,3.25,0,0,1,3,17.75V6.25A3.25,3.25,0,0,1,6.25,3Zm2.81,11.5L14.5,20.56V16.25a1.75,1.75,0,0,1,1.75-1.75Z" 
        transform="translate(-3 -3)" 
        fill={disable?"#bababa":"#2d2d2d"}
      />
    </svg>
  )
}

export default draft;