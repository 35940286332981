import { Grid } from "@mui/material";


interface ILeftSideHandler 
{
    children: React.ReactNode;
}

export default function LeftSideHandler({
    children
}: ILeftSideHandler)
{
    return (
        <Grid item sx={{
            flexGrow: 1,
            background: "white",
            height: "50%"
        }}>
            { children }
        </Grid>
    )
}