import { createUseStyles } from "react-jss"

export const useModifiedBy = createUseStyles({
  myDiv: {  
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 1
  },
  default: {
    display: "flex"
  }
})
