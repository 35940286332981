export interface BmpConfig {}

export const Bmp: React.FC<BmpConfig> = ({}) => {
  return(
    <svg id="icon-image" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Grupo_608" data-name="Grupo 608">
        <rect id="Retângulo_258" data-name="Retângulo 258" width="24" height="24" rx="2" fill="#3c9fe8" />
        <g id="Grupo_607" data-name="Grupo 607" transform="translate(3.917 4.086)">
          <path id="Caminho_201" data-name="Caminho 201" d="M10.755,16.81H3L7.191,9a.906.906,0,0,1,1.514-.131l2.7,3.407a.844.844,0,0,0,1.214.118l.822-.718a.913.913,0,0,1,1.319.131l3.9,5.013H10.755Z" transform="translate(-3 -1.482)" fill="#fff" />
          <circle id="Elipse_124" data-name="Elipse 124" cx="2.598" cy="2.598" r="2.598" transform="translate(9.557)" fill="#fff" />
        </g>
      </g>
    </svg>
  )
}