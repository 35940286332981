import React from "react";

const ErrorPage = (props: any) => {

    return (
        <div>
            <h1>Not Found</h1>
        </div>
    );
}

export default ErrorPage;