import React, { useState, useMemo, useEffect, useCallback } from "react";
import styles from "./Navbar.module.css";
import { Icon } from "@fluentui/react/lib/Icon";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider/useAuth";
import  DropdownExamplePointingTwo from "../CommandBar/CommandBar";
import { useProfile } from "../../context/ProfileProvider/useProfile";
import  Btn, { TypeStyle }  from '../Button/Btn';
import { ISearchBoxStyles, SearchBox } from '@fluentui/react/lib/SearchBox';
import { Organization } from "../../model/OrganizationModel";
import { WorkSpace } from "../../model/WorkSpaceModel";
import { IContextualMenuListProps, IContextualMenuItem, IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { IRenderFunction } from '@fluentui/react/lib/Utilities';
import {GetWorkspaceForNav, GetOrgWorkspaceForNav} from "./GetWorkspaceForNav/GetWorkspaceForNav"
import { useTranslation } from "react-i18next";


interface NavbarProps {

    Organization:Organization|undefined;
    OrgWorkspace:WorkSpace|undefined;
}

const NavbarWorkSpaceOrg : React.FC<NavbarProps> = ({Organization, OrgWorkspace}) => {
  const MenuHamb = () => <Icon iconName="CollapseMenu" />;
  const [isActive, setActive] = useState(false);
  const navegate = useNavigate();
  const auth = useAuth();
  const profile = useProfile();
  const { t, i18n } = useTranslation();
  const [items, setItems] = useState<IContextualMenuItem[]>([]);
  const [textSearsh, setTextSearsh] = useState<string>("");
  const onAbort = React.useCallback(() => {
    setItems([]);
  }, []);


  const onChangeOrgWork = React.useCallback((ev: React.ChangeEvent<HTMLInputElement>|undefined, newValue: string|undefined) => {
    if(newValue!= undefined)
    {
        setTextSearsh(newValue);
    }
  }, []);

  useEffect(() => {
    if(Organization!=undefined && Organization.internalName != undefined)
    {
      GetOrgWorkspaceForNav(Organization.internalName, textSearsh.toLowerCase()).then((filteretWOkspace) => {
        setItensOrgWorkspce(filteretWOkspace);
      });
    }
  }, [textSearsh]);


  useEffect(() => {
    if(Organization?.internalName != undefined)
    {
      GetOrgWorkspaceForNav(Organization?.internalName, undefined).then((filteretWOkspace) => {
        setItensOrgWorkspce(filteretWOkspace);
      });
    }
  }, [Organization, Organization?.internalName]);
  
  function setItensOrgWorkspce(filteretWOkspace:WorkSpace[])
  {
    let filteredItems:IContextualMenuItem[] = [];

    if (!filteretWOkspace || !filteretWOkspace.length) {
      filteredItems.push({
        key: 'no_results',
        onRender: (item, dismissMenu) => (
          <div key="no_results" style={filteredItemsStyle}>
            <Icon iconName="SearchIssue" />
            <span>{t("NotFound.message")}</span>
          </div>
        ),
      });
    }
    else
    {
      filteretWOkspace.forEach((workspace) =>{
        if(workspace.id != undefined)
        {
          filteredItems.push({
            key: workspace.id?.toString(),
            text: workspace.name,
            onClick:sendToOrgWorkspaceRoot
          });
        }
      });
    }
    setItems(filteredItems);
  }


  const sendToOrgWorkspaceRoot = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem)  => {
    if(ev)
      ev.preventDefault();
      
    navegate("/" + Organization?.internalName + "/WorkSpace/" + item?.key + "/validationRule");
  };

  const renderMenuListOrgWorkspace:IRenderFunction<IContextualMenuListProps> =
  (
    menuListProps: IContextualMenuListProps|undefined,
    defaultRender: IRenderFunction<IContextualMenuListProps>|undefined) => {
    if(menuListProps!= undefined && defaultRender!= undefined)
    {
      return (
        <div style={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', borderTopRightRadius: '10px', height: '180px' }}>
          <div style={{ padding: '10px' }}>
            <SearchBox
              onAbort={onAbort}
              onChange={onChangeOrgWork}
              styles={searchBoxStyles}
              value={textSearsh}
              placeholder="O que você está procurando..."
              showIcon
            />
          </div>
          {defaultRender(menuListProps)}
        </div>
      );
    }
    else
    {
      return null;
    }
  };
  
  const menuPropsOrgWorkspace= useMemo<IContextualMenuProps>(
    () => ({
      onRenderMenuList: renderMenuListOrgWorkspace,
      shouldFocusOnMount: true,
      items,
      styles: {
        root: {
          maxHeight: '180px'
        },
        subComponentStyles: {
          callout: {
            root: {
              boxShadow: 'none'
            },
            calloutMain: {
              borderRadius: '0px 10px 10px 10px',
              boxShadow: 'rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px'
            }
          }
        }
      },
    }),
    [items],
  );


  return (
        <div className={styles.bread} style={{display: "contents"}}>
          {OrgWorkspace != undefined && (
            <Btn text={OrgWorkspace.name} menuProps={menuPropsOrgWorkspace} style={TypeStyle.lastPathButtonStyles} pathButtonWithMenu />
          )}        
        </div>
  );
};

const filteredItemsStyle: React.CSSProperties = {
  width: '100%',
  height: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const searchBoxStyles: ISearchBoxStyles = {
  root: {
    width: '240px',
    marginTop: '10px',
    borderRadius: '8px',
    border: '1px solid #989898',
    opacity: 1,
  },
  icon: {
    color: '#989898',
    fontWeight: 600
  }
};

export default NavbarWorkSpaceOrg;
