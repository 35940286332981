import React, { useState, useEffect, useCallback, useRef } from "react";
import TextFieldComp from "../../../components/TextField/TextFieldComp";
import CheckboxComp from "../../../components/Checkbox/CheckboxComp";
import { IStackStyles, } from '@fluentui/react/lib/Stack';
import { TagPicker, ITag, IBasePickerSuggestionsProps } from '@fluentui/react/lib/Pickers';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import Btn from "../../../components/Button/Btn";
import { GetOrganizationUserName, GetOrganizationUser } from "../../../services/OrganizationServices";
import { useTranslation } from "react-i18next";
import { ValidationRuleVOActivityRetrieve, ValidationRuleVOActivityResponsabilityRetrieve } from "../../../model/ValidationRuleModel";
import Message from "../../../components/Message/Message";
import { MessageBarType } from "@fluentui/react";
import {SpinButton,ISpinButtonStyles, IComboBox, IComboBoxOption } from "@fluentui/react"
import ComboBoxComp from "../../../components/ComboBox/ComboBoxComp";
import {GetEnumTerm} from "../../../services/ValidationRuleService"
import {useParams } from "react-router-dom";

interface ValidationRuleActivityComp {
  nameOrg: string;
  validateNewEdit: ValidationRuleVOActivityRetrieve;
  returnValidation: (returnValidation:ValidationRuleVOActivityRetrieve) => void;
} 

const ValidationRuleActivity  = (props: ValidationRuleActivityComp) => {
    const { t, i18n } = useTranslation();
    const [id, setId] = useState<number>(0);
    const [order, setOrder] = useState<number>(0);
    const [name, setName] = useState<string>("");
    const [backOperationLabel, setBackOperationLabel] = useState<string>("");
    const [forwardOperationLabel, setForwardOperationLabel] = useState<string>("");
    const [type, setType] = useState<string>("");
    const [reaload, setReaload] = useState<boolean>(false);
    const [itensTagUser, setItensTagUser] = useState<ITag[] | undefined>([]);
    const [delayResults, setDelayResults] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState<string|undefined>(undefined);
    const [dinamicUserChoice, setDinamicUserChoice] = useState<boolean>(false);
    const [termNumber, setTermNumber] = useState<number>(0);
    const [options, setOptions] = useState<IComboBoxOption[] | undefined>(undefined);    
    const [descriptionTerm, setDescriptionTerm] = useState<string>("");
    const { nameOrg } = useParams();

    useEffect(() => {
      GetEnumTerm()
      .then((result) => {
        let options: IComboBoxOption[] = [];
              options.push( { key: "SE", text: "selecione" });
            result.map((response) => {
              options.push( { key: response.index, text: response.name });
            })

            setOptions(options)
      })
      .catch((error) => {

        if( error.response && error.response.data && typeof error.response.data  === 'string'){
          setErrorMessage(error.response.data);
        }
        else
        {
          setErrorMessage(t("ValidationRule.erroTermTypes"))
        }
      })
    },[nameOrg])

    useEffect(() => {
      if(descriptionTerm != "1"){
        setTermNumber(0)
      }
    },[descriptionTerm])

    useEffect(() => {
      if(props.validateNewEdit.id != undefined)
      {
        setId(props.validateNewEdit.id);
      }

      if(props.validateNewEdit.term != undefined)
      {
        setTermNumber(props.validateNewEdit.term);
      }

      if(props.validateNewEdit.enumTermDescription != undefined)
      {
        setDescriptionTerm(props.validateNewEdit.enumTermDescription.toString());
      }

      if(props.validateNewEdit.order != undefined)
      {
        setOrder(props.validateNewEdit.order);
      }

      if(props.validateNewEdit.name != undefined)
      {
        setName(props.validateNewEdit.name);
      }

      if(props.validateNewEdit.type != undefined)
      {
        setType(props.validateNewEdit.type);
      }

      if(props.validateNewEdit.responsability != undefined &&
         props.validateNewEdit.responsability.length > 0){
        let newItens: ITag[] =
        props.validateNewEdit.responsability.map(item => ({ key: item.idObject?item.idObject:"", name: item.nameObject?item.nameObject:"" }));
        setItensTagUser(newItens);
        setReaload(true);
      }

      if(props.validateNewEdit.backOperationLabel != undefined)
      {
        setBackOperationLabel(props.validateNewEdit.backOperationLabel);
      }

      if(props.validateNewEdit.forwardOperationLabel != undefined)
      {
        setForwardOperationLabel(props.validateNewEdit.forwardOperationLabel);
      }

      if(props.validateNewEdit.dinamicUserChoice != undefined)
      {
        setDinamicUserChoice(props.validateNewEdit.dinamicUserChoice);
      }

    }, [props.validateNewEdit]);

    useEffect(() => {
      if(reaload == true ){
        setReaload(false)
      }
    }, [reaload]);
    
    const onChangeSetName = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
      ) => {
        if (newValue != undefined) {
            setName(newValue);
        }
        else {
            setName("");
        }
      };

      const onChangeSetBackOperationLabel = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
      ) => {
        if (newValue != undefined) {
          setBackOperationLabel(newValue);
        }
        else {
          setBackOperationLabel("");
        }
      };


      const onChangeSetForwardOperationLabel = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
      ) => {
        if (newValue != undefined) {
          setForwardOperationLabel(newValue);
        }
        else {
          setForwardOperationLabel("");
        }
      };

      const onChangeQuantity = useCallback((event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
        if (newValue !== undefined) {
          setTermNumber(+newValue);
        }
        else{
          setTermNumber(0);
        }
      }, []);

      function changeTerm (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string)
      {
        if(index != undefined && index > 0)
        {
          setDescriptionTerm(options![index].key.toString());
        }
      }

      const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: t("UsuariosSugeridos.message"),
        noResultsFoundText: t("UsuariosNaoEncontradors.message"),
      };
    
      const onChange = (items: ITag[] | undefined) => {
        setItensTagUser(items);
      };

      const filterPromise = (selectedItems?: ITag[]): ITag[] | PromiseLike<ITag[]> => {
        if (delayResults) {
          return convertResultsToPromise(selectedItems!);
        } else {
          return GetOrganizationUser(props.nameOrg)
          .then((users) => {
            const userTags: ITag[] = users.map(item => ({ key: item.id, name: item.name}));
            return userTags;
          })
          .catch(() =>{
            return [];
          });
        }
      };

      const onResolveSuggestions = async (
        filter: string,
        selectedItems: ITag[] | undefined
      ): Promise<ITag[]> => {
        if (filter) {
          return GetOrganizationUserName(props.nameOrg, filter)
          .then((users) => {
            const userTags: ITag[] = users.map(item => ({ key: item.id, name: item.name}));
            return userTags;
          })
          .catch(() =>{
            return [];
          });
        }
        return [];
      };

      function changeDinamic (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) {
        if(checked == undefined || checked == false)
        {
          setDinamicUserChoice(false);
        }
        else
        {
          setItensTagUser([]);
          setReaload(true);
          setDinamicUserChoice(true);
        }
      };

      function convertResultsToPromise(results: ITag[]): PromiseLike<ITag[]> {
        return new Promise<ITag[]>((resolve, reject) => setTimeout(() => resolve(results), 2000));
      }

      const rootClass: Partial<IStackStyles> = {
        root: {
            width:"100%",
            borderColor: "#114D88",
            margin:"5px",
        }
      };
      const titlePicker = mergeStyles({
        fontWeight:900,
        margin:"5px",
        fontFamily: "Microsoft YaHei, sans-serif",
        fontSize:"14px"
      });
    
      const pickers = mergeStyles({
        marginTop: "10px"
      });

      const getTextFromItem = (item: ITag) => item.name;

      function crudActivity()
      {
        setErrorMessage(undefined);

        if(name.trim() == "")
        {
          setErrorMessage(t("ValidationRule.erroName"));
          return;
        }
        if(backOperationLabel.trim() == "")
        {
          setErrorMessage(t("ValidationRule.erroBackOperationLabel"));
          return;
        }
        if(forwardOperationLabel.trim() == "")
        {
          setErrorMessage(t("ValidationRule.erroForwardOperationLabel"));
          return;
        }

        if(dinamicUserChoice == false && (itensTagUser == undefined || itensTagUser.length == 0))
        {
          setErrorMessage(t("ValidationRule.erroItensTagUser"));
          return;
        }

        let validatereturn:ValidationRuleVOActivityRetrieve = props.validateNewEdit;
        validatereturn.id = id;
        validatereturn.order = order;
        validatereturn.type = type;
        validatereturn.name = name;
        validatereturn.backOperationLabel = backOperationLabel;
        validatereturn.forwardOperationLabel = forwardOperationLabel;
        validatereturn.term = termNumber;
        validatereturn.enumTermDescription = Number(descriptionTerm);
        let responsabilityList:ValidationRuleVOActivityResponsabilityRetrieve[] = [];
        itensTagUser?.map((tag =>{
          responsabilityList.push({
            idObject:tag.key.toString(),
            nameObject:tag.name,
            typeObject:'0'
          })
        }));
        validatereturn.responsability = responsabilityList;
        validatereturn.dinamicUserChoice = dinamicUserChoice;
        props.returnValidation(validatereturn);
      }

      const styles: Partial<ISpinButtonStyles> = {
        spinButtonWrapper: { height: "36px" },
        root: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: "6px",
          paddingLeft: "6px"
        },
        label:{
          color: "rgb(0, 0, 0)",
          fontFamily: "Microsoft YaHei,sans-serif",
          marginBottom:" 13px"
        }
      };
      
    return(
        <div>
            <TextFieldComp 
                value={name}
                onChange={onChangeSetName}
                label={t("ActivityName.message")}
            />
            <div className={pickers}>
                <label  className={titlePicker}>{t("Responsible.message")}</label>
                {reaload == false &&(
                    <TagPicker
                        removeButtonAriaLabel="Remove"
                        selectionAriaLabel="People"
                        onResolveSuggestions={onResolveSuggestions}
                        getTextFromItem={getTextFromItem}
                        pickerSuggestionsProps={pickerSuggestionsProps}
                        removeButtonIconProps={{ iconName: 'Delete' }}
                        pickerCalloutProps={{ doNotLayer: true }}
                        styles={rootClass}
                        onChange={onChange}
                        defaultSelectedItems={itensTagUser}
                        onEmptyInputFocus={filterPromise}
                        disabled={dinamicUserChoice}
                        itemLimit={type != "1" ? undefined : 1}
                    />
                )}
            </div>

            <CheckboxComp
              label={t("ValidationRule.dinamic")}
              onChange={changeDinamic}
              checked={dinamicUserChoice}
              disabled={type == "1"}
            />

            <TextFieldComp 
                value={backOperationLabel}
                onChange={onChangeSetBackOperationLabel}
                label={t("ValidationRule.backOperationLabel")}
            />
            <TextFieldComp 
                value={forwardOperationLabel}
                onChange={onChangeSetForwardOperationLabel}
                label={t("ValidationRule.forwardOperationLabel")}
            />

            <ComboBoxComp
                label={t("Term.message")}
                options={options!}
                onChange={changeTerm}
                selectedKey={descriptionTerm}
            />

             {descriptionTerm == "0" &&(
                <SpinButton 
                min={0}
                max={999}
                step={1}
                incrementButtonAriaLabel="Increase value by 1"
                decrementButtonAriaLabel="Decrease value by 1"
                onChange={onChangeQuantity}
                value={termNumber.toString()}
                label={t("Term.days")}
                styles={styles}
              />
             )}     
            
            {errorMessage != undefined &&(
              <Message text={errorMessage} type={MessageBarType.error} />
            )}
            <Btn text={t("Salvar.message")} onClick={crudActivity}/>
        </div>
    )
}

export default ValidationRuleActivity;