import { Link, Typography } from "@mui/material";
import home from '../../../../../../../assets/icons/home.svg';
import { Grid } from '@mui/material';
import DocumentDetailHeaderActionsWrapper from "./components/DocumentDetailHeaderActionsWrapper/DocumentDetailHeaderActionsWrapper";
import DocumentDetailHeaderActionsClose from "./components/DocumentDetailHeaderActionsClose/DocumentDetailHeaderActionsClose";
import DocumentDetailHeaderActionsFull from "./components/DocumentDetailHeaderActionsFull/DocumentDetailHeaderActionsFull";
interface IDocumentDetailHeaderActions
{
    closeDetail: () => void;
    fullScreenToggle: () => void;
    fullScreenSetted: boolean;
}

export default function DocumentDetailHeaderActions({
    closeDetail,
    fullScreenToggle,
    fullScreenSetted
}: IDocumentDetailHeaderActions)
{
    return (
        <DocumentDetailHeaderActionsWrapper>
            <Grid item paddingRight="30px">
            </Grid>
            <DocumentDetailHeaderActionsClose 
                closeDetail={closeDetail}
            />
            <DocumentDetailHeaderActionsFull
                fullScreenToggle={fullScreenToggle} 
                fullScreenSetted={fullScreenSetted}
            />
        </DocumentDetailHeaderActionsWrapper>
    )
}