import React from "react";
import { ItemTemporality } from "../../../../model/ItemModel"; 
import stylesList from "../../../Style/List.module.css";
import moment from "moment";
import styles from '../TemporalityList.module.css'
import { useTranslation } from "react-i18next";
import { UseStatus } from "../../../../components/Status/hooks/useStatus";

interface TemporalityListCompProps {
    itemTemporality:ItemTemporality,
    ref?: React.Ref<HTMLLIElement>
    lastLine: boolean
    firstLine: boolean
}

  const GridArchive: React.ForwardRefExoticComponent<TemporalityListCompProps> =
  React.forwardRef(({ itemTemporality, lastLine, firstLine }, ref: React.Ref<HTMLLIElement>) => {
  
    const { t } = useTranslation();

    var dataTemporality = moment(itemTemporality.itemTemporality?.itemTemporality).format('DD/MM/YYYY');

    return (
      <ul className={stylesList.ulContent} >
        <li style={{ borderBottomLeftRadius: lastLine ? "8px" : "", borderBottomRightRadius: lastLine ? "8px" : "", marginBottom: lastLine ? "25px": ""}} className={styles.gridTemporalityBodyLi} key={itemTemporality.workspaceId} ref={ref}>
          <div className={styles.gridTemporalityBodyDiv}>
            <span className={styles.gridTemporalitySpan}>
              {itemTemporality.name + "." + itemTemporality.extencion}
            </span>
          </div>
          <div className={styles.gridTemporalityBodyDiv}>
            <span className={styles.gridTemporalitySpan}>
              {itemTemporality.description != undefined?itemTemporality.description:t("tag.semDesc")}
            </span>
          </div>
          <div className={styles.gridTemporalityBodyDiv}>
            <span className={styles.gridTemporalitySpan}>
              {dataTemporality}
            </span>
          </div>
          <div style={{ borderRight:"0px"}} className={styles.gridTemporalityBodyDiv}>
            <span className={styles.gridTemporalitySpan}>
              {UseStatus(itemTemporality.isRelease, itemTemporality.validationRule!, itemTemporality.itemType!, false, itemTemporality.archiving, itemTemporality.expired, itemTemporality.d4Signed )}
            </span>
          </div>
        </li>
      </ul>
    );   
  });
  
  export default GridArchive;