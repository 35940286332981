import { IIconProps } from '@fluentui/react';
import { IconButton as IconButtonFluent } from '@fluentui/react/lib/Button';
import React, { useEffect, useState } from "react";
import { MenuConfig } from "../../components/Menu/MenuConfig";
import { FolderInformation, D4SignStatus, ItemValidationRule, WorkSpaceUserName, WorkspacePendenci } from "../../model/ItemModel";
import { RenameConfig } from '../../pages/WorkSpaceItem/PanelItens/RenameComp';
import WorkSpaceItemListFileTitle from "../../pages/WorkSpaceItem/WorkSpaceItemListFileTitle";
import {
  GetUserPendenciesAll
} from "../../services/ValidationRuleService";
import { FlowProps } from "../WorkSpaceItem/FlowFolder/WorkspaceItemFlowComponent";
import { NewFolderConfig } from '../WorkSpaceItem/PanelItens/CreateFolderComp';
import { ShareFileConfig } from "../WorkSpaceItem/PanelItens/ShareFile/ShareFileComp";
import ChoicerViwerModal from "../WorkSpaceItem/Viwer/ChoicerViwerModal";
import { WorkItemProps } from "../WorkSpaceItem/WorkSpaceItemListFileCompose/WorkItemProps/WorkItemProps";
import WorkSpaceItemListFileComponent from "../WorkSpaceItem/WorkSpaceItemListFileCompose/WorkSpaceItemListFileComponent";
import { ReturnColumnSizeUserPendencies } from "../WorkSpaceItem/WorkSpaceItemListStyle/ColumnStandard";
import styles from "./UserPendencies.module.css";

export interface UserPendenciesListCompConfig {
  skip: number;
  id: string | undefined;
}

export interface DropdownMenuContextoConfig{
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
}

interface ListUserPendenciesListCompId {
    nameOrg: string;
    ref?: React.Ref<HTMLLIElement>;
    setFolderConfig: (newFolder:NewFolderConfig) => void;
    setFolderExternal: (folderId:string) => void;
    deleteItem: (itemId:string) => void;
    onClickRevision: (itemId:string) => void;
    onclickMove: (itemId:string) => void;
    isAdmin: boolean;
    setPopupError: (set:boolean, message:string) => void;
    setPopupOk: (set:boolean, message:string) => void;
    rename: (renameConfig:RenameConfig) => void;
    objectReference?: any;
    array: any;
    CheckboxItemList: any;
    onClickFlow: (flowProps : FlowProps) => void;
}

const UserPendenciesListComp: React.FC<ListUserPendenciesListCompId> = ({
  nameOrg,
  setFolderConfig,
  setFolderExternal,
  deleteItem,
  onClickRevision,
  onclickMove,
  rename,
  setPopupError,
  setPopupOk,
  objectReference,
  array,
  CheckboxItemList,
  onClickFlow
}) => {
  const [WorkspacePendenci, setWorkspacePendenci] = useState<WorkspacePendenci[]>([]);
  const [limit, setLimit] = useState<number>(50);
  let [skip, setSkip] = useState<number>(0);
  let [WorkspacePendenciMax, setWorkspacePendenciMax] = useState<boolean>(true);
  const [layoutDivFatherId, setLayoutDivFatherId] = useState<string | undefined>(undefined);

  const [itemIdConst, setItemIdConst] = useState<string | undefined>(undefined)
  const [isOpenConst, setIsOpenConst] = useState<boolean>(false)
  const [nameConst, setNameConst ] = useState<string | undefined>(undefined)
  const [extensionConst, setExtensionConst] = useState<string | undefined>(undefined)

  const exibirViewerBoolean = (exibir: boolean) => {
    setIsOpenConst(exibir);
  };

  const exibirViewer = (idMongo: string, name: string, extension: string, menuConfig: MenuConfig) => {
    updateContent(idMongo, name, extension)
    exibirViewerBoolean(true);
  };

   const updateContent = (id: string, name: string, extension: string) => {
    setItemIdConst(id)
    setNameConst(name)
    setExtensionConst(extension)
  }

  useEffect(() => {
    if(nameOrg != undefined)
    {
        GetUserPendenciesAll(nameOrg,skip, limit)
        .then((newDocumentType) => {
          newDocumentType = newDocumentType.filter(isBigEnough);
            if (newDocumentType.length < limit) {
                setWorkspacePendenciMax(false);
            }
            setSkip(newDocumentType.length);
            setWorkspacePendenci(newDocumentType);
          });
    }
    
  }, [nameOrg]);

  const shareFileConfig = (shareFileConfig:ShareFileConfig) => {

  }

  const ascClick = (id:string|undefined) =>{
    setLayoutDivFatherId(id);
  };


  const descClick = () => {
    setLayoutDivFatherId(undefined);
  };

  const handleMultiDrag = (ev: React.DragEvent<HTMLDivElement>) => {}

const asc: IIconProps = {
    iconName: 'Down',
    styles: {
      root: {
        color: 'black',
        fontWeight:'bold'
      }
    }
  };

  const desc: IIconProps = {
    iconName: 'Up',
    styles: {
      root: {
        color: 'black',
        fontWeight:'bold'
      }
    }
  };

  const chengeAllChackbox = (change:boolean) => {	
        
  }

  function openDetailModal(index: number, setOpen: (bool: boolean) => void)
  { 
    setOpen(true);
  }

  const returnParamens = (indexPendenci: number, index:number, workSpaceC:WorkSpaceUserName, workspacePendencis: WorkspacePendenci) => {
    const paramens:WorkItemProps = 
    {
      nameOrg:nameOrg,
      refViwer:null,
      idWorkspace:workspacePendencis.workspaceId!,
      nameWorkspace:workspacePendencis.name!,
      deleteItem:deleteItem,
      setFolderExternal:setFolderExternal,
      setPopupOk:setPopupOk,
      setPopupError:setPopupError,
      onclickRevision:onClickRevision,
      onclikMoveOpen:onclickMove,
      layoutPendencies: true,
      refresh: () => {},
      setShareFileConfig:shareFileConfig,
      permissionType: undefined,
      isAdmin:false,
      renameUpdate:rename,
      boxLayout:false,
      objectReference:objectReference,
      CheckboxItemList:CheckboxItemList,
      handleMultiDrag:handleMultiDrag,
      onClickFlow:onClickFlow,
      notificationEmail:workSpaceC.notificationEmail,
      exibirViewer:exibirViewer,
      indexOfArray:index,
      batchRefresh: undefined,
      batchWaiter:undefined,
      updateTagName:undefined,
      workspaceItem:workSpaceC,
      updateNameDescriptionByIndex: () => {},
      updateArchivenByIndex:() => {},
      currentBatchStatus:undefined,
      isCurrentViewerItem:workSpaceC.id === itemIdConst ? true : false,
      selectedCheckbox:undefined,
      setMenuConfigConst:undefined,

      setIsReleaze: (isRelease: boolean, index:number) => setIsReleaze(isRelease, indexPendenci, index),
      setValidationRule: (itemValidationRule: ItemValidationRule | undefined, index:number) => setValidationRule(itemValidationRule,indexPendenci, index ),

      setWorkspace: (workSpaceUserName:WorkSpaceUserName, index:number) => updateWorks(workSpaceUserName, indexPendenci, index),
      setIsD4SignSignedConst: (d4SignStatus:D4SignStatus | undefined, isD4SignSignedConst: boolean| undefined, index: number) => updateD4SignStatus(d4SignStatus, isD4SignSignedConst, indexPendenci, index),
      currentFolder: {} as FolderInformation,
      columnsSended: ReturnColumnSizeUserPendencies(),
      openDetailModal,
    };
    return paramens
    
  }

  function isBigEnough(element:any) { 
      return (element.filePendeci != undefined && element.filePendeci.length > 0); 
  } 

    function setIsReleaze(isRelease: boolean, indexPendenci: number, index: number)
    {
      if(WorkspacePendenci.length > indexPendenci)
      {
        let array = WorkspacePendenci;
        if(
          array != undefined && 
          array.length > indexPendenci && 
          array[indexPendenci].filePendeci != undefined && 
          array[indexPendenci].filePendeci![index] == undefined && 
          array[indexPendenci].filePendeci!.length > index
        )
        {
          array[indexPendenci]!.filePendeci![index].isRelease = isRelease;
        }
      }
    }
    
    function setValidationRule(itemValidationRule: ItemValidationRule | undefined, indexPendenci: number, index:number)
    {
      if(WorkspacePendenci.length > indexPendenci)
      {
        let array = WorkspacePendenci;
        if(
          array != undefined && 
          array.length > indexPendenci && 
          array[indexPendenci].filePendeci != undefined && 
          array[indexPendenci].filePendeci![index] == undefined && 
          array[indexPendenci].filePendeci!.length > index
        )
        {
          array[indexPendenci]!.filePendeci![index].validationRule = itemValidationRule;
        }
      }
    }

    function updateWorks(workSpaceUserName:WorkSpaceUserName, indexPendenci: number, index:number)
    {
      if(WorkspacePendenci.length > indexPendenci)
      {
        let array = WorkspacePendenci;
        if(
          array != undefined && 
          array.length > indexPendenci && 
          array[indexPendenci].filePendeci != undefined && 
          array[indexPendenci].filePendeci![index] == undefined && 
          array[indexPendenci].filePendeci!.length > index
        )
        {
          array[indexPendenci]!.filePendeci![index] = workSpaceUserName;
        }
      }
    }

    function updateD4SignStatus(d4SignStatus:D4SignStatus | undefined, isD4SignSignedConst: boolean| undefined,  indexPendenci: number, index:number)
    {
      if(WorkspacePendenci.length > indexPendenci)
      {
        let array = WorkspacePendenci;
        if(
          array != undefined && 
          array.length > indexPendenci && 
          array[indexPendenci].filePendeci != undefined && 
          array[indexPendenci].filePendeci![index] == undefined && 
          array[indexPendenci].filePendeci!.length > index
        )
        {
          array[indexPendenci]!.filePendeci![index].isD4SignSigned = isD4SignSignedConst;
          array[indexPendenci]!.filePendeci![index].d4SignStatus = d4SignStatus;
        }
      }
    }

  return (
    <div>
      <ul>
        {WorkspacePendenci.map((workspacePendencis, indexPendenci) => (

          <div className={layoutDivFatherId != workspacePendencis.workspaceId ? `${styles.fatherListPendenciaVazia}` : `${styles.fatherList}`}>
            <div>
              <div className={styles.nameWork}>
                <h4>Workspace: {workspacePendencis.name}</h4>
                <div className={styles.margin}></div>
                {workspacePendencis.filePendeci == undefined || workspacePendencis.filePendeci.length == 0 ?
                  (
                    <div className={styles.circulo2}></div>
                  ) :
                  (
                    <div className={styles.circulo}></div>
                  )
                }
                {workspacePendencis.filePendeci == undefined || workspacePendencis.filePendeci.length == 0 ?
                  (
                    <div></div>
                  ) :
                  (
                    <div className={styles.iconbutton}>
                      {layoutDivFatherId != workspacePendencis.workspaceId ?
                        (
                          <IconButtonFluent iconProps={asc} title="Vizualizar" ariaLabel="Vizualizar" onClick={() => ascClick(workspacePendencis.workspaceId)} />
                        ) :
                        (
                          <IconButtonFluent iconProps={desc} title="Ocultar" ariaLabel="Ocultar" onClick={descClick} />
                        )
                      }
                    </div>
                  )
                }
              </div>
              <div>
                {
                  workspacePendencis.filePendeci != undefined &&
                  workspacePendencis.filePendeci.length > 0 &&
                  workspacePendencis.workspaceId != undefined &&
                  workspacePendencis.name != undefined && (
                    <div className={layoutDivFatherId != workspacePendencis.workspaceId ? `${styles.invisibleDiv}` : `${styles.IconButtonFluent1}`}>
                      <ul className={styles.ulContentPendecieList}>
                        <WorkSpaceItemListFileTitle
                          orderDesc={true}
                          chengeAllChackbox={chengeAllChackbox}
                          nameOrganization={nameOrg}
                          layoutPendencies={true}
                          columnsSended={ReturnColumnSizeUserPendencies()}
                        />
                        {workspacePendencis.filePendeci.map((workspacePendenciFile, indexOfArray) => (
                          <WorkSpaceItemListFileComponent
                            key={workspacePendenciFile.id}
                            workItemProps={returnParamens(indexPendenci, indexOfArray, workspacePendenciFile, workspacePendencis)}
                            isUpload={false}
                          />
                        ))}
                      </ul>
                    </div>
                  )}

              </div>

            </div>
          </div>
        ))}
      </ul>
      <div>
        {WorkspacePendenciMax === false ?
          null
          : null}
      </div>
      <ChoicerViwerModal
        idItem={itemIdConst!}
        isOpen={isOpenConst}
        setIsOpen={exibirViewerBoolean}
        menuConfig={undefined}
        name={nameConst!}
        extension={extensionConst!}
      />
    </div>
  );
}
export default UserPendenciesListComp;
