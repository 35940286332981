import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { GetOneItem } from "../../services/Item/ItemServicesGet";
import { GetOneWorkSpace } from "../../services/WorkSpaceServices";
import LoaderComp from "../../components/Loader/LoaderComp";
import { Box, Typography } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';

const LinkToFilePage = (props: any) => {
    const { t } = useTranslation();
    const { idFile } = useParams();

    const [error, setError] = useState<string | undefined>(undefined);
    const [wait, setWait] = useState<boolean>(false);

    const nameOrgItem = useRef<string>();

    useEffect(() => {
        if (idFile) {
            setWait(true);
            Promise.all([
                GetOneItem(idFile),
                GetOneItem(idFile).then(item => GetOneWorkSpace(item.workspaceId!))
            ])
                .then(([item, workspace]) => {
                    nameOrgItem.current = workspace?.orgInternalName!;
                    const link = `${window.location.protocol}//${window.location.host}/${nameOrgItem.current}/wsallowed/${item.workspaceId}${item.fatherId ? `/${item.fatherId}` : ''}?fileId=${idFile}`;
                    window.location.href = link;
                })
                .catch((error) => {
                    setError(t("FileNotFound.message"));
                })
                .finally(() => setWait(false));
        }
    }, [idFile, t]);
    
    return (
        <div>
            {wait && <LoaderComp big={true} />}
            {idFile == undefined || error != undefined && (
                <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', color: 'red', gap: '10px' }}>
                    <ErrorIcon sx={{fontSize: '32px'}}/>
                    <Typography fontSize='32px' fontWeight='600'>
                        {error != undefined ? error : t("ItemIdNotFound.message")}    
                    </Typography>
                </Box>
            )}
        </div>
    );
};

export default LinkToFilePage;
