import React, { useState, useEffect } from "react";
import { Outlet, Navigate, useLocation, useSearchParams } from "react-router-dom";
import { CreateUserLoggedByOrg, GetOneOrganization } from "../../services/OrganizationServices";
import { GetOneWorkSpace } from "../../services/WorkSpaceServices";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { useProfile } from "../../context/ProfileProvider/useProfile";
import Navbar from "../../components/navbar/Navbar";
import SideBar from "../../components/Sidebar/sidebar";
import style from "./ILayoutProtected.module.css";
import { useParams } from "react-router-dom";
import { Organization } from "../../model/OrganizationModel";
import { WorkSpace } from "../../model/WorkSpaceModel";
import { useNavigate } from "react-router-dom";
import { Api } from "../../services/api";
import { AxiosRequestConfig } from 'axios';
import {FileUploader} from "../Upload/FileUploader";
import {GetUserPendenciesBoolean} from "../../services/ValidationRuleService"
import {isValidUserPendencies} from "../../components/Sidebar/sidebar"
import WaitPage from "../ErroPage/WaitPage"
import { ContentbarProps } from "../../components/Contentbar/ContentSide/ContentSideComp";
import { handleAllowed } from "../../functions/handleAllowed";
import { SetOrganizationNameLocalStorage } from "../../context/AuthProvider/util";

const ILayoutProtected = (props: any) => {
  const auth = useAuth();
  const profile = useProfile();
  const { nameOrg, idWorkspace, idFile } = useParams();
  const [isOrganizationVisible, setOrganizationVisible] =
    useState<boolean>(false);
  const [isWorkspaceVisible, setWorkspaceVisible] = useState<boolean>(false);
  const [isUsersVisible, setUsersVisible] = useState<boolean>(false);
  const [isGroupspaceVisible, setGroupspaceVisible] = useState<boolean>(false);
  const [feedTrackerVisible, setFeedTrackerVisible] = useState<boolean>(false);
  const [isUserPendencies, setUserPendencies] = useState<isValidUserPendencies>();
  const [isDocuemntTypeVisible, setDocuemntTypeVisible] =
    useState<boolean>(false);
  const [isValidationRuleVisible, setValidationRuleVisible] =
  useState<boolean>(false);

  const [organization, setOrganization] = useState<Organization | undefined>(
    undefined
  );
  const [workSpace, setWorkSpace] = useState<WorkSpace | undefined>(
    undefined
  );
  const [orgWorkSpace, setOrgWorkSpace] = useState<WorkSpace | undefined>(
    undefined
  );
  const [orgValidationWorkSpace, setOrgValidationWorkSpace] = useState<WorkSpace | undefined>(
    undefined
  );

  const [fileVisible, setFileVisible] = useState<boolean>(false);
  const [contentbar, setContentbar] = useState<ContentbarProps[]>([]);
  const [hasBeenUpdated, setHasBeenUpdated] = useState<boolean>(false);
  const location = useLocation();
  const navegate = useNavigate();

  const [idSelectd, setIdSelectd] = useState<number|undefined>(undefined);

  useEffect(() => {
    var url ="/" + nameOrg + "/temporality/" + idWorkspace

    if(idSelectd == 9 && window.location.pathname != url){
      setIdSelectd(undefined)
    }
  },[idSelectd])

  function resetIdSelected()
  {
    setIdSelectd(undefined);
  }

  Api.interceptors.response.use(
    function (config: AxiosRequestConfig) {
      return config;
    },
    function (error: any) {
      if (error != undefined) {
        let errorJson = error.toJSON();
        if (errorJson.status == 401) {
          auth.logout();
          if (!auth.token) {
            navegate("/login");
          }
        }
      }
      return Promise.reject(error);
    });

  useEffect(() => {
    if (nameOrg != undefined) {
      
      GetOneOrganization(nameOrg)
        .then((message) => {
          if (message != null) {
            setOrganization(message);
          } else {
            setOrganization(undefined);
          }
        })
        .catch((error) => {
          setOrganization(undefined);
          setUsersVisible(false);
          setOrganizationVisible(false);
          setWorkspaceVisible(false);
          setGroupspaceVisible(false);
          setDocuemntTypeVisible(false);
          setValidationRuleVisible(false);
          setOrganization(undefined);
          setFileVisible(false);
          setFeedTrackerVisible(false);
          setUserPendencies("invisible");
        });
    } else {
      setOrganization(undefined);
      setUsersVisible(false);
      setOrganizationVisible(false);
      setWorkspaceVisible(false);
      setGroupspaceVisible(false);
      setDocuemntTypeVisible(false);
      setValidationRuleVisible(false);
      setFileVisible(false);
      setFeedTrackerVisible(false);
      setOrganization(undefined);
      setUserPendencies("invisible");
    }
    
    SetOrganizationNameLocalStorage(nameOrg);
    
  }, [nameOrg, profile.id]);

  useEffect(() => {
    if(organization != undefined)
    {
      let orgAdmin:boolean = false;
      let workspaceAdmin:boolean = false;
      let orgUser:boolean = false;
      if(profile.organizations != undefined)
      {
        for(let organization of profile.organizations)
        {
          if(nameOrg == organization.internalName)
          {
            if(organization.isUser == true)
            {
              orgUser = true;
            }
            if(organization.isAdmin == true)
            {
              orgAdmin = true;
            }

            if(organization.isAdminWorkspace == true)
            {
              workspaceAdmin = true;
            }

            if(organization.internalName && orgUser)
            {
              setUserPendencies("waiting");
              GetUserPendenciesBoolean(organization.internalName).then((message) =>{
                  if(message.isValid == false){
                    setUserPendencies("false");
                  }
                  else{
                    setUserPendencies("true");
                  }
              })  
              .catch(() => {
                setUserPendencies("invisible");
              })
            }

            break;
          }
        }
      }

      setFeedTrackerVisible(true);
      setOrganization(organization);
      setUsersVisible(profile.adminSystem == true || orgAdmin || workspaceAdmin);
      setGroupspaceVisible(orgAdmin || workspaceAdmin);
      setWorkspaceVisible(orgAdmin);
      setDocuemntTypeVisible(orgAdmin || workspaceAdmin);
      setValidationRuleVisible(orgAdmin || workspaceAdmin);
      setFileVisible(orgUser);
      setOrganizationVisible(profile.adminSystem == true || orgAdmin);
      setFeedTrackerVisible(true);
    }
  }, [organization]);
  
  useEffect(() => {
    if (auth.token && profile.login == undefined) {
      profile.create(auth.token)
        .catch((error) =>{
          auth.logout();
          navegate("/login");
        });
    }

    let interval = setInterval(() => {
      if(nameOrg != undefined)
      {
        CreateUserLoggedByOrg({ orgName: nameOrg });
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };

  }, [nameOrg]);

  useEffect(() => {
    let locationSplit:string[] = location.pathname.split("/");
    if(locationSplit.length >= 4 && locationSplit[2] == "wsallowed")
    {
      GetOneWorkSpace(locationSplit[3])
      .then((workspaceFound)=>{ 
        if(workspaceFound != null)
        {
          setWorkSpace(workspaceFound);
        }
      });
    }
    else if(locationSplit.length >= 5 && locationSplit[4] == "documentTypes")
    {
      GetOneWorkSpace(locationSplit[3])
      .then((workspaceFound)=>{ 
        if(workspaceFound != null)
        {
          setOrgWorkSpace(workspaceFound);
          setOrgValidationWorkSpace(undefined);
          setWorkSpace(undefined);
        }
      });
    }
    else if(locationSplit.length >= 5 && locationSplit[4] == "validationRule")
    {
      GetOneWorkSpace(locationSplit[3])
      .then((workspaceFound)=>{ 
        if(workspaceFound != null)
        {
          setOrgWorkSpace(undefined);
          setWorkSpace(undefined);
          setOrgValidationWorkSpace(workspaceFound);
        }
      });
    }
    else
    {
      setWorkSpace(undefined);
      setOrgWorkSpace(undefined);
      setOrgValidationWorkSpace(undefined);
    }
  }, [location.pathname]);

  if (auth.token == undefined) {
    const loginPageUrl = window.location.protocol + '//' + window.location.host + '/login';
    const fileFromCopiedLink = idFile;

    if (fileFromCopiedLink !== undefined) {
      const linkParams = "?link=" + 'file/' + fileFromCopiedLink;
      return <Navigate to={`/login` + linkParams} />;
    }

    if ( window.location.href !== loginPageUrl) {
      const currentPath = window.location.pathname;
      const currentSearch = window.location.search;

      if (currentPath !== '/login') {
        const linkParams = "?link=" + currentPath + currentSearch;
        return <Navigate to={`/login` + linkParams} />;
      }
    }

    return <Navigate to="/login" />;
  }

  if(profile.id == undefined)
  {
    return <WaitPage/>
  }

  function catchContentbar(contentForBar: ContentbarProps[]){
   setContentbar(contentForBar)
  }

  function updateContentbar(){
   setHasBeenUpdated(!hasBeenUpdated);
  }

  return (
    <div>
      <Navbar 
        Organization={organization} 
        Workspace={workSpace} 
        OrgWorkspace={orgWorkSpace} 
        OrgValidation={orgValidationWorkSpace}
        contentbar={contentbar}
        updateContentbar={updateContentbar}
        resetIdSelected={resetIdSelected}
        />
      <main className={style.headerMain}>
        <SideBar
          isCheckedInitial={true}
          isOrganizationVisible={isOrganizationVisible}
          idOrganization={organization?.id}
          isWorkspaceVisible={isWorkspaceVisible}
          isUsersVisible={isUsersVisible}
          isGroupspaceVisible={isGroupspaceVisible}
          isDocuemntTypeVisible={false}
          isValidationRuleVisible={isValidationRuleVisible}
          isFileVisible={fileVisible}
          idFeedTrackerVisible={feedTrackerVisible}
          isUserPendencies={isUserPendencies}
          internalName={organization?.internalName}
          catchContentbar={catchContentbar}
          hasBeenUpdated={hasBeenUpdated}
          idSelectd={idSelectd}
          setIdSelectd={setIdSelectd}
        />
        
        <main className={`${handleAllowed(true, "wsallowed", location) ? `${style.FormComp}` : `${style.FormComp} ${style.paddingExcencial}`}`}>
          <Outlet />
        </main>
      </main>
      <FileUploader/>
    </div>
  );
};

export default ILayoutProtected;
