import { IStyleFunctionOrObject,IBasePickerStyleProps,IBasePickerStyles } from "@fluentui/react";


export const standartStyle : IStyleFunctionOrObject<IBasePickerStyleProps, IBasePickerStyles>=
{
    text: {
        borderRadius: 8,
        height: 44,
        textAlign: "center",
        paddingBottom: 5,
        outline: 'none'
    },
    root: {
        top: "310px",
        maxHeight: "663px",
    },
    itemsWrapper: {
        width: "103px",
        height: "32px",
        borderRadius: "100px",
        backgroundColor: 'transparent'
    }
}