import React, { useState, useMemo } from "react";
import { IContextualMenuListProps, IContextualMenuItem, IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { Organization } from "../../../model/OrganizationModel";
import { WorkSpace } from "../../../model/WorkSpaceModel";
import { 
    GetOrgWorkSpaceTrim
   } from "../../../services/WorkSpaceServices";
   import {
    GetOrganizationWorkspaceTrimName, GetOrganizationWorkspaceAdmTrim, GetOrganizationWorkspaceAdmTrimName
  } from "../../../services/OrganizationServices";


export function GetWorkspaceForNav(orgasnizationInternalName:string, searschText: string |undefined) {
  
    if(searschText == undefined)
    {
        return GetOrganizationWorkspaceAdmTrim(orgasnizationInternalName, 0, 10, false);
    }
    else
    {
        return GetOrganizationWorkspaceAdmTrimName(orgasnizationInternalName, 0, 10, false, searschText);
    }
}

export function GetWorkspaceForNavEnd(orgasnizationInternalName:string) {
  
    return GetWorkspaceForNav(orgasnizationInternalName, undefined)
}


export function GetOrgWorkspaceForNav(orgasnizationInternalName:string, searschText: string |undefined) {
  
    if(searschText == undefined)
    {
        return GetOrgWorkSpaceTrim(orgasnizationInternalName, 0, 10, false);
    }
    else
    {
        return GetOrganizationWorkspaceTrimName(orgasnizationInternalName, 0, 10, false, searschText);
    }
}

export function GetOrgWorkspaceForNavEnd(orgasnizationInternalName:string) {
  
    return GetOrgWorkspaceForNav(orgasnizationInternalName, undefined)
}