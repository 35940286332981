
export const MenuStyles = {
    root: {
    minWidth: '84px',
    maxWidth: '150px',

      },
      subComponentStyles: {
        callout: {
          root: {
            borderRadius: '10px 10px 10px 10px',
            boxShadow: 'none',
          
            "& > ul": {
              padding: '0',
              width: '100%',
            }
          },
          calloutMain: {
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            boxShadow: "rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px",
          },
          
        }
      }
}