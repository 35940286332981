import React, {useEffect} from "react";
import { MaskedTextField } from '@fluentui/react/lib/TextField';
import { IIconProps } from "@fluentui/react/lib/Icon";
import { ILabelStyles, ILabelStyleProps } from '@fluentui/react/lib/Label';

export interface MaskedTextFieldCompConfig{
    label?: string;
    value?: string;
    disabled?: boolean;
    round?: boolean;
    onChange?: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void;
    iconProps?:IIconProps;
    float?:string
    isPassword?: boolean;
    bigSize?: boolean;
    autofocus?: boolean;
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
    mask?: string;
}

const MaskedTextFieldComp = (props: MaskedTextFieldCompConfig) => {

    let disabled:boolean = false;
    if(props.disabled != undefined)
    {
        disabled = props.disabled;
    } 

    let isPassword:boolean = false;
    if(props.isPassword != undefined)
    {
        isPassword = props.isPassword;
    } 

    var float:string = "none";
    if(props.float)
    {
        float = props.float;
    }

    function getLabelStyles(propsLabel: ILabelStyleProps): ILabelStyles {
        return {
          root: {
            color:props.bigSize?"rgb(255, 255, 255)":"rgb(0, 0, 0)",
            fontFamily:"Microsoft YaHei, sans-serif",
            marginBottom:"8px"
          },
        };
      }

    return(
        <MaskedTextField
        label={props.label}
        value={props.value}
        autoFocus={props.autofocus}
        disabled={disabled}
        onKeyDown={props.onKeyDown}
        styles={{
            
            root: { 
                width:"100%",
                maxWidth: props.bigSize?480:300,
                borderColor: "#114D88",
                margin:"5px",
            },
            fieldGroup: {
                borderRadius: props.round?"5px":"0px",
                height:props.bigSize?"50px":"35px",
                paddingTop:props.bigSize?"8px":"0px",
                paddingBottom:props.bigSize?"8px":"0px",
                backgroundColor: props.bigSize?"rgb(232, 240, 254)":"rgb(255, 255, 255)",
            },
            subComponentStyles: {
                label: getLabelStyles,
            },
        }}
        onChange={props.onChange} 
        iconProps={props.iconProps}
        type={isPassword? "password" : undefined}
        revealPasswordAriaLabel="Show password"
        canRevealPassword
        mask={props.mask}
        />
    )
}

export default MaskedTextFieldComp;