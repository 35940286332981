import { Box, Grid } from '@mui/material';
import FormContextText from './FormContextText';
import FormActionLink from './FormActionLink';

interface FormActionsProps {
    contextText?: string;
    text?: string;
    href?: string;
    isRenderingArrow?: boolean;
    isRenderingFormActions?: boolean;
    flexDirection?: 'row' | 'column';
    boldLink?: boolean;
}

export default function FormActions({
    href,
    isRenderingArrow,
    text,
    contextText,
    flexDirection,
    boldLink
}: FormActionsProps) 
{
    return (
        <Grid item xs={12}>
            <Box sx={{
                display: 'flex',
                flexDirection: flexDirection ? flexDirection : 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '4px',
                padding: 1
            }}>
                <FormContextText 
                    contextText={contextText}
                />
                <FormActionLink 
                    href={href}
                    text={text}
                    isRenderingArrow={isRenderingArrow}
                    boldLink={boldLink}
                />
            </Box>
        </Grid>
    )
}