import { Box, SxProps, Theme } from "@mui/material";
import { PublicHeader } from "./PublicHeader/PublicHeader";
import { PublicFooter } from "./PublicFooter/PublicFooter";
import W3KLogo from '../../../../assets/images/logo-w3k.svg';
import KeyDriveLogo from '../../../../assets/images/IconLogin.png'
import styles from "../../ILayoutPublic.module.css";
import { useTranslation } from "react-i18next";

interface BackgroundImageProps {
    outContainerStyles?: SxProps<Theme> | undefined;
    innerContainerStyles?: SxProps<Theme> | undefined;
    backgroundImage: string;
    component: string & (React.ElementType<any> | undefined)
    children?: React.ReactNode;
}

export function BackgroundImage({
    backgroundImage,
    component,
    children
}: BackgroundImageProps) {

    const year = new Date().getFullYear();
    const { t } = useTranslation();

    return (
      <>
        <Box sx={{
              position: 'fixed',
              top: 0,
              right: 0,
              width: '100%',
              height: '100%',
              overflow: 'hidden'
            }}
            style={{zIndex:-1}}
          >
            <Box 
                component={component}
                sx={{
                    height: '100%',
                    width: '100%',
                    display:{
                      xs:"none",
                      sm:"none",
                      md:"none",
                      lg:"block",
                      xl:"block"

                    },
                  backgroundImage: `url(${backgroundImage})`,
                  backgroundSize: 'cover', 
                  backgroundPosition: 'right',
                  backgroundRepeat: 'no-repeat',
                }}

            />
            <PublicHeader 
                icon={KeyDriveLogo}
              />
            <PublicFooter
              logo={W3KLogo}
              privacy={t("Login.privacyPolicy")}
              rights={t("Login.allRights")}
              year={year!}
            >
          </PublicFooter>
          
      </Box>

      <Box sx={{
              position: 'fixed',
              width: '100%',
              height: '100%',
              overflow: 'hidden'
            }}
          >
          <div className={styles.userBody}>
          </div>
      
      </Box>
      <Box sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              display: "table"
            }}
          >
            <div
              style={{
                display: "table-cell",
                verticalAlign: "middle"
              }}  
            >
              { children }
            </div>
      </Box>
      </>
    )
}