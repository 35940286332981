import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export interface IframeD4signParams{
    fileKey: string;
    signer_email: string;
    signer_display_name: string;
    signer_documentation: undefined | string;
    showErrorMessage: (message:string) => void;
    ForwardItemInternal: () => void;
}

const IframeD4sign = (props: IframeD4signParams) => {
    const { t, i18n } = useTranslation();
    const [saveServer, setSaveServer] = useState<boolean>(false);

    const handleKeyDown = (event:any) => {
        if (event.data === "signed") {
            setSaveServer(true);
            props.ForwardItemInternal();
        }
        if (event.data === "wrong-data") {
            props.showErrorMessage("D4Sign: wrong-data");
        }
    };
    
    React.useEffect(() => {
        window.addEventListener('message', handleKeyDown);
        setSaveServer(false);
        // cleanup this component
        return () => {
            window.removeEventListener('message', handleKeyDown);
        };
    }, []);

    return (
        <div>
            {saveServer == false ?(
                <iframe 
                    src={"https://secure.d4sign.com.br/embed/viewblob/"+props.fileKey+'?email='+props.signer_email+'&display_name='+props.signer_display_name+'&disable_preview=0'}  
                    height={"1025"} 
                    width={"500"} 
                    allow={"geolocation"}
                />
            ):(
                <div>
                    <h3>{t("CPF.SaveInServer")}</h3>
                </div>
            )}
        </div>
    )
}

export default IframeD4sign;
