import React, { useEffect, useState, useRef, useCallback } from "react";
import { UserLazy, UserGroupsVO } from "../../../model/UserModel";
import { GetUsersGroup, GetUsersGroupName } from "../../../services/GroupsServices";
import { IIconProps, IContextualMenuProps, Stack, Link } from '@fluentui/react';
import { IconButton } from '@fluentui/react/lib/Button';
import styles from "../../Style/List.module.css";
import { RemoveUserGroup } from "../../../services/UserServices";
import { useTranslation } from "react-i18next";

 function useVisibility(
    cb: (isVisible: boolean) => void,
    deps: React.DependencyList
  ): (node: any) => void {
    const intersectionObserver = useRef<IntersectionObserver | null>(null);
    return useCallback((node) => {
      if (intersectionObserver.current) {
        intersectionObserver.current.disconnect();
      }
  
      intersectionObserver.current = new IntersectionObserver(([entry]) => {
        cb(entry.isIntersecting);
      });
  
      if (node) intersectionObserver.current.observe(node);
    }, deps);
  }

 interface UserGroupItemConfig {
    idUser?: string | undefined;
    idGroup?: string | undefined;
    name?: string;
    ref?: React.Ref<HTMLLIElement>;
  }

  interface ListUserGroup {
    idOrg: string;
    idGroup: string;
    name: string;
  }
  
  const GroupUsersList: React.FC<ListUserGroup> = ({ idOrg,idGroup, name }) => {
    const [users, sertUsers] = useState<UserLazy[]>([]);
    let [skip, setSkip] = useState<number>(0);
    const [limit, setLimit] = useState<number>(50);
    let [userGroupMax, setUserGroupMax] = useState<boolean>(true);
    const { t, i18n } = useTranslation();
    const lastUser = useVisibility(
        (visible) => {
          if (visible) {
            if(name == "")
            {
              GetUsersGroup(idGroup, skip, limit).then((newOrg) => {
                if (newOrg.length < limit) {
                  setUserGroupMax(false);
                }
                setSkip(skip + newOrg.length);
                sertUsers([...users, ...newOrg]);
              });
            }
            else
            {
              GetUsersGroupName(idGroup, skip, limit, name).then((newOrg) => {
                if (newOrg.length < limit) {
                  setUserGroupMax(false);
                }
                setSkip(skip + newOrg.length);
                sertUsers([...users, ...newOrg]);
              });
            }
          }
        },
        [skip, users]
      );
    
      useEffect(() => {
        if(name == "")
        {
          GetUsersGroup(idGroup, 0, limit).then((newOrg) => {
            if (newOrg.length < limit) {
              setUserGroupMax(false);
            }
            setSkip(newOrg.length);
            sertUsers(newOrg);
          });
        }
        else
        {
          GetUsersGroupName(idGroup, 0, limit, name).then((newOrg) => {
            if (newOrg.length < limit) {
              setUserGroupMax(false);
            }
            setSkip(newOrg.length);
            sertUsers(newOrg);
          });
        }
      }, [name]);

    const UserGroupItem: React.ForwardRefExoticComponent<UserGroupItemConfig> =
    React.forwardRef(({ idUser, idGroup, name }, ref: React.Ref<HTMLLIElement>) => {

    const [isRemoved, setIsRemoved] = useState<boolean>(false);

    const add: IIconProps = {
      iconName: 'BlockContact',
      styles: {
        root: { color: 'red' }
      }
    };

    const ok: IIconProps = {
      iconName: 'Accept',
      styles: {
        root: { color: 'red' }
      }
    };

    const removeClick = () => {
      let userGroupsVO:UserGroupsVO = {
        groupId:idGroup,
        userId:idUser
      }
      RemoveUserGroup(userGroupsVO)
      .then(() =>{
        setIsRemoved(true);
      });
    };

    return (
        <li className={`${styles.itemContent} ${styles.row}`} key={idUser} ref={ref}>
            {name}
            {isRemoved?
            (<IconButton iconProps={ok} title="Ok" ariaLabel="Ok" />):
            (<IconButton iconProps={add} title={t("Excluir.message")} ariaLabel={t("Excluir.message")} onClick={removeClick}/>)}
        </li>
        );
    });

    return(
      <ul className={styles.ulContent} >
        <li className={`${styles.itemContent} ${styles.title}`}>
        {t("Nome.message")}{" "}
        </li>
        {users.map((user) => (
          <UserGroupItem
            key={user.id}
            idUser={user.id}
            idGroup={idGroup}
            name={user.name}
            ref={
                users[users.length - 1].id === user.id && userGroupMax
                ? lastUser
                : null
            }
          />
        ))}
      </ul>);
  }

  export default GroupUsersList;
