import { Close, FilterList } from "@mui/icons-material";
import { Box } from "@mui/material";

interface IDocumentDetailTabFilterButton 
{
    showFilters: boolean;
    setShowFilters: React.Dispatch<React.SetStateAction<boolean>>;
    isLoading: boolean;
    onClick?: () => void;
}

export default function DocumentDetailTabFilterButton({
    showFilters,
    setShowFilters,
    isLoading,
    onClick
}: IDocumentDetailTabFilterButton)
{
    return (
        <>
            { !isLoading && (
                <Box
                    sx={{
                    width: '38px',
                    height: '38px',
                    border: '1px solid #989898',
                    opacity: 0.6,
                    borderRadius: '5px',
                    cursor: 'pointer',
                    transition: 'all 0.3s',
                    background: showFilters ? '#989898' : 'white',
                    ':hover': {
                        background: '#989898'
                    },
                    }}
                    onClick={() => {
                        if (showFilters) {
                            setShowFilters(false);
                            onClick!();
                        } else {
                            setShowFilters(true);
                        }
                    }}
                >
                    { showFilters ? <Close fontSize='large' /> : <FilterList fontSize='large' /> }
                </Box>
            ) }
        </>
    )
}