import React, { useEffect, useState, useRef, useCallback } from "react";

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
//import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Btn from '../Button/Btn'
import I18n, { TranslatorP, Translator, TranslatorS } from '../../components/I18n';

import "./MenuContexto.css";

export interface DropdownMenuContextoConfig{
    onClickEditar?: React.MouseEventHandler<HTMLElement> |  undefined;
    onClickExcluir?: React.MouseEventHandler<HTMLElement> | undefined;
    idMenu?:string;
  }
  
  const ITEM_HEIGHT = 48;
  
  function LongMenu(props: DropdownMenuContextoConfig) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <div className="iconButton">
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon/>
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',                    
            },
          }}
          onClose={handleClose}
        >
          <MenuItem data-my-value={props.idMenu} onClick={props.onClickEditar}>
            {TranslatorS({path:"Editar.message"})}
          </MenuItem>
          <MenuItem data-my-value={props.idMenu} onClick={props.onClickExcluir}>
          {TranslatorS({path:"Excluir.message"})}
          </MenuItem>
        </Menu>
      </div>
    );
  }

  export default LongMenu;