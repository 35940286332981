export function haveDisplay (extension:string | undefined) {
    if(
        extension == "pdf" || 
        extension == "docx" || 
        extension == "doc" || 
        extension == "png" || 
        extension == "jpg" || 
        extension == "gif" || 
        extension == "svg" ||
        extension == "bmp" || 
        extension == "webp" || 
        extension == "xlsx" || 
        extension == "ppt" || 
        extension == "pptx" || 
        extension == "mpp" || 
        extension == "xls" || 
        extension == "dwg"
    )
        return true;

    return false;
  }