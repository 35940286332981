import { Box } from '@mui/material';

interface IDocumentDetailMetaDataWrapper
{
    children: React.ReactNode;
}

export default function DocumentDetailMetaDataWrapper({
    children
}: IDocumentDetailMetaDataWrapper)
{
    return (
        <Box sx={{
            paddingLeft: '66px',
            marginTop: '35px',
        }}>
            { children }
        </Box>
    )
}