import { Box } from '@mui/material';
import TabContext from '@mui/lab/TabContext';

interface IDocumentDetailTabWrapper
{
    value: string;
    children: React.ReactNode;
}

export default function DocumentDetailTabWrapper({
    value,
    children
}: IDocumentDetailTabWrapper) 
{

    return (
        <Box sx={{ width: '100%', typography: 'body1', marginTop: '34px', background: "white" }}>
            <TabContext value={value}>
                { children }
            </TabContext>
        </Box>
    )
}