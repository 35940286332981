import React from "react";
import { Box, Typography } from "@mui/material"

interface PanelGenericTextTypes {
  text: string
  paragraph: string
  style?: React.CSSProperties
  textCenter?: boolean
}


const PanelGenericText: React.ForwardRefExoticComponent<PanelGenericTextTypes> = React.forwardRef(({
  text,
  paragraph,
  style,
  textCenter
} , ref) => {

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      gap: "6px"
    }}
    style={style}
    >
      <Typography
        sx={{
          textAlign: textCenter ? "center" : "left",
          font: "normal normal bold 30px/45px Segoe UI",
          letterSpacing: "0px",
          color: "#2D2D2D",
          opacity: 1,
        }}
      >
        { text }
      </Typography>
      <Typography
        sx={{
          textAlign: textCenter ? "center" : "left",
          font: "normal normal normal 20px/30px Segoe UI",
          letterSpacing: "0px",
          color: "#2D2D2D",
          opacity: 1,
        }}
      >
        { paragraph }
      </Typography>
    </Box>
  )
})

export default PanelGenericText;
