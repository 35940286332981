export function pdfStylesSet(isSidebarCollapsed: boolean, fullScreenSetted: boolean, isAcept: boolean, isModalViwer: boolean): { width: string, height: string } {
    if (isAcept) 
        return { width: "100%", height: "100%" }

    if (isModalViwer) 
        return { width: "100%", height: "100%" }

    if (isSidebarCollapsed && fullScreenSetted)
        return { width: "95.5%", height: "92%" }

    if (!isSidebarCollapsed && fullScreenSetted)
        return { width: "70.3%", height: "92%" }

    if (isSidebarCollapsed && !fullScreenSetted)
        return { width: "96%", height: "725px" }

    return { height: "725px", width: "63.8%" }
}

export function dwgStyleSet(isSidebarCollapsed: boolean, fullScreenSetted: boolean, isAcept: boolean, isModalViwer: boolean): { width: string, height: string } {
    if (isAcept) 
        return { width: "100%", height: "100%" }

    if (isModalViwer) 
        return { width: "100%", height: "100%" }

    if (isSidebarCollapsed && fullScreenSetted)
        return { width: "100%", height: "100%" }

    if (!isSidebarCollapsed && fullScreenSetted)
        return { width: "70.3%", height: "92%" }

    if (isSidebarCollapsed && !fullScreenSetted)
        return { width: "1669px", height: "460px" }

    return { height: "460px", width: "63.8%" }
}




export function noViwerStylesSet(isSidebarCollapsed: boolean, fullScreenSetted: boolean, isAcept: boolean, isModalViwer: boolean): { width: string, height: string } {
    if (isAcept) 
        return { width: "100%", height: "100%" }
    
    if (isModalViwer) 
        return { width: "100%", height: "100%" }

    if (isSidebarCollapsed && fullScreenSetted)
        return { width: "95.7%", height: "65.9%" }

    if (!isSidebarCollapsed && fullScreenSetted)
        return { width: "76%", height: "65.9%" }

    if (isSidebarCollapsed && !fullScreenSetted)
        return { width: "89.3%", height: "49.6%" }

    return { height: "49.6%", width: "69.5%" }
}


export function fileStylesSet(isSidebarCollapsed: boolean, fullScreenSetted: boolean, isAcept: boolean, isModalViwer: boolean): { width: string, height: string } {
    if (isAcept) 
        return { width: "100%", height: "100%" }

    if (isModalViwer) 
        return { width: "100%", height: "100%" }

    if (isSidebarCollapsed && fullScreenSetted)
        return { width: "95.5%", height: "65.2%" }

    if (!isSidebarCollapsed && fullScreenSetted)
        return { width: "70.5%", height: "65.2%" }

    if (isSidebarCollapsed && !fullScreenSetted)
        return { width: "89.2%", height: "47.8%" }

    return { height: "47.8%", width: "63.9%" }
}

export const tableWidthHandler = (isSidebarCollapsed: boolean, fullScreenSetted: boolean) : { width: string, height: string } => {

    if (isSidebarCollapsed && fullScreenSetted) {
        return { width: "1400px", height: "-webkit-fill-available" }
    }

    if (isSidebarCollapsed && !fullScreenSetted) {
        return { width: "1400px", height: "50%" }
    }

    if (!isSidebarCollapsed && fullScreenSetted) {
        return { width: "1000px", height: "-webkit-fill-available"}
    }

    return { width: "1000px", height: "50%" }
}