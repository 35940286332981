export interface SvgConfig {}

export const Svg: React.FC<SvgConfig> = ({}) => {
  return(
    <svg id="icon-svg" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="Retângulo_258" data-name="Retângulo 258" width="24" height="24" rx="2" fill="#3c9fe8" />
      <ellipse id="Elipse_194" data-name="Elipse 194" cx="3.207" cy="3.207" rx="3.207" ry="3.207" transform="translate(3.628 3.736)" fill="#fff" />
      <rect id="Retângulo_269" data-name="Retângulo 269" width="5.947" height="5.947" transform="translate(14.426 5.859)" fill="#fff" />
      <path id="Caminho_247" data-name="Caminho 247" d="M9.048,8.32l2.009,4.486,2.009,4.486H5.03l2.009-4.486Z" transform="translate(2.593 2.972)" fill="#fff" />
    </svg>
  )
}
