import { Api } from "../services/api";
import { Group } from "../model/GroupModel";
import { UserLazy } from "../model/UserModel";
import {AxiosResponse} from 'axios';

export async function GetGroup() {
    const request = await Api.get<Group[]>("group");
  
      return request.data;
  }

  export async function CreateGroup(group: Group) {
    const request = await Api.post<any>("group", group);

    return request.data;
  }

  export async function GetOneGroup(grouId:string) {
    const request = await Api.get<Group>("group/" + grouId);
  
      return request.data;
  }

  export async function EditGroup(group: Group) {
    const request = await Api.put<any>("group/", group);

    return request.data;
  }

  export async function DeleteGroup(groupId: string) {
    const request = await Api.delete(
      "group/",    
      { data: { id: groupId } }
    );
  }

  export async function GetUsersGroup(gorupId : string, skip:number, limit:number ) {
    const request = await Api.get<UserLazy[]>(
      "group/" + gorupId + "/users?skip=" + skip + "&limit=" + limit
      );
  
      return request.data;
  }

  export async function GetUsersGroupName(gorupId : string, skip:number, limit:number, name:string ) {
    console.log(name);
    const request = await Api.get<UserLazy[]>(
      "group/" + gorupId + "/users?skip=" + skip + "&limit=" + limit + "&name=" + name
      );
  
      return request.data;
  }

  export async function GetAddUsersGroup(gorupId : string, skip:number, limit:number ) {
    const request = await Api.get<UserLazy[]>(
      "group/" + gorupId + "/outsideUsers?skip=" + skip + "&limit=" + limit
      );
  
      return request.data;
  }

  export async function GetAddUsersGroupName(gorupId : string, skip:number, limit:number, name:string ) {
    const request = await Api.get<UserLazy[]>(
      "group/" + gorupId + "/outsideUsers?skip=" + skip + "&limit=" + limit + "&name=" + name
      );
  
      return request.data;
  }
