import { Grid, Modal } from '@mui/material';
import DocumentDetailHeaderActions from '../LeftSideHandler/DocumentDetailHeader/components/DocumentDetailHeaderActions/DocumentDetailHeaderActions';

interface IDocumentDetailWrapper
{
    children: React.ReactNode;
    isOpen: boolean;
    documentName: string;
    closeDetail: () => void;
    extension: string;
    setFullScreenSetted: (bool: boolean) => void;
    fullScreenSetted: boolean;
}

const style = {
    width: '100%',
    height: '100%',
    background: 'transparent',
    padding: "60px",
};

const styleFullScreen = {
    ...style,
    padding: "0px",
}

export default function DocumentDetailWrapper({
    children,
    isOpen,
    closeDetail,
    setFullScreenSetted,
    fullScreenSetted
}: IDocumentDetailWrapper) {    

    function fullScreenToggle() {
        setFullScreenSetted(!fullScreenSetted);
    }

    const sxStyle = {
        background: "white"
    }

    return (
        <Modal
            open={isOpen}
            sx={fullScreenSetted ? styleFullScreen : style}
        >
            <Grid container sx={sxStyle}>
                <DocumentDetailHeaderActions 
                    closeDetail={closeDetail}
                    fullScreenToggle={fullScreenToggle}
                    fullScreenSetted={fullScreenSetted}
                />
                { children }
            </Grid>
        </Modal>
    )
}