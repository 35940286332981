import { TextField } from "@fluentui/react/lib/TextField";
import CheckIcon from "@mui/icons-material/Check";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import React, { useCallback, useState } from 'react';
import { useTranslation } from "react-i18next";
import { RenameItem, WorkSpaceUserName } from "../../../../../model/ItemModel";
import { Rename } from "../../../../../services/Item/ItemServicesCRUD";
import styles from "../../../WorkSpaceItemList.module.css";
import nameFieldStyles from "./NameFieldStyles.module.css";
import { name } from "../../../WorkSpaceItemListStyle/ColumnStandard";


interface INameField {
  indexOfArray:number;
  itemType: string;
  id?: string;
  nameFile?: string;
  description?: string;
  extension?: string;
  setFolderExternal: (itemId: string, itemName: string | undefined) => void;
  isHover: boolean;
  layoutPendencies?: boolean;
  nameContainerRef?: React.MutableRefObject<any>;
  onDragEnterAndOver: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragLeave: (event: React.DragEvent<HTMLDivElement>) => void;
  onDrop?: (
    event: React.DragEvent<HTMLDivElement>,
    key: string | undefined,
    endFunction: () => void,
  ) => void;
  leave: () => void;
  isValidateReadViwer: boolean;
  unvalidType: boolean;
  progress: number;
  
  isValidateViwer: boolean;
  refRef?: React.MutableRefObject<any>;
  isDetailPage?: boolean;
  boxLayout: boolean;
  setPopupError: (set: boolean, message: string) => void;
  setPopupOk: (set: boolean, message: string) => void;
  updateNameDescriptionByIndex:(index:number, name:string, isUpload: boolean, description:string) => void;
  isBoxLayouShowed?: boolean;
  openDetailModal?: (index: number, setOpen: (bool: boolean) => void, setWorkSpace: (workItem: WorkSpaceUserName) => void, e: React.MouseEvent<HTMLDivElement, MouseEvent>, isUpload: boolean) => void;
  setIsDetailOpen?: (value: React.SetStateAction<boolean>) => void; 
  setWorkspace?: (value: WorkSpaceUserName) => void; 
  setIsEditedName?: React.Dispatch<React.SetStateAction<boolean>>;
  isUpload: boolean;
}

export function NameField({
indexOfArray,
itemType,
id,
nameFile,
description,
setFolderExternal,
isHover,
layoutPendencies,
nameContainerRef,
onDragEnterAndOver,
onDragLeave,
onDrop,
leave,
isValidateReadViwer,
unvalidType,
progress,
extension,
isValidateViwer,
isDetailPage,
boxLayout,
setPopupError,
setPopupOk,
updateNameDescriptionByIndex,
openDetailModal,
setIsDetailOpen,
setWorkspace,
setIsEditedName,
 isUpload
}: INameField) {

  const [nameTimed, setNameTimed] = useState<string|undefined>(nameFile);
  const [editName, setEditName] = useState<boolean>(false);
  const [isShownName, setIsShownName] = useState(false);
  const [isWaitingName, setIsWaitingName] = useState<boolean>(false);

  function onClickEditName() {
    setNameTimed(nameFile);
    setEditName(true);
    if(setIsEditedName != undefined)
      setIsEditedName(true);
  }

  function onClickCanceEditName() {
    setEditName(false);
    setIsShownName(false);
    if(setIsEditedName != undefined)
      setIsEditedName(false);
  }

  function rename() {
    setIsWaitingName(true);
    setEditName(false);
    if(setIsEditedName != undefined)
      setIsEditedName(false);

    if (id != undefined && nameFile != undefined) {
      let rename: RenameItem = {
        idMongo: id,
        newName: nameTimed != undefined ? nameTimed : "",
        newFileExtension: extension,
      };

      Rename(rename)
        .then(() => {
          updateNameDescriptionByIndex(indexOfArray, nameTimed != undefined ? nameTimed : "",  isUpload, description == undefined? "" : description);
          setPopupOk(true, "Modificado!");
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            typeof error.response.data === "string"
          ) 
          {
            setPopupError(true, error.response.data);
          }
          else
          {
            setPopupError(true, t("renameItem.error"));
          }
        })
        .finally(() => {
          setIsWaitingName(false);
        });
      }
  }

  const onChangeName = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (newValue != undefined) {
        if (newValue.includes("\\") || newValue.includes("/")) {
          setPopupError(true, t("NameMustNotIncludeEitherSlashes.message"));
        } else {
          setPopupError(false, "");
          setNameTimed(newValue);
        }
      }
    },
    []
  );

    const { t } = useTranslation();

    const haveDisplay = () => {
      return (extension == "dwg" || extension == "pdf" || extension == "docx" || extension == "doc" || extension == "png" || extension == "jpg" || extension == "gif" || extension == "svg" ||
        extension == "bmp" || extension == "webp" || extension == "xlsx" || extension == "ppt" || extension == "pptx" || extension == "mpp" || extension == "xls" || extension == "dwg")
    }

    const composeStyle: React.CSSProperties = {   
      overflow: "hidden",
      wordWrap: "break-word",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    }

    const detailStyle: React.CSSProperties = {   
      overflow: "hidden",
      wordWrap: "break-word",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "50vw",
    }

    const innerDetailStyle: React.CSSProperties = {   
      overflow: "hidden",
      wordWrap: "break-word",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "100%",
    }

    function returnClassName()
    {
      if(isDetailPage)
      {
        if(editName)
        {
          return styles.nameGridDetailWithTextField;
        }
        else
        {
          return styles.nameGridDetail
        }
      }
      else if(boxLayout)
      {
        if(editName)
        {
          return styles.nameGridBoxWithTextField;
        }
        else
        {
          return styles.nameGrid;
        }
      }
      else
      {
        if(editName)
        {
          return styles.nameGridBoxWithTextField;
        }
        else
        {
          return styles.nameGrid;
        }
      }
    }

    return (
      <Box
        style={{
          gridArea:name
        }}
        className={returnClassName()}
        onMouseEnter={() => setIsShownName(true)}
        onMouseLeave={() => setIsShownName(false)}
        ref={nameContainerRef}
        onDragEnter={
          layoutPendencies && itemType != "folder"
          ? undefined
          : (e) => onDragEnterAndOver(e)
        }
        onDragLeave={
          layoutPendencies && itemType != "folder" ? undefined : (e) => onDragLeave(e)
        }
        onDragOver={
          layoutPendencies && itemType != "folder"
          ? undefined
          : (e) => onDragEnterAndOver(e)
        }
        onDrop={
          (layoutPendencies && itemType != "folder") || onDrop == undefined
          ? undefined
          : (e) => onDrop(e, id, leave)
        }
      >
        {
          itemType == "folder" ? 
        (
          editName ? 
          (
            <>                
              <TextField
                value={nameTimed}
                onChange={onChangeName}
                styles={{ fieldGroup: { height: '25px', borderRadius: '8px', whiteSpace: 'nowrap', minHeight: '30px' }, field: {
                  whiteSpace: 'nowrap',
                  border: '1px solid black',
                  borderRadius: '8px',
                  width: '900px'
                }, root: { flexGrow: 1 } }}
                className={styles.focus}
                borderless={true}
                autoFocus
              />
              <div className={styles.iconsGrid}>
                <CheckIcon className={styles.checkicon} onClick={rename} />
                <CloseOutlinedIcon
                  className={styles.closeicon}
                  onClick={onClickCanceEditName}
                />
              </div>
            </>
          ) : (
            <Tooltip title={nameFile ? nameFile : ""} disableInteractive>
              <div
                style={{ position: 'relative', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' , cursor:"pointer"}}
              >
                <div
                  onClick={() => setFolderExternal(id!, nameFile)}
                  className={isHover? `${styles.hoverDrop}`: undefined}
                  draggable={layoutPendencies ? false : true}
                  ref={nameContainerRef}
                  onDragEnter={
                    layoutPendencies
                      ? undefined
                      : (e) => onDragEnterAndOver(e)
                  }
                  onDragLeave={
                    layoutPendencies ? undefined : (e) => onDragLeave(e)
                  }
                  onDragOver={
                    layoutPendencies
                      ? undefined
                      : (e) => onDragEnterAndOver(e)
                  }
                  onDrop={
                    layoutPendencies || onDrop == undefined
                      ? undefined
                      : (e) => onDrop(e, id, leave)
                  }
                  style={{}}
                >
                  {nameFile}
                </div>
              </div>
            </Tooltip>
          )
        ) : editName && !layoutPendencies ? 
        (
          <>
            <TextField
              value={nameTimed}
              onChange={onChangeName}
              styles={{ fieldGroup: { height: '25px', borderRadius: '8px', whiteSpace: 'nowrap', minHeight: '30px' }, field: {
                whiteSpace: 'nowrap',
                border: '1px solid black',
                borderRadius: '8px'
              }, root: { flexGrow: 1 } }}
              borderless={true}
              autoFocus
            />
            <div className={styles.iconsGrid}>
              <CheckIcon className={styles.checkicon} onClick={rename} />
              <CloseOutlinedIcon
                className={styles.closeicon}
                onClick={onClickCanceEditName}
              />
            </div>
          </>
          ) : unvalidType ? (
            <Tooltip title={nameFile ? nameFile : ""} disableInteractive>
              <div
                style={!isDetailPage ? composeStyle : detailStyle}
              >
                <div ref={nameContainerRef} style={!isDetailPage ? composeStyle : innerDetailStyle}>
                  {progress != 0 &&
                    progress != 100 &&
                    progress.toString() + "% "}
                    {nameFile}
                  {extension != undefined && extension != ""
                    ? "." + extension
                    : ""}
                  {""}
                </div>
              </div>
            </Tooltip>
          ) : (haveDisplay())? 
          (
          <Tooltip
            title={
              t("Exibir.message").toString() +
              " / " +
              nameFile +
              (extension != undefined ? "." + extension : "")
            }
            disableInteractive
          >
            <div
              style={!isDetailPage ? composeStyle : detailStyle}
            >
              <div
                onClick={(openDetailModal == undefined || 
                  setWorkspace == undefined  || 
                  indexOfArray == undefined ||
                  setIsDetailOpen == undefined
                ) ? undefined : (e) => openDetailModal!(indexOfArray, setIsDetailOpen, setWorkspace, e, isUpload)}
                
                ref={nameContainerRef}
                style={!isDetailPage ? composeStyle : innerDetailStyle}
                >           
                {progress != 0 &&
                  progress != 100 &&
                  progress.toString() + "% "}
                {nameFile}
                {extension != undefined ? "." + extension: ""}
              </div>
            </div>
          </Tooltip>
        ) : (
          <Tooltip
            title={
              isValidateViwer
                ? nameFile +
                  (extension != undefined ? "." + extension : "")
                : "Download / " +
                  nameFile +
                  (extension != undefined ? "." + extension : "")
            }
            disableInteractive
          >
            <div
              style={!isDetailPage ? composeStyle : detailStyle}
            >
              <div
                onClick={isValidateViwer ? undefined : 
                  (
                    openDetailModal == undefined || 
                    setWorkspace == undefined  || 
                    indexOfArray == undefined ||
                    setIsDetailOpen == undefined
                  ) ? undefined: (e) => openDetailModal!(indexOfArray, setIsDetailOpen, setWorkspace, e, isUpload)
                }
                ref={nameContainerRef}
                style={!isDetailPage ? composeStyle : innerDetailStyle}
              >
                {progress != 0 &&
                  progress != 100 &&
                  progress.toString() + "% "}
                  {nameFile}
                {extension != undefined && extension != ""
                  ? "." + extension
                  : ""}
              </div>
            </div>
          </Tooltip>
        )}



        {isWaitingName && (
          <div className={styles.load}>
            <CircularProgress color="inherit" size="20px" style={{ margin: "4px" }} />
          </div>
        )}
        {!editName && (
          <>
            <div className={isDetailPage ? undefined : boxLayout ? nameFieldStyles.editIconStyle : nameFieldStyles.editIconStyle}>
              {(!isValidateReadViwer && isShownName) && (
                <EditIcon className={styles.EditiconName} onClick={onClickEditName} style={{ color: "rgb(17, 77, 136)" }} />
              )} 
            </div>
          </>
        )}
      </Box>
    ) 
}