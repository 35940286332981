import { WizardModel, WizardToken } from "../model/WizardModel";
import { ApiWithoutToken } from "./api";

export const CreateWizard = async (wizard: WizardModel) => {
  const request = await ApiWithoutToken.post<WizardModel>('Wizard/CreateWizard', wizard)
  return request.data
}

export const GetWizardToken = async (token: string) => {
  const request = await ApiWithoutToken.get<WizardToken>(`Wizard/GetTokenWizard?token=${token}`)
  return request.data
}