export interface ExeConfig {}

export const Exe: React.FC<ExeConfig> = ({}) => {
  return(
	<svg version="1.1" id="Camada_2_00000039127297054938278630000011511440548856758705_"
		xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 39.8 51.9"
		style={{ background: "new 0 0 39.8 51.9" }} xmlSpace="preserve">
		<style type="text/css">
			{`
				.exe0{fill-rule:evenodd;clip-rule:evenodd;fill:#B3B3B3;}
				.exe1{fill-rule:evenodd;clip-rule:evenodd;fill:#D6D6D6;}
				.exe2{fill:#FFFFFF;}
			`}
		</style>
		<g id="Grupo_736">
			<path id="Caminho_136" className="exe0" d="M39.8,13.7v34.2c0,2.3-1.8,4.1-4.1,4.1H4.1c-2.3,0-4.1-1.8-4.1-4.1l0,0V4.1
				C0,1.8,1.9,0,4.1,0h21.9C26.1,0,39.8,13.7,39.8,13.7z"/>
			<path id="Caminho_138" className="exe1" d="M39.8,12.9v1.7H28.5c-2.3,0-3.4-1.9-3.4-4.2V0h1.7C26.9,0,39.8,12.9,39.8,12.9z"/>
		</g>
		<g>
			<path className="exe2" d="M16.5,31.6h-4.1c0,0.6,0.2,1,0.5,1.3s0.8,0.5,1.3,0.5c0.6,0,1.2-0.2,1.8-0.6v1.1c-0.5,0.3-1.3,0.5-2.2,0.5
				c-0.9,0-1.6-0.3-2.1-0.8S11,32.2,11,31.2c0-0.9,0.3-1.7,0.8-2.3s1.2-0.9,2.1-0.9s1.5,0.3,1.9,0.8s0.7,1.3,0.7,2.2V31.6z M15.2,30.6
				c0-0.5-0.1-0.9-0.3-1.1s-0.5-0.4-0.9-0.4c-0.4,0-0.7,0.1-1,0.4s-0.4,0.7-0.5,1.1H15.2z"/>
			<path className="exe2" d="M22.8,28.2l-2,3l2,3h-1.6l-1.1-1.9c-0.1-0.1-0.1-0.3-0.2-0.4h0c0,0-0.1,0.2-0.2,0.4l-1.1,1.9H17l2-2.9
				l-1.9-3.1h1.6l1,2c0.1,0.1,0.2,0.3,0.2,0.4h0l1.3-2.4H22.8z"/>
			<path className="exe2" d="M28.7,31.6h-4.1c0,0.6,0.2,1,0.5,1.3s0.8,0.5,1.3,0.5c0.6,0,1.2-0.2,1.8-0.6v1.1c-0.5,0.3-1.3,0.5-2.2,0.5
				c-0.9,0-1.6-0.3-2.1-0.8s-0.8-1.3-0.8-2.3c0-0.9,0.3-1.7,0.8-2.3s1.2-0.9,2.1-0.9s1.5,0.3,1.9,0.8s0.7,1.3,0.7,2.2V31.6z
				M27.4,30.6c0-0.5-0.1-0.9-0.3-1.1s-0.5-0.4-0.9-0.4c-0.4,0-0.7,0.1-1,0.4s-0.4,0.7-0.5,1.1H27.4z"/>
		</g>
	</svg>
  )
}
