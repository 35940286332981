import { Api } from "../services/api";
import { DocumentTypeCreate } from "../model/DocumentType";
import { UserLazy } from "../model/UserModel";
import {AxiosResponse} from 'axios';

export async function CreateDocumentType(documentType: DocumentTypeCreate) {
    const request = await Api.post<any>("DocumentType", documentType);

    return request.data;
  }

  export async function EditDocumentType(documentType: DocumentTypeCreate) {
    const request = await Api.put<any>("DocumentType/", documentType);

    return request.data;
  }

  export async function DeleteDocumentType(documentTypeId: string) {
    const request = await Api.delete(
      "DocumentType/",    
      { data: { id: documentTypeId } }
    );
  }
  
  export async function GetDocumentType(id: string) {
    if (id === null) return null;
    const request = await Api.get<DocumentTypeCreate>(
      "DocumentType/" + id
    );
  
  return request.data;
}
