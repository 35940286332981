export interface FileConfig {}

export const File: React.FC<FileConfig> = ({}) => {
  return(
	<svg version="1.1" id="Camada_2_00000039127297054938278630000011511440548856758705_"
		xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 39.8 51.9"
		style={{ background: 'new 0 0 39.8 51.9' }} xmlSpace="preserve">
		<style type="text/css">
			{`
				.file0{fill-rule:evenodd;clip-rule:evenodd;fill:#B3B3B3;}
				.file1{fill-rule:evenodd;clip-rule:evenodd;fill:#D6D6D6;}
				.file2{fill:#FFFFFF;}
			`}
		</style>
		<g id="Grupo_736">
			<path id="Caminho_136" className="file0" d="M39.8,13.7v34.2c0,2.3-1.8,4.1-4.1,4.1H4.1C1.8,52,0,50.2,0,47.9l0,0V4.1C0,1.8,1.9,0,4.1,0
				H26C26.1,0,39.8,13.7,39.8,13.7z"/>
			<path id="Caminho_138" className="file1" d="M39.8,12.9v1.7H28.5c-2.3,0-3.4-1.9-3.4-4.2V0h1.7C26.9,0,39.8,12.9,39.8,12.9z"/>
		</g>
		<g>
			<path className="file2" d="M15.9,26.5c-0.2-0.1-0.4-0.2-0.6-0.2c-0.7,0-1,0.4-1,1.1v0.8h1.4v1.1h-1.4v4.9h-1.4v-4.9h-1v-1.1h1v-1
				c0-0.6,0.2-1.1,0.6-1.5s0.9-0.5,1.6-0.5c0.3,0,0.6,0,0.8,0.1V26.5z"/>
			<path className="file2" d="M17.3,27c-0.2,0-0.4-0.1-0.6-0.2s-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2
				s0.2,0.3,0.2,0.6c0,0.2-0.1,0.4-0.2,0.5S17.5,27,17.3,27z M18,34.3h-1.4v-6H18V34.3z"/>
			<path className="file2" d="M21.1,34.3h-1.4v-8.9h1.4V34.3z"/>
			<path className="file2" d="M27.9,31.7h-4.1c0,0.6,0.2,1,0.5,1.3s0.8,0.5,1.3,0.5c0.6,0,1.2-0.2,1.8-0.6v1.1c-0.5,0.3-1.3,0.5-2.2,0.5
				c-0.9,0-1.6-0.3-2.1-0.8s-0.8-1.3-0.8-2.3c0-0.9,0.3-1.7,0.8-2.3s1.2-0.9,2.1-0.9s1.5,0.3,1.9,0.8s0.7,1.3,0.7,2.2V31.7z
				M26.6,30.7c0-0.5-0.1-0.9-0.3-1.1s-0.5-0.4-0.9-0.4c-0.4,0-0.7,0.1-1,0.4s-0.4,0.7-0.5,1.1H26.6z"/>
		</g>
	</svg>
  )
}

