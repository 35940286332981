import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material"
import moment from "moment"
import { ButonSendBone } from "../../../../components/Button/ButonSendBone"
import PanelGenericText from "../../../../components/PanelGenericText/PanelGenericText"
import { UserCreate } from "../../../../model/UserModel";
import usuarios from '../../../../assets/icons/usuarios_alter.svg'
import email from '../../../../assets/icons/e-mail.svg'
import check__validation from '../../../../assets/icons/check-sucesso-envio.svg'
import { useTranslation } from "react-i18next";
import styles from '../../ValidationRule.module.css'
import { BaseSvg } from "../../../../components/BaseSVg/BaseSvg";
import { ValidationTarget } from "../../../../model/ValidationRuleModel";

interface ValidationRuleConclusionPanelTypes {
  target : ValidationTarget | undefined;
  dismissPanel: () => void;
  errorMessage: string | undefined
}

const ValidationRuleConclusionPanel: React.ForwardRefExoticComponent<ValidationRuleConclusionPanelTypes> = React.forwardRef(({
  target,
  dismissPanel,
  errorMessage
} , ref) => {

  const { t } = useTranslation();

  return (
    <div>
      { errorMessage != undefined ? (
        <div>
          <PanelGenericText
            text="Validation Rule cancelada!"
            paragraph={errorMessage}
            textCenter
          />

          <ButonSendBone
            text={t("validationRulePanel.OkUnderstand")}
            waitLoader={false}
            onClick={
              (e) => {
                dismissPanel()
              }
            }
          />
        </div>
      ) : (
        <div className={styles.conclusionDiv}>
          <Box sx={{
            textAlign: 'center',
            marginBottom: '11px'
          }}>
            <img src={check__validation} />
          </Box>
          <PanelGenericText
            text={t("validationRulePanel.validationRuleSubmit")}
            paragraph={t("validationRulePanel.validationRuleData")}
            textCenter
          />
          <Grid container sx={{
            padding: 4,
            background: "#F5F5F5 0% 0% no-repeat padding-box",
            borderRadius: "8px",
            opacity: 1,
            marginTop: '40px',
            marginBottom: '20px'
          }}>
            {target != undefined && target.users != undefined && target.users.length > 0 && (
              <>
            <Grid item xs={12} sx={{ display: 'flex' }}>
              <Box
                sx={{
                  textAlign: "left",
                  font: "normal normal 600 18px/27px Segoe UI",
                  letterSpacing: "0px",
                  color: "#2D2D2D",
                  display: 'flex',
                  gap: 1,
                  marginBottom: '20px'
                }}
              >
                
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    marginTop: '2px'
                  }}
                >
                  <Box sx={{
                    marginTop: '4px'
                  }}>
                    <img src={usuarios} width={20} />  
                  </Box> 
                  { t("validationRulePanel.responsible") + ":" }
                </Box>
                
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                  { target.users?.map(({ name }, index) => 
                    <Typography
                      sx={{
                        textAlign: "left",
                        font: "normal normal normal 18px/27px Segoe UI",
                        letterSpacing: "0px",
                        color: "#2D2D2D",
                      }}
                      key={index}
                    >
                      { name }
                    </Typography>
                )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex' }}>
              <Box
                sx={{
                  textAlign: "left",
                  font: "normal normal 600 18px/27px Segoe UI",
                  letterSpacing: "0px",
                  color: "#2D2D2D",
                  display: 'flex',
                  gap: 1,
                  marginBottom: '20px'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    marginTop: '2px'
                  }}
                >
                  <Box sx={{
                    marginTop: '4px'
                  }}>
                    <img src={email} width={20} />  
                  </Box> 
                  { target.users?.length! > 1 ? 'E-mails:' : 'E-mail:' } 
                </Box>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                  { target.users?.map(({ login }, index) => 
                    <Typography 
                      sx={{
                        textAlign: "left",
                        font: "normal normal normal 18px/27px Segoe UI",
                        letterSpacing: "0px",
                        color: "#2D2D2D",
                      }}
                      key={index}
                    >
                      { login }
                    </Typography>
                )}
                </Box>
              </Box>
            </Grid>
            </>
            )}

            <Grid item xs={12}>
              <Box
                sx={{
                  textAlign: "left",
                  font: "normal normal 600 18px/27px Segoe UI",
                  letterSpacing: "0px",
                  color: "#2D2D2D",
                  display: 'flex',
                  gap: 1,
                  marginBottom: '20px'
                }}
              >
                <BaseSvg color="#2D2D2D" width="20" height="20" /> {t("Term.message") + ":"} 
                <Typography sx={{
                  textAlign: "left",
                  font: "normal normal normal 18px/27px Segoe UI",
                  letterSpacing: "0px",
                  color: "#2D2D2D",
                }}>
                  { target != undefined && target.timeDeadLine != undefined ? (
                    <>
                      {moment(target.timeDeadLine).format('DD/MM/YYYY')}
                    </>
                  ) : (
                    <>
                      {t("validationRulePanel.withTerm")}
                    </>
                  ) }
                </Typography>
              </Box>
            </Grid>

          </Grid>
          
          <ButonSendBone
            text={t("validationRulePanel.OkUnderstand")}
            waitLoader={false}
            onClick={
              (e) => {
                dismissPanel()
              }
            }
          />
        </div>
      ) }
    </div>
  )
})

export default ValidationRuleConclusionPanel;