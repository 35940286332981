import { Box } from "@mui/material";

interface IDocumentDetailBasicWrapper
{
    children: React.ReactNode;
    isSidebarCollapsed: boolean;
}

export default function DocumentDetailBasicWrapper({ children, isSidebarCollapsed }: IDocumentDetailBasicWrapper)
{
    return (
        <Box sx={{
            display: 'flex',
            marginTop: '26px',
         }}>
             <Box sx={{
                 width: isSidebarCollapsed ? '80%' : '66%',
                 height: 20,
                 justifyContent: 'space-between',
                 display: 'flex'
             }}>
                { children }
            </Box>
        </Box>
    )
}