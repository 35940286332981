export interface ZipConfig {}

export const Zip: React.FC<ZipConfig> = ({}) => {
  return(
	<svg id="icon-zip" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		<g id="Grupo_606" data-name="Grupo 606">
			<rect id="Retângulo_252" data-name="Retângulo 252" width="24" height="24" rx="2" fill="#ffd864" />
			<rect id="Retângulo_253" data-name="Retângulo 253" width="3.428" height="3.428" transform="translate(5.253)" fill="#aeaeae" />
			<rect id="Retângulo_254" data-name="Retângulo 254" width="3.428" height="3.428" transform="translate(8.681 3.428)" fill="#aeaeae" />
			<rect id="Retângulo_255" data-name="Retângulo 255" width="3.428" height="3.428" transform="translate(5.253 6.842)" fill="#aeaeae" />
			<rect id="Retângulo_257" data-name="Retângulo 257" width="3.428" height="3.428" transform="translate(5.253 13.698)" fill="#aeaeae" />
			<rect id="Retângulo_256" data-name="Retângulo 256" width="3.428" height="3.428" transform="translate(8.681 10.27)" fill="#aeaeae" />
		</g>
	</svg>
  )
}

