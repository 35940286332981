import React, { ChangeEvent, useCallback, useState } from "react";
import styles from "../WorkSpaceItemList.module.css";
import { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {TagsVO, TagName} from "../../../model/Tags"
import { useModifiedBy } from "../ResizeStyles/WorkSpaceItemListStyles/ModifiedBy";
import Btn, { TypeStyle } from "../../../components/Button/Btn";
import CircularProgress from '@mui/material/CircularProgress';
import { tags } from "../WorkSpaceItemListStyle/ColumnStandard";

interface TagsFieldProps {
    itemType:string;
    boxLayout:boolean;
    onClickTag:() => void;
    tagInternal:TagName[] | undefined;
}

const TagsField: React.ForwardRefExoticComponent<TagsFieldProps> =
	React.forwardRef(({itemType,boxLayout,onClickTag, tagInternal }, ref: React.Ref<HTMLLIElement>) => {

    const { t, i18n } = useTranslation();
    
    const firstTagName: string|undefined = tagInternal == undefined ? undefined : tagInternal.length == 0 ? undefined : tagInternal[0].name;
    const tagLength: number =tagInternal == undefined ? 0 : tagInternal.length - 1;

    const modifiedBy = useModifiedBy();

    return (
        <div  className={`${boxLayout?modifiedBy.myDiv:modifiedBy.default}`}
          style={{
            gridArea:tags
          }}
        >
             {itemType != "folder"&&(
                 tagInternal != undefined ? (
                  tagInternal.length > 0 ?(
                    <div style={{display:"flex"}}>
                        <Btn
                            text={firstTagName}
                            style={TypeStyle.EditTagButton}
                            onClick={onClickTag}
                        />
                        <div className={styles.lengthTag}>
                          {
                            tagLength != 0 && (                                            
                              <div>+{tagLength}</div>
                            )                      
                          }
                        </div>
                    </div> 
                  ):(
                    <div style={{ display: "flex" }}>
                      <Btn
                        text={t("tag.add")}
                        style={TypeStyle.AddTagButton}
                        onClick={onClickTag}
                      />
                    </div> 
                  )
                
                ):(
                  <div style={{display:"flex"}}> 
                      <Btn
                        text={t("tag.add")}
                        style={TypeStyle.AddTagButton}
                        onClick={onClickTag}
                      />        
                  </div> 
                )              
              )}
        </div>        
    );
});

    
export default TagsField;
