export const stepOneValidation = (
  organization: string,
  workspace: string,
  password: string,
  cpass: string,
  fn: () => void,
  criteria: boolean,
  isOfficeAuthenticationScheme: boolean
): string | undefined => {
  if (criteria || isOfficeAuthenticationScheme) {
    if ((organization === '' || workspace === '')) {
      return "Preencha todos os campos de maneira correta";
    } 
  } else {
    if ((organization === '' || workspace === '' || password === '' || cpass === '')) {
      return "Preencha todos os campos de maneira correta";
    } 
    if (cpass !== password) {
        return "Preencha todos os campos de maneira correta";
    }
  }
  fn()
  return undefined;
}