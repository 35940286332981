import React, { useState, useEffect } from "react";
import  Btn  from '../../components/Button/Btn';
import  {
    GetByToken, 
    DownloadByToken, 
    GetOrganizationUser,
    GetOrganizationUserName
}  from '../../services/UserPendencies';
import { useNavigate, useParams } from "react-router-dom";
import styles from "./Acept.module.css";
import { useTranslation } from "react-i18next";
import {useSearchParams } from "react-router-dom";
import {
    mergeStyleSets,
  } from '@fluentui/react';
import { WorkSpaceUserName, D4SignStatus } from "../../model/ItemModel";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Stack } from '@fluentui/react';
import ChoicerViwer from "../../pages/WorkSpaceItem/Viwer/ChoicerViwer";
import PanelGenericText from "../../components/PanelGenericText/PanelGenericText"; 
import { PublicHeader } from "../PublicLayout/components/BackgroundImage/PublicHeader/PublicHeader";
import KeyDriveLogo from '../../assets/images/IconLogin.png'
import DesloggedButton from "../WorkSpaceItem/WorkSpaceItemListFileCompose/ValidationRuleButton/components/DesloggedButton";
import BackAndFowardComponent from "../WorkSpaceItem/WorkSpaceItemListFileCompose/ValidationRuleButton/BackAndFowardComponent";
import { BackItem, ForwardItem, GetPreconditionActivityBackByToken, GetPreconditionActivityFowardByToken } from "../../services/UserPendencies";
import I18n from "../../components/I18n";

const Acept  = (props: any) => {
    //Support
    const navegate = useNavigate();
    const { t, i18n } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    //Paramns
    const [activityName, setActivityName] = useState<string>("");

    const [name, setName] = useState<string|undefined>(undefined);
    const [extension, setExtension] = useState<string|undefined>(undefined);
    const [signer_email, setSigner_email] = useState<string|undefined>(undefined);
    const [signer_display_name, setSigner_display_name] = useState<string|undefined>(undefined);

    const [showError, setShowError] = useState<boolean>(false);
    const [reloadPendenci, setReloadPendenci] = useState<boolean>(true);
    const [token, setToken] =  useState<string |undefined>(undefined);
    const [erroMessage, setErroMessage] = useState<string>("");

    const [popupOk, setPopupOk] = useState<boolean>(false);

    const [workspaceItem, setWorkspaceItem] = useState<WorkSpaceUserName>();

    useEffect(() => {
        getUserPendency();
    }, [token]);

    function getUserPendency()
    {
        setReloadPendenci(true);
        setShowError(false);
        setActivityName("");
        setSigner_email(undefined);
        setSigner_display_name(undefined);

        if(token != undefined ){
            GetByToken(encodeURI(token))
            .then((result)=>{

                setName(result.name);
                setExtension(result.extension);
                setWorkspaceItem(result);
                if(result.validationRule)
                {
                    if(result.validationRule.currentOperation)
                    {
                        setActivityName(result.validationRule.currentOperation)
                    }

                    if(result.loginToken)
                    {
                        setSigner_email(result.loginToken);
                    }


                    if(result.nameToken)
                    {
                        setSigner_display_name(result.nameToken);
                    }
                }
            })
            .catch((error) =>{
                if( error.response && error.response.data && typeof error.response.data  === 'string'){
                    setPopupOkExternalError(true, error.response.data);
                }
                else
                {
                    setPopupOkExternalError(true, "Erro");
                }

                setReloadPendenci(false);
            })
        }
    }

    useEffect(() => {
        if(token != undefined ){
            GetByToken(encodeURI(token))
            .then()
            .catch((error) =>{
                if( error.response && error.response.data && typeof error.response.data  === 'string'){
                    setPopupOkExternalError(true, error.response.data);
                }
                else
                {
                    setPopupOkExternalError(true, "Erro");
                }

                setReloadPendenci(false);
            })
        }
    }, [i18n.language])

    useEffect(() => {
        let token = searchParams.get("token");
        setToken(token!);
      },[searchParams])


    function setPopupOkExternalError(set:boolean, message:string) {
        setErroMessage(message);
        setShowError(set);
    }

    const handleClose = (event:any, reason:any) => {
        closePopUpOk();
    }
  
    const handleClosePO = (event:any) => {
        closePopUpOk();
    }

    function closePopUpOk() {
        setPopupOk(false);
    }
    
    return (
        <div>
            <Stack horizontal className={styles.main}>
                <Stack.Item grow>
                    <h3>
                        {extension == undefined || extension == ""? name : name + '.' + extension}{" "}{activityName}
                    </h3>
                </Stack.Item>
                <Stack className={styles.stackSide}>
                    <Stack.Item className={styles.InputSearch}>
                        <I18n/>

                        { (showError == false && token != undefined && signer_email != undefined && signer_display_name != undefined && workspaceItem != undefined) && (
                            <BackAndFowardComponent 
                                FbComponent={DesloggedButton}
                                workspaceItem={workspaceItem}
                                userFilter={token}
                                setPopupOk={setPopupOk}
                                userPromisse={GetOrganizationUser}
                                userPromisseWords={GetOrganizationUserName}
                                indexOfArray={0}
                                setWorkspace={setWorkspaceItem}
                                signer_display_name={signer_display_name}
                                signer_email={signer_email}
                                backItem={BackItem}
                                forwardItem={ForwardItem}
                                validationPreconditionBackItem={GetPreconditionActivityBackByToken}
                                validationPreconditionFoward={GetPreconditionActivityFowardByToken}
                                isAccept={true}
                                isUpload={true}
                            />
                        ) }
                            
                        <Btn onClick={getUserPendency} text={t("Reload.message")} disabled={reloadPendenci} hidden={reloadPendenci}/>
                       
                    </Stack.Item>
                </Stack>
            </Stack>
            
            {showError ?(
                <>
                    <PublicHeader 
                        icon={KeyDriveLogo}
                        withoutOpacity={true}
                    />
                    <div className={styles.error}>
                        <svg height="100" width="100" style={{ marginLeft: "calc(50% - 50px)"}}>
                            <circle cx={50} cy={50} r={40} fill={"#2D2D2D"} opacity={"0.05"}>
                            </circle>
                            <circle cx={50} cy={50} r={24} fill={"#005A9E"}>
                            </circle>
                            <polyline points="42,49 50,56 63,44" style={{
                                fill:"none",
                                stroke: "rgb(244,244,244)",
                                strokeWidth:"6",
                                strokeLinecap:"round"
                            }}/>
                        </svg>
                        <PanelGenericText
                            text={erroMessage}
                            paragraph={t("validationRulePanel.emailWrong")}
                            style={{
                                marginTop: '31px',
                                marginBottom: '31px',
                            }}
                            textCenter={true}
                        />
                    </div>
                </>
            ): token != undefined && (
                <ChoicerViwer
                    idItem={encodeURI(token)}
                    menuConfig={undefined}
                    download={DownloadByToken}
                    getItem={GetByToken}
                    positionAbsolute={true}
                    isAcept={true}
                    fullScreenSetted={false}
                    isSharedFilePage={false}
                /> 
            )}

            <Snackbar
              open={popupOk}
              autoHideDuration={6000}
              message="Archived"
              onClose={handleClose}
              anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
              sx={{ bottom: { xs: 50, sm: 20 } }}
            >
              <MuiAlert elevation={6} variant="filled" sx={{ width: '100%' }} severity={"success"} onClose={handleClosePO}>
                {t("AlteradoComSucesso.message")}
              </MuiAlert>
          </Snackbar>
        </div>
    );
};

const contentStyles = mergeStyleSets({
    loader:{
        marginLeft: "48%",
        marginTop: "19%" 
    }
})

export default Acept;