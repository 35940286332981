import { Box } from "@mui/material";



export default function DocumentDetailInternalCircle()
{
    return (
        <Box 
            sx={{
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                background: 'black'
            }}
        />
    )
}