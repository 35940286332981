import React from "react";
import Loader from 'react-ts-loaders';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { useTranslation } from "react-i18next";

const loaderSave = mergeStyles({
    justifyContent:"left",
    color: "#114D88"
  });


const WaitPage = (props: any) => {
    const { t, i18n } = useTranslation();

    return (
        <div>
            <h1>
                <Loader size={110} className={loaderSave}/>
            </h1>
        </div>
    );
}

export default WaitPage;