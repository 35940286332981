import React, { useEffect, useState, useRef, useCallback  } from "react";
import stylesList from "../../Style/List.module.css";
import { useTranslation } from "react-i18next";
import { GroupPermission } from "../../../model/GroupModel";
import { 
  GetWorkGroup, GetWorkGroupName
 } from "../../../services/WorkSpaceServices";
 import GroupPermissionItem from "./GroupPermissionItem";

interface WorkSpacePermissionsListConfig {
    nameOrg: string;
    idWorkspace: string;
    name: string;
  }

  function useVisibility(
    cb: (isVisible: boolean) => void,
    deps: React.DependencyList
  ): (node: any) => void {
    const intersectionObserver = useRef<IntersectionObserver | null>(null);
    return useCallback((node) => {
      if (intersectionObserver.current) {
        intersectionObserver.current.disconnect();
      }
  
      intersectionObserver.current = new IntersectionObserver(([entry]) => {
        cb(entry.isIntersecting);
      });
  
      if (node) intersectionObserver.current.observe(node);
    }, deps);
  }

  


const WorkSpacePermissionsList: React.FC<WorkSpacePermissionsListConfig> = ({nameOrg,idWorkspace, name}) => {
    const { t, i18n } = useTranslation();
    const [groupsPermission, setGroupsPermission] = useState<GroupPermission[]>([]);
    let [GroupMax, setGroupMax] = useState<boolean>(true);
    let [skip, setSkip] = useState<number>(0);
    const [limit, setLimit] = useState<number>(50);

    const lastGroup = useVisibility(
      (visible) => {
        if (visible) {
          if(name == "")
          {
            GetWorkGroup(idWorkspace,skip, limit).then((newGroups) => {
              if (newGroups.length < limit) {
                setGroupMax(false);
              }
              setSkip(skip + newGroups.length);
              setGroupsPermission([...groupsPermission, ...newGroups]);
            });
          }
          else
          {
            GetWorkGroupName(idWorkspace, skip, limit, name).then((newGroups) => {
              if (newGroups.length < limit) {
                setGroupMax(false);
              }
              setSkip(skip + newGroups.length);
              setGroupsPermission([...groupsPermission, ...newGroups]);
            });
          }
        }
      },
      [skip, idWorkspace, groupsPermission]
    );

    useEffect(() => {
      if(name == "")
      {
        GetWorkGroup(idWorkspace,0, limit).then((newWork) => {
          if (newWork.length < limit) {
            setGroupMax(false);
          }
          setSkip(newWork.length);
          setGroupsPermission(newWork);
        });
      }
      else
      {
        GetWorkGroupName(idWorkspace, 0, limit, name).then((newWork) => {
          if (newWork.length < limit) {
            setGroupMax(false);
          }
          setSkip(newWork.length);
          setGroupsPermission(newWork);
        });
      }
    }, [idWorkspace,name]);

    return (
            <ul className={stylesList.ulContent}>
                <li className={`${stylesList.itemContent} ${stylesList.title}`}>
                    <>{t("Nome.message")}</>{" "}
                    </li>
                      {groupsPermission.map((groupPermission) => (
                            <GroupPermissionItem
                            key={groupPermission.groupId}
                            idGroup={groupPermission.groupId}
                            nameGroup={groupPermission.groupName}
                            typeGroup={groupPermission.permissionType}
                            idWorkspace={idWorkspace}
                            ref={groupsPermission[groupsPermission.length - 1].groupId === groupPermission.groupId && GroupMax ? lastGroup : null}
                            />
                ))}
                </ul>
    )
}

export default WorkSpacePermissionsList;