import { Location } from "react-router-dom";

/**
 * Função criada para armazenar um booleano sempre que 
 * necessitada uma alteração no código conforme a rota acessada pelo usuário.
 * @param continuous 
 * Feito para verificar se a rota é continua ou não.
 * Adiciona um barra no criterio (criteria), indicando a continuidade.
 * @param criteria 
 * O criterio da condição.
 * @param locationPathName
 * O Objeto onde será guardado o pathname da rota em questão. 
 * @returns 
 * Retorna um booleano, para indicar a alteração de contexto do código.
 */

export const handleAllowed = (continuous: boolean, criteria: string, locationPathName: Location): boolean => {
  if (locationPathName.pathname !== undefined) {
    if (continuous) {
      return locationPathName.pathname.includes(criteria + '/');
    } else {
      return locationPathName.pathname.includes(criteria);
    }
  }
  return false;
}