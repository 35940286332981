import { Box, Tooltip, Typography, LinearProgress } from "@mui/material";
import { bytesConverter } from "../MemoryProgress/bytesConverter";
import { useEffect, useState } from "react";
import { convertToNextUnit } from "../../functions/convertNextUnit";
import { useTranslation } from "react-i18next";

interface IProgressBar {
    maxValue?: number;
    consumedValue?: number;
    isGraph?: boolean;
    minValue?: number;
    hasContract: boolean;
}

export default function ProgressBar({
    maxValue,
    consumedValue,
    isGraph,
    minValue,
    hasContract
}: IProgressBar) {

    const [progress, setProgress] = useState<number>(0);
    const { i18n } = useTranslation()

    function setPercentage() {
        let result = 0;
        const minValuePercentage = 1;
        if (maxValue && consumedValue) {
            if (!isGraph) {
                result = consumedValue * 100 / maxValue;
            } else {
                let totalRange = maxValue - minValue!;
                result = (consumedValue - minValue!) * 100 / totalRange;
            }

            result = Math.max(result, minValuePercentage);
        }
        return result;
    }

    useEffect(() => {
        const timer = setInterval(() => {
        setProgress((oldProgress) => {
            if (oldProgress >= setPercentage()) {
            clearInterval(timer);
            return setPercentage();
            }
            return oldProgress + 1;
        });
        }, 40);

        return () => {
        clearInterval(timer);
        };
    }, []);

    return (
        <>
            { isGraph ? (
                <LinearProgress 
                    variant="determinate"
                    value={progress}
                    sx={{
                        height: "30px",
                        borderRadius: "8px",
                        background: "transparent",
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#114D88', 
                        },
                    }}
                />
            ) : (
                <Box
                    sx={{
                        width: "100%",
                        display:"flex",
                        justifyContent: "end",
                        gap: 2
                    }}
                >
                    <Tooltip
                        title={ convertToNextUnit(consumedValue!, maxValue!, consumedValue!, i18n.language).bytesNotConverted }

                    >
                        <Typography
                            sx={{
                                textAlign: "left",
                                font: "normal normal bold 16px/24px Segoe UI",
                                letterSpacing: "0px",
                                color: "#2D2D2D",
                                opacity: 1,
                            }}
                        >
                            { !hasContract ? convertToNextUnit(consumedValue!, maxValue!, consumedValue!, i18n.language).bytesConverted : convertToNextUnit(consumedValue!, maxValue!, consumedValue!, i18n.language).bytesConverted }
                        </Typography>
                    </Tooltip> 
                    <Box
                        sx={{
                            width: "100%",
                            position: "relative"
                        }}
                    >
                        <LinearProgress 
                            variant="determinate"
                            value={progress}
                            sx={{
                                height: "30px",
                                borderRadius: "8px",
                                width: "98%",
                                backgroundColor: '#f0f0f0',
                                '& .MuiLinearProgress-bar': {
                                    backgroundColor: '#114D88', 
                                },
                            }}
                        />
                        { !hasContract && (
                            <Tooltip
                                title="NA" 
                            >
                                <Typography
                                    sx={{
                                        textAlign: "left",
                                        font: "normal normal bold 16px/24px Segoe UI",
                                        letterSpacing: "0px",
                                        opacity: 1,
                                        position: "absolute",
                                        top: 0,
                                        right: 0
                                    }}
                                >
                                    NA
                                </Typography>
                            </Tooltip> 
                        ) }
                    </Box>
                </Box>
            ) }
        </>
    )
}