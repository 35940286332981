import { Api } from "../services/api";
import {
  ValidationRuleVORetrieve,
  ValidationRuleVO,
  ValidationRuleVOCreateUpdate,
  ReturnBooleanVO,
  ItemToValidationRuleVO,
  ValidationTypes,
  PreconditionActivity,
  ForwardItemVO,
  BackItemVO,
  Diseble,
  ValidationInformation
} from "../model/ValidationRuleModel";
import { User } from "../model/OrganizationModel";
import {
  WorkSpaceUserName
} from "../model/ItemModel";
import {WorkspacePendenci} from "../model/ItemModel";

export async function GetVrByWorkspaceId(workspaceId:string, skip: number, limit:number) {
    const request = await Api.get<ValidationRuleVO[]>(
        "WorkSpace/" + workspaceId + "/validationRule?skip=" + skip + "&limit=" + limit
    );

    return request.data;
  }

  export async function GetVrByWorkspaceIdName(workspaceId:string, skip: number, limit:number, name:string) {
    const request = await Api.get<ValidationRuleVO[]>(
        "WorkSpace/" + workspaceId + "/validationRule?skip=" + skip + "&limit=" + limit + "&name=" + name
    );

    return request.data;
  }

  export async function GetOneVr(vrId:string) {
    const request = await Api.get<ValidationRuleVORetrieve>(
        "ValidationRule/" + vrId
    );

    return request.data;
  }

  export async function CreateVr(ValidationRuleVOCreateUpdate:ValidationRuleVOCreateUpdate) {
    const request = await Api.post<any>(
        "ValidationRule",ValidationRuleVOCreateUpdate
    );

    return request.data;
  }

  export async function SetPseudoValidation(idVr:string,idItem:string,idUser:string) {
    const request = await Api.post<any>(
        "ValidationRule/SetPseudoValidation?idVr="+idVr+"&idItem="+idItem+"&idUser="+idUser
    );
    return request.data;
  }

  export async function UpdateVr(ValidationRuleVOCreateUpdate:ValidationRuleVOCreateUpdate) {
    const request = await Api.put<any>(
        "ValidationRule",ValidationRuleVOCreateUpdate
    );

    return request.data;
  }

  export async function DeleteVr(vrId:string) {
    const request = await Api.delete(
      "ValidationRule/",    
      { data: { id: vrId } }
    );
  }

  export async function GetUserPendenciesBoolean(internalName:string) {
    const request = await Api.get<ReturnBooleanVO>(
        "ValidationRule/UserPendecies/" + internalName
    );

    return request.data;
  }

  export async function GetUserPendenciesAll(internalName:string, skip: number, limit:number) {
    const request = await Api.get<WorkspacePendenci[]>(
        "ValidationRule/UserPendeciesAll/" + internalName +"?skip"+ skip + "&limit=" + limit 
    );

    return request.data;
  }

  export async function ValidationPreconditionFoward(id: string) {
    const request = await Api.get<PreconditionActivity>(
        "ValidationRule/ValidationPreconditionFoward?itemId=" + id
    );

    return request.data;
  }

  export async function ValidationPreconditionBackItem(id: string) {
    const request = await Api.get<PreconditionActivity>(
        "ValidationRule/ValidationPreconditionBackItem?itemId=" + id
    );
    return request.data;
  }


  export async function ValidationPrecondition(itemToValidationRuleVO: ItemToValidationRuleVO) {
    const request = await Api.get<PreconditionActivity>(
        "ValidationRule/ValidationPrecondition?itemId=" + itemToValidationRuleVO.itemId +  "&validationRuleId=" + itemToValidationRuleVO.validationRuleId
    );

    return request.data;
  }

  export async function CreateUserPendencie(itemToValidationRuleVO: ItemToValidationRuleVO) {
    const request = await Api.post<ValidationInformation>(
        "ValidationRule/SendToValidation",itemToValidationRuleVO
    );

    return request.data;
  }

  export async function BackItem(backItemVO:BackItemVO) {
    const request = await Api.post<ValidationInformation>(
        "ValidationRule/BackItem",backItemVO
    );

    return request.data;
  }


  export async function ForwardItem(forwardItemVO:ForwardItemVO) {
    const request = await Api.post<ValidationInformation>(
        "ValidationRule/ForwardItem", forwardItemVO
    );

    return request.data;
  }

  export async function GetValidationTypes(organizationId:string) {
    const request = await Api.get<ValidationTypes[]>(
        "ValidationRule/validationTypes/" + organizationId
    );

    return request.data;
  }

  export async function GetUserAlowed(itemId:string) {
    const request = await Api.get<User[]>(
        "ValidationRule/UserValidation/" + itemId
    );

    return request.data;
  }

  export async function GetUserAlowedByName(itemId:string, name:string) {
    const request = await Api.get<User[]>(
        "ValidationRule/UserValidation/" + itemId + "?name=" + name
    );

    return request.data;
  }

  export async function DisableValidationRule(idVr: Diseble) {
    const request = await Api.put<any>("validationRule/Diseble",idVr)
  
    return request.data;
  }

  export async function GetEnumTerm() {
    const request = await Api.get<ValidationTypes[]>("validationRule/validationEnumTerm/")
  
    return request.data;
  }