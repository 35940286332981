export interface DwgConfig { }

export const Dwg: React.FC<DwgConfig> = ({ }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="icon-dwg" transform="translate(0 0.108)">
                <g id="Grupo_602" data-name="Grupo 602" transform="translate(0 -0.108)">
                    <rect id="Retângulo_242" data-name="Retângulo 242" width="24" height="24" rx="2" fill="#1896d0" />
                    <path id="Caminho_199" data-name="Caminho 199" d="M23.618,7.125H9.277V4.553H6.692V0H6.036V4.553H3.464V7.125H0v.656H3.464v2.572H6.036v6.7h.656v-6.7H9.277V7.781H23.618Zm-15-1.916V7.125H6.692V5.209Zm-4.5,0H6.036V7.125H4.12V5.209Zm0,4.5V7.794H6.036V9.71H4.12Zm4.5,0H6.692V7.794H8.621Z" transform="translate(0 0.128)" fill="#fff" />
                    <path id="Retângulo_243" data-name="Retângulo 243" d="M0,0H24a0,0,0,0,1,0,0V6a2,2,0,0,1-2,2H2A2,2,0,0,1,0,6V0A0,0,0,0,1,0,0Z" transform="translate(0 16)" fill="#ffc700" />
                </g>
            </g>
        </svg>
    )
}
