import { formatDate } from "./dateFormater"

export const formatedDates = (dateOne: Date, dateTwo: Date): { termdate: string, currentDate: string } => {
  return {
    termdate: formatDate(dateOne),
    currentDate: formatDate(dateTwo)
  }
}

export const formatedDateTemporality = (dateOne: Date, dateTwo: Date): { temporalitydate: string, currentDateTemporality: string } => {
  return {
    temporalitydate: formatDate(dateOne),
    currentDateTemporality: formatDate(dateTwo)
  }
}