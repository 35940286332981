import {ReactComponent as ExpandIcon} from '../SVG/expandir.svg';
import {ReactComponent as CloseIcon} from '../SVG/fechar-x.svg';
import {ReactComponent as DiviserIcon} from '../SVG/diviser.svg';
import styles from "../Viewer.module.css"
import { TooltipHost } from '@fluentui/react';
import React, {useState} from 'react';
import { MenuConfig } from '../../Menu/MenuConfig';
import MenuCont from '../../Menu/Menu';
import { Settings } from '../../../assets/icons/settings';
import { PanZoom } from '../../../assets/icons/panZoom';
import { ZoomPng } from '../../../assets/icons/zoomPng';

interface HeaderViewerCompProps {
    title?: string
    showList?:boolean;
    pageControls?: React.ReactNode;
    scaleControls?: React.ReactNode;
    highlightControls?: React.ReactNode;
    printControls?: React.ReactNode;
    isAcept?: boolean;
    haveChoicerViwerChangeList: boolean;
    hideSidebar?: boolean
    menuConfig?: MenuConfig;
    isPdfViewer?: boolean;
    toggleHideSidebar?: () => void;
    cancel?: () => void
    toggleHideDialog?: () => void;
    openFileSetting?: () => void;
    panZoomProps?:PanZoomProps;
    isSidebarCollapsed?: boolean;
    fullScreenSetted?: boolean;
}

interface PanZoomProps {
    panZoom:boolean,
    onclickDisablePanZoom:(disable:boolean) => void;
}

const HeaderViewerComp: React.FC<HeaderViewerCompProps> = (props: HeaderViewerCompProps) => {

    function headerStylesHandle()
    {
        if (props.fullScreenSetted)
            return styles.headerViwerFullScreenSetted

        if (props.isSidebarCollapsed)
            return styles.headerViwerCollapsed

        return styles.headerViewer

    }
    
    function disablePanZoom(){
        if(props.panZoomProps)
        {
            props.panZoomProps.onclickDisablePanZoom(true);
        }
    }

    function panZoomVisible(){
        if(props.panZoomProps)
        {
            props.panZoomProps.onclickDisablePanZoom(false);
        }

    }

    return (
        <div className={headerStylesHandle()}>
            <div className={styles.titleDivViewer}>
                {props.isPdfViewer === true && (
                    <div className={styles.displayFlexViewer}>
                        <button className={styles.buttonIconHeaderViewer} 
                            style={{transform: props.hideSidebar === true ? "rotate(360deg)" : "rotate(180deg)"}}
                            onClick={props.toggleHideSidebar}
                        >
                            <ExpandIcon className={styles.iconSizeViewer}/>
                        </button>
                    </div>        
                )}
                <h2 className={styles.titleViewer}>
                    <TooltipHost
                        content={props.title}
                        closeDelay={500}
                        id={props.title}
                    >
                            {props.title}
                    </TooltipHost>
                </h2>
                {props.menuConfig != undefined &&(
                    <div className={styles.buttonMenuIconHeader} style={{position: "relative", paddingTop: 0}}>
                        <MenuCont
                            menuConfig={props.menuConfig}
                        />
                    </div>
                )}

                {(props.isAcept != true && props.haveChoicerViwerChangeList == true) && (    
                    <div className={styles.displayFlexViewer}>
                        <button className={styles.buttonIconHeaderViewer} 
                            style={{transform: props.showList === true ? "rotate(270deg)" : "rotate(90deg)"}}
                            onClick={props.toggleHideDialog}
                        >
                            <ExpandIcon className={styles.iconSizeViewer}/>
                        </button>
                    </div>        
                )}

                {props.openFileSetting != undefined && (
                    <div className={styles.displayFlexViewer}>
                        <button className={styles.buttonIconHeaderViewer} 
                            style={{padding: "6px"}}
                            onClick={props.openFileSetting}
                        >
                            <Settings color="#000000"/>
                        </button>
                    </div>      
                )}

                {props.panZoomProps != undefined &&(
                    <>
                    {props.panZoomProps.panZoom == false ? (
                        <div className={styles.displayFlexViewer}>
                            <button className={styles.buttonIconHeaderViewer} 
                                style={{padding: "6px"}}
                                onClick={disablePanZoom}
                            >
                                <ZoomPng color="#000000"/>
                            </button>
                        </div>  
                    ):(
                        <div className={styles.displayFlexViewer}>
                            <button className={styles.buttonIconHeaderViewer} 
                                style={{padding: "6px"}}
                                onClick={panZoomVisible}
                            >
                                <PanZoom color="#000000"/>
                            </button>
                        </div>  
                    )}  
                    </>                
                 )}
            </div>
            <div className={styles.mainHeader}>
                {props.printControls && (
                    <div className={styles.mainContent}>
                       {props.printControls} 
                       <DiviserIcon style={{transform: "rotate(90deg)"}}/>
                    </div>
                )}
                {props.pageControls && (
                    <div className={styles.mainContent}>
                       {props.pageControls} 
                       <DiviserIcon style={{transform: "rotate(90deg)"}}/>
                    </div>
                )}
                {props.scaleControls && (
                    <div className={styles.mainContent}>
                       {props.scaleControls} 
                    </div>
                )}
                {props.highlightControls && (
                    <div className={styles.mainContent}>
                       <DiviserIcon style={{transform: "rotate(90deg)"}}/>
                       {props.highlightControls} 
                    </div>
                )}          
            </div>
            <div className={styles.closeDivViewer}>
                {props.isAcept != true  && props.cancel != undefined && (
                    <button className={`${styles.closeButtonViewer} ${styles.buttonIconHeaderViewer}`} onClick={props.cancel}>
                        <CloseIcon className={styles.iconSizeViewer} />
                    </button>
                )}
            </div>
        </div>
    )
}

export default HeaderViewerComp;