import { Api } from "./api";
import { WorkSpace, WorkSpaceCreate, WorkSpaceUser, WorkSpacePermission, WorkSpaceUpdate } from "../model/WorkSpaceModel";
import {FolderInformation} from "../model/ItemModel"
import { GroupPermission } from "../model/GroupModel";
import { ObjectPermission, ObjectPermissionUser } from "../model/PermissionModel";
import { UserPermission } from "../model/UserModel";
import {DocumentTypeCreate} from "../model/DocumentType";

export async function CreateWorkSpace(WorkSpace: WorkSpaceCreate) {
    const request = await Api.post<any>("workSpace/",WorkSpace);

    return request.data;
}

export async function Edit(WorkSpace: WorkSpace) {
    const request = await Api.put<any>("workSpace/",WorkSpace);

    return request.data;
}
  
export async function Delete(id: string) {
    const request = await Api.delete(
        "workSpace/",    
        { data: { id:  id} }    
    );
}

export async function GetOneWorkSpace(id: string | null) {
    if (id === null) return null;
    const request = await Api.get<WorkSpaceUpdate>(
      "workspace/" + id
    );
  
  return request.data;
}

export async function GetOneWorkSpaceFolderInformation(id: string) {  
  const request = await Api.get<FolderInformation>(
    "workspace/folderInformation/" + id
  );

return request.data;
}

export async function GetOneWorkSpacePermission(id: string | null) {
  if (id === null) return null;
  const request = await Api.get<WorkSpacePermission>(
    "workspace/" + id + "/permission/"
  );

return request.data;
}

export async function GetWorkSpace() {
    const request = await Api.get<WorkSpace[]>("WorkSpace");
  
    return request.data;
}

export async function GetWorkSpaceTrim(skip:number, limit:number) {
    const request = await Api.get<WorkSpace[]>("workspaces?skip=" + skip + "&limit=" + limit);
  
     return request.data;
}

export async function GetOrgWorkSpaceTrim(internalName: string, skip:number, limit:number, desc:boolean) {
    const request = await Api.get<WorkSpace[]>(
      "organization/" + internalName + "/workspaces?skip=" + skip + "&limit=" + limit + "&desc=" + desc 
    );
  
    return request.data; 
}

export async function GetOrgWorkSpaceAdmTrim(internalName: string, skip:number, limit:number, desc:boolean) {
  const request = await Api.get<WorkSpace[]>(
    "organization/" + internalName + "/wsallowed?skip=" + skip + "&limit=" + limit + "&desc=" + desc 
  );

  return request.data; 
}

export async function GetWorkSpaceUser(id: string) {
    const request = await Api.get<WorkSpaceUser>(
      "WorkSpace/" + id + "/user"
    );
    return request.data; 
}

export async function GetWorkGroup(idWorkspace: string, skip:number, limit:number) {
  const request = await Api.get<GroupPermission[]>(
    "WorkSpace/permission/" + idWorkspace + "?skip=" + skip + "&limit=" + limit
  );
  return request.data; 
}

export async function GetWorkGroupName(idWorkspace: string, skip:number, limit:number, name:string) {
  const request = await Api.get<GroupPermission[]>(
    "WorkSpace/permission/" + idWorkspace + "?skip=" + skip + "&limit=" + limit + "&name=" + name
  );
  return request.data; 
}

export async function GetWorkGroupAll(idWorkspace: string, skip:number, limit:number) {
  const request = await Api.get<GroupPermission[]>(
    "WorkSpace/permission/" + idWorkspace + "/all?skip=" + skip + "&limit=" + limit
  );
  return request.data; 
}

export async function GetWorkGroupAllName(idWorkspace: string, skip:number, limit:number, name:string) {
  const request = await Api.get<GroupPermission[]>(
    "WorkSpace/permission/" + idWorkspace + "/all?skip=" + skip + "&limit=" + limit + "&name=" + name
  );
  return request.data;
}

export async function AddPermission(objectPermission: ObjectPermission) {
  const request = await Api.put<any>("workSpace/permission/add",objectPermission);

  return request.data;
}

export async function RemovePermission(objectPermission: ObjectPermission) {
  const request = await Api.put<any>("workSpace/permission/remove",objectPermission);

  return request.data;
}

export async function AddPermissionUser(objectPermission: ObjectPermissionUser) {
  const request = await Api.put<any>("workSpace/permissionUser/add",objectPermission);

  return request.data;
}


export async function RemovePermissionUser(objectPermission: ObjectPermissionUser) {
  const request = await Api.put<any>("workSpace/permissionUser/remove",objectPermission);

  return request.data;
}

export async function IsWoskpaceAdmin(idWorkspace: string) {
  const request = await Api.get(
    "workSpace/admin/" + idWorkspace
  );
}

export async function GetWorkUser(idWorkspace: string, skip:number, limit:number) {
  const request = await Api.get<UserPermission[]>(
    "WorkSpace/permissionUser/" + idWorkspace + "?skip=" + skip + "&limit=" + limit
  );
  return request.data; 
}

export async function GetWorkUserName(idWorkspace: string, skip:number, limit:number, name:string) {
  const request = await Api.get<UserPermission[]>(
    "WorkSpace/permissionUser/" + idWorkspace + "?skip=" + skip + "&limit=" + limit + "&name=" + name
  );
  return request.data; 
}

export async function GetWorkUserAll(idWorkspace: string, skip:number, limit:number) {
  const request = await Api.get<UserPermission[]>(
    "WorkSpace/permissionUser/" + idWorkspace + "/all?skip=" + skip + "&limit=" + limit
  );
  return request.data; 
}

export async function GetWorkUserAllName(idWorkspace: string, skip:number, limit:number, name:string) {
  const request = await Api.get<UserPermission[]>(
    "WorkSpace/permissionUser/" + idWorkspace + "/all?skip=" + skip + "&limit=" + limit + "&name=" + name
  );
  return request.data;
}

export async function GetDocumentTypes(idWorkspace: string, skip:number, limit:number, name:string) {
  const request = await Api.get<DocumentTypeCreate[]>(
    "WorkSpace/" + idWorkspace + "/documentTypes?skip=" + skip + "&limit=" + limit + "&name=" + name
  );
  return request.data;
}

export async function CloneWorkspace(WorkSpace: WorkSpace) {
  const request = await Api.post<any>("workSpace/clone", WorkSpace);

  return request.data;
}