import {Color} from "./interface/Color"

export const Settings: React.FC<Color> = ({
    color
  }) => {
    return(
        <svg width="19.454" height="20" viewBox="0 0 19.454 20">
            <path 
                id="Caminho_27" 
                data-name="Caminho 27" 
                d="M19.827,13.379a7.369,7.369,0,0,0,.063-.979,6.017,6.017,0,0,0-.073-.979l2.115-1.646a.511.511,0,0,0,.125-.635l-2-3.458a.509.509,0,0,0-.615-.229l-2.49,1a7.359,7.359,0,0,0-1.687-.979l-.375-2.646a.5.5,0,0,0-.5-.427h-4a.493.493,0,0,0-.49.427L9.525,5.473a7.521,7.521,0,0,0-1.687.979l-2.49-1a.5.5,0,0,0-.615.229L2.743,9.14a.474.474,0,0,0,.125.635l2.115,1.646a5.8,5.8,0,0,0-.021,1.958L2.847,15.025a.511.511,0,0,0-.125.635l2,3.458a.509.509,0,0,0,.615.229l2.49-1a7.359,7.359,0,0,0,1.688.979l.375,2.646a.512.512,0,0,0,.5.427h4a.486.486,0,0,0,.49-.427l.375-2.646a7.145,7.145,0,0,0,1.687-.979l2.49,1a.5.5,0,0,0,.615-.229l2-3.458a.482.482,0,0,0-.125-.635ZM12.389,16.15a3.75,3.75,0,1,1,3.75-3.75A3.761,3.761,0,0,1,12.389,16.15Z" 
                transform="translate(-2.662 -2.4)" 
                fill={color}/>
        </svg>
    )
  }