import React, { useState, useEffect, useCallback, useRef } from "react";
import { ITextFieldStyles } from "@fluentui/react/lib/TextField";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import {
  Delete
} from "../../services/WorkSpaceServices";
import { useNavigate, useParams } from "react-router-dom";
import { useProfile } from '../../context/ProfileProvider/useProfile';
import Btn from "../../components/Button/Btn";
import TextFieldComp from "../../components/TextField/TextFieldComp";
import { Stack } from "@fluentui/react/lib/Stack";
import { Panel } from "@fluentui/react/lib/Panel";
import { useBoolean } from "@fluentui/react-hooks";
import WorkSpaceListComp from "./WorkSpaceListComp";
import styles from "../Style/List.module.css";
import WorkSpaceConfig from "./WorkSpaceConfig";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogType,
  DialogFooter,
  DialogContent,
} from "@fluentui/react/lib/Dialog";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useUpload } from "../../context/UploadProvider/useUpload";
import { WorkspaceClone } from "./WorkspaceClone/WorkspaceClone";

const exampleChildClass = mergeStyles({
  display: "block",
  marginBottom: "10px",
});

const textFieldStyles: Partial<ITextFieldStyles> = {
  root: { maxWidth: "300px" },
};


const WorkSpaceList  = (props: any) => {
  const navegate = useNavigate();
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);
  const [isOpenClone, { setTrue: openPanelClone, setFalse: dismissPanelClone }] =
    useBoolean(false);
  const [newOrgName, setNewOrgName] = useState<string>();
  const forceUpdate = useCallback((teste: string) => setNewOrgName(teste), []);
  const { t, i18n } = useTranslation();

  const [userEdit, setUserEdit] = useState<string|undefined|number>(0)
  const [userDeleted, setUserDeleted] = useState<string|undefined>(undefined);
  const [userClone, setUserClone] = useState<string|undefined>(undefined)

  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [userDeletedName, setUserDeletedName] = useState<string>("");

  const [isChecked, setChecked] = useState<boolean>(false);
  const [popupOk, setPopupOk] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  
  const [reload, setReload] = useState<boolean>(false);
  const [searchName, setSearchName] = useState<string>("");

  const profile = useProfile();
  const [isAdminOrganization, setisAdminOrganization] = useState<boolean>(false);

  const { nameOrg } = useParams();
  const upload = useUpload();

  const updatePanel = () => {
    dismissPanel();
    dismissPanelClone();
    setReload(true);
  };


  useEffect(() => {
    if(typeof userEdit != "number")
    {
      openPanel();
    }
  }, [userEdit]);

  useEffect(() => {
    if(userDeleted != undefined)
    {
      toggleHideDialog();
    }
  }, [userDeleted]);

  useEffect(() => {
    if(userClone != undefined)
    {
      openPanelClone()
    }
  }, [userClone]);

  const toggleEdit = (
    value:string|undefined
  ) => {
    if(value ==  userEdit)
    {
      openPanel();
    }
    else
    {
      setUserEdit(value);
    }
  };

  const toggleDelete = (
    value:string|undefined
  ) => {
    if(value ==  userDeleted)
    {
      if(userDeleted != undefined)
      {
        toggleHideDialog();
      }
    }
    else
    {
      setUserDeleted(value);
    }
  };

  const toggleClone = (
    value:string|undefined
  ) => {
    if(value == userClone)
    {
      if(userClone != undefined)
      {
        openPanelClone();
      }
    }
    else
    {
      setUserClone(value);
    }
  };

  const iconProps = { iconName: "Filter" };

 
  const onChangeSearchName =
  (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    if (newValue != undefined ) {
      setSearchName(newValue);
    }
  };


  const onClickNew = () => {
    toggleEdit(undefined);
    
  };

  const onClickEdit = (
    event: React.FormEvent<HTMLElement>
  ) => {
    const { myValue } = event.currentTarget.dataset;
    toggleEdit(myValue);
  };

  const onClickDelete = (
    event: React.FormEvent<HTMLElement>
  ) => {
    const { myValue } = event.currentTarget.dataset;
    toggleDelete(myValue);
  };
  
  const onClickClone = (
    event: React.FormEvent<HTMLElement>
  ) => {
    const { myValue } = event.currentTarget.dataset;
    toggleClone(myValue);
  };

  function deleteWorkSpacePopup() {
    console.log(userDeleted);
    if(userDeleted != undefined)
    {
      setChecked(false);
      Delete(userDeleted)
      .then(() => {
        setMessage(t("Workspace.deleteSucess"));
        setPopupOk(true);
        setReload(true);
      });
    }
    toggleHideDialog();
  }

  useEffect(() => {
    if(reload == true ){
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    if(profile.organizations != undefined)
    {
      for(let organization of profile.organizations)
      {
        if(nameOrg == organization.internalName)
        {
          console.log(organization.isAdmin);
          console.log(organization.isAdmin == true);
          if(organization.isAdmin == true)
          {
            setisAdminOrganization(true);
          }
          else{
            setisAdminOrganization(false);
          }
        }
      }
    }
  }, [profile]);

  function closePopUpOk() {
    setPopupOk(false);
  }

  const handleClose = (event:any, reason:any) => {
    closePopUpOk();
  }

  const handleClosePO = (event:any) => {
    closePopUpOk();
  }

  const updatePanelSave = () => {
    updatePanel();
    setPopupOk(true);
    setMessage(t("Workspace.createSucess"));
    upload.setShowEx(false);
  };

  const updatePanelCloned = () => {
    updatePanel();
    setPopupOk(true);
    setMessage(t("Workspace.cloneSucess"));
    upload.setShowEx(false);
  };

  return (
    <div>
      <Stack horizontal className={styles.hPage}>   
        <Stack.Item grow>
          <h3 className={styles.hList}>Workspaces</h3>
        </Stack.Item>     
        <Stack className={styles.stackSide}>
          <Stack.Item className={styles.BtnNew}>
            <Btn text={t("Novo.message")} onClick={onClickNew} hidden={isAdminOrganization == false} />
          </Stack.Item>
          <Stack.Item className={styles.InputSearch}>
            <TextFieldComp iconProps={iconProps}  value={searchName} onChange={onChangeSearchName} />
          </Stack.Item>
        </Stack>
      </Stack>
      
      {reload?(<div></div>):(
          nameOrg != undefined?(
            <WorkSpaceListComp
              nameOrg={nameOrg}
              name={searchName}
              onClickEditar={onClickEdit}
              onClickExcluir={onClickDelete}
              onClickClone={onClickClone}
              hiddenContexMenu={isAdminOrganization == false}
            />
          ):(<li className={styles.itemContent}></li>))}
      <Panel
        isOpen={isOpen}
        closeButtonAriaLabel={t("Fechar.message")}
        onDismiss={updatePanel}
        headerText={t("SalvarWork.message")}
        isFooterAtBottom={true}
      >              
        {(nameOrg != undefined && typeof userEdit != "number")&&
          (
            <WorkSpaceConfig
              nameOrg={nameOrg}
              idWork={userEdit}
              saveOkMetod={updatePanelSave}
            />
          )}      
      </Panel>

      <Panel
        isOpen={isOpenClone}
        closeButtonAriaLabel={t("Fechar.message")}
        onDismiss={updatePanel}
        headerText={t("CloneWork.message")}
        isFooterAtBottom={true}
      >              
        {(nameOrg != undefined)&&
          (
            <WorkspaceClone 
              nameOrg={nameOrg}
              saveOkMetod={updatePanelCloned}
              idWork={userClone}
            />
          )}      
      </Panel>

      <Dialog hidden={hideDialog} onDismiss={toggleHideDialog}>
          <DialogContent title={t("Excluir.message")}>
            <h3>{t("CertezaExcluirWorkspace.message")}{userDeletedName}</h3>
          </DialogContent>
          <DialogFooter>
            <Btn onClick={toggleHideDialog} text={t("Cancelar.message")} />
            <Btn onClick={deleteWorkSpacePopup} text={t("Excluir.message")}/>
          </DialogFooter>
        </Dialog>

        <Snackbar
          open={popupOk}
          autoHideDuration={6000}
          message="Archived"
          onClose={handleClose}
          anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
          sx={{ bottom: { xs: 50, sm: 20 } }}
        >
          <MuiAlert elevation={6} variant="filled" sx={{ width: '100%' }} severity="success" onClose={handleClosePO}>
            {message}
          </MuiAlert>
        </Snackbar>
    </div>
  );
};


export default WorkSpaceList;
