import { ApiWithoutToken } from "../services/api";
import { WorkSpaceUserName } from "../model/ItemModel";
import { BackItemVO, ForwardItemVO, PreconditionActivity,ValidationInformation } from "../model/ValidationRuleModel";
import { User } from "../model/OrganizationModel";
import { WorkSpaceUserNameTokenInformation} from "../model/UserPendenciesModel";
import { ParameterViwer } from "../pages/WorkSpaceItem/Viwer/ParameterViwer";
import { ReturnParameterViwer } from "./ReturnParameterViwer";

export async function GetByToken(token:string) {

    const request = await ApiWithoutToken.get<WorkSpaceUserNameTokenInformation>(
        "UserPendencie?token=" + token
    );
    return request.data; 
}

export async function DownloadByToken(token: string, parameter?:ParameterViwer, onDownloadProgress?: (progressEvent: any) => void){
    let returnParameter = ReturnParameterViwer(parameter, false);
    const request = await  ApiWithoutToken.get<any>(
        "/UserPendencie/download?token=" + token + returnParameter,
        {
            responseType: 'blob',
            onDownloadProgress:onDownloadProgress
        }
    );

    return request.data;
}

export async function GetPreconditionActivityFowardByToken(token:string) {

    const request = await ApiWithoutToken.get<PreconditionActivity>(
        "UserPendencie/Precondition?token=" + token
    );
    return request.data;
}

export async function GetPreconditionActivityBackByToken(token:string) {

    const request = await ApiWithoutToken.get<PreconditionActivity>(
        "UserPendencie/preconditionBack?token=" + token
    );
    return request.data;
}

export async function BackItem(backTokenVO:BackItemVO) {
    const request = await ApiWithoutToken.post<ValidationInformation>(
        "UserPendencie/BackItem",backTokenVO
    );

    return request.data;
}


export async function ForwardItem(forwardTokenVO:ForwardItemVO) {
    const request = await ApiWithoutToken.post<ValidationInformation>(
        "UserPendencie/ForwardItem",
        forwardTokenVO
    );

    return request.data;
}

export async function GetOrganizationUserName(token: string, name: string) {
    const request = await ApiWithoutToken.get<User[]>(
      "UserPendencie/UserPendenciePaged?token=" + token + "&name=" + name,
    );
  
    return request.data;
  }
  
  export async function GetOrganizationUser(token: string) {
    const request = await ApiWithoutToken.get<User[]>(
      "UserPendencie/UserPendenciePaged?token=" + token,
    );
  
    return request.data;
  }