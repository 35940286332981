import { TableHead, TableRow } from "@mui/material";

interface IDocumentDetailTableHeader<T> {
    data: T[];
    renderCells: (item: T, index: number) => React.ReactNode;
}

export default function DocumentDetailTableHeader<T>({
    data,
    renderCells
}: IDocumentDetailTableHeader<T>)
{
    return (
        <TableHead>
          <TableRow>
            { data.map((element, index) => (
                <>
                    { renderCells(element, index) }
                </>
            )) }
          </TableRow>
        </TableHead>
    )
}