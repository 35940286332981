import React, {useState} from 'react'; 
import { AuthProvider } from "../../context/AuthProvider";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useProfile } from '../../context/ProfileProvider/useProfile';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import './CommandBar.css';
import Btn from "../../components/Button/Btn";
import { AiOutlineLink } from "react-icons/ai";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import I18n, { TranslatorP, Translator, TranslatorS } from '../../components/I18n';
import { useBoolean } from "@fluentui/react-hooks"
import {
  mergeStyleSets,
  Modal,
  IIconProps,
  IContextualMenuProps
} from '@fluentui/react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {Api,ApiAuth} from '../../services/api'
import { BiCopy } from "react-icons/bi";
import Tooltip from '@mui/material/Tooltip';
import copy from "copy-to-clipboard";
import { ApiOutlined } from '@mui/icons-material';
import { AiOutlineClose } from "react-icons/ai";

const CommandBar = () => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const ITEM_HEIGHT = 48;
  const [copyTextAuth, setCopyTextAuth] = useState(ApiAuth.defaults.baseURL!);
  const [copyTextGRC, setCopyTextGRC] = useState(Api.defaults.baseURL!);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const [popupSucessOk, setPopupSucessOk] = useState<boolean>(false);
  const [popupErrorOk, setPopupErrorOk] = useState<boolean>(false);
  const profile = useProfile();
  const auth = useAuth();

  const logout = (event: any) => {
    event.preventDefault();

    auth.logout();
    window.history.replaceState(null, '', '/login');
    if (!auth.token) {
      return <Navigate to="/login" replace />;
    }
    return true;
  };

  const copyToClipboardAuth = () => {
    if(copyTextAuth != null || copyTextAuth != ''){
      copy(copyTextAuth);
      setPopupSucessOk(true)
    }
    else{
      setPopupErrorOk(true)
    }

  }

  const copyToClipboardGRC = () => {
    if(copyTextGRC != null  || copyTextAuth != ''){
      copy(copyTextGRC);
      setPopupSucessOk(true)
    }
    else{
      setPopupErrorOk(true)
    }
  }

  const handleCloseSnack = (event:any, reason:any) => {
    closePopUpOk();
  }

  const handleClosePO = (event:any) => {
    closePopUpOk();
  }

  function closePopUpOk() {
    setPopupSucessOk(false);
    setPopupErrorOk(false)
  }

  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      minWidth: '400px',
    },
    header: [
      {
        flex: '1 1 auto',
        borderTop: `4px solid`,
        color: "#114D88",
        display: 'flex',
        alignItems: 'center',
        padding: '12px 12px 14px 24px',
      },
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
  });

  const closeModal = () => {
    hideModal();
  };

  function onclickDrivreLinks(){
    showModal();
  }

  return (
      <div className="iconMenu">
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <div className='nameUser'>
            {profile.name}
          </div>     
          <AccountCircleIcon className='circleIcon'/>
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',                    
            },
          }}
          onClose={handleClose}
        >
          <MenuItem>
            <div className='icons'>
              <I18n/>
            </div>         
          </MenuItem>
          <MenuItem onClick={onclickDrivreLinks}>
            Drive
            <AiOutlineLink size={30} className='iconRight'/>    
          </MenuItem>
          <MenuItem onClick={logout}>
            {TranslatorS({path:"Sair.message"})}
            <LogoutIcon className='iconRight'/>
          </MenuItem>
        </Menu>

        <Modal
          titleAriaId="Links Login Drive"
          isOpen={isModalOpen}
          onDismiss={closeModal}
          isBlocking={false}
          containerClassName={contentStyles.container}
        >
          <div className={contentStyles.header}>
            <h3 className='hList'>Links Login Drive</h3>
            <AiOutlineClose className='close' size={20} onClick={closeModal}/>
          </div>
          
          <ul>
            <li>
              <h4 className='tituloLinks'>Servidor Auth</h4>
              <p>
                <input value={copyTextAuth} className='inputCopy' disabled/>
                <BiCopy size={20} className='copy' onClick={copyToClipboardAuth}/>
              </p>
            
              <hr className='linhaHr'/>
            </li>
            <li>
              <h4 className='tituloLinks'>Servidor GRC</h4>
              <p>
                <input value={copyTextGRC} className='inputCopy' disabled/> 
                <BiCopy size={20} className='copy' onClick={copyToClipboardGRC}/>
              </p>
            </li>
          </ul>
          <Snackbar
                  open={popupSucessOk}
                  autoHideDuration={6000}
                  message="Archived"
                  onClose={handleCloseSnack}
                  anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
                  sx={{ bottom: { xs: 50, sm: 20 } }}
                >
                  <MuiAlert elevation={6} variant="filled" sx={{ width: '100%' }} severity="success" onClose={handleClosePO}>
                  {TranslatorS({path:"Copy.message"})} 
                  </MuiAlert>
          </Snackbar>
          <Snackbar
                open={popupErrorOk}
                autoHideDuration={6000}
                message="Archived"
                onClose={handleCloseSnack}
                anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
                sx={{ bottom: { xs: 50, sm: 20 } }}
              >
                <MuiAlert elevation={6} variant="filled" sx={{ width: '100%' }} severity="error" onClose={handleClosePO}>
                {TranslatorS({path:"error.message"})} 
                </MuiAlert>
            </Snackbar>
        </Modal>

      </div>
  );
}

export default CommandBar;
