import { CheckIcon } from '../../../../assets/svg/checkIcon';
import { useEffect, useState } from 'react';

export interface SvgFlowCircleType {
    CircleType?: "CURRENT" | "FUTURE" | "OVERCOME"
    className?: any; 
    delayAnimationTime: number,
    timeAnimationTime: number,
    activityNumber?: number
}

export const SvgFlowCircle: React.FC<SvgFlowCircleType> = ({ CircleType, className, delayAnimationTime,timeAnimationTime, activityNumber }) => {
    
    const [escala, setEscala] = useState(0);
    
    useEffect(() => {
        const timeout = setTimeout(() => {
            setEscala(1);
        }, delayAnimationTime*1000);
    
        return () => clearTimeout(timeout);
      }, []);

    return (
        <svg height="60" width="60" style={{zIndex: 999, position: "relative"}} className={`${className}`}>
            {(CircleType === "FUTURE") && 
               <foreignObject x={-2} y={10} width="60" height="60" style={{ transform: `scale(${escala})`, transition: `all ${timeAnimationTime}s ease-in-out` }}>
                    <div style={{
                        width:38,
                        height:38, 
                        border:"solid 3px rgb(0, 90, 158, 0.5)", 
                        borderRadius:"50%", 
                        marginLeft:"5px",
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center",
                        color:"rgb(0, 90, 158, 0.5)",
                        fontSize:"18px",
                        fontWeight:"600",
                    }}>
                        {activityNumber}
                    </div>
           </foreignObject>
            }

            {(CircleType === "CURRENT") && 
               <foreignObject x={-2} y={10} width="60" height="60" style={{ transform: `scale(${escala})`, transition: `all ${timeAnimationTime}s ease-in-out` }}>
                    <div style={{
                        width:38,
                        height:38, 
                        border:"solid 3px #005A9E", 
                        borderRadius:"50%", 
                        marginLeft:"5px",
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center",
                        color:"#005A9E",
                        fontSize:"18px",
                        fontWeight:"600",
                    }}>
                        {activityNumber}
                    </div>
           </foreignObject>
            }
      
            {(CircleType === "OVERCOME") &&(
                <foreignObject y={5} width="60" height="60" style={{ transform: `scale(${escala})`, transition: `all ${timeAnimationTime}s ease-in-out` }}>
                    <CheckIcon />
                </foreignObject>
            )}
            
        </svg>
    )
}