import { GridColumnDeleted } from "./ColumnStandard";

export function CanShow(gridColumn: GridColumnDeleted[], columnArea: string)
{
    for (const column of gridColumn) {
        if (column.name === columnArea && column.deleted === false) {
            return true;
        }
    }

    return false;
}