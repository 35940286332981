import React, {
	ChangeEvent,
	useEffect,
	useState,
	useRef,
	useCallback,
	KeyboardEventHandler,
	MouseEventHandler,
} from "react";

import { DownloadRevision, GetRevision } from "../../services/Item/ItemServicesGet";
import { WorkSpaceItem, WorkSpaceItemRevision } from "../../model/ItemModel";
import styles from "./WorkSpaceItemList.module.css";
import stylesList from "../Style/List.module.css";
import I18n, { TranslatorS } from "../../components/I18n";
import { useTranslation } from "react-i18next";
import { Description, InterestsSharp } from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import moment from "moment";
import { IIconProps } from "@fluentui/react";
import WorkSpaceItemListFileComponentImage from "./WorkSpaceItemListFileComponentImage";
import { IconButton as IconButtonFluent } from "@fluentui/react/lib/Button";
import Btn from "../../components/Button/Btn";
import D4Sign from "./Viwer/Signed/D4SignFilewView";
import { GetD4SignStatusRevision } from "../../services/Item/ItemServicesGet";

interface WorkItemRevisionProps {
	revisions?: string[];
	id?: string;
	itemName?: string;
	itemExtension?: string;
	currentDraft?: boolean;
	currentRelease?: boolean;
	draftBy?: string;
	draftDate?: string;
	releasedBy?: string;
	releasedDate?: string;
	description?: string;
	isD4SignSigned?: boolean;
	enumerate?: number;
}
interface WorkItemRevisionConfig {
	idItem?: string;
}
const WorkSpaceItemListFileRevisionComponent: React.ForwardRefExoticComponent<WorkItemRevisionConfig> =
	React.forwardRef(({ idItem }, _ref: React.Ref<HTMLLIElement>) => {
		const { t } = useTranslation();
		const [WorkSpaceRevision, setWork] = useState<WorkSpaceItemRevision[]>([]);
		const [popupOk, setPopupOk] = useState<boolean>(false);
		const [errorMessage, setErrorMessage] = useState<string>("");
		const handleClose = (_event: any, _reason: any) => {
			closePopUpOk();
		};

		const handleClosePO = (_event: any) => {
			closePopUpOk();
		};

		function closePopUpOk() {
			setPopupOk(false);
		}

		useEffect(() => {
			GetRevision(idItem!).then((itens) => {
				setWork(itens);
			});
		}, [idItem]);

		const RevisionItem: React.ForwardRefExoticComponent<WorkItemRevisionProps> =
			// eslint-disable-next-line react/display-name
			React.forwardRef(
				(
					{ id, itemName, draftDate, itemExtension, description, isD4SignSigned, enumerate },
					ref: React.Ref<HTMLLIElement>,
				) => {
					const [showInfo, setShowInfo] = useState<boolean>(false);
					const [isOPenModalD4Sign, setIsOPenModalD4Sign] = useState<boolean>(false);

					const ExibirItemD4SignTrue = () => {
						setIsOPenModalD4Sign(true);
					};

					const ExibirItemD4Sign = (exibr: boolean) => {
						setIsOPenModalD4Sign(exibr);
					};

					function downloadFile() {
						if (itemName && idItem && id) {
							DownloadRevision(idItem, id)
								.then((blob) => {
									const url = window.URL.createObjectURL(new Blob([blob]));
									const link = document.createElement("a");
									link.href = url;
									link.setAttribute("download", itemName + "." + itemExtension);

									document.body.appendChild(link);

									link.click();
									link.parentNode?.removeChild(link);
								})
								.catch((error) => {
									if (
										error.response &&
										error.response.data &&
										typeof error.response.data === "string"
									) {
										const blob = new Blob([error.response.data]);
										blob
											.text()
											.then((message) => {
												setErrorMessage(message);
											})
											.catch(() => {
												setErrorMessage(t("DownloadFileError.message"));
											});
									} else {
										setErrorMessage(t("DownloadFileError.message"));
									}

									setPopupOk(true);
								});
						}
					}

					const asc: IIconProps = {
						iconName: "Down",
						styles: {
							root: {
								color: "black",
								fontWeight: "bold",
								marginLeft: "auto",
							},
						},
					};

					const desc: IIconProps = {
						iconName: "Up",
						styles: {
							root: {
								color: "black",
								fontWeight: "bold",
								marginLeft: "auto",
							},
						},
					};

					return (
						<li
							className={`${stylesList.itemContentAdm} ${stylesList.row} ${stylesList.listFiles} ${styles.listMideaRevision}`}
							key={id}
							ref={ref}
							style={{ display: "block" }}
						>
							<div style={{ width: "100%" }}>
								<div className={styles.fatherFile}>
									<div className={styles.Enumerate}>{enumerate}</div>
									<div className={styles.IconFilesRevisionList}>
										<WorkSpaceItemListFileComponentImage
											extension={itemExtension}
											isFolder={false}
										/>
									</div>
									<div onClick={downloadFile} className={styles.itemNameList}>
										{itemExtension != undefined && itemExtension != ""
											? itemName + "." + itemExtension
											: itemName}
									</div>
									<div className={styles.draftDateList}>
										{moment(draftDate).format("DD/MM/YYYY")}
									</div>
									{showInfo ? (
										<IconButtonFluent
											iconProps={desc}
											title="Vizualizar"
											ariaLabel="Vizualizar"
											onClick={() => setShowInfo(false)}
										/>
									) : (
										<IconButtonFluent
											iconProps={asc}
											title="Ocultar"
											ariaLabel="Ocultar"
											onClick={() => setShowInfo(true)}
										/>
									)}
								</div>
							</div>
							{showInfo && (
								<div style={{ width: "100%" }}>
									<div>
										{t("Description.message") + ": "}
										{description}
									</div>
									<div>
										{isD4SignSigned && (
											<Btn text={t("Item.d4SignVersion")} onClick={ExibirItemD4SignTrue} />
										)}
									</div>
								</div>
							)}
							<D4Sign
								idItem={id!}
								isOpen={isOPenModalD4Sign}
								setIsOpen={ExibirItemD4Sign}
								getLink={GetD4SignStatusRevision}
							/>
						</li>
					);
				},
			);

		return (
			<div>
				<ul className={styles.ulContentComp}>
					<li className={`${stylesList.itemContent} ${stylesList.title} ${styles.List}`}>
						<div className={styles.IconFileRevision}></div>
						<div className={styles.NameItemRevision}>{t("Nome.message")}</div>
						<div className={styles.DraftDate}>{t("DraftDate.message")}</div>
					</li>
					{WorkSpaceRevision.map((workSpaceC) => (
						<RevisionItem
							key={workSpaceC.id}
							id={workSpaceC.id}
							itemName={workSpaceC.itemName}
							currentDraft={workSpaceC.currentDraft}
							currentRelease={workSpaceC.currentRelease}
							draftBy={workSpaceC.draftBy}
							draftDate={workSpaceC.draftDate}
							releasedBy={workSpaceC.releasedBy}
							releasedDate={workSpaceC.releasedDate}
							itemExtension={workSpaceC.itemExtension}
							description={workSpaceC.description}
							isD4SignSigned={workSpaceC.isD4SignSigned}
							enumerate={workSpaceC.enumerate}
						/>
					))}
				</ul>
				<Snackbar
					open={popupOk}
					autoHideDuration={6000}
					message="Archived"
					onClose={handleClose}
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					sx={{ bottom: { xs: 50, sm: 20 } }}
				>
					<MuiAlert
						elevation={6}
						variant="filled"
						sx={{ width: "100%" }}
						severity="error"
						onClose={handleClosePO}
					>
						{errorMessage}
					</MuiAlert>
				</Snackbar>
			</div>
		);
	});

export default WorkSpaceItemListFileRevisionComponent;
