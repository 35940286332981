import { Box, Typography } from '@mui/material';

interface IDocumentDetailTabTitle 
{
    textToShowInButton: string;
}

export default function DocumentDetailTabTitle({
    textToShowInButton,
}: IDocumentDetailTabTitle)
{
    return (
        <Box sx={{
          width: '100px'
        }}>
          <Typography sx={{
            textAlign: "left",
            font: "normal normal 600 16px/24px Segoe UI",
            letterSpacing: "0px",
            color: "#2D2D2D",
            opacity: 0.6,
            display: 'flex',
            transition: 'all .3s ease-in',
            borderRadius: '6px',
            width: '100%',
          }}>
            { textToShowInButton }
          </Typography>
      </Box>
    )
}