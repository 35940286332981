import { ParameterViwer } from "../pages/WorkSpaceItem/Viwer/ParameterViwer";

export function ReturnParameterViwer (parameter:ParameterViwer | undefined, initial: boolean) {
    let prt: string = "";
    let firstCharactere:string = "&";

    if (initial == true) {
      firstCharactere = "?"
    }

    if(parameter == undefined || initial == undefined){
        return""
    }

    if (parameter.converterType != 1) {
      prt = `${firstCharactere}size=${parameter.size}&pallete=${parameter.palette}&orientation=${parameter.orientation}&converterType=${parameter.converterType}`;
      return prt;
    }

    if(parameter.positionXZoomPng == undefined && parameter.positionYZoomPng  == undefined) {
      prt = `${firstCharactere}converterType=${parameter.converterType}&backgroundColors=${parameter.backgroundColors}&zoom=${parameter.zoom}`;
      return prt;
    }
    
    prt = `${firstCharactere}converterType=${parameter.converterType}&backgroundColors=${parameter.backgroundColors}&zoom=${parameter.zoom}&positionXZoomPng=${parameter.positionXZoomPng}&positionYZoomPng=${parameter.positionYZoomPng}`;
    return prt;
}
