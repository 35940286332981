import React, {useState, useEffect, KeyboardEventHandler, ComponentType, FC} from "react";
import {
    mergeStyleSets,
} from '@fluentui/react';
import {Color} from "../../../assets/icons/interface/Color";

export const imageStyle = mergeStyleSets({
    img:
    {
        width:"18px",
        height:"18px",
        marginRight:"3px",
        marginLeft:"3px"
    }
})


export enum ButtonTyppe {
    primary,
    secondary
}

export interface BtnBigWithIconConfig{
    text?:string;
    textBeforeIcon?:string;
    icon?: ComponentType<Color>;
    src?:string;
    isPrimary?:ButtonTyppe;
    specificWidth?:string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    display?:boolean |undefined;
    disabled?:boolean |undefined;
    hasLoading?:boolean |undefined;
    isTextButton?:boolean |undefined;
    iconGap?: string |undefined;
}

function getLabelColor(disabled?:boolean |undefined,isPrimary?:ButtonTyppe )
{
    if(isPrimary == ButtonTyppe.secondary ) {
        if(disabled == true)
            return "#ccdeec";
        else
            return "#22638D";
    }
    else
    {
        return "white";
    }
}

function getBackGroundColor(disabled?:boolean |undefined,isPrimary?:ButtonTyppe )
{
    if(isPrimary == ButtonTyppe.secondary ) {
        return "white";
    }
    else
    {
        if(disabled == true)
            return "#ccdeec";
        else
            return "#22638D";
        
    }
}

const BtnBigWithIcon = (props: BtnBigWithIconConfig) => {

    const contentStyles = mergeStyleSets({
        btn:{
            width: "100%",
            height: "40px",
            marginTop:"5px",
            marginBottom:"5px",
            backgroundColor: getBackGroundColor(props.disabled,props.isPrimary),
            cursor: "pointer",
            border: props.isTextButton ? 'none' : `1px solid`,
            borderRadius: "8px",
            borderColor: props.isPrimary == ButtonTyppe.secondary ? "#22638D":"white",
            ':hover':{
                backgroundColor: getBackGroundColor(props.disabled,props.isPrimary),
                color: props.isPrimary == ButtonTyppe.secondary ? "#22638D" :"white",
                borderColor: props.isTextButton ? 'none' : "#005A9E",
                borderStyle: props.isTextButton ? 'none' : props.disabled != true ? "solid" : undefined,
                borderWidth: props.isTextButton ? 'none' : "2px"
            },
        },
        label:
        {
            color:getLabelColor(props.disabled,props.isPrimary),
            font:"normal normal normal 16px/21px Segoe UI",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: props.iconGap ? props.iconGap : 4,
        }
    })

    if(props.display != undefined && props.display == true)
    {
        return null;
    }

    return(
        <button
            className={contentStyles.btn} 
            onClick={props.onClick}
            disabled={props.disabled}
        >
            <span className={contentStyles.label} >
                {props.icon != undefined && (
                    <props.icon
                    color={getLabelColor(props.disabled,props.isPrimary)}
                    />
                )}
                {props.textBeforeIcon != undefined && (props.textBeforeIcon)}
                {props.src != undefined &&(
                    <img 
                        src={props.src} 
                        width={props.specificWidth ? props.specificWidth : 20} 
                        style={{margin: '0 4px'}}
                    />
                )}
                {props.text != undefined && (props.text)}
            </span>
        </button>
    )
}

export default BtnBigWithIcon;