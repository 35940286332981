import React, { useState } from "react";

import axios from "axios";

import { WorkspaceItemMove, WorkspaceItemMultiMove } from "../../model/ItemModel";

import { Move,MoveList } from "../../services/Item/ItemServicesCRUD";

import styles from "./WorkSpaceItemList.module.css";

import { INavLink } from "@fluentui/react/lib/Nav";

import { IRenderFunction } from "@fluentui/react/lib/Utilities";

import { useTranslation } from "react-i18next";

import { FontIcon } from "@fluentui/react/lib/Icon";

import { Manyfilepload } from "./UploadFunction";

import { useUpload } from "../../context/UploadProvider/useUpload";
import { folderIcon } from '../../assets/icons'
import src from "react-jss";

export interface WorkSpaceItemListFoldeComponentConfig {
	idWorkspace: string;

	idFolder: string | undefined;

	setPopupSucess: (ok: boolean, message: string) => void;

	setPopupError: (ok: boolean, message: string) => void;

	defaultRender: IRenderFunction<INavLink>;

	menuListProps: INavLink;

	onDrop?: (
		event: React.DragEvent<HTMLDivElement>,
		key: string | undefined,
		endFunction: () => void,
	) => void;
}

const WorkSpaceItemListFolderComponent: React.FC<WorkSpaceItemListFoldeComponentConfig> = ({

	idFolder,

	menuListProps,

	onDrop
}) => {
	const { t, i18n } = useTranslation();

	const [isHover, setisHover] = useState<boolean>(false);

	const upload = useUpload();

	function getUpload() {
		return upload;
	}

	const preventDefaultBehaviour = (event: React.DragEvent<HTMLDivElement>) => {
		event.stopPropagation();

		event.preventDefault();
	};

	const onDragEnterAndOver = (event: React.DragEvent<HTMLDivElement>) => {
		preventDefaultBehaviour(event);

		setisHover(true);
	};

	const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
		preventDefaultBehaviour(event);

		setisHover(false);
	};

	function leave() {
		setisHover(false);
	}

	return (
		<div
			style={{ display: "flex", height: "38px", paddingLeft: "5px", paddingRight: "5px" }}
			onDragEnter={(e) => onDragEnterAndOver(e)}
			onDragLeave={(e) => onDragLeave(e)}
			onDragOver={(e) => onDragEnterAndOver(e)}
			onDrop={(e) =>  onDrop!= undefined && onDrop(e, idFolder, leave)}
			className={isHover ? styles.hoverDrop : undefined}
		>
			<img width={20} src={folderIcon} draggable={false} style={{ marginRight: '20px' }} />

			<div
				style={{
					color: "black",

					fontWeight: 600,
				}}
			>
				{menuListProps.name}
			</div>
		</div>
	);
};

export default WorkSpaceItemListFolderComponent;

