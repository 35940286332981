import React, { useState, useEffect } from "react";

export function SetIdWorkSpaceLocalStorage(idWorkspace: string) {
   return localStorage.setItem("idWorkspace", idWorkspace)
}

export function GetIdWorkSpaceLocalStorage(idNameWorkspace: string) {
    return localStorage.getItem(idNameWorkspace)   
}



export function SetInternalNameOrg(internalNameOrg: string) {
    return localStorage.setItem("internalNameOrg", internalNameOrg)
 }
 
export function GetInternalNameOrg(internalNameOrg: string){
    return localStorage.getItem(internalNameOrg)   
}
  


