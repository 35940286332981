import { TableBody, TableRow, Grid, Stack, Table, Box, TableCell } from "@mui/material";
import DescriptionLayer from "../../DocumentDetailReviewTable/components/DescriptionLayer/DescriptionLayer";


interface IDocumentDetailTableBody<T>
{
    data: T[];
    renderCells: (item: T, index: number) => React.ReactNode;
    description?: string;
    showDescription?: boolean;
    clickId?: number;
}

export default function DocumentDetailTableBody<T>({
    data,
    renderCells,
    description,
    showDescription,
    clickId,
}: IDocumentDetailTableBody<T>)
{
    return (
        <TableBody>
          { data?.map((element, index) => (
            <>            
                <TableRow>
                    { renderCells(element, index) }
                </TableRow>
                <DescriptionLayer 
                    description={description!}
                    showDescription={showDescription!}
                    clickId={clickId!}
                    index={index}
                />
            </>
          )) }
        </TableBody>
    )
}