import { GetFlowInformationByItemVO } from '../../../../model/ItemModel'
import { ValidationRuleVOActivityUserGroup } from '../../../../model/ValidationRuleModel'
import styled, {keyframes} from 'styled-components'

export interface SvgFlowTypes {
    getFlowInformationByItemVO?: GetFlowInformationByItemVO | undefined
    data?: ValidationRuleVOActivityUserGroup | undefined
    strokeColor?: string | undefined
    isStrokeFixed?: boolean
    delayAnimationTime?: number
    timeAnimationTime?: number
}

export const SvgFlow: React.FC<SvgFlowTypes> = ( { getFlowInformationByItemVO, data, isStrokeFixed, strokeColor, delayAnimationTime, timeAnimationTime }: SvgFlowTypes ) => {
    const decideStrokeColor = (getFlowInformationByItemVO: GetFlowInformationByItemVO | undefined, data: ValidationRuleVOActivityUserGroup | undefined) => {
        if(getFlowInformationByItemVO?.validationRule?.actionId === data!.id! && !getFlowInformationByItemVO!.isRelease!) { 
            return "CURRENT"
            } else if (getFlowInformationByItemVO?.validationRule?.actionId! < data!.id!){
                return "FUTURE"
            } else {
                return "OVERCOME"
            }
    }

    const strokeColorize = (strokeDecided: string) => {
        if (strokeDecided === "CURRENT") {
            return "rgba(68, 114, 196)"
        } else if (strokeDecided === "FUTURE") {
            return "rgba(68, 114, 196, 0.5)"
        } else if (strokeDecided === "OVERCOME") {
            return "#4472C4"
        }
    }

    const animatedLine = keyframes`
        from {
            stroke-dashoffset: 150;
            stroke: ${isStrokeFixed ? strokeColor : strokeColorize(decideStrokeColor(getFlowInformationByItemVO, data))};
        }

        to {
            stroke-dashoffset: 0;
            stroke: ${isStrokeFixed ? strokeColor : strokeColorize(decideStrokeColor(getFlowInformationByItemVO, data))};
        }
    `

    const SvgStyle = styled.svg`
        stroke-dasharray: 150;
        stroke-dashoffset: 0;
        animation: ${animatedLine} ${timeAnimationTime}s linear ${delayAnimationTime}s 1 normal forwards;
    `;
    
    return (
        <SvgStyle width="100%" height="100%" >
            <line y1="20%" y2="85%" x1="0%" x2="0%" strokeWidth={5} />
        </SvgStyle>
    )   
}
