import * as React from 'react';
import { mergeStyleSets, Callout } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { ValidationRuleVOActivityResponsabilityRetrieve } from '../../../../model/ValidationRuleModel';
import { Typography } from '@mui/material';

interface CalloutResponsabilityProps{
    responsability?: ValidationRuleVOActivityResponsabilityRetrieve[]
}

export const CalloutResponsability: React.FunctionComponent<CalloutResponsabilityProps> = ({responsability}) => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId('callout-button');
  
const titleText = `+${responsability?.length ? responsability?.length -1 : ""}`

return (
    <>
      <DefaultButton
        text={titleText}
        id={buttonId}
        onClick={toggleIsCalloutVisible}
        className={styles.button}
      />
      {isCalloutVisible && (
        <Callout className={styles.callout} target={`#${buttonId}`} onDismiss={toggleIsCalloutVisible} role="alert">
          {responsability?.slice(1).map((value, index) => (
            <React.Fragment key={index}>
                <Typography>{value.nameObject}</Typography>
                {index < responsability.length - 2 && <Typography sx={{ border: "solid 1px #2D2D2D" }} />}
            </React.Fragment>
            ))}
        </Callout>
      )}
    </>
  );
};

const styles = mergeStyleSets({
  button: {
    border: 'none', 
    padding:'0',
    minWidth: '20px'
  },
  callout: {
    width: 320,
    padding: '20px 24px',
  },
});
