import { Box } from "@mui/material";
import WorkSpaceItemListFileComponentImage from "../../../../../../../../../WorkSpaceItem/WorkSpaceItemListFileComponentImage";
import { WorkSpaceUserName } from "../../../../../../../../../../model/ItemModel";

interface IDocumentDetailMetaDataInformationImage
{
    workItem: WorkSpaceUserName;
}

export default function DocumentDetailMetaDataInformationImage({
    workItem
}: IDocumentDetailMetaDataInformationImage)
{
    return (
        <Box>
            <WorkSpaceItemListFileComponentImage 
                extension={workItem?.extension}
            />
        </Box>
    )
}