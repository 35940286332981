import React, { useState, useEffect } from "react";
import {
  GetOneOrganization,
  Delete,
  SetName,
  GetD4SignSafeBox,
  SetD4Sign,
  UnsetD4Sign,
  SetAllowDrive,
  GetOrganizationAdminUsers,
  SetUserService,
  GetOneOrganizationWithContractResource,
  UnsetUserService
} from "../../services/OrganizationServices";
import { Organization, OrganizationNameVO, OrganizationUserServiceVO } from "../../model/OrganizationModel";
import Btn from "../../components/Button/Btn";
import MessageComp from "../../components/Dialog/Message";
import TextFieldComp from "../../components/TextField/TextFieldComp";
import { useNavigate, useParams } from "react-router-dom";
import { Separator } from "@fluentui/react/lib/Separator";
import { useBoolean } from "@fluentui/react-hooks";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogType,
  DialogFooter,
  DialogContent,
} from "@fluentui/react/lib/Dialog";
import Message from "../../components/Message/Message";
import { MessageBarType } from "@fluentui/react";
import { useProfile } from "../../context/ProfileProvider/useProfile";
import ComboBoxComp from "../../components/ComboBox/ComboBoxComp";
import { IComboBox, IComboBoxOption } from '@fluentui/react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, {AlertColor} from '@mui/material/Alert';
import Checkbox from '../../components/Checkbox/CheckboxComp'
import LoaderComp from '../../components/Loader/LoaderComp';

const OrganizationConfig = (props: any) => { 
  const [orgFound, setOrgFound] = useState<Organization | null>(null);
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [popupOk, setPopupOk] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [isChecked, setChecked] = useState<boolean>(false);
  const [nameDeleted, setNameDeleted] = useState<string>("");
  const [nameChanged, setNameChanged] = useState<string>("");
  const [adminOrg, setAdminOrg] = useState<boolean>(false);
  const profile = useProfile();
  const { t, i18n } = useTranslation();
  const navegate = useNavigate();
  const { nameOrg } = useParams();
  const [reaload, setReaload] = useState<boolean>(false);
  const [options, setOptions] = useState<IComboBoxOption[]>([]);
  const [keySelect, setKeySelect] = useState<string | null>(null);
  const [safeBox, setSafeBox] = useState<string | null>(null);
  const [snackBar, setSnackBar] = useState<boolean>(false);
  const [alertColor, setAlertColor] = useState<AlertColor>("error");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [allow, setAllow] = useState<boolean>(false);
  const [blockD4Sign, setBlockD4Sign] = useState<string | undefined>(undefined);
  const [optionsAdminUsers, setOptionsAdminUsers] = useState<IComboBoxOption[]>([]);
  const [keySelectAdminUsers, setKeySelectAdminUsers] = useState<string | null>(null);
  const [realoadAdminUsers, setRealoadAdminUsers] = useState<boolean>(false);
  const [blockAdminUsers, setBlockAdminUsers] = useState<string | undefined>(undefined);
  const [loadOrganization, setLoadOrganization] = useState<boolean>(false);

  const handleClose = (event:any, reason:any) => {
    setSnackBar(false);
  }

  const handleClosePO = (event:any) => {
    setSnackBar(false);
  }
  useEffect(() => {
    if(profile.organizations != undefined)
    {
      for(let organization of profile.organizations)
      {
        if(nameOrg == organization.internalName)
        {
          if(organization.isAdmin == true)
          {
            setAdminOrg(true);
          }
          break;
        }
      }
    }
  }, [profile]);


  useEffect(() => {
    if(safeBox != null && options.length > 1)
    {
      setKeySelect(safeBox);
    }
    else if(safeBox == null && options.length > 1)
    {
      setKeySelect("sel");
    }
    else if(options.length == 1)
    {
      setKeySelect("sel");
    }
    else
    {
      setKeySelect(null);
    }
  }, [options, safeBox]);

  function d4Sign()
  {
    setReaload(true);
    setBlockD4Sign(undefined);
    GetD4SignSafeBox()
    .then((result) =>{
      let optionInter:IComboBoxOption[] = [];
      optionInter.push({
        key:"sel",
        text:"Selecionar"
      });
      result.map((d4SignSafeBox) =>
      {
        optionInter.push({
          key:d4SignSafeBox.uuidSafe,
          text:d4SignSafeBox.nameSafe
        });
      });
      setOptions(optionInter);
      setReaload(false);
    })
    .catch((error)=>{
      if( error.response && error.response.data && typeof error.response.data  === 'string')
      {
        setBlockD4Sign(error.response.data );
      }
      else
      {
        setBlockD4Sign("Erro ao pegar cofre")
      }
      setKeySelect(null);
      setOptions([]);
      setReaload(false);
    });
  }

  function getAdminUsers()
  {
    setRealoadAdminUsers(true);
    if(nameOrg){
      GetOrganizationAdminUsers(nameOrg)
      .then((result) =>{
        let optionInter:IComboBoxOption[] = [];
        optionInter.push({
          key:"sel",
          text:"Selecionar"
        });
        result.map((adminUsers) =>
        {
          optionInter.push({
            key:adminUsers.id,
            text:adminUsers.name
          });
        });
        setOptionsAdminUsers(optionInter);
        setRealoadAdminUsers(false);
      })
      .catch((error)=>{
        if( error.response && error.response.data && typeof error.response.data  === 'string')
        {
          setBlockAdminUsers(error.response.data );
        }
        else
        {
          setBlockAdminUsers(t("ErrorReceivingAdminUsers.message"))
        }
        setKeySelectAdminUsers(null);
        setOptionsAdminUsers([]);
        setRealoadAdminUsers(false);
      });
    }
  }

  useEffect(() => {

    d4Sign();

    getAdminUsers();

    if (nameOrg != undefined) {

      setLoadOrganization(true);
      GetOneOrganizationWithContractResource(nameOrg)
        .then((message) => {
          if (message != null) {
            setOrgFound(message);
            setKeySelectAdminUsers(message.serviceUserId ? message.serviceUserId : "");
            if (message.name) {
              setNameChanged(message.name);
            }
            if (message.d4SignSafeBox) {
              setSafeBox(message.d4SignSafeBox);
            }
            if(message.allowDrive){
              setAllow(true)
            }
          } 
        })
        .catch((error) => {
          if( error.response && error.response.data && typeof error.response.data  === 'string')
          {
            setMessage(error.response.data );
          }
          else
          {
            setMessage("Não encontrada a organização");
          }
          setMessage("Não encontrada a organização");
          setPopupOk(true);
        })
        .finally(() =>{
          setLoadOrganization(false);
        });
    } else {
      setMessage("Não encontrada a organização");
      setPopupOk(true);
    }
  }, []);

  function deleteOrganizationPopup() {
    if (orgFound != null && orgFound.id != null) {
      if (orgFound.name != null && nameDeleted != orgFound.name) {
        setChecked(true);
        return;
      }

      setChecked(false);
      Delete(orgFound.id).then(() => {
        setMessage(t("ExcluidoComSucesso.message"));
        setPopupOk(true);
      });
    }
    toggleHideDialog();
  }

  function deleteOrganization() {
    setChecked(false);
    if (orgFound != null && orgFound.id != null) {
      toggleHideDialog();
    }
  }

  function closePopUpOk() {
    setPopupOk(false);
  }

  function EditUserService() {
    if (orgFound != null && orgFound.id) {
      let itemModelo: OrganizationUserServiceVO = {
        organizationId: orgFound.id,
        serviceUserId: keySelectAdminUsers!
      };
      
      SetUserService(itemModelo)
      .then(() => {
        setMessage(t("EditadoComSucesso.message"));
        setPopupOk(true);        
      })
      .catch((error)=> {
        if( error.response && error.response.data && typeof error.response.data  === 'string'){
          setErrorMessage(error.response.data);
        }
        else
        {
          setErrorMessage(t("AlgoErradoAconteceu."));
        }
        setAlertColor('error');
        setSnackBar(true);
      });
    }
  }

  function editOrganization() {
    if (orgFound != null && orgFound.id) {
      let itemModelo: OrganizationNameVO = {
        organizationId: orgFound.id,
        organizationName: nameChanged,
      };
      
      SetName(itemModelo)
      .then(() => {
        setMessage(t("EditadoComSucesso.message"));
        setPopupOk(true);        
      })
      .catch((error)=> {
        if( error.response && error.response.data && typeof error.response.data  === 'string'){
          setErrorMessage(error.response.data);
        }
        else
        {
          setErrorMessage(t("AlgoErradoAconteceu."));
        }
        setAlertColor('error');
        setSnackBar(true);
      });
    }
  }

  function editAllowDrive(){
    
    if (orgFound != null && orgFound.id) {
      let itemModelo: Organization = {
        id: orgFound.id,
        allowDrive: allow
      };
      
      SetAllowDrive(itemModelo)
      .then(() => {
        setMessage(t("EditadoComSucesso.message"));
        setPopupOk(true);        
      });
    }
  }

  function unSaveD4Sign() {
    setKeySelect(null);
    if (orgFound != undefined && orgFound.id != undefined) {
      unsetInternal(orgFound.id)
    }
  }

  function unsaveUserService() {
    setKeySelect(null);
    if (orgFound != undefined && orgFound.id != undefined) {
      unsetServiceUser(orgFound.id);
    }
  }

  function saveD4Sign() {
    if (orgFound != undefined && orgFound.id != undefined) {
      if (keySelect != null && keySelect != "sel") {
        SetD4Sign({
          organizationId:orgFound.id,
          d4SignSafeBox:keySelect
        })
        .then(()=>{
          setErrorMessage(t("AlteradoComSucesso."));
          setAlertColor('success');
          setSnackBar(true);
        })
        .catch((error)=> {
          if( error.response && error.response.data && typeof error.response.data  === 'string'){
            setErrorMessage(error.response.data);
          }
          else
          {
            setErrorMessage(t("AlgoErradoAconteceu."));
          }
          setAlertColor('error');
          setSnackBar(true);
        });
      }
      else
      {
        unsetInternal(orgFound.id)
      }
    }
  }

  function unsetInternal(thisIdOrg:string)
  {
    UnsetD4Sign(thisIdOrg)
    .then(()=>{
      setErrorMessage(t("AlteradoComSucesso."));
      setAlertColor('success');
      setSnackBar(true);
    })
    .catch((error)=> {
      if( error.response && error.response.data && typeof error.response.data  === 'string'){
        setErrorMessage(error.response.data);
      }
      else
      {
        setErrorMessage(t("AlgoErradoAconteceu."));
      }
      setAlertColor('error');
      setSnackBar(true);
    });
  }
  
  function unsetServiceUser(thisIdOrg:string) {
    setRealoadAdminUsers(true);
    UnsetUserService(thisIdOrg)
    .then(()=>{
      setErrorMessage(t("AlteradoComSucesso."));
      setAlertColor('success');
      setSnackBar(true);
      setKeySelectAdminUsers("");
    })
    .catch((error)=> {
      if( error.response && error.response.data && typeof error.response.data  === 'string')
      {
        setErrorMessage(error.response.data);
      }
      setAlertColor('error');
      setSnackBar(true);
    })
    .finally(() => setRealoadAdminUsers(false))
  }

  const onChangeSecondTextFieldValue = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (newValue != undefined) {
        setNameDeleted(newValue);
      }
    },
    []
  );

  const onChangeSetName = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (newValue != undefined) {
        setNameChanged(newValue);
      }
    },
    []
  );

  const dialogContentProps = {
    type: DialogType.normal,
    title: "Excluir",
    closeButtonAriaLabel: "Excluir",
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if(event.key ===  'Enter'){
      editOrganization();
    }
  } 

  function changeChangeBox (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string)
  {
    if(index != undefined && index > 0)
    {
      setKeySelect(options[index].key.toString());
    }
  }

  function changeChangeBoxAdminUsers (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string)
  {
    if(index != undefined && index > 0)
    {
      setKeySelectAdminUsers(optionsAdminUsers[index].key.toString());
    }
  }

  function changeAllowDrive (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) {
    if(checked == undefined || checked == false)
    {
      setAllow(false);
    }
    else
    {
      setAllow(true);     
    }
  };

  return (
    <div>
      {(profile.adminSystem == true || adminOrg) && (
      <div className="configdiv">
        <h3>{t("ConfigOrg.message")}</h3>
        <TextFieldComp
          label={t("Nome.message")}
          value={nameChanged}
          onChange={onChangeSetName} 
          autofocus  
          onKeyDown={onKeyDown}   
          disabled={loadOrganization}
        />
        <Btn 
          text={t("Salvar.message")} 
          onClick={editOrganization}
          disabled={loadOrganization}
        />
        {loadOrganization && (
          <LoaderComp/>
        )}
      </div>
      )}
      <>
        {profile.adminSystem == true && (<Separator />)}
          {((profile.adminSystem == true || adminOrg && orgFound?.resource == undefined)) && (
            <div className="configdiv">
              <h3>{t("AssignServiceUser.message")}</h3>
              <ComboBoxComp
                label={t("Usuarios.message")}
                options={optionsAdminUsers}
                onChange={changeChangeBoxAdminUsers}
                selectedKey={keySelectAdminUsers}
                disabled={realoadAdminUsers || loadOrganization}  
              />
              {(realoadAdminUsers || loadOrganization) && (
                <LoaderComp />
              )}
              { orgFound?.contractId == undefined || orgFound?.resource == undefined && (
                <Message
                  type={MessageBarType.error}
                  text={t("OrganizationMessages.resourceAlert")}
                  style={{
                    maxWidth: "310px",
                    marginTop: "10px",
                    marginBottom: "10px"
                  }}
                />
              ) }
              <Btn 
                text={t("Salvar.message")} 
                onClick={EditUserService}
                disabled={realoadAdminUsers || loadOrganization}
              />
              <Btn
                text={t("Resetar.message")}
                onClick={unsaveUserService}
                disabled={realoadAdminUsers || loadOrganization}
              />
            </div>
        )}
      </>
      {profile.adminSystem == true && (<Separator />)}
      {profile.adminSystem == true && (
        <div>
           <h3>{t("permissionDriveOrg.message")}</h3>
           <Checkbox
            label={t("permissionDriveOrg.permission")}
            onChange={changeAllowDrive}
            checked={allow}          
          />
          <Btn text={t("Salvar.message")} onClick={editAllowDrive}/>
        </div>
      )}
      {profile.adminSystem == true && (<Separator />)}
      {profile.adminSystem == true && (
      <div className="configdiv">
        <h3>{t("ExcluirOrg.message")}</h3>
        <Btn text={t("Excluir.message")} red={true} onClick={deleteOrganization}/>
        <Dialog hidden={hideDialog} onDismiss={toggleHideDialog}>
          <DialogContent title={t("Excluir.message")}>
            <h3>{t("CertezaExcluirOrganization.message")}</h3>
            <TextFieldComp
              label={t("EscrevaNomeOrg.message")}
              onChange={onChangeSecondTextFieldValue}
            />
            {isChecked ? (
              <Message text={t("NomeIncorreto.message")} type={MessageBarType.error} />
            ) : (
              <div></div>
            )}
          </DialogContent>
          <DialogFooter>
            <Btn onClick={toggleHideDialog} text={t("Cancelar.message")} />
            <Btn onClick={deleteOrganizationPopup} text={t("Excluir.message")} />
          </DialogFooter>
        </Dialog>
      </div>
      )}
      {profile.adminSystem == true && (<Separator />)}
      {profile.adminSystem == true && (
        <div className="configdiv">
          <h3>{t("D4Sign.Confi")}</h3>
          <ComboBoxComp
              label={t("D4Sign.SafeBox")}
              options={options}
              onChange={changeChangeBox}
              selectedKey={keySelect}
              disabled={reaload || blockD4Sign != undefined}
          />
          {reaload && (
            <LoaderComp/>
          )}
          
          {blockD4Sign != undefined && (
            <Message
                type={MessageBarType.error}
                text={blockD4Sign}
                style={{
                  maxWidth:"310px",
                  marginTop:"10px",
                  marginBottom: "10px"
                }}
            />
          )}

          <Btn
            text={t("Atualizar.message")}
            onClick={d4Sign}
          />
          
          <Btn 
            text={t("Salvar.message")} 
            onClick={saveD4Sign} 
            disabled={reaload || blockD4Sign != undefined}
          />

          <Btn 
            text={t("Excluir.message")} 
            onClick={unSaveD4Sign}
          />
        </div>
      )}

      {popupOk && (
          <MessageComp
            title={message}
            closeButtonAriaLabel={t("Fechar.message")}
            buttonMethod={closePopUpOk}
            text={t("Continuar.message")}
          />
        )}
        <Snackbar
            open={snackBar}
            autoHideDuration={6000}
            message="Archived"
            onClose={handleClose}
            anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
            sx={{ bottom: { xs: 50, sm: 20 } }}
          >
            <MuiAlert elevation={6} variant="filled" sx={{ width: '100%' }} severity={alertColor} onClose={handleClosePO}>
              {errorMessage}
            </MuiAlert>
        </Snackbar>
    </div>
  );
};

export default OrganizationConfig;
