import { useTranslation } from 'react-i18next'

export const TranslatorP =  (path: any ) => {
  let returnString:string = Translator(path);
  return(
    <p>{returnString}</p>
  );
}

export const TranslatorS = ({ path }: {path: string}) => {
  const { t } = useTranslation()
  let returnString:string = t(path);
  return returnString;
}

export const Translator = ({ path }: {path: any}) => {
  const { t } = useTranslation()
  let returnString:any = t(path);
  return returnString;
}


export default Translator
