import { useState, useEffect } from "react";
import {MenuConfig} from "../../../components/Menu/MenuConfig";
import ChoicerViwer from "./ChoicerViwer"
import { DownloadViwer, GetOneItem } from "../../../services/Item/ItemServicesGet";
import { useBoolean } from '@fluentui/react-hooks';

import {
    mergeStyleSets,
    Modal,
  } from '@fluentui/react';
import React from "react";
import PreviewWorkspaceList, {PreviewWorkspaceListProps} from "./PreviewWorkspace/PreviewWorkspaceList";

export interface ChoicerViwerModalProps {
    isOpen:boolean;
    setIsOpen: (hideDialog:boolean) => void;
    menuConfig?: MenuConfig;
    idItem: string;
    name: string;
    extension: string;
    ref?: React.Ref<HTMLDivElement>;
    idFolder?: string;
    previewWorkspaceListProps?:PreviewWorkspaceListProps | undefined;
}

const ChoicerViwerModal: React.ForwardRefExoticComponent<ChoicerViwerModalProps> = 
    React.forwardRef(({
        isOpen,
        setIsOpen,
        menuConfig,
        idItem,
        name,
        extension,
        idFolder,
        previewWorkspaceListProps
    }, ref: React.Ref<HTMLDivElement>) => {
        const [isOpenConst, setIsOpenConst] = useState<boolean>(isOpen);

        const [showList, { toggle: toggleHideDialog }] = useBoolean(false);

        useEffect(() =>{
            setIsOpenConst(isOpen)
        },[isOpen])

        useEffect(() => {
            if(showList){
                toggleHideDialog()
            }
        }, [idFolder])
        
        function cancel()
        {
            setIsOpen(false);
            setIsOpenConst(false);
        }

        return(
            <Modal
                isOpen={isOpenConst}
                onDismiss={cancel}
                isBlocking={false}
                containerClassName={contentStyles.container}
                scrollableContentClassName={contentStyles.scrollableContent}
            >
                {(showList && previewWorkspaceListProps != undefined && previewWorkspaceListProps.changeModalContent != undefined)&& (
                    <PreviewWorkspaceList
                        idItem={idItem}
                        changeModalContent={previewWorkspaceListProps.changeModalContent}
                        workspaceItemList={previewWorkspaceListProps.workspaceItemList} 
                        getWorkspaceWhenVisible={previewWorkspaceListProps.getWorkspaceWhenVisible}
                        workMax={previewWorkspaceListProps.workMax}
                    />
                )}
                <ChoicerViwer
                    idItem={idItem}
                    menuConfig={menuConfig}
                    download={DownloadViwer}
                    getItem={GetOneItem}
                    positionAbsolute={true}
                    toggleHideDialog={toggleHideDialog}
                    showList={showList}
                    changeModalContent={previewWorkspaceListProps != undefined ? previewWorkspaceListProps.changeModalContent : undefined}
                    title={`${name}.${extension}`}
                    cancel={cancel}
                    fullScreenSetted={false}
                    isAcept={false}
                    isDetailPage={false}
                    isFilePage={false}
                    isSidebarCollapsed={false}
                    isSharedFilePage={false}
                />
            </Modal>
        )
    }
)

const contentStyles = mergeStyleSets({
    header: {
        display: "flex",
        justifyContent: "space-between",
        padding: "8px",
    },
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      width: "90%",
      height: "90%",
      overflowY: "none",
    },
    closeButton: {
        fontSize:"26px",
        cursor:"pointer",
    },
    scrollableContent: {
        overflowY: "none",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
    }
})


export default ChoicerViwerModal;
