import * as React from 'react';
import { mergeStyleSets, Callout, IIconProps } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { Typography } from '@mui/material';
import { GetFlowInformationByItemVO } from '../../../../model/ItemModel';
import { UserIcon } from '../../../../assets/svg/UserIcon';
import { DataIcon } from '../../../../assets/svg/DataIcon';
import moment from 'moment';
import { ValidationRuleVOActivityRetrieve } from '../../../../model/ValidationRuleModel';
import { useMemo } from 'react';
import { CloseIcon } from '../../../../assets/svg/CloseIcon';
import { CheckIcon2 } from '../../../../assets/svg/checkIcon';
import { useTranslation } from 'react-i18next';

interface children{
    title?: string
    children?: React.ReactNode
    getFlowInformationByItemVO?: GetFlowInformationByItemVO
}

export const StatusCallout: React.FunctionComponent<children> = ({getFlowInformationByItemVO, children, title}) => {
  const { t, i18n } = useTranslation();
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId('callout-button');

  const returnDateAndIconMemo = useMemo(() => (action?: number, dateBack?: string, dateForwad?: string) => {
    if(action === 0) {
        const backDate = moment(dateBack).format("DD/MM/YY HH:mm")
        return (
            <Typography sx={{color: "#2D2D2D", display: "flex", alignItems:"center" }}>
                <DataIcon/>
                <Typography sx={{margin:"0 4px 0 8px"}} fontWeight="600">{t("WorkspaceItemFlowListing.date")}:</Typography>
                <Typography sx={{opacity:"60%", display:"flex"}}>{ backDate } <CloseIcon/></Typography>                   
            </Typography>
        )
    }
    if(action === 1) {
        const forwardDate = moment(dateForwad).format("DD/MM/YY HH:mm")
        return (
            <Typography sx={{color: "#2D2D2D", display: "flex", alignItems:"center" }}>
                <DataIcon/>
                <Typography sx={{margin:"0 4px 0 8px"}} fontWeight="600">{t("WorkspaceItemFlowListing.date")}:</Typography>
                <Typography sx={{opacity:"60%", display:"flex"}}>{forwardDate} <CheckIcon2/></Typography>                   
            </Typography>
        )
    }    
}, [getFlowInformationByItemVO])

const infoIcon: IIconProps = {
    iconName: 'Info',
    styles: { root: { fontSize: '16px' } },
  };
  

  return (
    <>
      <DefaultButton
        iconProps={infoIcon}
        id={buttonId}
        onClick={toggleIsCalloutVisible}
        className={styles.button}
      />
      {isCalloutVisible && (
        <Callout className={styles.callout} target={`#${buttonId}`} onDismiss={toggleIsCalloutVisible} role="alert">
            <Typography style={{fontSize:"20px", color:"#2D2D2D", fontWeight:"600"}}>
                {title}
            </Typography>
            {title === "Draft" &&
                <Typography>
                    <Typography sx={{border:"solid 1px #2D2D2D"}}></Typography>
                    { children}
                </Typography>
            }

             
            {getFlowInformationByItemVO?.activity?.filter(item => item.name === title).map((data: ValidationRuleVOActivityRetrieve, index) => {

                let responsability: string = "";
                if(data.responsability != undefined && data.responsability.length > 0)
                {
                    for(let i = 0; i < data.responsability.length; i++)
                    {
                        if(data.responsability[i].nameObject != undefined)
                        {
                            responsability = responsability + data.responsability[i].nameObject;
                            if(i != data.responsability.length - 1)
                            {
                                responsability = responsability + " - ";
                            }
                        }
                    }
                }

                if(getFlowInformationByItemVO?.dateActionForwadOrBack){
                    const relatedActions = getFlowInformationByItemVO?.dateActionForwadOrBack?.filter(item => item.idAction === data.id);
                    if(relatedActions?.length){
                        
                    return (
                    relatedActions.map((item, itemIndex, list) => (
                        <Typography key={index} fontWeight="600">
                            {list.length - 1 !== itemIndex ? "" :
                                <Typography>
                                    <Typography sx={{border:"solid 1px #2D2D2D"}}></Typography>
                                    <Typography sx={{ color: "#2D2D2D", display: "flex", alignItems: "center" }}>
                                        <UserIcon/>
                                        <Typography sx={{ margin: "0 4px 0 8px" }} fontWeight="600">
                                            {t("WorkspaceItemFlowListing.responsible")}:
                                        </Typography>
                                        <Typography sx={{ opacity: "60%" }}>
                                            {getFlowInformationByItemVO?.currentUserDraftName!}{" "}
                                        </Typography> 
                                    </Typography> 
                                    {returnDateAndIconMemo(item.action, item.dateUpdateBackItem, item.dateUpdateForwadItem)}
                                </Typography>
                            }

                            {list.length - 2 !== itemIndex ? "" :
                                <Typography> 
                                    <Typography sx={{border:"solid 1px #2D2D2D"}}></Typography>
                                    <Typography sx={{ color: "#2D2D2D", display: "flex", alignItems: "center" }}>
                                        <UserIcon/>
                                        <Typography sx={{ margin: "0 4px 0 8px" }} fontWeight="600">
                                            {t("WorkspaceItemFlowListing.responsible")}:
                                        </Typography>
                                        <Typography sx={{ opacity: "60%" }}>
                                            {getFlowInformationByItemVO?.currentUserDraftName!}{" "}
                                        </Typography> 
                                    </Typography> 
                                    {returnDateAndIconMemo(item.action, item.dateUpdateBackItem, item.dateUpdateForwadItem)}
                                </Typography>
                            }

                            {list.length - 3 !== itemIndex ? "" :
                                <Typography> 
                                    <Typography sx={{border:"solid 1px #2D2D2D"}}></Typography>
                                    <Typography sx={{ color: "#2D2D2D", display: "flex", alignItems: "center" }}>
                                        <UserIcon/>
                                        <Typography sx={{ margin: "0 4px 0 8px" }} fontWeight="600">
                                            {t("WorkspaceItemFlowListing.responsible")}:
                                        </Typography>
                                        <Typography sx={{ opacity: "60%" }}>
                                            {getFlowInformationByItemVO?.currentUserDraftName!}{" "}
                                        </Typography> 
                                    </Typography> 
                                    {returnDateAndIconMemo(item.action, item.dateUpdateBackItem, item.dateUpdateForwadItem)}
                                </Typography>
                            }

                            {list.length - 1 !== itemIndex ? "" :                                  
                                <Typography>{item.idAction === getFlowInformationByItemVO?.validationRule?.actionId! && !getFlowInformationByItemVO?.isRelease ?
                                <> 
                                    <Typography sx={{border:"solid 1px #2D2D2D"}}></Typography>
                                    <Typography >{t("WorkspaceItemFlowListing.progress")}</Typography>
                                    <Typography sx={{ color: "#2D2D2D", display: "flex", alignItems: "center" }}>
                                        <UserIcon />
                                        <Typography sx={{ margin: "0 4px 0 8px" }} fontWeight="600">
                                        {t("WorkspaceItemFlowListing.responsible")}:
                                        </Typography>
                                        <Typography sx={{ opacity: "60%" }}>
                                            {responsability}
                                        </Typography>
                                    </Typography>
                                </> : "" }</Typography>    
                            }
                            
                            {list.length - 1 !== itemIndex ? "" :                                
                                <Typography>{item.idAction! > getFlowInformationByItemVO?.validationRule?.actionId! || !list[itemIndex] ?  
                                    <>
                                        <Typography sx={{border:"solid 1px #2D2D2D"}}></Typography> 
                                        <Typography>{t("WorkspaceItemFlowListing.by")} "{item.nameResponsability}", {t("WorkspaceItemFlowListing.waiting")}</Typography>
                                    </> 
                                : "" }</Typography>                              
                            }                                 
                        </Typography>
                    ))
                    )}else if(getFlowInformationByItemVO?.validationRule?.actionId === data.id){ 
                        return (
                            <>
                                <Typography sx={{border:"solid 1px #2D2D2D"}}></Typography>
                                <Typography >{t("WorkspaceItemFlowListing.progress")}</Typography>
                                <Typography sx={{ color: "#2D2D2D", display: "flex", alignItems: "center" }}>
                                    <UserIcon />
                                    <Typography sx={{ margin: "0 4px 0 8px" }} fontWeight="600">
                                        {t("WorkspaceItemFlowListing.responsible")}:
                                    </Typography>
                                    <Typography sx={{ opacity: "60%" }}>
                                        {responsability}
                                    </Typography>
                                </Typography>
                            </>
                        );                                       
                    } else {  
                        return (
                            <>
                                <Typography sx={{border:"solid 1px #2D2D2D"}}></Typography>
                                <Typography>{t("WorkspaceItemFlowListing.by")} {responsability}, {t("WorkspaceItemFlowListing.waiting")}</Typography>
                            </>
                        );                                        
                    }
                }else {
                    return (
                        getFlowInformationByItemVO?.validationRule?.actionId === data.id ? 
                        
                        <>
                            <Typography sx={{border:"solid 1px #2D2D2D"}}></Typography>
                            <Typography >{t("WorkspaceItemFlowListing.progress")}</Typography>
                            <Typography sx={{ color: "#2D2D2D", display: "flex", alignItems: "center" }}>
                                <UserIcon />
                                <Typography sx={{ margin: "0 4px 0 8px" }} fontWeight="600">
                                    {t("WorkspaceItemFlowListing.responsible")}:
                                </Typography>
                                <Typography sx={{ opacity: "60%" }}>
                                    {responsability}
                                </Typography>
                            </Typography>
                        </>
                            :
                        <>
                            <Typography sx={{border:"solid 1px #2D2D2D"}}></Typography>
                            <Typography >{t("WorkspaceItemFlowListing.by")} {responsability}, {t("WorkspaceItemFlowListing.waiting")}</Typography>
                        </>                           
                    )
                }
            })}    
        
        </Callout>
      )}
    </>
  );
};

const styles = mergeStyleSets({
  button: {
    border: 'none', 
    padding:'0',
    minWidth: '20px'
  },
  callout: {
    width: 320,
    padding: '20px 24px',
  },
});
