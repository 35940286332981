export function bytesConverter(bytes: number) {
    if (bytes < 1024) {
        return bytes + 'B';
    } else if (bytes < Math.pow(1024, 2)) {
        return (bytes / 1024).toFixed(0) + 'KB';
    } else if (bytes < Math.pow(1024, 3)) {
        return (bytes / Math.pow(1024, 2)).toFixed(0) + 'MB';
    } else if (bytes < Math.pow(1024, 4)) {
        return (bytes / Math.pow(1024, 3)).toFixed(0) + 'GB';
    } else if (bytes < Math.pow(1024, 5)) {
        return (bytes / Math.pow(1024, 4)).toFixed(0) + 'TB';
    } 
}