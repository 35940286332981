import { Grid, Box } from '@mui/material';
import { WorkSpaceUserName } from '../../../../../../../../model/ItemModel'; 
import DocumentDetailMetaDataImage from './components/DocumentDetailMetaDataImage/DocumentDetailMetaDataImage';
import {useParams} from 'react-router-dom';
import { TagName } from '../../../../../../../../model/Tags';
import DocumentDetailMetaDataInformationName from './components/DocumentDetailMetaDataName/DocumentDetailMetaDataName';

interface IDocumentDetailMetaDataInformation
{
    workItem: WorkSpaceUserName;
    setPopupError: (set: boolean, message: string) => void;
    setPopupOk: (set: boolean, message: string) => void;
    tagInternal: TagName[] | undefined;
    openPanelTag: () => void;
    boxLayout: boolean;
    onClickTag: () => void;
    itemType: string | undefined;
    isSidebarCollapsed?: boolean;
    isHover: boolean;
    isValidateReadViwer: boolean;
    isValidateViwer: boolean;
    leave: () => void;
    onDragEnterAndOver: (event: React.DragEvent<HTMLDivElement>) => void;
    onDragLeave: (event: React.DragEvent<HTMLDivElement>) => void;
    progress: number;
    setFolderExternal: (itemId: string, itemName: string | undefined) => void;
    unvalidType: boolean;
    updateTagName: (index:number, tagName: TagName[], isUpload: boolean) => void;
    indexOfArray: number;
    setTagInternal: (tagName: TagName[] | undefined) => void;
    updateNameDescriptionByIndex:(index:number, name:string, isUpload: boolean, description:string) => void;
    isUpload: boolean;
}

export default function DocumentDetailMetaDataInformation({
    workItem,
    setPopupError,
    setPopupOk,
    tagInternal,
    openPanelTag,
    boxLayout,
    itemType,
    onClickTag,
    isSidebarCollapsed,
    isHover,
    isValidateReadViwer,
    isValidateViwer,
    leave,
    onDragEnterAndOver,
    onDragLeave,
    progress,
    setFolderExternal,
    unvalidType,
    updateTagName,
    indexOfArray,
    setTagInternal,
    updateNameDescriptionByIndex,
    isUpload
}: IDocumentDetailMetaDataInformation) {

    const { idWorkspace } = useParams();

    return (
        <Grid item xs={8} sx={{ background: "white" }}>
            <Box sx={{
                display: 'flex',
                gap: 3,
                alignItems: 'center',
                width: `${isSidebarCollapsed ? "100%" : ""}`,
                height: '42px'
            }}>
                <DocumentDetailMetaDataImage 
                    workItem={workItem}
                />
                <DocumentDetailMetaDataInformationName 
                    workItem={workItem}
                    boxLayout={boxLayout}
                    idMongo={workItem?.id!}
                    itemType={itemType!}
                    onClickTag={onClickTag}
                    openPanelTag={openPanelTag}
                    setPopupError={setPopupError}
                    setPopupOk={setPopupOk}
                    tagInternal={tagInternal!}
                    workspaceId={idWorkspace!}
                    isHover={isHover}
                    isValidateReadViwer={isValidateReadViwer}
                    isValidateViwer={isValidateViwer}
                    leave={leave}
                    onDragEnterAndOver={onDragEnterAndOver}
                    onDragLeave={onDragLeave}
                    progress={progress}
                    setFolderExternal={setFolderExternal}
                    unvalidType={unvalidType}
                    updateTagName={updateTagName}
                    indexOfArray={indexOfArray}
                    setTagInternal={setTagInternal}
                    updateNameDescriptionByIndex={updateNameDescriptionByIndex}
                    isUpload={isUpload}
                />
            </Box>
        </Grid>
    )
}