import {
    mergeStyleSets,
} from '@fluentui/react';
import PrintIcon from '@mui/icons-material/Print';
import React from "react";
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { ViwerProps } from "../../pages/WorkSpaceItem/Viwer/ViwerProps";
import styles from "../PDF/PDFViewer/PDFPageViwer.module.css";
import { ReactComponent as MoreIcon } from '../PDF/PDFViewer/SVG/adicionar.svg';
import { ReactComponent as LessIcon } from '../PDF/PDFViewer/SVG/menos-linha.svg';
import HeaderViewerComp from "../Viewer/Header/HeaderViewerComp";
import "./Image.css";

const ImagePageViwer  = (props: ViwerProps) => {
    return(
        <TransformWrapper
            minScale={0.5}
            maxScale={5}
            initialScale={1}
        >
        {(utils: ReactZoomPanPinchRef) => (
          <React.Fragment>
            <HeaderViewerComp 
                haveChoicerViwerChangeList={props.changeModalContent != undefined}
                title={props.title}
                showList={props.showList}
                cancel={props.cancel}
                toggleHideDialog={props.toggleHideDialog}
                isAcept={props.isAcept}
                menuConfig={props.menuConfig}
                hideSidebar={props.hideSidebar}
                toggleHideSidebar={props.toggleHideSidebar}    
                printControls={
                    props.openPdfWindowPrint == undefined ?
                        undefined :
                        <div className={styles.displayFlex}>
                            <button className={styles.buttonIconPrint}
                                onClick={() => props.openPdfWindowPrint && props.openPdfWindowPrint('image')}
                            >
                                <PrintIcon className={styles.iconSize} />
                            </button>
                        </div>
                }
                scaleControls={
                    <div className={styles.displayFlex}>
                        <button className={styles.buttonIconHeader}
                            onClick={() => utils.zoomIn(0.5)}
                            disabled={utils.state.scale === 5}
                        >
                            <MoreIcon className={styles.iconSize}/>
                        </button>
                        <button className={styles.buttonIconHeader}
                            onClick={() => utils.zoomOut(0.5)}
                            disabled={utils.state.scale === 0.5}  
                        >
                            <LessIcon className={styles.iconSize}/>
                        </button>
                    </div>
                }           
            />
            <div className={contentStyles.imageContent} style={{height: props.showList ? "calc(93% - " + props.heightViewer.toString() + "px)" : "93%"}}>
                <TransformComponent wrapperStyle={{overflow: "auto"}}>
                        <img
                            src={props.url}
                            style={{
                                margin:"auto",
                                paddingBottom: "30%"
                        }}
                        /> 
                </TransformComponent>
            </div>
          </React.Fragment>
        )}
      </TransformWrapper>                                                                        
       
    );
}

const contentStyles = mergeStyleSets({
    btnZoom:{
        marginLeft:"5px",
        border: "none",
        backgroundColor: "white",
        cursor: "pointer",
        ':hover':{
            background: "lightgray"
        },
    },
    header:{
        background:"white", 
        textAlign:"center",
        display:"flex",
        placeContent:"center",
    },
    headerRoot:{
        display: "grid", 
        gridTemplateColumns: "6fr 3fr 3fr"
    },
    headerButtons:{
        position: "relative", 
        padding: "8px 0", 
        alignSelf: "center",
        display: "flex",
        justifyContent: "space-evenly",
    },
    imageContent:{
        position: "absolute",
        width:"100%",
        overflow:"auto",
        background: "#e1e1e1",
    },
    pageCurrPrev:{
        minWidth: "109px",
        minHeight: "163px",
        maxWidth: "109px",
        maxHeight: "163px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
    },
    imgCurrPrev:{
        maxHeight: "128px",
        maxWidth: "93px",
        border: "1px solid #D1D1D1",
        outline: "8px solid #F5F5F5",
        borderRadius: "4px",
    },
})

export default ImagePageViwer;