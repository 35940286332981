import { useEffect } from 'react';
import moment from "moment";
import { formatedDates,formatedDateTemporality } from "../../../functions/formatedDates";
import { IconComp } from "../../../components/IconComp/iconComp";
import { ItemValidationRule, Temporality } from "../../../model/ItemModel";
import { useTranslation } from 'react-i18next';
import { term } from "../WorkSpaceItemListStyle/ColumnStandard";

export interface ClockFieldTypes {
  timeDeadLine: string | undefined
  isReleaseConst: boolean | undefined
  itemTemporality: Temporality| undefined
  validatioRule: ItemValidationRule | undefined;
  iconNeeded?: boolean
}


export const ClockField: React.FC<ClockFieldTypes> = ({
  timeDeadLine,
  isReleaseConst,
  validatioRule,
  itemTemporality,
  iconNeeded
}) => {

  const { t } = useTranslation();

  if (
    (timeDeadLine === undefined && itemTemporality == undefined && !iconNeeded) ||
    (isReleaseConst == true && itemTemporality == undefined && !iconNeeded)
  ) {
    return (
      <div
        style={{
          gridArea: term,
        }}
        >
        <p style={{ textAlign: 'center', opacity: 0.2}}>{t("Term.noDeadline")}</p>
      </div>
    )
  }
  else if (timeDeadLine === undefined && itemTemporality == undefined && iconNeeded){
    return <IconComp tooltip={''} type={'ON'} opacity="0.2" />
  }
  else if (isReleaseConst == true && itemTemporality == undefined && iconNeeded){
    return <IconComp tooltip={''} type={'ON'} opacity="0.2" />
  }
  
  function handleClock() {
  
    if(itemTemporality!= undefined){
        const currentDayTemporality = new Date();
        const temporalityUtc = new Date(itemTemporality.itemTemporality!);     
        const { temporalitydate, currentDateTemporality } = formatedDateTemporality(temporalityUtc,currentDayTemporality);
  
        if (moment(currentDateTemporality).isSame(temporalitydate)) {
          return <IconComp type={'CURRENT'} tooltip={t("Term.current")} date={moment(temporalitydate).format('DD/MM/YYYY')} />
        }
        if (moment(currentDateTemporality).isBefore(temporalitydate)) {
          return <IconComp type={'ON'} tooltip={t("Term.on")} date={moment(temporalitydate).format('DD/MM/YYYY')} />
        }
        if (moment(currentDateTemporality).isAfter(temporalitydate)) {
          return <IconComp type={'OUT'} tooltip={t("Term.out")} date={moment(temporalitydate).format('DD/MM/YYYY')} />
        }
    }
    else{
      if (isReleaseConst === false && validatioRule !== undefined && timeDeadLine !== undefined) {
        const currentDay = new Date();
        const termUtc = new Date(timeDeadLine);
        const { currentDate, termdate } = formatedDates(termUtc, currentDay);
        if (moment(currentDate).isSame(termdate)) {
          return <IconComp type={'CURRENT'} tooltip={t("Term.current")} date={moment(termdate).format('DD/MM/YYYY')} />
        }
        if (moment(currentDate).isBefore(termdate)) {
          return <IconComp type={'ON'} tooltip={t("Term.on")} date={moment(termdate).format('DD/MM/YYYY')} />
        }
        if (moment(currentDate).isAfter(termdate)) {
          return <IconComp type={'OUT'} tooltip={t("Term.out")} date={moment(termdate).format('DD/MM/YYYY')} />
        }
      }
    }
    
  }
  

  return (
    <>
      { handleClock() }
    </>
  );
}