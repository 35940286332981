import { TextField } from "@fluentui/react";
import { Box } from "@mui/material";
import PasswordVisibilityIcon from "../../../../../../assets/images/passwordVisibilityIcon.svg"
import PasswordVisibilityOffIcon from "../../../../../../assets/icons/visibilityOffPassword.svg"
import { useState } from "react";


interface IPasswordTextFieldWithReveal{
    onChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void;
    value: string;
    isChecked?: boolean;
    setCheckedState?: (value: React.SetStateAction<boolean>) => void
    label?: string;
    placeholder?: string;
    passwordBorderColor?: string;
    disable?: boolean;
    right?: number;
    top?: number;
    bottom?: number;
    marginX?: string
    marginY?: string
};

export function PasswordTextFieldWithReveal({
    onChange, value, setCheckedState, isChecked, label, placeholder, passwordBorderColor, disable, right, top,bottom, marginX, marginY
}: IPasswordTextFieldWithReveal) {

    const [visiblePassword, setVisiblePassword] = useState<{ icon: string, type: "password" | "text" }>({ icon: PasswordVisibilityIcon, type: "password" })

    function toggleVisbilePassword() {
        if (value.length > 0) {
            setVisiblePassword(prevState => ({
                type: prevState.type === "password" ? "text" : "password",
                icon: prevState.icon === PasswordVisibilityIcon ? PasswordVisibilityOffIcon : PasswordVisibilityIcon
            }));
        }
    }

    return (
        <Box sx={{ position: 'relative' }}>
            <TextField
                onChange={onChange}
                value={value}
                label={label ? label : undefined}
                placeholder={placeholder ? placeholder : undefined}
                styles={{
                    wrapper: {
                        '.ms-Label': {
                            color: `${passwordBorderColor ? passwordBorderColor : '#2D2D2D4D'}`,
                            font: 'normal normal 600 12px/18px Segoe UI',
                        }
                    },  
                    fieldGroup: {
                        background: "#FBFBFB",
                        border: "0px",
                        borderBottom: `1px solid ${passwordBorderColor ? passwordBorderColor : '#2D2D2D4D'}`,
                        opacity: 1,
                        height: '30px',
                        borderRadius: '0px',

                        "::after": {
                            outline: "none",
                            borderColor: 'transparent'
                        },
                        "input": {
                            padding: '0px 0px'
                        },
                    },
                    
                }}
                type={visiblePassword.type}
                onFocus={() => {
                    if (isChecked) {
                        setCheckedState!(false)
                    }
                }}
                disabled={disable}
            />
            <Box component={'img'} src={visiblePassword.icon}
                sx={{
                    position: 'absolute',
                    right: right? right: '',
                    top: top ? top :  '',
                    bottom: bottom ? bottom :  '',

                    cursor: 'pointer',
                    '&:hover': {
                        opacity: 1
                    }
                }}
                onClick={() => toggleVisbilePassword()}
            />
        </Box>
    )
}