import { useTranslation } from "react-i18next";
import AlertComponent from "../../../../../../components/Alert/AlertComponent";
import { PasswordState, styleTypes } from "../../RealTimePasswordCheck/Types/PasswordTypes";

export interface PasswordWarningProps {
    state: PasswordState;
    variant: string;
}

export interface PasswordObjectReturn {
    stylesTypes: styleTypes;
    textInformation: string;
}

export default function PasswordWarning({ state, variant }: PasswordWarningProps) {
    const { t } = useTranslation();

    function handleTypes(variant: string): PasswordObjectReturn {
        switch(variant) {
            case 'minimunCharacters':
                return {
                    stylesTypes: state.minimunCharacters,
                    textInformation: t("Entrar.characterMinimun")
                };
            case 'specialCharacter':
                return {
                    stylesTypes: state.specialCharacter,
                    textInformation: t("Entrar.specialcharacters")
                };
            case 'capitalCharacter':
                return {
                    stylesTypes: state.capitalCharacter,
                    textInformation: t("Entrar.capitalLetter")
                };
            case 'numberCharacter':
                return {
                    stylesTypes: state.numberCharacter,
                    textInformation: t("Entrar.oneNumber"),
                    
                };
            default: 
                return {
                    stylesTypes: state.capitalCharacter,
                    textInformation: t("Entrar.characterMinimun")
                };
        }
    }

    return (
        <AlertComponent
            type={handleTypes(variant).stylesTypes.error ? "error" : "success"}
            text={handleTypes(variant).textInformation}
        />
    )
}