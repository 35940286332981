import { Box, Link } from '@mui/material';
import blueArrow from '../../../../../../../../assets/icons/arrow-left-blue.svg';

interface IFormActionLink {
    isRenderingArrow?: boolean;
    href?: string;
    text?: string;
    boldLink?: boolean;
}

export default function FormActionLink({
    isRenderingArrow,
    href,
    text,
    boldLink
}: IFormActionLink)
{
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2
        }}>
            { isRenderingArrow && (
                <Box>
                    <img src={blueArrow} />
                </Box>
            ) }
            <Link
                underline='hover'
                sx={{
                    cursor: 'pointer',
                    textAlign: "center",
                    font: `normal normal ${boldLink ? '600' : 'normal'} 16px/24px Segoe UI`,
                    letterSpacing: "0px",
                    color: "#005A9E",
                }}
                href={href}
            >
                { text }
            </Link>
        </Box>
    )
}