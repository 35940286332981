import { ProSidebar } from "react-pro-sidebar";
import styles from '../../../../../Documentdetail.module.css';
interface IFlowSidebarWrapper 
{
    isSidebarCollapsed: boolean;
    children: React.ReactNode;
    fullScreenSetted: boolean;
}

export default function FlowSidebarWrapper({
    isSidebarCollapsed,
    children,
    fullScreenSetted
}: IFlowSidebarWrapper)
{
    return (
        <ProSidebar 
            collapsed={isSidebarCollapsed} 
            className={fullScreenSetted ? styles.sideBarStylesFull : styles.sideBarStyles}
            collapsedWidth={80}
            width={550}
        >
            { children }
        </ProSidebar>
    )
}