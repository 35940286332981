import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Btn, { TypeStyle } from "../../../../../components/Button/Btn";
import { IContextualMenuItem } from "@fluentui/react/lib/ContextualMenu";
import { useBoolean } from "@fluentui/react-hooks";
import ValidationRuleStartPanel from "../../../../ValidationRule/ValidationRulePanel/ValidationRuleStartPanel";
import { WorkSpaceUserName, ItemValidationRule} from "../../../../../model/ItemModel";


export interface IStartButton {
    idWorkspace:string;
    workspaceItem: WorkSpaceUserName;
    indexOfArray: number,
    setWorkspace: ((workSpaceUserName: WorkSpaceUserName, index: number, isUpload: boolean) => void);
    isUpload: boolean;
}

const StartButton: React.ForwardRefExoticComponent<IStartButton> = React.forwardRef(({
    idWorkspace,
    workspaceItem,
    indexOfArray,
    setWorkspace,
    isUpload
} , ref) => {

    const [isOpenPanelValidation, { setTrue: openPanelValidation, setFalse: dismissPanelValidation }] = useBoolean(false);

    const [waitPanelResp, setWaitPanelResp] = useState<boolean>(false);

    const { t } = useTranslation();

    const openValidationPanel = (
        ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
        item?: IContextualMenuItem
      ) => {
        if (ev) ev.preventDefault();
        openPanelValidation()
      };

    return (
        <>
            {workspaceItem.id != undefined && (
                <>
                    <Btn
                        text={t("Start.message")}
                        onClick={openValidationPanel}
                        style={TypeStyle.startButton}
                    />

                    {isOpenPanelValidation &&(
                        <ValidationRuleStartPanel
                            idWorkspace={idWorkspace}
                            idMongo={workspaceItem.id}
                            indexOfArray={indexOfArray}
                            setWorkspace={setWorkspace}
                            isUpload={isUpload}
                            setWaitLoader={setWaitPanelResp}
                            waitLoader={waitPanelResp}
                            isOpen={isOpenPanelValidation}
                            dismissPanel={dismissPanelValidation}
                        />
                    )}
                </>
            )}
        </>
    )
})

export default StartButton;