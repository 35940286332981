import React,{useEffect, useState, WheelEvent,UIEvent,useRef } from "react";
import {
    mergeStyleSets,
} from '@fluentui/react';
import {GoToZon} from "./DwgViwerPng"
import './ImageStyles.css';

interface ImageProps{
    url:string,
    showList?:boolean,
    heightViwer: number;
    setZoom: (zoom:number, go: GoToZon) => void;
    nextZoon?: number;
    backZoon?: number;
    goToZon?: GoToZon;
    disablePanZoom:boolean;
}

interface ImageType{
    width:number,
    height:number,
}

interface Position{
  x:number,
  y:number,
}

interface DOMReacrinternal{
  top:number,
  left:number,
  width:number,
  height:number
}

interface PositionTiype{
  translateX:number,
  translateY:number,
  prevMouseX:number,
  prevMouseY:number,
  scale:number
}

const Image  = (props: ImageProps) => {

  const ZOOM_FACTOR:number = 0.1;
  const ZOOM_FACTOR_IN:number = 1 + ZOOM_FACTOR;
  const ZOOM_FACTOR_OUT:number = 1 - ZOOM_FACTOR;

  const scrollViewerRef = useRef<HTMLDivElement>(null);

  const [isPanning, setPanning] = useState(false);
  const [image, setImage] = useState<ImageType|undefined>(undefined);

  const [position, setPosition] = useState<PositionTiype>({
    translateX: 0,
    translateY: 0,
    prevMouseX: 0,
    prevMouseY: 0,
    scale: 1,
  });

  
  useEffect(() => {
    if(props.goToZon != undefined && image !=undefined)
    {
      var offSetWidth  = (props.goToZon.width - props.goToZon.containerRectwidth) / 2;
      var offSetHeigth = (props.goToZon.height - props.goToZon.containerRectheight) / 2;

      const positionZoom = {
        x: props.goToZon.width  * (props.goToZon.scale - 1) / 2,
        y: props.goToZon.height * (props.goToZon.scale - 1) / 2,
      }

      if(props.goToZon.deltaY < 0){

        if(props.nextZoon == 2){
          //Zoom In
          setPosition({
            ...position,
            translateX:((positionZoom.x * -1) + props.goToZon.positionX + offSetWidth /2 /2 /2 + positionZoom.x) ,
            translateY:((positionZoom.y * -1) + props.goToZon.positionY + offSetHeigth  /2 /2 /2 + positionZoom.y),
          }) 
        }
        else if(props.nextZoon == 3){
          setPosition({
            ...position,
            translateX:((positionZoom.x * -1) + props.goToZon.positionX + offSetWidth  + positionZoom.x /2 ) ,
            translateY:((positionZoom.y * -1) + props.goToZon.positionY + offSetHeigth + positionZoom.y /2 / 2),
          })  
        }
        else{
          setPosition({
            ...position,
            translateX:((positionZoom.x * -1) + props.goToZon.positionX + offSetWidth  + positionZoom.x /2 / 2) ,
            translateY:((positionZoom.y * -1) + props.goToZon.positionY + offSetHeigth + positionZoom.y /2 / 2),
          })
        }   
      }
      else{
        //Zoom Out
        if(props.nextZoon == 1 || props.nextZoon == 2){
          setPosition({
            ...position,
            translateX:((positionZoom.x * -1) + props.goToZon.positionX + offSetWidth /2 /2 /2  + positionZoom.x), 
            translateY:((positionZoom.y * -1) + props.goToZon.positionY + offSetHeigth /2 /2 /2 + positionZoom.y),
            scale: 1.9
          })
        }
        else{         
          setPosition({
            ...position,
            translateX:((positionZoom.x * -1) + props.goToZon.positionX + offSetWidth /2 /2 /2 /2 / + positionZoom.x), 
            translateY:((positionZoom.y * -1) + props.goToZon.positionY + offSetHeigth /2 /2 /2  /2 /2 + positionZoom.y),
            scale: 1.9
          })
        }
      }
    }
  },[props.goToZon, image])


  const onLoad = (e:any) => {
    setImage({
      width: e.target.naturalWidth,
      height: e.target.naturalHeight,
    });
  };

  const onMouseDown = (e:any) => {
      e.preventDefault();
      setPanning(true);

      setPosition({
        ...position,
        prevMouseX: e.clientX,
        prevMouseY: e.clientY,
      });
  };

  const mouseup = () => {
    setPanning(false);
  };

  const mousemove = (e:any) => {
    if (isPanning) {
      const deltaMouseX = e.clientX - position.prevMouseX;
      const deltaMouseY = e.clientY - position.prevMouseY;
      setPosition({
        ...position,
        translateX: position.translateX + deltaMouseX,
        translateY: position.translateY + deltaMouseY,
        prevMouseX: e.clientX,
        prevMouseY: e.clientY,
      });
    }
  };

  const onWheel = (eve:WheelEvent<HTMLDivElement>) => {
    eve.preventDefault();

    const containerRect = scrollViewerRef.current!.getBoundingClientRect();

    let zoomFactor:number =  eve.deltaY < 0 ? ZOOM_FACTOR_IN : ZOOM_FACTOR_OUT;
    let scaleCurrent:number =  position.scale * zoomFactor;

    if(scaleCurrent >= 2 && props.nextZoon != undefined)
    {
      props.setZoom(props.nextZoon, {
          height:image!.height ,
          width:image!.width ,
          positionX:position.translateX,
          positionY:position.translateY,
          scale:scaleCurrent,
          eclientX: eve.clientX,
          eclientY: eve.clientY,
          containerRectLeft: containerRect.left,
          containerRectTop:containerRect.top,
          containerRectwidth: containerRect.width,
          containerRectheight:containerRect.height,
          deltaY: eve.deltaY
      })
    }
    else if(scaleCurrent <= 1 && props.backZoon != undefined)
    {
          props.setZoom(props.backZoon, {
            height:image!.height ,
            width:image!.width ,
            positionX:position.translateX,
            positionY:position.translateY,
            scale:scaleCurrent,
            eclientX: eve.clientX,
            eclientY: eve.clientY,
            containerRectLeft: containerRect.left,
            containerRectTop:containerRect.top,
            containerRectwidth: containerRect.width,
            containerRectheight:containerRect.height,
            deltaY: eve.deltaY
        })
      }
    else
    {
      
      var xs = (eve.clientX - position.translateX) / position.scale;
      var ys = (eve.clientY - position.translateY) /  position.scale;
      
      var pointX = (eve.clientX - xs * scaleCurrent)
      var pointY = (eve.clientY - ys * scaleCurrent)

      setPosition({
        ...position,
        scale: scaleCurrent,
        translateX: pointX,
        translateY: pointY,
      });
    }
  }



  return (
      <div className={contentStyles.imageContent}
              style={{
                  height: props.showList ? "calc(93% - " + props.heightViwer.toString() + "px)" : "93%",
                  overflow:"hidden",
                  cursor:props.disablePanZoom?"zoom-in":"grab"
              }} ref={scrollViewerRef}
                onMouseDown={props.disablePanZoom == true?undefined:onMouseDown}
                onWheel={onWheel}
                onMouseMove={props.disablePanZoom == true?undefined:mousemove}
                onMouseUp={props.disablePanZoom == true?undefined:mouseup}
              >
                  <div
                      style={{
                      transform: `translate(${position.translateX}px, ${position.translateY}px) scale(${position.scale})`,
                      }}
                      id="zoom"        
                  >
                      <img
                          src={props.url}
                          onLoad={onLoad}
                          alt="zoom"
                      />
              </div>
          </div>
    )
}

const contentStyles = mergeStyleSets({
    imageContent:{
        position: "absolute",
        width:"100%",
        overflow:"auto",
        background: "#e1e1e1",
    }
})

export default Image;