import { Grid } from '@mui/material';

interface IRightSideHandler
{
    children: React.ReactNode;
}

export default function RightSideHandler({
    children
}: IRightSideHandler)
{
    return (
        <Grid item>
            { children }
        </Grid>
    )
}