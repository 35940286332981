import {Color} from "./interface/Color"

export interface PendencieConfig extends Color{
    colorCircle: string;
}

export const Pendencie: React.FC<PendencieConfig> = ({
    color,
    colorCircle
  }) => {
    return(
        <svg width="20.012" height="23.348" viewBox="0 0 20.012 23.348">
            <g id="Grupo_4" data-name="Grupo 4" transform="translate(-20 -561.652)">
                <path 
                    id="Caminho_26" 
                    data-name="Caminho 26" 
                    d="M18.453,13.23a5.559,5.559,0,1,0,5.559,5.559A5.561,5.561,0,0,0,18.453,13.23ZM19.565,3.224H16.03a3.322,3.322,0,0,0-6.271,0H6.224A2.23,2.23,0,0,0,4,5.447V22.124a2.23,2.23,0,0,0,2.224,2.224h6.793a7.5,7.5,0,0,1-1.579-2.224H6.224V5.447H8.447V8.783h8.894V5.447h2.224V11.1a7.816,7.816,0,0,1,2.224.667V5.447A2.23,2.23,0,0,0,19.565,3.224ZM12.894,5.447a1.112,1.112,0,1,1,1.112-1.112A1.115,1.115,0,0,1,12.894,5.447Z" 
                    transform="translate(16 560.652)" 
                    fill={color}
                />
                <circle 
                    id="Elipse_2" 
                    data-name="Elipse 2" 
                    cx="5.5" 
                    cy="5.5" 
                    r="5.5" 
                    transform="translate(29 574)" 
                    fill={colorCircle}
                />
            </g>
        </svg>

    )
  }