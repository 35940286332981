import { createUseStyles } from "react-jss";

export const itemContent = createUseStyles<"myDiv" | "default">({
  myDiv: {
    display: "none"
  },
  default: {
    position: "relative",
    boxSizing: "border-box",
    display: "flex",
    borderTop: "1px solid rgba(234, 234, 234, 1)",
    borderBottom: "1px solid rgba(234, 234, 234, 1)",
    borderRight: "1px solid rgba(234, 234, 234, 1)",
    borderLeft: "1px solid rgba(234, 234, 234, 1)",  
    textDecoration: "none",
    alignItems: "center",
    paddingLeft: "10px",
    minHeight: "40px",
    padding: "8px",
  }
})
