import React from "react";
import styles from '../TemporalityList.module.css'
import { useTranslation } from "react-i18next";

interface TemporalityListCompProps {
    ref?: React.Ref<HTMLLIElement>
  }

  const GridTitle: React.ForwardRefExoticComponent<TemporalityListCompProps> =
  React.forwardRef(({  }, ref: React.Ref<HTMLLIElement>) => {
   
    const { t, i18n } = useTranslation();

    return (
        <li className={styles.gridTemporalityTitleLi}>
          <div className={styles.gridTemporalityTitleDiv}>
            <span className={styles.gridTemporalitySpan}>
              {t("Nome.message")}
            </span>
          </div>
          <div className={styles.gridTemporalityTitleDiv}>
            <span className={styles.gridTemporalitySpan}>
              {t("Description.message")}
            </span>
          </div>
          <div className={styles.gridTemporalityTitleDiv}>
            <span className={styles.gridTemporalitySpan}>
              {t("dataArqchive.message")}
            </span>
          </div>
          <div style={{ borderRight:"0px"}} className={styles.gridTemporalityTitleDiv}>
            <span className={styles.gridTemporalitySpan}>
              Status
            </span>
          </div>
        </li>
    );
    
  });
  

  export default GridTitle;